<template>
  <labeled :label="label" class="d-block c-select__cont" :class="{'is-invalid': isInvalid}">
    <div class="cont">
      <div class="right va" v-if="suffix">
        <slot name="suffix">
          <span class="vam pr-3 c-input__suffix" v-html="suffix"></span>
        </slot>
      </div>
      <div class="left" :class="{'flex-shrink': suffix}">
        <c-select-ext class="c-select" :class="{suffix: suffix}"
          :disabled="disabled"
          :options="options"
          :placeholder="placeholder"
          :filter="filter"
          :filterable="true"
          :taggable="taggable"
          :pushTags="true"
          :createOption="createOption"
          :searchable="!!filter"
          :selectOnTab="!!filter || taggable"
          :clearSearchOnSelect="true"
          :getOptionLabel="getOptionLabel"
          label="title"
          :index="indexName"
          :clearable="clearable"
          :value="value"
          @input="input"
          ref="select">
        </c-select-ext>
      </div>
    </div>
  </labeled>
</template>

<script>
  // :searchable="!!search" @search="search"
  import _ from 'lodash';
  import ValidateMixin from './mixin-validate';
  export default {
    name: 'c-select',
    mixins: [ValidateMixin],
    inject: ['validation'],
    props: {
      value: [String, Number, Object],
      placeholder: String,
      options: {
        type: Array,
        default: () => []
      },
      // search: Function,
      disabled: Boolean,
      suffix: Boolean,
      filter: Function,
      getOptionLabel: Function,
      taggable: Boolean,
      valueAsTitle: Boolean,
      moptions: Array,
      clearable: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      label() {
        let item = _.find(this.options, item => item.value == this.value);
        // console.log(this.value, !!item, this.placeholder);
        return this.value || item ? this.placeholder : '';
      },
      indexName() {
        let first = this.options[0];
        return _.isPlainObject(first) ? 'value' : undefined; 
      }
    },
    methods: {
      input(value) {
        // console.log('asdaskdj', value);
        if (this.valueAsTitle) {
          let options = this.$refs.select.mutableOptions;
          // console.log(options);
          value = _.get(_.find(options, obj => _.get(obj, 'value') == value), 'title');
          // console.log(value);
        }
        if (_.isPlainObject(value)) {
          value = value.title;
        }
        if (this.moptions) {
          this.moptions.length = 0;
          [].push.call(this.moptions, ...this.$refs.select.mutableOptions);
        }
        this.$emit('input', value);
      },
      createOption(title) {
        // console.log(111111111);
        this.input(null);
        let $select = this.$refs.select;
        let options = $select.mutableOptions;
        let index = _.findIndex(options, option => option.value == -100);
        if (~index) {
          options.splice(index, 1);
        }
        let option = {
          value: -100,
          title
        };
        options.unshift(option);
        // console.log(option);
        // this.$refs.select.$emit('option:created', option);
        // console.log(title);
        this.input(-100);
        return option;
      }
    }
  }
</script>

<style lang="scss" scoped>
  .cont {
    .left {
      width: auto;
      overflow: hidden;
      /deep/ {
        .vs__selected-options {
          max-width: 100%;
        }
        .v-select:not(.searching) .selected-tag {
          display: block;
          padding-top: 6px;
        }
      }
    }
    .right {
      float: right;
      height: 33px;
    }
  }
  .c-select {
    width: 100%;
    overflow: hidden;
  }
  .c-select {
    position: static;
    min-height: 33px;
    padding-bottom: 0;
    &__cont {
      /deep/ {
        .col {
          position: static;
        }
      }
    }
    /deep/ {
      .c-option {
        &__title {
          font-size: 12px;
          line-height: 15px;
          color: #393939;

          text-overflow: ellipsis;
          overflow: hidden;
        }
        &__subtitle {
          font-size: 12px;
          line-height: 15px;
          color: #909498;
        }
      }
      .dropdown-menu {
        border: none;
        border-radius: 4px;
        &::-webkit-scrollbar {
          height: 4px;
          width: 4px;
        }
        &::-webkit-scrollbar-track {
          background: transparent; 
        }
        &::-webkit-scrollbar-thumb {
          background: #dcdcdc;
          border-radius: 8px;
        }
        .highlight > a {
          background: #ddd;
          color: #000;
        }
        a:hover {
          background: #ebebeb;
        }
        li > a {
          white-space: normal !important;
        }
      }
      .dropdown-toggle {
        border: none;
        padding: 0;
      }
      .vs__selected-options {
        height: 33px;
      }
      .selected-tag {
        margin: 0;
        padding: 0 8px;
        height: 31px;
        white-space: normal;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .form-control {
        margin: 0 !important;
        font-size: 14px !important;
        color: #2c2c2c !important;
        padding: 0 10px !important;

        font-family: Roboto;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
      }
      .open-indicator {
        i {
          pointer-events: none;
        }
        &:before {
          display: none;
        }
        // &:after {
        //   content: '';
        //   display: block;
        //   width: 12px;
        //   height: 6px;
        //   background: #909498;
        //   mask-image: url(~src/img/svg/icon-triangle-down.svg);
        //   mask-repeat: no-repeat;
        //   mask-position: center center;
        // }
      }
    }
    &.disabled {
      /deep/ {
        .selected-tag {
          color: #909498 !important;
        }
      }
    }
    &.suffix {
      /deep/ .open-indicator {
        display: none;
      }
    }
  }
</style>