<template>
  <nav class="nav-sidebar mb-3" role="nav" v-if="navs && navs.length">
    <ul>
      <li v-for="(item, index) in navs" :key="index" class="nav-sidebar-item" :class="{'nav-sidebar-item--active': item.active}">
        <a href="javascript:void(0)" @click="click(item.id)">{{item.name}}</a>
      </li>
    </ul>
  </nav>
</template>

<script>
  import _ from 'lodash'

  export default {
    props: {
      items: {
        type: Array,
        default: []
      },
      value: {
        type: [String, Number],
        default: 0
      }
    },
    data() {
      return {
        valueCurrent: this.value
      }
    },
    watch: {
      value(val) {
        this.valueCurrent = val
        this.click(val)
      }
    },
    computed: {
      navs() {
        return this.items.map((item, index) => {
          this.$set(this.items[index], 'active', this.valueCurrent == item.id ? true : false)
          return item
        })
      }
    },
    methods: {
      click(id) {
        this.valueCurrent = id

        this.navs.forEach(el => el.active = el.id == id ? true : false)
        this.$emit('nav-change', id)
      }
    },
    mounted() {
      this.valueCurrent = this.value
    }
  }
</script>