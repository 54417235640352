import Vue from 'vue';
import InputMask from 'inputmask';

let updateInputMask = (el, mask) => {
  if (mask) {
    if (el.nodeName == 'INPUT') {
      if (!el.inputmask) {
        // console.log('add mask', el)
        InputMask(mask).mask(el);
      }
    } else {
      let $el = el.querySelector('input');
      if (!$el.inputmask) {
        // console.log('add mask', el)
        InputMask(mask).mask($el);
      }
    }
  } else {
    // console.log('remove mask', el)
    if (el.nodeName == 'INPUT') {
      InputMask.remove(el);
    } else {
      let $el = el.querySelector('input');
      InputMask.remove($el);
    }
  }
}

Vue.directive('mask', {
  bind: (el, binding) => updateInputMask(el, binding.value),
  update: (el, binding) => updateInputMask(el, binding.value),
  unbind: (el, binding) => updateInputMask(el, false)
});