import Vue from 'vue';
import _ from 'lodash';
import moment from 'moment';

moment.locale('ru');

Vue.filter('dic', (value, dic) => {
  return _.get(_.find(dic, item => item.value == value), 'title') || '';
});

Vue.filter('abs', value => Math.abs(value));

Vue.filter('toFixed', (value, precision = 2) => {
  let pow = Math.pow(10, precision);
  return _.isNumber(value) ? Math.floor(value * pow) / pow : value;
});

Vue.filter('parts', (value, precision = 2) => {
  let num = parseFloat(value);
  let int = num | 0;
  let float = (num - int);
  let floatFixed = float.toFixed(precision);
  return _.chunk(('' + int).split('').reverse(), 3).map(a => a.reverse().join('')).reverse().join(' ') + (float ? ('' + floatFixed).slice(1) : '');
});

Vue.filter('date', (value, format) => {
  let date = moment(value);
  return date.isValid() ? date.format(format || 'DD.MM.YYYY') : '';
});

Vue.filter('fulldate', (value, format) => {
  // console.log(value)
  if (value == null) return ' '
  return moment(value).format(format || 'DD MMMM YYYY');
});

var counterDefault = num => {
  if (num % 10 == 1 && num % 100 != 11) return 'one';
  if (num % 10 === 0 || [5, 6, 7, 8, 9].indexOf(num % 10) != -1 || [11, 12, 13, 14].indexOf(num % 100) != -1) return 'many';
  if ([2, 3, 4].indexOf(num % 10) != -1 && [12, 13, 14].indexOf(num % 100) == -1) return 'few';
  return 'other';
};

Vue.filter('plural', (value, forms) => {
  // console.log('azaza', value, forms);
  return (_.get(forms, 'base') || '') + (_.get(forms, counterDefault(value)) || '');
});