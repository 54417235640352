<template>
  <div class="plusminus bg-gray-200" :class="{'is-invalid': isInvalid}">
    <div class="row mx-0 flex-nowrap">
      <div class="col text-gray-400 va">
        <div class="vam f-12">
          {{placeholder}}
        </div>
      </div>
      <div class="col col-auto p-0">
        <div class="row mx-0">
          <div class="col-auto p-0">
            <div class="minus" :class="{disabled: !canMinus}" @click="minus"></div>
          </div>
          <div class="col-auto px-4 f-18 va">
            <div class="vam">
              {{value || 0}}
            </div>
          </div>
          <div class="col col-auto p-0" :class="{disabled: !canPlus}" @click="plus">
            <div class="plus"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import _ from 'lodash';
  import ValidateMixin from './mixin-validate';

  export default {
    mixins: [ValidateMixin],
    inject: ['validation'],
    props: {
      value: [String, Number, Boolean],
      placeholder: String,
      disabled: Boolean,
      min: Number,
      max: Number
    },
    computed: {
      canPlus() {
        return !this.max || (this.max && this.value < this.max);
      },
      canMinus() {
        return !this.min || (this.min && this.value > this.min);
      }
    },
    methods: {
      plus() {
        if (this.canPlus) {
          this.input(this.value + 1);
        }
      },
      minus() {
        if (this.canMinus) {
          this.input(this.value - 1);
        }
      },
      input(value) {
        this.$emit('input', value);
      }
    }
  }
</script>

<style lang="scss" scoped>
  .plusminus {
    border-radius: 3px;
  }
  .plus, .minus {
    width: 35px;
    height: 35px;
    background: #ca1226;
    mask-size: contain;
    mask-position: center center;
    mask-repeat: no-repeat;
    &.disabled {
      opacity: .5;
    }
  }
  .plus {
    mask-image: url(~src/img/svg/icon-plus-quad.svg);
  }
  .minus {
    mask-image: url(~src/img/svg/icon-minus-quad.svg);
  }
</style>