<template lang="pug">
  div
    h3
      | Регистрация
    c-row.mt-5.mt-md-3
      c-col.col-12.col-lg-4.col-sm-6
        preloader(over, v-if="sending")
        template(v-if="!success")
          c-form
            c-input.mt-1(placeholder="ФИО", v-model="user.full_name")
            c-input.mt-1(placeholder="Наименование организации", v-model="user.company")
            c-input.mt-1(placeholder="Email", v-model="user.email")
            c-input.mt-1(placeholder="Пароль", v-model="user.password", :suffix="true", :input-type="showPass ? 'text' : 'password'")
              template(slot="suffix")
                div.vam.hoverable.f-0.pr-2.text-gray-400(@click="showPass = !showPass")
                  i.f-18.lh-0(:class="{'icon-eye': !showPass, 'icon-eye-disabled': showPass}")
            c-input.mt-1(placeholder="Подтверждение пароля", v-model="user.password2", :suffix="true", :input-type="showPass ? 'text' : 'password'")
              template(slot="suffix")
                div.vam.hoverable.f-0.pr-2.text-gray-400(@click="showPass = !showPass")
                  i.f-18.lh-0(:class="{'icon-eye': !showPass, 'icon-eye-disabled': showPass}")
            .text-center.mt-3.f-0
              .d-inline-block
                vue-recaptcha(sitekey="6LehNK0UAAAAAClNzy4YgJMi-BFlDJNRjhuEQuPN", @verify="captchaVerified", ref="captcha")
            //- c-row.mt-1
              //- c-col.col-auto
              //-   | картинка
              //- c-col
              //-   c-input(placeholder="Число с картинки", v-model="user.code")
            .shadow-box.mt-3
              c-checkbox(v-model="user.agree")
                | Я соглашаюсь с 
                .d-inline-block.text-primary(@click="openAgreement()")
                  | условиями
                |  сервиса
            .alert.alert-danger.mb-0.f-12.d-block.mt-3(v-if="errors")
              div(v-for="(error, index) in errors", :key="index")
                | {{error}}
            c-button.mt-3.w-100(type="submit", :disabled="$v.user.$invalid || sending", @click="register")
              | Зарегистрироваться
            div.mt-3.text-center
              | У вас уже есть аккаунт? 
              router-link(:to="{name: 'login'}")
                | Войти.
        template(v-if="success")
          .alert.alert-success.d-block
            .fw-500.mb-2
              | Вы успешно зарегистрированы
            | На указанный Email вам направлено письмо со ссылкой и инструкциями для активации вашей учетной записи.
          c-button.mt-2.w-100(:to="{name: 'login'}")
            | Войти
</template>

<script>
  import {required, email} from 'vuelidate/lib/validators';
  import api from '@/js/api';
  export default {
    data: () => ({
      user: {
        full_name: null,
        company: null,
        email: null,
        password: null,
        password2: null,
        code: null,
        agree: false,
        captcha: false
      },
      sending: false,
      showPass: false,
      errors: null,
      success: false
    }),
    provide() {
      return {
        validation: this.$v
      };
    },
    validations() {
      return {
        user: {
          full_name: {required},
          company: {required},
          email: {required, email},
          password: {required, equal: value => value == this.user.password2},
          password2: {required, equal: value => value == this.user.password},
          // code: {required},
          agree: {required, isTrue: value => value},
          captcha: {check: value => !!value}
        }
      }
    },
    methods: {
      openAgreement() {
        window.open('/media/documents/%D0%A3%D1%81%D0%BB%D0%BE%D0%B2%D0%B8%D1%8F_%D1%81%D0%B5%D1%80%D0%B2%D0%B8%D1%81%D0%B0_%D0%A1%D0%BE%D0%B3%D0%BB%D0%B0%D1%81%D0%B8%D0%B5_%D0%BD%D0%B0_%D0%BE%D0%B1%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BA%D1%83_%D0%BF%D0%B5%D1%80%D1%81%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D1%85_%D0%B4%D0%B0%D0%BD%D0%BD%D1%8B%D1%85_skif-cargo.ru.pdf', '_blank');
      },
      captchaVerified(value) {
        this.user.captcha = value;
      },
      register() {
        this.sending = true;
        let code = this.$route.params.tokenid;
        let userData = _.pick(this.user, ['full_name', 'company', 'email', 'password', 'captcha']);
        if (code) {
          userData.code = code;
        }
        api.registerUser(userData)
          .then(response => {
            let data = _.get(response, 'data') || {};
            this.$notify({
              group: 'foo',
              clean: true
            })
            // console.log(data);
            let errorsData = _.get(data, 'errors');
            let errors = _.isString(errorsData) ? [errorsData] : _.flatten(_.map(errorsData, item => item));
            if (errors.length) {
              this.errors = errors;
            } else {
              this.errors = null;
            }
            if (data.status == 200) {
              this.success = true;
            }
          }, err => {
            if (err.response.status === 504) {
              this.$notify({
                group: 'foo',
                type: 'error',
                duration: -1,
                title: 'Ошибка',
                text: 'Сервер временно недоступен'
              })
            }
          })
          .finally(() => {
            this.sending = false;
            this.user.captcha = false;
            this.$refs.captcha.reset();
          });
      }
    }
  }
</script>