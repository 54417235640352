<template>
  <div>
    <c-row>
        <c-col class="col-12 col-md-4">
          <c-input class="mb-2" placeholder="Имя" v-model="form.name" />
          <c-input class="mb-2" placeholder="E-mail" v-model="form.email" />
          <c-input class="mb-2" placeholder="Телефон" v-model="form.phone" v-mask="{mask: '+99999999999'}" />
          <c-input class="mb-2" placeholder="Город" v-model="form.city" />
          <c-textarea v-model="form.message" class="form-control mb-2" placeholder="Сообщение"></c-textarea>
        </c-col>
      </c-row>
      <c-row class="mt-4">
        <c-col>
          <el-upload
            :action="api.uploudFileUrl"
            :limit="3"
            ref="upload"
            :on-success="handleSuccess"
            :on-remove="handleRemove"
            >
            <span class="el-upload__btn" v-if="form.files.length < 3" slot="trigger"><i class="icon-attach-file"></i> Прикрепить файл</span>
          </el-upload>
        </c-col>
      </c-row>
      <c-row class="mt-4">
        <c-col>
          <c-button @click="bSave">Отправить</c-button>
        </c-col>
      </c-row>
  </div>
</template>

<script>
  import api from '@/js/api'
  import { required, email } from 'vuelidate/lib/validators';
  import { clone } from 'lodash'

  const MODEL = {
    vacancy_id: null,
    name: '',
    email: '',
    phone: '',
    city: '',
    message: '',
    files: []
  }

  export default {
    props: {
      currentModel: {
        type: Object,
        required: true,
        default: () => ({})
      }
    },
    data: () => ({
      api,
      form: clone(MODEL)
    }),
    provide() {
      return {
        validation: this.$v
      }
    },
    validations() {
      return {
        form: {
          name: {required},
          email: {required, email},
          city: {required},
          message: {required}
        }
      }
    },
    methods: {
      clear() {
        this.form = clone(MODEL)
      },
      bSave() {
        this.$v.form.$touch()
        if (this.$v.form.$invalid) return false
        if (!this.currentModel.id) {
          this.$emit('error', 'Вакансия не найдена')
          return false
        }
        this.form.vacancy_id = this.currentModel.id

        this.$emit('loading', true)
        api.vacancyForm(this.form).then(res => {
          if (res.status === 200) {
            this.form = clone(MODEL)
            this.$emit('success', 'Отклик отправлен')
            window.location.href = `/vacancyConfirm${DEBUG ? '.html' : '/'}`
          } else {
            this.$emit('error', 'Ошибка, сообщение не отправлено.')
          }
        }, error => {
          this.$emit('error', error)
        }).finally(() => {
          this.$emit('loading', false)
        })
      },
      convertFileList() {
        return this.$refs.upload.uploadFiles.map(item => ({ uid: item.response.uid, name: item.name }))
      },
      handleSuccess(res, file) {
        this.form.files = this.convertFileList()
      },
      handleRemove(file, fileList) {
        api.deleteFile(file.response.uid).then(res => {
          if (res.status === 200) {
            this.$emit('error', '')
            this.form.files = this.convertFileList()
          } else {
            this.$emit('error', 'Ошибка, удаления файла')
          }
        }, error => {
          this.$emit('error', error)
        })
      },
    }
  };
</script>