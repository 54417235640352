// import "core-js/stable";
// import "regenerator-runtime/runtime";

import _ from 'lodash';
import Vue from 'vue';

import './core/vue-router';
import './core/components';
// import './core/vue-select';
import './core/vue-inputmask';
import './core/vuelidate';
import './core/vuex';
import './core/filters';
import './core/vue-datepicker';
import './core/ui-element';
import './core/vue-youtube';
import './core/vue-recaptcha';
import './core/vue-notify';
import './core/bootstrap';

Vue.config.productionTip = false;

import CalculatorApp from './calculator/app.vue';
import TrackingApp from './tracking/app.vue';
import FeedbackApp from './feedback/app.vue';
import DocumentsApp from './documents/app.vue';
import InstructionsApp from './instructions/app.vue';
import CabinetApp from './cabinet/app.vue';
import VacancyApp from './vacancy/app.vue';
import ContactsApp from './contacts/app.vue';

import VTooltip from 'v-tooltip'

Vue.use(VTooltip)

const PATHS = {
  calculator: {
    paths: [/^\/calculator/],
    app: CalculatorApp,
  },
  tracking:{
    paths: [/^\/tracking/],
    app: TrackingApp,
  },
  '/feedback': FeedbackApp,
  // '/documents': DocumentsApp,
  documents: {
    paths: [/^\/documents/],
    app: DocumentsApp
  },
  // '/instructions': InstructionsApp,
  instructions: {
    paths: [/^\/instructions/],
    app: InstructionsApp
  },
  cabinet: {
    paths: ['/cabinet', '/login', '/register', '/forgot-password', /^\/request/],
    app: CabinetApp
  },
  vacancy: {
    paths: [/^\/vacancy/],
    app: VacancyApp
  },
  contacts: {
    paths: [/^\/contacts/],
    app: ContactsApp
  }
};

let pathname = location.pathname;
let checkPath = path => {
  if (_.isString(path)) {
    return path + (DEBUG ? '.html' : '') == pathname || path + '/' == pathname;
  }
  if (_.isRegExp(path)) {
    return path.test(pathname);
  }
};
let App = _.filter(PATHS, (data, path) => {
  if (data.paths) {
    return _.find(data.paths, checkPath);
  }
  return checkPath(path);
}).map(data => data.app || data)[0] || CabinetApp;

let inst;
let $app = document.querySelector('#app');
if (App && $app) {
  inst = new Vue({
    components: {
      App
    },
    router: App.router || undefined,
    template: '<App/>'
  }).$mount('#app');
}

export default {
  current: App,
  cabinet: CabinetApp,
  inst
};