<template>
  <div class="c-tip">
    <div class="cont">
      <slot name="subject"></slot>
    </div>
    <div class="c-tip__text" :class="direction">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      direction: {
        type: String,
        default: 'right'
      }
    }
  };
</script>