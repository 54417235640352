var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loading ? _c("preloader", { staticClass: "mt-5" }) : _vm._e(),
      !_vm.loading
        ? _c(
            "c-row",
            [
              _c(
                "c-col",
                { staticClass: "col-12 col-md-6 col-lg-4" },
                [
                  _c("div", { staticClass: "mb-2 f-18 text-gray-800 fw-500" }, [
                    _vm._v("Профиль")
                  ]),
                  _c("c-input", {
                    staticClass: "mt-1",
                    attrs: { placeholder: "ФИО" },
                    model: {
                      value: _vm.user.full_name,
                      callback: function($$v) {
                        _vm.$set(_vm.user, "full_name", $$v)
                      },
                      expression: "user.full_name"
                    }
                  }),
                  _c("c-input", {
                    staticClass: "mt-1",
                    attrs: { placeholder: "Наименование организации" },
                    model: {
                      value: _vm.user.company,
                      callback: function($$v) {
                        _vm.$set(_vm.user, "company", $$v)
                      },
                      expression: "user.company"
                    }
                  }),
                  _c("c-input", {
                    directives: [
                      {
                        name: "mask",
                        rawName: "v-mask",
                        value: { mask: "+99999999999" },
                        expression: "{mask: '+99999999999'}"
                      }
                    ],
                    staticClass: "mt-1",
                    attrs: { placeholder: "Номер телефона" },
                    model: {
                      value: _vm.user.phone,
                      callback: function($$v) {
                        _vm.$set(_vm.user, "phone", $$v)
                      },
                      expression: "user.phone"
                    }
                  }),
                  _c("c-input", {
                    staticClass: "mt-1",
                    attrs: { placeholder: "Email" },
                    model: {
                      value: _vm.user.email,
                      callback: function($$v) {
                        _vm.$set(_vm.user, "email", $$v)
                      },
                      expression: "user.email"
                    }
                  }),
                  _vm.profileSuccess
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "alert alert-success mb-0 f-12 d-block mt-3"
                        },
                        [_vm._v(_vm._s(_vm.profileSuccess))]
                      )
                    : _vm._e(),
                  _vm.profileError
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "alert alert-danger mb-0 f-12 d-block mt-3"
                        },
                        [_vm._v(_vm._s(_vm.profileError))]
                      )
                    : _vm._e(),
                  _c(
                    "c-button",
                    {
                      staticClass: "mt-3",
                      attrs: {
                        disabled: _vm.$v.user.$invalid || _vm.savingProfile
                      },
                      on: { click: _vm.saveProfile }
                    },
                    [_vm._v("Сохранить")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "mt-4 mt-md-5 mb-2 f-16 text-gray-800 fw-500"
                    },
                    [_vm._v("Изменение пароля")]
                  ),
                  _c("c-input", {
                    staticClass: "mt-1",
                    attrs: {
                      placeholder: "Старый пароль",
                      "input-type": "password"
                    },
                    model: {
                      value: _vm.pass.old_password,
                      callback: function($$v) {
                        _vm.$set(_vm.pass, "old_password", $$v)
                      },
                      expression: "pass.old_password"
                    }
                  }),
                  _c("c-input", {
                    staticClass: "mt-1",
                    attrs: {
                      placeholder: "Новый пароль",
                      "input-type": "password"
                    },
                    model: {
                      value: _vm.pass.password1,
                      callback: function($$v) {
                        _vm.$set(_vm.pass, "password1", $$v)
                      },
                      expression: "pass.password1"
                    }
                  }),
                  _c("c-input", {
                    staticClass: "mt-1",
                    attrs: {
                      placeholder: "Подтверждение пароля",
                      "input-type": "password"
                    },
                    model: {
                      value: _vm.pass.password2,
                      callback: function($$v) {
                        _vm.$set(_vm.pass, "password2", $$v)
                      },
                      expression: "pass.password2"
                    }
                  }),
                  _vm.passwordSuccess
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "alert alert-success mb-0 f-12 d-block mt-3"
                        },
                        [_vm._v(_vm._s(_vm.passwordSuccess))]
                      )
                    : _vm._e(),
                  _vm.passwordError
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "alert alert-danger mb-0 f-12 d-block mt-3"
                        },
                        [_vm._v(_vm._s(_vm.passwordError))]
                      )
                    : _vm._e(),
                  _c(
                    "c-button",
                    {
                      staticClass: "mt-3",
                      attrs: {
                        disabled: _vm.$v.pass.$invalid || _vm.savingPassword
                      },
                      on: { click: _vm.changePassword }
                    },
                    [_vm._v("Сохранить")]
                  )
                ],
                1
              ),
              _c("c-col", { staticClass: "mt-4 mt-md-0" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-md-inline-block rounded bg-gray-200 py-3 px-4"
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "mb-3 f-16 text-gray-800 fw-500" },
                      [_vm._v("Права доступа")]
                    ),
                    _c("div", { staticClass: "f-12 text-gray-400" }, [
                      _vm.user.right_to_edit_rights
                        ? _c("div", { staticClass: "mt-1" }, [
                            _vm._v("Назначение прав пользователей")
                          ])
                        : _vm._e(),
                      _vm.user.right_to_create_order
                        ? _c("div", { staticClass: "mt-1" }, [
                            _vm._v("Создание новой заявки")
                          ])
                        : _vm._e(),
                      _vm.user.right_to_see_order_docs
                        ? _c("div", { staticClass: "mt-1" }, [
                            _vm._v("Доступ к документам заявки")
                          ])
                        : _vm._e(),
                      _vm.user.right_to_add_payer
                        ? _c("div", { staticClass: "mt-1" }, [
                            _vm._v("Добавление плательщиков")
                          ])
                        : _vm._e()
                    ])
                  ]
                )
              ])
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }