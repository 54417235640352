<template>
  <div class="labeled">
    <div class="labeled__cont">
      <div class="labeled__label">{{label}}</div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['label']
  }
</script>