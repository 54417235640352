var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-5 mt-md-0" },
    [
      _c("div", { staticClass: "mb-2 f-18 text-gray-800 fw-500" }, [
        _vm._v("Права пользователей")
      ]),
      _c(
        "c-table",
        { staticClass: "mt-3", attrs: { data: _vm.list } },
        [
          _c("c-column", { attrs: { prop: "email", label: "Пользователь" } }),
          _c("c-column", {
            attrs: { prop: "rights", label: "Назначение прав пользователей" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("c-checkbox", {
                      staticClass: "d-inline-block d-md-block",
                      model: {
                        value: scope.row.rights.rights,
                        callback: function($$v) {
                          _vm.$set(scope.row.rights, "rights", $$v)
                        },
                        expression: "scope.row.rights.rights"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("c-column", {
            attrs: { prop: "request", label: "Создание новой заявки" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("c-checkbox", {
                      staticClass: "d-inline-block d-md-block",
                      model: {
                        value: scope.row.rights.request,
                        callback: function($$v) {
                          _vm.$set(scope.row.rights, "request", $$v)
                        },
                        expression: "scope.row.rights.request"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("c-column", {
            attrs: { prop: "requestDocs", label: "Доступ к документам заявки" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("c-checkbox", {
                      staticClass: "d-inline-block d-md-block",
                      model: {
                        value: scope.row.rights.requestDocs,
                        callback: function($$v) {
                          _vm.$set(scope.row.rights, "requestDocs", $$v)
                        },
                        expression: "scope.row.rights.requestDocs"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("c-column", {
            attrs: { prop: "payers", label: "Добавление плательщиков" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("c-checkbox", {
                      staticClass: "d-inline-block d-md-block",
                      model: {
                        value: scope.row.rights.payers,
                        callback: function($$v) {
                          _vm.$set(scope.row.rights, "payers", $$v)
                        },
                        expression: "scope.row.rights.payers"
                      }
                    })
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }