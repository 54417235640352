var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._m(0),
      _vm._v(" "),
      _vm.error
        ? _c("div", { staticClass: "alert alert-danger" }, [
            _vm._v("\n    " + _vm._s(_vm.error) + "\n  ")
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.loading ? _c("preloader", { attrs: { over: "" } }) : _vm._e(),
      _vm._v(" "),
      !_vm.error && !_vm.loading
        ? [
            _c(
              "div",
              {
                staticClass: "nav-mobile-title mb-3",
                on: {
                  click: function($event) {
                    return _vm.$router.go(-1)
                  }
                }
              },
              [
                _c("div", { staticClass: "nav-mobile-title--sub" }, [
                  _vm._v("Категория")
                ]),
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.category.name || "Не названия") +
                    "\n    "
                )
              ]
            ),
            _vm._v(" "),
            _vm.content
              ? [
                  _c(
                    "div",
                    { staticClass: "vacancy-card vacancy-card--mobile" },
                    [
                      _c("div", { staticClass: "vacancy-card-header" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "vacancy-card-header__name f-14 f-md-16 fw-500 mb-3"
                          },
                          [_vm._v(_vm._s(_vm.content.name))]
                        ),
                        _vm._v(" "),
                        _c(
                          "ul",
                          { staticClass: "vacancy-card-header__actions" },
                          [
                            _vm.content.city_name
                              ? _c("li", [
                                  _c("i", {
                                    staticClass: "icon-placeholder-copy"
                                  }),
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.content.city_name) +
                                      "\n            "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.content.schedule
                              ? _c("li", [
                                  _c("i", { staticClass: "icon-time-line" }),
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.content.schedule) +
                                      "\n            "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.content.wage
                              ? _c("li", [
                                  _c("i", {
                                    staticClass: "icon-credit-card-line"
                                  }),
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.content.wage) +
                                      "\n            "
                                  )
                                ])
                              : _vm._e()
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "vacancy-card-body" },
                        [
                          _vm.content.responsibility
                            ? [
                                _c("div", { staticClass: "fw-500 mb-2 mt-3" }, [
                                  _vm._v(
                                    "\n              Обязанности:\n            "
                                  )
                                ]),
                                _vm._v(" "),
                                _c("div", {
                                  staticClass: "vacancy-card-body__text",
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.content.responsibility
                                    )
                                  }
                                })
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.content.requirements
                            ? [
                                _c("div", { staticClass: "fw-500 mb-2 mt-3" }, [
                                  _vm._v(
                                    "\n              Требования:\n            "
                                  )
                                ]),
                                _vm._v(" "),
                                _c("div", {
                                  staticClass: "vacancy-card-body__text",
                                  domProps: {
                                    innerHTML: _vm._s(_vm.content.requirements)
                                  }
                                })
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.content.conditions
                            ? [
                                _c("div", { staticClass: "fw-500 mb-2 mt-3" }, [
                                  _vm._v(
                                    "\n              Условия:\n            "
                                  )
                                ]),
                                _vm._v(" "),
                                _c("div", {
                                  staticClass: "vacancy-card-body__text",
                                  domProps: {
                                    innerHTML: _vm._s(_vm.content.conditions)
                                  }
                                })
                              ]
                            : _vm._e()
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "vacancy-card-footer mt-5" },
                        [
                          _c(
                            "c-button",
                            {
                              staticClass: "w-100",
                              on: {
                                click: function($event) {
                                  return _vm.$router.push({
                                    name: "mobile.vacancy.send",
                                    params: { id: _vm.content.id }
                                  })
                                }
                              }
                            },
                            [_vm._v("Откликнуться")]
                          )
                        ],
                        1
                      )
                    ]
                  )
                ]
              : _c("div", { staticClass: "alert alert-danger" }, [
                  _vm._v("\n      Нет данных\n    ")
                ])
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", [
      _c("span", { staticClass: "d-inline-block mb-3" }, [
        _vm._v("\n      Вакансии\n    ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }