var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-easy-dialog",
    {
      attrs: { "backdrop-class": "backdrop" },
      model: {
        value: _vm.isOpen,
        callback: function($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen"
      }
    },
    [
      _c("div", { staticClass: "dialog" }, [
        _c("div", { staticClass: "dialog-header" }, [
          _vm._v("Копировать заявку №" + _vm._s(_vm.order.OrderShortNo))
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "dialog-body" },
          [
            _c(
              "c-checkbox",
              {
                model: {
                  value: _vm.copyConf.direction,
                  callback: function($$v) {
                    _vm.$set(_vm.copyConf, "direction", $$v)
                  },
                  expression: "copyConf.direction"
                }
              },
              [_vm._v("Направление")]
            ),
            _vm._v(" "),
            _c(
              "c-checkbox",
              {
                model: {
                  value: _vm.copyConf.cargoParameters,
                  callback: function($$v) {
                    _vm.$set(_vm.copyConf, "cargoParameters", $$v)
                  },
                  expression: "copyConf.cargoParameters"
                }
              },
              [_vm._v("Параметры груза")]
            ),
            _vm._v(" "),
            _c(
              "c-checkbox",
              {
                model: {
                  value: _vm.copyConf.services,
                  callback: function($$v) {
                    _vm.$set(_vm.copyConf, "services", $$v)
                  },
                  expression: "copyConf.services"
                }
              },
              [_vm._v("Услуги")]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "dialog-footer" },
          [
            _c(
              "c-button",
              {
                staticClass: "white",
                on: {
                  click: function($event) {
                    return _vm.$emit("change", false)
                  }
                }
              },
              [_vm._v("\n        Отмена\n      ")]
            ),
            _vm._v(" "),
            _c(
              "c-button",
              {
                staticClass: "mt-3",
                attrs: { disabled: _vm.disable },
                on: {
                  click: function($event) {
                    return _vm.openCalc()
                  }
                }
              },
              [_vm._v("\n        Копировать\n      ")]
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }