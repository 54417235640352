<template lang="pug">
  .d-inline-block.file-icon
    .file-icon-text
      | {{title}}
</template>

<script>
export default {
  props: {
    title: String
  }
}
</script>

<style lang="scss" scoped>
  .file-icon {
    position: relative;
    width: 28px;
    height: 38px;
    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-image: url('~@/img/svg/icon-file-big.svg');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      opacity: 0.3;
    }
  }
  .file-icon-text {
    position: absolute;
    top: 58%;
    left: 50%;
    transform: translate(-50%, -50%);

    font-size: 8px;
    line-height: 8px;
    text-transform: uppercase;
    color: #96999a;
  }
</style>
