<template lang="pug">
  .mb-5
    settings-menu
    c-row.mt-3
      c-col
        .f-18.fw-500.text-gray-800
          | Доступные плательщики
      c-col.col-auto.floating-pane
        c-button.w-100.w-md-auto(:to="{name: 'payers-create'}")
          | Новый плательщик
    paginate.mt-3.mt-md-2(:load="load")
      .mx-n3.mx-md-0.mt-md-3.bg-md-gray-100.px-3.py-3.border-bottom.border-top(slot-scope="{item, index}", @click="edit(item.AgentID)", :class="{'border-md-bottom-0': !item.DefaultPayer, 'border-md-top-0': !item.DefaultPayer}")
        .mt-1.mb-4
          c-checkbox.mt-2(v-model="item.mainPayer", :disabled="item.mainPayer" @input="changeMainPayer(item)")
            | Основной плательщик
        c-row.flex-nowrap
          c-col
            .f-10.fw-500.text-muted.text-uppercase.d-none.d-md-block
              | Название
            .mt-md-2.f-16.text-gray-800
              | {{item.Name}}
            .mt-1.f-14.fw-300.text-gray-800(:class="{'text-transparent': item.PassportType}")
              | {{item.Contact}} &nbsp;
          c-col.col-auto.d-block.d-md-none
            .h-100.fa
              i.icon-arrow-chevron.r-270.text-muted
          c-col.col-3.d-none.d-md-block
            .f-10.fw-500.text-muted.text-uppercase
              | Способы связи
            .mt-2.f-14.text-gray-800(v-if="item.Phone")
              | {{item.Phone}}
            .mt-1.f-12.fw-300.text-gray-800(v-if="item.Email")
              | {{item.Email}}
          c-col.col-3.d-none.d-md-block
            template(v-if="item.PassportType")
              .f-10.fw-500.text-muted.text-uppercase
                | Тип документа
              .mt-2.f-14.text-gray-800
                | {{item.PassportType}}
              .mt-1.f-12.fw-300.text-gray-800
                | {{item.PassportS}} {{item.PassportN}}
            template(v-else)
              .f-10.fw-500.text-muted.text-uppercase
                | Данные
              .mt-2.f-14.text-gray-800
                | ИНН {{item.Inn}}
              .mt-1.f-12.fw-300.text-gray-800
                | КПП {{item.Kpp}}
          c-col.col-3.d-none.d-md-block
            template(v-if="item.AddEmail")
              .f-10.fw-500.text-muted.text-uppercase
                | E-mail уведомлений
              c-checkbox.mt-2(v-model="item.AddEmailActive", :disabled="true")
                | {{item.AddEmail}}
        c-row.mt-3.flex-nowrap.d-none.d-md-flex
          c-col
            .f-10.fw-500.text-muted.text-uppercase
              | Адрес
            .mt-2.f-14.text-gray-800
              | {{item.TownName}}
            .mt-1.f-12.fw-300.text-gray-800
              | {{item.Address}}
          c-col.col-3
            .f-10.fw-500.text-muted.text-uppercase
              | Форма собственности
            .mt-2.f-14.text-gray-800
              | {{item.AgentForm}}
          c-col.col-3
            .f-10.fw-500.text-muted.text-uppercase
              | Форма оплаты
            .mt-2.f-14.text-gray-800
              | {{item.PayForm}}
          c-col.col-3
            template(v-if="item.AddPhone")
              .f-10.fw-500.text-muted.text-uppercase
                | Телефон уведомлений
              c-checkbox.mt-2(v-model="item.AddPhoneActive", :disabled="true")
                | {{item.AddPhone}}

        .text-right.mr-2.d-none.d-md-block
          .mt-2.pt-2.text-primary.f-12.fw-500.text-nowrap.hoverable(@click="$router.push({name: 'payers-edit', params: {id: item.AgentID}})")
            i.icon-pencil.mr-2
            .d-none.d-md-inline-block
              | Редактировать
</template>

<script>
  import api from '@/js/api';

  export default {
    data:{
      list: []
    },
    provide() {
      return {
        validation: this.$v
      };
    },
    methods: {
      load(page = 1) {
        let perPage = window.innerWidth <= 768 ? 10 : 4;
        return api.getPayers(page, perPage)
          .then(({data}) => {
            let items = _.get(data, 'Page') || [];
            items.forEach(item => item.mainPayer = !!item.DefaultPayer)
            this.$notify({
              group: 'foo',
              clean: true
            })
            this.list = items
            return {
              items,
              pageInfo: {
                itemsCount: data.ItemsCount,
                pagesCount: data.PageCount,
                currentPage: data.CurrPage
              }
            };
          }, err => {
            if (err.response.status === 504) {
              this.$notify({
                group: 'foo',
                type: 'error',
                duration: -1,
                title: 'Ошибка',
                text: 'Сервер временно недоступен'
              })
            }
          });
      },
      edit(id) {
        if (window.innerWidth <= 768) {
          this.$router.push({name: 'payers-edit', params: {id}});
        }
      },
      changeMainPayer({AgentID}) {
        this.list = this.list.map(i => {
          i.mainPayer = i.AgentID === AgentID
          return i
        })
        api.setDefaultPayer(AgentID)
      },
    }
  };
</script>

<style lang="scss" scoped>
  .border-red{
    border-color: #ca1226 !important;
  }
  .floating-pane {
    @media (max-width: 768px) {
      position: fixed;
      bottom: 64px;
      left: 0;
      right: 0;
    }
  }
  .icon-pencil {
    display: inline-block;
    width: 16px;
    height: 16px;
    background: #ca1226;
    mask-image: url(~src/img/svg/icon-pencil.svg);
    mask-repeat: no-repeat;
    mask-position: center center;
    mask-size: cover;
  }
</style>