<template lang="pug">
  div
    h3
      | Вход
    c-row.mt-3
      c-col.col-12.col-lg-4.col-sm-6
        preloader(over, v-if="sending")
        c-form
          c-input.mt-1(placeholder="Email", v-model="user.email")
          c-input.mt-1(placeholder="Пароль", v-model="user.password", :suffix="true", :input-type="showPass ? 'text' : 'password'")
            template(slot="suffix")
              div.vam.hoverable.f-0.pr-2.text-gray-400(@click="showPass = !showPass")
                i.f-18.lh-0(:class="{'icon-eye': !showPass, 'icon-eye-disabled': showPass}")
          .alert.alert-danger.mb-0.f-12.d-block.mt-3(v-if="errors")
            | {{errors}}
          div.mt-3
            router-link.text-primary(:to="{name: 'forgot-password'}")
              | Забыли пароль?
          c-button.mt-3.w-100(type="submit", :disabled="$v.user.$invalid", @click="login")
            | Войти
        div.mt-3
          | У Вас еще нет аккаунта? 
          router-link.text-primary(:to="{name: 'register'}")
            | Зарегистрироваться
          | .
</template>

<script>
  import {required} from 'vuelidate/lib/validators';
  export default {
    data: () => ({
      user: {
        email: null,
        password: null
      },
      showPass: false,
      sending: false,
      errors: null
    }),
    provide() {
      return {
        validation: this.$v
      };
    },
    validations() {
      return {
        user: {
          email: {required},
          password: {required}
        }
      }
    },
    methods: {
      login() {
        if (!this.sending) {
          this.sending = true;
          this.$viewer.login(this.user)
            .then(response => {
              let data = _.get(response, 'data') || {};
              this.$notify({
                group: 'foo',
                clean: true
              })
              if (data.errors) {
                this.errors = data.errors;
              } else {
                this.errors = null;
              }
              if (!data.errors && data.status == 200) {
                this.$router.push({name: 'request-list'});
              }
              // console.log('success');
            }, err => {
              if (err.response.status === 504) {
                this.$notify({
                  group: 'foo',
                  clean: true
                })
                setTimeout(() => {
                  this.$notify({
                group: 'foo',
                type: 'error',
                duration: -1,
                title: 'Ошибка',
                text: 'Сервер временно недоступен'
              })
                }, 300)
                
              }
            })
            .finally(() => {
              this.sending = false;
            });
        }
      }
    },
    mounted() {
      // console.log(this.$route);
    }
  }
</script>