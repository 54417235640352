<template>
  <div>
    <h3>
      <span class="d-inline-block mb-3">
        Документы
      </span>
    </h3>
    <preloader over v-if="loading"></preloader>
    <div v-if="error" class="alert alert-danger">
      {{error}}
    </div>
    <template v-if="!error && !loading">
      <div class="nav-mobile-title mb-3" @click="$router.go(-1)">
        <div class="nav-mobile-title--sub">Категория</div>
        {{category.name || 'Не названия'}}
      </div>
      <table class="documents-table" v-if="content && content.length">
              <tr class="documents-table-row" v-for="item in content" :key="item.doc_name">
                <td class="documents-table-namecell">
                  <i class="icon-file documents-box-icon"></i>
                  <p class="documents-box-filename">{{item.doc_name}}</p>
                </td>
                <td class="documents-table-linkcell">
                  <a class="documents-box-link" :href="item.doc_url" download><i class="icon-download documents-box-link-icon"></i> <span class="desktop">Скачать</span></a>
                </td>
              </tr>
            </table>
      <div v-else class="alert alert-danger">
        Нет данных
      </div>
    </template>
  </div>
</template>

<script>
  import Base from '../base'
  import api from '@/js/api'
  import _ from 'lodash'
  import parseContent from '@/js/core/parse-content-simple'

  export default {
    extends: Base,
    data: () => ({
      category: {},
      loading: false,
      error: '',
      content: []
    }),
    mounted() {
      this.loading = true
      Promise.all([
        api.getDocCategory(this.$route.params.category_id),
        api.getDocList(this.$route.params.category_id)
			]).then(([category, content]) => {
        this.category = category.data
        this.content = content.data
        // .map(item => {
        //   item.doc_url = item.doc_url
        //   item.id = item.id
        //   item.doc_name = item.doc_name
        //   return item
        // })
			}).catch(error => {
				this.error = error
			}).finally(() => {
        this.loading = false
      });
    }
  };
</script>