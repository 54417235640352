<template>
  <div>
    <h3>
      <span class="d-inline-block mb-3">
        Вакансии
      </span>
    </h3>
    <preloader over v-if="loading"></preloader>
    <div v-if="error" class="alert alert-danger">
      {{error}}
    </div>
    <template v-if="!error && !loading">
      <div class="nav-mobile-title mb-3" @click="$router.go(-1)">
        <div class="nav-mobile-title--sub">Категория</div>
        {{category.name || 'Не названия'}}
      </div>
      <template v-if="content && content.length">
        <div class="vacancy-card vacancy-card--mobile vacancy-card-shadow" v-for="item in content" :key="item.id">
          <div class="vacancy-card-header">
            <div class="vacancy-card-header__name vacancy-card-header__name--bg f-18 f-md-16 fw-500 mb-3">{{item.name}}</div>
            <ul class="vacancy-card-header__actions">
              <li>
                <i class="icon-placeholder-copy"></i>
                {{item.city_name}}
              </li>
              <li>
                <i class="icon-time-line"></i>
                {{item.schedule}}
              </li>
              <li>
                <i class="icon-credit-card-line"></i>
                {{item.wage}}
              </li>
            </ul>
          </div>
          <div class="vacancy-card-body">
            <div class="vacancy-card-body__text" v-html="item.responsibility"></div>
          </div>
          <div class="vacancy-card-footer">
            <a href="javascript:void(0)" class="vacancy-card-footer__more" @click="$router.push({ name: 'mobile.vacancy', params: { id: item.id } })">Подробнее <i class="icon-arrow-chevron rotate-left"></i></a>
          </div>
        </div>
      </template>
      <div v-else class="alert alert-danger">
        Нет данных
      </div>
    </template>
  </div>
</template>

<script>
  import Base from '../base'
  import api from '@/js/api'
  import parseContent from '@/js/core/parse-content-simple'

  export default {
    extends: Base,
    data: () => ({
      category: {},
      loading: false,
      error: '',
      content: []
    }),
    mounted() {
      this.loading = true
      Promise.all([
        api.vacancy.category.get(this.$route.params.category_id),
        api.vacancy.content.list(this.$route.params.category_id)
			]).then(([category, content]) => {
        this.category = category.data
        this.content = content.data.map(item => {
          item.conditions = parseContent(item.conditions)
          item.requirements = parseContent(item.requirements)
          item.responsibility = parseContent(item.responsibility)
          return item
        })
			}).catch(error => {
        // this.error = error
        this.error = 'Нет данных'
			}).finally(() => {
        this.loading = false
      });
    }
  };
</script>