var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._t("before"),
      _vm._v(" "),
      _c("c-select", {
        attrs: {
          placeholder: "Форма собственности",
          options: _vm.options.ownershipTypes
        },
        model: {
          value: _vm.value.agent_type,
          callback: function($$v) {
            _vm.$set(_vm.value, "agent_type", $$v)
          },
          expression: "value.agent_type"
        }
      }),
      _vm._v(" "),
      _vm.value.agent_type == 1
        ? _c("c-input", {
            staticClass: "mt-2 mt-md-0",
            attrs: { placeholder: "ФИО" },
            model: {
              value: _vm.value.agent_name,
              callback: function($$v) {
                _vm.$set(_vm.value, "agent_name", $$v)
              },
              expression: "value.agent_name"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      ~[2, 3].indexOf(_vm.value.agent_type)
        ? _c("c-input", {
            staticClass: "mt-2 mt-md-0",
            attrs: { placeholder: "Наименование" },
            model: {
              value: _vm.value.agent_name,
              callback: function($$v) {
                _vm.$set(_vm.value, "agent_name", $$v)
              },
              expression: "value.agent_name"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.value.agent_type == 1
        ? [
            _c("c-select", {
              staticClass: "mt-2 mt-md-0",
              attrs: { placeholder: "Документ", options: _vm.options.docTypes },
              model: {
                value: _vm.value.agent_document_type_id,
                callback: function($$v) {
                  _vm.$set(_vm.value, "agent_document_type_id", $$v)
                },
                expression: "value.agent_document_type_id"
              }
            }),
            _vm._v(" "),
            _c(
              "c-row",
              { staticClass: "mx-n1" },
              [
                _c(
                  "c-col",
                  { staticClass: "col-12 col-md-6 px-1 mt-2 mt-md-0" },
                  [
                    _c("c-input", {
                      attrs: { placeholder: "Серия" },
                      model: {
                        value: _vm.value.agent_document_series,
                        callback: function($$v) {
                          _vm.$set(_vm.value, "agent_document_series", $$v)
                        },
                        expression: "value.agent_document_series"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "c-col",
                  { staticClass: "col-12 col-md-6 px-1 mt-2 mt-md-0" },
                  [
                    _c("c-input", {
                      attrs: { placeholder: "Номер" },
                      model: {
                        value: _vm.value.agent_document_number,
                        callback: function($$v) {
                          _vm.$set(_vm.value, "agent_document_number", $$v)
                        },
                        expression: "value.agent_document_number"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]
        : _vm._e(),
      _vm._v(" "),
      ~[2, 3].indexOf(_vm.value.agent_type)
        ? [
            _c("c-input", {
              staticClass: "mt-2 mt-md-0",
              attrs: { placeholder: "ИНН" },
              model: {
                value: _vm.value.agent_inn,
                callback: function($$v) {
                  _vm.$set(_vm.value, "agent_inn", $$v)
                },
                expression: "value.agent_inn"
              }
            }),
            _vm._v(" "),
            _c("c-input", {
              staticClass: "mt-2 mt-md-0",
              attrs: { placeholder: "КПП" },
              model: {
                value: _vm.value.agent_kpp,
                callback: function($$v) {
                  _vm.$set(_vm.value, "agent_kpp", $$v)
                },
                expression: "value.agent_kpp"
              }
            }),
            _vm._v(" "),
            _c("c-input", {
              staticClass: "mt-2 mt-md-0",
              attrs: { placeholder: "Контактное лицо" },
              model: {
                value: _vm.value.agent_contact,
                callback: function($$v) {
                  _vm.$set(_vm.value, "agent_contact", $$v)
                },
                expression: "value.agent_contact"
              }
            })
          ]
        : _vm._e(),
      _vm._v(" "),
      _c(
        "c-row",
        { staticClass: "mx-n1" },
        [
          _c(
            "c-col",
            { staticClass: "col-12 col-md-6 px-1 mt-2 mt-md-0" },
            [
              _c("c-input", {
                directives: [
                  {
                    name: "mask",
                    rawName: "v-mask",
                    value: { mask: "+99999999999" },
                    expression: "{mask: '+99999999999'}"
                  }
                ],
                attrs: { placeholder: "Телефон" },
                model: {
                  value: _vm.value.agent_phone,
                  callback: function($$v) {
                    _vm.$set(_vm.value, "agent_phone", $$v)
                  },
                  expression: "value.agent_phone"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "c-col",
            { staticClass: "col-12 col-md-6 px-1 mt-2 mt-md-0" },
            [
              _c("c-input", {
                directives: [
                  {
                    name: "mask",
                    rawName: "v-mask",
                    value: { mask: "9999" },
                    expression: "{mask: '9999'}"
                  }
                ],
                attrs: { placeholder: "Добавочный" },
                model: {
                  value: _vm.value.agent_extra_phone,
                  callback: function($$v) {
                    _vm.$set(_vm.value, "agent_extra_phone", $$v)
                  },
                  expression: "value.agent_extra_phone"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("c-input", {
        staticClass: "mt-2 mt-md-0",
        attrs: { placeholder: "E-Mail" },
        model: {
          value: _vm.value.agent_email,
          callback: function($$v) {
            _vm.$set(_vm.value, "agent_email", $$v)
          },
          expression: "value.agent_email"
        }
      }),
      _vm._v(" "),
      _vm.$v.agent_email.$model && !_vm.$v.agent_email.$invalid
        ? _c(
            "div",
            {
              staticClass: "shadow-box mt-3",
              class: { active: _vm.value.agent_is_informing_mail }
            },
            [
              _c(
                "c-checkbox",
                {
                  model: {
                    value: _vm.value.agent_is_informing_mail,
                    callback: function($$v) {
                      _vm.$set(_vm.value, "agent_is_informing_mail", $$v)
                    },
                    expression: "value.agent_is_informing_mail"
                  }
                },
                [
                  _c("span", { staticClass: "fw-500 fw-md-normal" }, [
                    _vm._v("\n        Оповещать по e-mail\n      ")
                  ])
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.$v.agent_phone.$model && !_vm.$v.agent_phone.$invalid
        ? _c(
            "div",
            {
              staticClass: "shadow-box mt-3",
              class: { active: _vm.value.agent_is_informing_sms }
            },
            [
              !_vm.value.agent_extra_phone
                ? _c(
                    "c-checkbox",
                    {
                      model: {
                        value: _vm.value.agent_is_informing_sms,
                        callback: function($$v) {
                          _vm.$set(_vm.value, "agent_is_informing_sms", $$v)
                        },
                        expression: "value.agent_is_informing_sms"
                      }
                    },
                    [
                      _c("span", { staticClass: "fw-500 fw-md-normal" }, [
                        _vm._v("\n        Оповещать по SMS\n      ")
                      ])
                    ]
                  )
                : _c(
                    "c-checkbox",
                    {
                      attrs: { disabled: true },
                      model: {
                        value: _vm.value.agent_is_informing_sms,
                        callback: function($$v) {
                          _vm.$set(_vm.value, "agent_is_informing_sms", $$v)
                        },
                        expression: "value.agent_is_informing_sms"
                      }
                    },
                    [
                      _c("span", { staticClass: "fw-500 fw-md-normal" }, [
                        _vm._v("\n        Оповещать по SMS\n      ")
                      ]),
                      _vm._v(" "),
                      _c(
                        "c-tip",
                        {
                          staticClass:
                            "tip-express ml-2 d-none d-md-inline-block"
                        },
                        [
                          _c("template", { slot: "subject" }, [
                            _c("i", {
                              staticClass: "icon-help text-gray-300 f-16 lh-0"
                            })
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _vm._v(
                              "\n          Отправка уведомлений на телефон с добавочным номером невозможна\n        "
                            )
                          ])
                        ],
                        2
                      )
                    ],
                    1
                  )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._t("after")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }