var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h3", [
        _c("span", { staticClass: "d-inline-block mb-3" }, [
          _vm._v("\n      Вакансии\n    ")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "f-16 mb-1" }, [
          _vm._v("\n      Отклик на вакансию\n    ")
        ]),
        _vm._v(" "),
        _vm.content.name
          ? _c("div", { staticClass: "f-14 fw-400 text-gray-400 mb-3" }, [
              _vm._v("«" + _vm._s(_vm.content.name) + "»")
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm.error
        ? _c("div", { staticClass: "alert alert-danger" }, [
            _vm._v("\n    " + _vm._s(_vm.error) + "\n  ")
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.success
        ? _c("div", { staticClass: "alert alert-success" }, [
            _vm._v("\n    " + _vm._s(_vm.success) + "\n  ")
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.loading ? _c("preloader", { attrs: { over: "" } }) : _vm._e(),
      _vm._v(" "),
      _c("send-form", {
        attrs: { currentModel: _vm.content },
        on: {
          success: _vm.successEvent,
          error: _vm.errorEvent,
          loading: _vm.loadingEvent
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }