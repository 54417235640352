<template>
  <div>
    <div>
      <div class="c-tabs__head w-100 w-md-auto" ref="head">
        <div class="c-tabs__label text-center w-50 w-md-auto" :class="{active: value == index, 'text-muted': value != index}" v-for="(label, index) in labels" :key="index" @click="select(index)">
          <slot :name="'label-' + index">
            {{label}}
          </slot>
        </div>
        <div class="c-tabs__line">
          <div class="c-tabs__line__active" :style="{transform: 'translate(' + left + 'px, 0)', width: width + 'px'}">
          </div>
        </div>
      </div>
    </div>
    <div class="c-tabs__container pt-3">
      <div v-if="value == index" v-for="(label, index) in labels" :key="index">
        <slot :name="'tab-' + index">
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      value: [Number, String],
      labels: {
        type: [Array, Object],
        default: () => []
      }
    },
    data: () => ({
      width: 0,
      left: 0
    }),
    watch: {
      value(value) {
        this.select(value);
      }
    },
    methods: {
      select(value) {
        this.$emit('input', value);
        let raf = window.requestAnimationFrame || window.setTimeout;
        setTimeout(() => {
          raf(() => {
            let index = value;
            if (typeof value == 'string') {
              index = _.findIndex(_.keys(this.labels), key => key == value);
            }
            let cont = this.$refs.head;
            if (cont) {
              let child = cont.childNodes[index];
              if (child) {
                let parentRect = cont.getBoundingClientRect();
                let childRect = child.getBoundingClientRect();
                this.width = childRect.width;
                this.left = childRect.x - parentRect.x;
              }
            }
          });
        }, 100);
      }
    },
    mounted() {
      this.select(this.value);
    }
  };
</script>

<style lang="scss" scoped>
  .c-tabs {
    &__head {
      display: inline-block;
      max-width: 100%;
      white-space: nowrap;
    }
    &__label {
      display: inline-block;
      padding: 10px 0;
      font-family: Roboto;
      font-size: 13px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.15;
      letter-spacing: normal;
      cursor: pointer;
      user-select: none;
      &.active {
        color: #ca1226;
      }
      & + & {
        @media (min-width: 768px) {
          margin-left: 20px;
        }
      }
    }
    &__line {
      position: relative;
      width: 100%;
      border-bottom: 1px solid #bdbdbd;
      &__active {
        position: absolute;
        top: -1px;
        left: 0;
        width: 100px;
        border-bottom: 2px solid #ca1226;
        transition: width .4s, transform .4s;
        transform: translateZ(0);
      }
    }
    &__container {

    }
  }
</style>