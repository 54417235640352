<template lang="pug">
  div
    settings-menu
    back.mt-3.text-gray-300(name="payers-list")
      | Назад к списку
    preloader.mt-5(v-if="loading")
    template(v-else)
      .f-18.fw-500.text-gray-800.mt-2
        | Создание плательщика
      c-row.mt-3
        c-col.col-12.col-md-6.col-lg-4
          c-subject(v-model="agents[0]", :options="options")
            template(slot="before")
              c-select(placeholder="Город", :options="options.cities", v-model="agents[0].TownID", :filter="filterCities", :getOptionLabel="getCityLabel", accessor="value.TownID")
              c-input.mt-2.mt-md-0(placeholder="Адрес", v-model="agents[0].Address", accessor="value.Address")
              c-select.mt-2.mt-md-0.mb-2.mb-md-0(placeholder="Форма оплаты", :options='options.paymentTypes', v-model='agents[0].type_pay', accessor="value.type_pay")
            template(slot="after")
              c-checkbox.mt-3(v-model="defaultPayer")
                | Основной плательщик
              c-input.mt-3.mt-md-2(placeholder="E-mail для информирования", v-model="agents[0].AddEmail", accessor="value.AddEmail")
              c-input.mt-1.mt-md-0(placeholder="Телефон для информирования", v-model="agents[0].AddPhone", v-mask="{mask: '+99999999999'}", accessor="value.AddPhone")
          .alert.alert-danger.mb-0.f-12.d-block.mt-3(v-if="error")
            | {{error}}
          c-button.mt-4.w-100.w-md-auto(@click="save", :disabled="$v.agents.$each[0].$invalid || saving")
            | Сохранить
</template>

<script>
  import _ from 'lodash';
  import api from '@/js/api';
  import {required, email, helpers} from 'vuelidate/lib/validators';
  import validateObject from '@/js/core/validation-to-object';

  const notEmpty = value => !!value;
  const phoneRegExp = /^[+]?\d{11,12}$/;
  const phoneCheck = value => phoneRegExp.test(('' + value || '').replace(/[\!\-\(\)\{\} ]/g, ''));
  const skip = (check, validators) => {
    let params = {skip: {value: false}};
    return helpers.withParams(params, function(value, model) {
      let result;
      if (check.call(this, value, model)) {
        params.skip.value = false;
        if (_.isFunction(validators)) {
          return validators.call(this, value, model)
        }
        if (_.isPlainObject(validators) || _.isArray(validators)) {
          return _.reduce(validators, (s, validator) => s && validator.call(this, value, model), true);
        }
        return false;
      }
      params.skip.value = true;
      return true;
    });
  };
  const keep = () => true;

  const DEFAULT_AGENT = {
    TownID: null,
    Address: null,
    AddEmail: null,
    AddPhone: null,

    agent_type: null,
    agent_name: null,
    agent_document_type_id: null,
    agent_document_series: null,
    agent_document_number: null,
    agent_inn: null,
    agent_kpp: null,
    agent_contact: null,
    agent_phone: null,
    agent_extra_phone: null,
    agent_email: null,
    agent_is_informing_sms: false,
    agent_is_informing_mail: false,
    type_pay: null
  };

  export default {
    data: () => ({
      loading: true,
      saving: false,
      error: null,
      defaultPayer: false,
      options: {
        cities: [],
        ownershipTypes: [
          {
            value: 1,
            title: 'Физическое лицо'
          },
          {
            value: 2,
            title: 'Индивидуальный предприниматель'
          },
          {
            value: 3,
            title: 'Юридическое лицо'
          }
        ],
        paymentTypes: [
          {
            value: 1,
            title: 'Безналичный расчет'
          },
          {
            value: 2,
            title: 'Касса'
          },
          {
            value: 3,
            title: 'Квитанция'
          },
        ],
      },
      agents: [
        _.cloneDeep(DEFAULT_AGENT)
      ]
    }),
    provide() {
      return {
        validation: this.$v
      }
    },
    validations() {
      return {
        agents: {
          $each: {
            agent_type: {required},
            agent_name: {required},
            agent_document_type_id: {skip: skip((value, model) => model.agent_type == 1, required)},
            agent_document_series: {skip: skip((value, model) => model.agent_type == 1, required)},
            agent_document_number: {skip: skip((value, model) => model.agent_type == 1, required)},
            agent_inn: {keep}, // {skip: skip((value, model) => ~[2,3].indexOf(model.agent_type), required)},
            agent_kpp: {keep}, // {skip: skip((value, model) => ~[2,3].indexOf(model.agent_type), required)},
            agent_contact: {skip: skip((value, model) => ~[2,3].indexOf(model.agent_type), required)},
            agent_phone: {phoneCheck},
            agent_extra_phone: {keep},
            agent_email: {required, email},
            agent_is_informing_sms: {keep},
            agent_is_informing_mail: {keep},
            type_pay: {required, notEmpty},

            TownID: {required},
            Address: {required},
            AddEmail: {email: value => value ? email(value) : true},
            AddPhone: {check: value => value ? phoneCheck(value) : true}
          }
        }
      };
    },
    methods: {
      parseCity(city) {
        return city ? {
          value: city.town_id,
          title: city.town_name,
          subtitle: city.area_name
        } : city;
      },
      getCityLabel(option) {
        return _.get(this.options.towns[option], 'town_name');
      },
      filterCities(options, search) {
        return search ? _.filter(this.options.citiesAll, town => (town.title || '').toLowerCase().indexOf(search.toLowerCase()) == 0) : options;
      },
      save() {
        if (!this.saving) {
          this.saving = true;
          let vObject = validateObject(this, this.$v);
          let agent = _.extend({}, DEFAULT_AGENT, vObject.agents[0]);
          api.createPayer(agent)
            .then(({data}) => {
              this.$notify({
                group: 'foo',
                clean: true
              })
              if (data.status == 200 && !data.error) {
                if (this.defaultPayer && data.payer_id) api.setDefaultPayer(data.payer_id)
                this.$router.push({name: 'payers-list'});
              }
              if (data.error) {
                this.error = data.error;
              }
            })
            .finally(() => {
              this.saving = false;
            });
        }
      }
    },
    mounted() {
      Promise.all([
        this.$store.dispatch('towns'),
        this.$store.dispatch('docTypes')
      ]).then(([towns, docTypes]) => {
        this.$notify({
          group: 'foo',
          clean: true
        })
        // console.log(towns);
        this.options.towns = _.keyBy(towns, 'town_id');
        this.options.citiesAll = _.map(towns, this.parseCity);
        this.options.cities = _.take(this.options.citiesAll, 1000);
        this.options.docTypes = _.map(docTypes, item => ({value: item.tb_id, title: item.name}));
      })
      .finally(() => {
        this.loading = false;
      });
    }
  };
</script>
