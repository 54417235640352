<template>
  <div></div>
</template>

<script>
  export default {
    mounted() {
      this.$viewer.logout();
    } 
  }
</script>
