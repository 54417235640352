<template>
  <div>
    <h3>
      <span class="d-inline-block mb-3">
        Документы
      </span>
    </h3>
    <div class="mt-3"></div>
    <div v-if="success" class="alert alert-success">
      {{success}}
    </div>
    <div v-if="error" class="alert alert-danger">
      {{error}}
    </div>
    <preloader over v-if="loading"></preloader>
    <template v-if="page === 1 && !error">
      <c-row v-if="!loading">
        <c-col class="col-12 col-lg-3 col-md-4">
          <nav-sidebar v-if="navs && navs.length" :value="currentCategoryId" :items="navs" @nav-change="getContent"/>
          <div v-else class="alert alert-danger">
            Нет данных
          </div>
        </c-col>
        <c-col class="col-12 col-lg-9 col-md-8">
          <preloader v-if="loadingContent"></preloader>
          <template v-else>
            <!-- <h2 class="documents-box-title">{{activeCategory}}</h2> -->
            <table class="documents-table" v-if="content && content.length">
              <tr class="documents-table-row" v-for="item in content" :key="item.doc_name">
                <td class="documents-table-namecell">
                  <i class="icon-file documents-box-icon"></i>
                  <p class="documents-box-filename">{{item.doc_name}}</p>
                </td>
                <td class="documents-table-linkcell">
                  <a class="documents-box-link" :href="item.doc_url" download><i class="icon-download documents-box-link-icon"></i> <span class="desktop">Скачать</span></a>
                </td>
              </tr>
            </table>
          </template>
        </c-col>
      </c-row>
    </template>
    <template v-if="page === 2">
      <send-form
        ref="sendForm"
        :currentModel="currentModelPage"
        @success="successEvent"
        @error="errorEvent"
        @loading="loadingEvent"
      />
    </template>
  </div>
</template>

<script>
import api from '@/js/api'
import _ from 'lodash'
import isMobile from '@/js/core/is-mobile'
import parseContent from '@/js/core/parse-content-simple'

export default {
  data() {
    return {
      api,
      page: 1,
      currentModelPage: {},
      error: '',
      loading: true,
      loadingContent: false,
      navs: [],
      content: [],
      success: '',
      activeCategory: '',
      currentCategoryId: null
    }
  },
  methods: {
    successEvent(event) {
      this.success = event
      this.error = ''
    },
    errorEvent(event) {
      this.success = ''
      this.error = event
    },
    loadingEvent(event) {
      this.success = ''
      this.error = ''
      this.loading = event
    },
    setPage(page) {
      if (page === 1) {
        this.currentModelPage = {}
        this.$refs.sendForm.clear()
      }
      this.page = page
    },
    bSend(item) {
      this.setPage(2)
      this.currentModelPage = item
    },
    getContent(id) {
      this.$router.push(`/documents/${id}`)
      this.error = ''
      this.loadingContent = true
      api.getDocCategory(id).then(response => {
        this.activeCategory = response.data.name
      })
      api.getDocList(id).then(response => {
        this.content = response.data.map(item => {
          item.doc_url = item.doc_url
          item.id = item.id
          item.doc_name = item.doc_name
          return item
        })
      }, error => {
        this.error = error
      }).finally(() => {
        this.loadingContent = false
      })
    },
    getMenu() {
      this.loading = true
      this.error = ''
      return api.getDocTypes().then(response => {
        if (_.isEmpty(response.data)) {
          this.error = 'Нет данных'
          return false
        }

        this.navs = response.data
        return this.navs
      }, error => {
        this.error = error
        return this.error
      }).finally(() => {
        this.loading = false
      })
    }
  },
  mounted() {
    // window.addEventListener('resize', (event) => {
    //   if (isMobile()) this.$router.push({ name: 'mobile.list' })
    // })

    if (isMobile()) {
      this.$router.push({ name: 'mobile.list' })
      return false
    }
    this.getMenu().then(res => {
      if (!_.isEmpty(res)) {
        if (this.$route.params.desk_id) {
          this.currentCategoryId = this.$route.params.desk_id
          this.getContent(this.currentCategoryId)
        } else {
          this.currentCategoryId = res[0].id
          this.getContent(res[0].id)
        }
      }
    })
  }
}
</script>
