var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h3", [_vm._v("Смена пароля")]),
      !_vm.status ? _c("preloader", { staticClass: "mt-5" }) : _vm._e(),
      _c(
        "c-row",
        { staticClass: "mt-3" },
        [
          _c(
            "c-col",
            { staticClass: "col-12 col-lg-4 col-sm-6" },
            [
              _vm.status == 1
                ? [
                    _c(
                      "c-form",
                      [
                        _c(
                          "c-input",
                          {
                            staticClass: "mt-1",
                            attrs: {
                              placeholder: "Новый пароль",
                              suffix: true,
                              "input-type": _vm.showPass ? "text" : "password"
                            },
                            model: {
                              value: _vm.user.password1,
                              callback: function($$v) {
                                _vm.$set(_vm.user, "password1", $$v)
                              },
                              expression: "user.password1"
                            }
                          },
                          [
                            _c("template", { slot: "suffix" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "vam hoverable f-0 pr-2 text-gray-400",
                                  on: {
                                    click: function($event) {
                                      _vm.showPass = !_vm.showPass
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "f-18 lh-0",
                                    class: {
                                      "icon-eye": !_vm.showPass,
                                      "icon-eye-disabled": _vm.showPass
                                    }
                                  })
                                ]
                              )
                            ])
                          ],
                          2
                        ),
                        _c(
                          "c-input",
                          {
                            staticClass: "mt-1",
                            attrs: {
                              placeholder: "Подтверждение пароля",
                              suffix: true,
                              "input-type": _vm.showPass ? "text" : "password"
                            },
                            model: {
                              value: _vm.user.password2,
                              callback: function($$v) {
                                _vm.$set(_vm.user, "password2", $$v)
                              },
                              expression: "user.password2"
                            }
                          },
                          [
                            _c("template", { slot: "suffix" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "vam hoverable f-0 pr-2 text-gray-400",
                                  on: {
                                    click: function($event) {
                                      _vm.showPass = !_vm.showPass
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "f-18 lh-0",
                                    class: {
                                      "icon-eye": !_vm.showPass,
                                      "icon-eye-disabled": _vm.showPass
                                    }
                                  })
                                ]
                              )
                            ])
                          ],
                          2
                        ),
                        _vm.errors
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "alert alert-danger mb-0 f-12 d-block mt-3"
                              },
                              _vm._l(_vm.errors, function(error, index) {
                                return _c("div", { key: index }, [
                                  _vm._v(_vm._s(error))
                                ])
                              }),
                              0
                            )
                          : _vm._e(),
                        _c(
                          "c-button",
                          {
                            staticClass: "mt-3 w-100",
                            attrs: {
                              type: "submit",
                              disabled: _vm.$v.user.$invalid || _vm.sending
                            },
                            on: { click: _vm.send }
                          },
                          [_vm._v("Отправить")]
                        )
                      ],
                      1
                    )
                  ]
                : _vm._e(),
              _vm.status == 2
                ? [
                    _vm._v(
                      "Истек срок действия ссылки для восстановления пароля. Восстановите пароль еще раз."
                    )
                  ]
                : _vm._e(),
              _vm.status == 3
                ? [
                    _vm._v("Пароль успешно изменен."),
                    _c(
                      "c-button",
                      {
                        staticClass: "mt-3 w-100",
                        attrs: { to: { name: "login" } }
                      },
                      [_vm._v("Вход")]
                    )
                  ]
                : _vm._e()
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }