<template lang="pug">
  .border.border-0.border-md-2.border-gray-200.rounded.bg-md-gray-100.py-3.px-3.text-gray-400.shadow-box.bg-white
    c-row.mx-n3.mx-md-n2.items-cols
      c-col.col-6.col-md-3.pr-0
        .f-10.text-muted.fw-500.text-uppercase
          | Отправление
        .f-14.text-gray-800.mt-2
          c-row.mx-0
            c-col.col-auto.p-0.pr-2
              | {{item.calc_fields.city_from}}
            c-col.p-0
              c-row.m-0.mt-1.order-path
                c-col.col-auto.p-0
                  .circle
                c-col.p-0.px-1
                  .path
                c-col.col-auto.p-0
                  .circle
        .f-12.mt-1.fw-300.d-none.d-md-block(v-if="item.calc_fields.cargo_pickup_adress")
          | {{item.calc_fields.cargo_delivery_adress}}
        .f-12.mt-1.text-gray-800
          | {{item.calc_fields.cargo_pickup_date | date('DD.MM.YY')}}
      c-col.col-6.col-md-3.px-3.px-md-2
        .f-10.text-muted.fw-500.text-uppercase
          | Получение
        .f-14.text-gray-800.mt-2
          | {{item.calc_fields.city_to}}
        .f-12.mt-1.fw-300.d-none.d-md-block(v-if="item.calc_fields.cargo_delivery_adress")
          | {{item.calc_fields.cargo_delivery_adress}}
        .f-12.mt-1
          span.text-gray-800
            | {{item.calc_fields.cargo_delivery_date | date}}
          //- span
          //-   |  {{item.calc_fields.cargo_delivery_date | date('HH:mm')}}
      c-col.col-12.col-md.text-center.text-md-right.mt-3.mt-md-0
        .d-inline-block
          .f-12.fw-500.text-gray-800.fa
            .text-nowrap.fa
              i.f-16.lh-0.icon-cargo.text-gray-300.mr-1
              | {{item.calc_response.cargo_weight}} кг / {{item.calc_response.cargo_amount}} {{item.calc_response.cargo_amount | plural(pluralPlaces)}}
            .text-nowrap.ml-3.fa
              i.f-16.lh-0.icon-fullscreen.text-gray-300.mr-1
              | {{item.calc_response.cargo_volume | toFixed}} м
              sup
                | 3
        .f-14.mt-2.fw-300.text-gray-800.d-none.d-md-block
          | {{item.calc_fields.cargo_name}}
      c-col.col-12.col-md-auto.text-center.text-md-left
        .border-top.border-2.border-gray-200.mx-n3.my-3.d-block.d-md-none
        .d-inline-block.d-md-block.my-0.my-md-0.mx-3.mx-md-0.py-1.f-12.hoverable(@click="show")
          i.mr-2.icon-arrow-simple
          | Показать
        //- .d-inline-block.d-md-block.my-0.my-md-0.mx-3.mx-md-0.py-1.f-12.hoverable(@click="downloadPDF")
        //-   i.mr-2.icon-download
        //-   | Скачать PDF
        .d-inline-block.d-md-block.my-0.my-md-0.mx-3.mx-md-0.py-1.f-12.hoverable(@click="remove")
          i.mr-2.icon-close
          | Удалить
</template>

<script>
  const PLURAL_PLACES = {
    base: 'мест',
    one: 'о',
    few: 'а',
    many: '',
    other: ''
  };
  export default {
    props: {
      item: [Array, Object, Boolean, String, Number],
      full: {
        type: Boolean,
        default: false
      }
    },
    data: () => ({
      pluralPlaces: PLURAL_PLACES
    }),
    methods: {
      show() {
        this.$emit('show', this.item);
      },
      // downloadPDF() {
      //   this.$emit('download', this.item);
      // },
      remove() {
        this.$emit('remove', this.item);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .items-cols {
    @media (min-width: 768px) {
      flex-wrap: nowrap;
    }
  }
</style>