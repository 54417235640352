var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "news-element" }, [
    _vm.showNews
      ? _c("div", { staticClass: "news-element-content" }, [
          _c("div", { staticClass: "news-element-item" }, [
            _c("div", { staticClass: "text" }, [
              _vm._v(_vm._s(_vm.news.title))
            ]),
            _c(
              "a",
              {
                staticClass: "link",
                attrs: { href: _vm.news.link },
                on: {
                  click: function($event) {
                    return _vm.close()
                  }
                }
              },
              [_vm._v("Подробнее")]
            ),
            _c("i", {
              staticClass: "icon-close hoverable",
              on: {
                click: function($event) {
                  return _vm.close()
                }
              }
            })
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }