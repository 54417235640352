<template>
<div class="page">
  <div class="container container-full map">
    <router-view :key="$route.fullPath" />
  </div>
</div>
</template>

<script>
  import Vue from 'vue';
  import router from './router';

  export default {
    router: router
  };
</script>