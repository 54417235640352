<template>
    <div :dir="dir" class="dropdown v-select" :class="dropdownClasses">
    <div ref="toggle" @mousedown.prevent="toggleDropdown" class="dropdown-toggle">

      <div class="vs__selected-options" ref="selectedOptions">
        <!-- {{valueAsArray}} -->
        <slot v-for="option in valueAsArray" name="selected-option-container"
              :option="(typeof option === 'object')?option:{[label]: option}" :deselect="deselect" :multiple="multiple" :disabled="disabled">
          <span class="selected-tag" v-bind:key="option.index">
            <slot name="selected-option" v-bind="(typeof option === 'object')?option:{[label]: option}">
              {{ getOptionLabel(option) }}
            </slot>
            <button v-if="multiple" :disabled="disabled" @click="deselect(option)" type="button" class="close" aria-label="Remove option">
              <span aria-hidden="true">&times;</span>
            </button>
          </span>
        </slot>

        <input
                ref="search"
                :value="search"
                @keydown.delete="maybeDeleteValue"
                @keyup.esc="onEscape"
                @keydown.up.prevent="typeAheadUp"
                @keydown.down.prevent="typeAheadDown"
                @keydown.enter.prevent="typeAheadSelect"
                @keydown.tab="onTab"
                @blur="onSearchBlur"
                @focus="_onSearchFocus"
                @input="search = $event.target.value"
                type="search"
                class="form-control"
                :autocomplete="autocomplete"
                :disabled="disabled"
                :placeholder="searchPlaceholder"
                :tabindex="tabindex"
                :readonly="!searchable"
                :id="inputId"
                role="combobox"
                :aria-expanded="dropdownOpen"
                aria-label="Search for option"
        >

      </div>
      <div class="vs__actions">
        <button
          v-show="showClearButton"
          :disabled="disabled"
          @click="clearSelection"
          type="button"
          class="clear"
          title="Clear selection"
        >
          <span aria-hidden="true">&times;</span>
        </button>

        <i v-if="!noDrop" ref="openIndicator" role="presentation" class="open-indicator"><i class="icon-triangle-down r-90 f-12 text-gray-300 lh-0"></i></i>

        <slot name="spinner">
          <div class="spinner" v-show="mutableLoading">Loading...</div>
        </slot>
      </div>
    </div>

    <transition :name="transition">
      <ul ref="dropdownMenu" v-if="dropdownOpen" class="dropdown-menu" :style="{ 'max-height': maxHeight }" role="listbox" @mousedown="onMousedown" @mouseup="onMouseup">
        <li role="option" v-for="(option, index) in filteredOptions" v-bind:key="index" :class="{ active: isOptionSelected(option), highlight: index === typeAheadPointer }">
          <a @mousedown.prevent.stop="_select(option)">
            <div class="c-option__title py-1" v-if="typeof option == 'object'">
              {{option.title}}
              <div class="c-option__subtitle">
                {{option.subtitle}}
              </div>
            </div>
            <div v-else>
              {{option}}
            </div>
          </a>
        </li>
        <li v-if="!filteredOptions.length" class="no-options" @mousedown.stop="">
          Список пуст.
        </li>
      </ul>
    </transition>
  </div>
</template>

<script>
  // import VueSelect from 'vue-select/src/components/Select';
  import VueSelect from 'vue-select';
  export default {
    extends: VueSelect,
    computed: {
      isValueEmpty() {
        // if (this.mutableValue) {
          if (this.mutableValue && typeof this.mutableValue === 'object') {
            return ! Object.keys(this.mutableValue).length
          }
          return ! this.valueAsArray.length
        // }

        return true;
      },
      isValueExists() {
        return !!_.find(this.mutableOptions, option => option.value == this.mutableValue);
      },
      valueAsArray() {
        // console.log(this.mutableValue, this.isValueExists);
        if (this.multiple && this.mutableValue) {
          return this.mutableValue
        } else if (this.mutableValue || this.isValueExists) {
        // } else if ((this.mutableValue && _.isString(this.mutableValue)) || _.isNumber(this.mutableValue) || _.isPlainObject(this.mutableValue)) {
          return [].concat(this.mutableValue)
        }

        return []
      }
    },
    methods: {
      _select(option) {
        this.searchBlurDisabled = true;
        this.select(option);
      },
      _onSearchFocus() {
        this.searchBlurDisabled = false;
        this.onSearchFocus();
      },
      findOptionByIndexValue(value) {
        this.mutableOptions.forEach(_option => {
          if (JSON.stringify(_option[this.index]) === JSON.stringify(value)) {
            value = _option
          }
        })
        return value
      },
      maybePushTag(option) {
        if (this.pushTags) {
          this.mutableOptions.unshift(option)
        }
      },
      onSearchBlur() {
        // console.log('azaza', this.mousedown, !this.searching);
        if (this.mousedown && !this.searching) {
          this.mousedown = false;
          this.searchBlurDisabled = false;
        } else {
          if (this.selectOnTab && !this.searchBlurDisabled) {
            this.typeAheadSelect();
            this.searchBlurDisabled = false;
          }
          if (this.clearSearchOnBlur) {
            this.search = ''
          }
          // console.log('az2');
          this.closeSearchOptions()
          return
        }
        // Fixed bug where no-options message could not be closed
        if(this.search.length === 0 && this.options.length === 0){
          this.closeSearchOptions();
          this.searchBlurDisabled = false;
          return
        }
      }
    }
  }
</script>