var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("settings-menu"),
      _vm.loading ? _c("preloader", { staticClass: "mt-5" }) : _vm._e(),
      !_vm.loading
        ? _c(
            "c-row",
            { staticClass: "mt-3" },
            [
              _c(
                "c-col",
                { staticClass: "col-12 col-md-6 col-lg-4" },
                [
                  _c("div", { staticClass: "mb-2 f-18 text-gray-800 fw-500" }, [
                    _vm._v("Добавление пользователей")
                  ]),
                  _c("div", { staticClass: "mt-3 f-12 text-gray-800" }, [
                    _vm._v(
                      "Вы можете дать другим пользователям доступ к аккаунту вашей организации (клиентскoму аккаунту). После отправки приглашения пользователь получит письмо со ссылкой на страницу регистрации а также специальный код клиента."
                    )
                  ]),
                  _c("c-input", {
                    staticClass: "mt-3",
                    attrs: { placeholder: "E-mail" },
                    model: {
                      value: _vm.email,
                      callback: function($$v) {
                        _vm.email = $$v
                      },
                      expression: "email"
                    }
                  }),
                  _vm.error
                    ? _c("div", { staticClass: "alert alert-primary mt-3" }, [
                        _vm._v(_vm._s(_vm.error))
                      ])
                    : _vm._e(),
                  _c(
                    "c-button",
                    {
                      staticClass: "mt-3",
                      attrs: { disabled: _vm.$v.email.$invalid || _vm.sending },
                      on: { click: _vm.invite }
                    },
                    [_vm._v("Пригласить")]
                  ),
                  _c(
                    "div",
                    { staticClass: "mt-4" },
                    _vm._l(_vm.items, function(item, index) {
                      return _c(
                        "div",
                        { staticClass: "mt-3 bg-gray-200 px-4 py-3" },
                        [
                          _c(
                            "c-row",
                            { staticClass: "mb-3" },
                            [
                              _c("c-col", [
                                _c(
                                  "div",
                                  { staticClass: "f-14 fw-500 text-gray-800" },
                                  [_vm._v("Приглашение отправлено")]
                                )
                              ]),
                              _c("c-col", { staticClass: "col-auto" }, [
                                _c("i", {
                                  staticClass: "icon-close hoverable",
                                  on: {
                                    click: function($event) {
                                      return _vm.remove(item)
                                    }
                                  }
                                })
                              ])
                            ],
                            1
                          ),
                          _c(
                            "c-row",
                            { staticClass: "f-12 fw-500" },
                            [
                              _c(
                                "c-col",
                                { staticClass: "col-2 text-gray-300" },
                                [_vm._v("Адрес")]
                              ),
                              _c("c-col", { staticClass: "text-gray-800" }, [
                                _vm._v(_vm._s(item.invite_email))
                              ])
                            ],
                            1
                          ),
                          _c(
                            "c-row",
                            { staticClass: "mt-2 f-12 fw-500" },
                            [
                              _c(
                                "c-col",
                                { staticClass: "col-2 text-gray-300" },
                                [_vm._v("Код")]
                              ),
                              _c("c-col", { staticClass: "text-gray-800" }, [
                                _vm._v(_vm._s(item.code))
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    0
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }