<template>
  <button :disabled="disabled" :type="type" class="c-button" @click="click">
    <slot></slot>
  </button>
</template>

<script>
  export default {
    props: {
      type: {
        type: String,
        default: ''
      },
      disabled: {
        type: Boolean,
        default: false
      },
      to: [Object, String]
    },
    methods: {
      click() {
        if (this.to) {
          this.$router.push(this.to); 
        } else {
          this.$emit('click');
        }
      }
    }
  }
</script>