<template>
  <div class="container pt-5 page-pc mt-5 mt-md-0">
    <!-- <menu-list class="d-block d-md-none mb-4 mt-4 mt-md-0" :items="items" v-if="$route.meta && $route.meta.menu" /> -->
    <notifications group="foo" position="top center" />
    <router-view :key="$route.fullPath" />
  </div>
</template>

<script>
  import Vue from 'vue';
  import router from './router';

  import '@/js/core/classes/viewer';

  export default {
    router: router,
    data() {
      let self = this;
      return {
        items: [
          {
            icon: 'box',
            title: 'Заявки',
            get active() {
              return /^\/request\//.test(self.$route.path);
            },
            action() {
              self.$router.push({name: 'request-list'});
            }
          },
          {
            icon: 'file-2',
            title: 'Отчеты',
            get active() {
              return /^\/report\//.test(self.$route.path);
            },
            action() {
              self.$router.push({name: 'report-list'});
            }
          },
          {
            icon: 'settings',
            title: 'Настройки',
            get active() {
              return /^\/settings\//.test(self.$route.path);
            },
            action() {
              self.$router.push({name: 'profile-view'});
            }
          }
        ]
      }
    }
  };
</script>

<style lang="scss" scoped>
  .page-pc {
    @media (max-width: 768px) {
      padding-bottom: 66px;
    }
  }
</style>
