var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h3", [_vm._v("Восстановление пароля")]),
      _c(
        "c-row",
        { staticClass: "mt-3" },
        [
          _c(
            "c-col",
            { staticClass: "col-12 col-lg-4 col-sm-6" },
            [
              !_vm.success
                ? [
                    _c("div", [
                      _vm._v("Если вы забыли пароль, введите логин или E-mail.")
                    ]),
                    _c("div", [
                      _vm._v(
                        "Контрольная строка для смены пароля, а также ваши регистрационные данные, будут высланы вам по E-mail."
                      )
                    ]),
                    _c("c-input", {
                      staticClass: "mt-3",
                      attrs: { placeholder: "E-mail" },
                      model: {
                        value: _vm.email,
                        callback: function($$v) {
                          _vm.email = $$v
                        },
                        expression: "email"
                      }
                    }),
                    _vm.errors
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "alert alert-danger mb-0 f-12 d-block mt-3"
                          },
                          [_vm._v(_vm._s(_vm.errors))]
                        )
                      : _vm._e(),
                    _c(
                      "c-button",
                      {
                        staticClass: "w-100 mt-3",
                        attrs: {
                          disabled: _vm.$v.email.$invalid || _vm.sending
                        },
                        on: { click: _vm.send }
                      },
                      [_vm._v("Восстановить")]
                    )
                  ]
                : [
                    _c("div", [_vm._v("Пароль отправлен на email адрес.")]),
                    _c(
                      "c-button",
                      {
                        staticClass: "w-100 mt-3",
                        attrs: { to: { name: "login" } }
                      },
                      [_vm._v("Назад")]
                    )
                  ]
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }