var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pagination f-14 f-md-12 f-500 text-gray-400" },
    [
      _vm.count > _vm.diff * 2
        ? _c("div", {
            staticClass: "prev d-inline-block hoverable px-2",
            class: { active: _vm.value > 1 },
            on: { click: _vm.prev }
          })
        : _vm._e(),
      _vm._l(_vm.items, function(item, index) {
        return _c("div", { key: index, staticClass: "d-inline-block" }, [
          _c(
            "div",
            {
              staticClass: "d-inline-block px-2 hoverable",
              class: { "text-primary fw-500": item == _vm.value },
              on: {
                click: function($event) {
                  return _vm.click(item)
                }
              }
            },
            [_vm._v(_vm._s(item))]
          ),
          _vm.items[index + 1] && _vm.items[index + 1] - item > 1
            ? _c("div", { staticClass: "d-inline-block px-2" }, [_vm._v("...")])
            : _vm._e()
        ])
      }),
      _vm.count > _vm.diff * 2
        ? _c("div", {
            staticClass: "next d-inline-block hoverable px-2",
            class: { active: _vm.value < _vm.count },
            on: { click: _vm.next }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }