import _ from 'lodash';
import Router from 'vue-router';

let loadPage = path => require(`./pages/${path}/page.vue`).default;

const PAGES = {
  container: loadPage('container'),
  map: loadPage('map'),
  mapView: loadPage('map-view')
};

const CONFIG = {
  mode: 'history',
  scrollBehavior: () => ({
    x: 0,
    y: 0
  }),
  linkActiveClass: 'active',
  routes: [{
      path: '/contacts',
      component: PAGES.container,
      children: [
        {
          path: '',
          name: 'map',
          component: PAGES.map,
        },
        {
          path: '/contacts/:id',
          name: 'mapView',
          component: PAGES.mapView,
        }
      ]
    },
    {
      path: '*',
      component: PAGES.empty
    }
  ]
};

let router = new Router(CONFIG);

export default router;