<template lang="pug">
  div
    .hint-label.f-12.fw-500.text-center.text-pink(tabindex="1")
      | {{title}}
    .hint-text.f-12.text-center.text-white.p-2
      slot
</template>

<script>
  export default {
    props: ['title']
  };
</script>

<style lang="scss" scoped>
  .hint-label {
    position: relative;
    outline: none;
    &:before {
      content: '';
      display: none;
      position: absolute;
      bottom: -13px;
      left: 50%;
      width: 6px;
      height: 6px;
      background-color: #737373;
      transform: translate(-50%, 0) rotate(45deg);
    }
    &:focus {
      &:before {
        display: block;
      }
    }
  }
  .hint-text {
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    margin-top: 10px;
    background-color: #737373;
    border-radius: 5px;
    z-index: 10;
  }
  .hint-label:focus + .hint-text {
    display: block;
  }
</style>