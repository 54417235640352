<template lang="pug">
.news-element
  .news-element-content(v-if="showNews")
    .news-element-item
      .text {{news.title}}
      a.link(:href="news.link", @click="close()") Подробнее
      i.icon-close.hoverable(@click="close()")
</template>

<script>
import api from "@/js/api.js";
export default {
  data() {
    return {
      showNews: false,
      news: null,
      localStorage: "",
      closedId: []
    };
  },
  async created() {
    this.localStorage = localStorage.getItem("closedNews")
      ? localStorage.getItem("closedNews")
      : "";
    this.localStorageParse();
    const res = await api.getLatestNews();
    if (res.data) {
      const isClosed = this.findById(res.data.id);
      if (!isClosed) {
        this.showNews = true;
        this.setNewsStyle();
        const { title, link, id, pub_date } = res.data;
        this.news = { title, link, id, pub_date };
      }
    }
  },
  methods: {
    close() {
      this.localStorage = `${this.localStorage}${this.news.id}-`;
      localStorage.setItem("closedNews", this.localStorage);
      this.showNews = false;
      this.news = null;
      this.removeNewsStyle();
    },
    localStorageParse() {
      if (this.localStorage) {
        this.closedId = this.localStorage.split("-");
      }
    },
    findById(id) {
      const res = this.closedId.find(item => {
        return item == id;
      });
      return !!res;
    },
    setNewsStyle() {
      document.querySelector("#header").classList.add("news");
      document.querySelector("#Dropdown").classList.add("news");
    },
    removeNewsStyle() {
      document.querySelector("#header").classList.remove("news");
      document.querySelector("#Dropdown").classList.remove("news");
    }
  }
};
</script>

<style lang="scss" scoped>
.news-element {
  position: absolute;
  left: 0;
  top: -48px;
  width: 100%;
  z-index: 100;

  &-content {
    display: flex;
    justify-content: center;
    padding: 12px 0;
    border-bottom: 1px solid #bbb;
    border-top: 2px solid #ca1226;
    box-shadow: 0 2px 4px #ccc;
    background-color: #fff;
  }

  &-item {
    display: flex;
    align-items: center;
  }

  .text {
    padding-right: 5px;
  }

  .link {
    padding-right: 7px;
    text-decoration: underline;
  }

  .icon-close {
    font-size: 12px;
    &:hover {
      opacity: 0.7;
    }
  }
}
</style>
