/**
 * Парсит текст, с разделителями, т.к. в бд хранится не верстка, а просто текст.
 * Возможно придется форматировать что-то еще по признаку.
 */
export default (text) => {
  let li = ''
  if (!text) return li
  text.split('|').forEach(item => {
    if (item) li += `<li>${item}</li>`
  });
  return `<ul class="parse-content-simple mb-0">${li}</ul>`
}