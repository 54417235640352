var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.page !== 1
        ? _c(
            "a",
            {
              staticClass: "btn-back mb-2",
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  return _vm.setPage(1)
                }
              }
            },
            [
              _c("i", { staticClass: "icon-arrow-simple rotate-down" }),
              _vm._v(" Назад к списку")
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("h3", [
        _vm.page === 2
          ? _c("span", { staticClass: "d-inline-block mb-3" }, [
              _vm._v("\n      Отклик на вакансию "),
              _vm.currentModelPage.name
                ? _c("span", [
                    _vm._v("«" + _vm._s(_vm.currentModelPage.name) + "»")
                  ])
                : _vm._e()
            ])
          : _c("span", { staticClass: "d-inline-block mb-3" }, [
              _vm._v("\n      Вакансии\n    ")
            ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "mt-3" }),
      _vm._v(" "),
      _vm.success
        ? _c("div", { staticClass: "alert alert-success" }, [
            _vm._v("\n    " + _vm._s(_vm.success) + "\n  ")
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.error
        ? _c("div", { staticClass: "alert alert-danger" }, [
            _vm._v("\n    " + _vm._s(_vm.error) + "\n  ")
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.loading ? _c("preloader", { attrs: { over: "" } }) : _vm._e(),
      _vm._v(" "),
      _vm.page === 1 && !_vm.error
        ? [
            !_vm.loading
              ? _c(
                  "c-row",
                  [
                    _c(
                      "c-col",
                      { staticClass: "col-12 col-lg-3 col-md-4" },
                      [
                        _vm.navs && _vm.navs.length
                          ? _c("nav-sidebar", {
                              attrs: {
                                value: _vm.currentCategoryId,
                                items: _vm.navs
                              },
                              on: { "nav-change": _vm.getContent }
                            })
                          : _c("div", { staticClass: "alert alert-danger" }, [
                              _vm._v("\n          Нет данных\n        ")
                            ])
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "c-col",
                      { staticClass: "col-12 col-lg-9 col-md-8" },
                      [
                        _vm.loadingContent
                          ? _c("preloader")
                          : [
                              _vm.content && _vm.content.length
                                ? _vm._l(_vm.content, function(item) {
                                    return _c(
                                      "div",
                                      {
                                        key: item.id,
                                        staticClass: "vacancy-card"
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "vacancy-card-header"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "f-18 f-md-16 fw-500 mb-3"
                                              },
                                              [_vm._v(_vm._s(item.name))]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "ul",
                                              {
                                                staticClass:
                                                  "vacancy-card-header__actions mb-2"
                                              },
                                              [
                                                item.city_name
                                                  ? _c(
                                                      "li",
                                                      { staticClass: "mb-2" },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "icon-placeholder-copy"
                                                        }),
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              item.city_name
                                                            ) +
                                                            "\n                  "
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _c(
                                                  "li",
                                                  { staticClass: "mb-2" },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "icon-time-line"
                                                    }),
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(item.schedule) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "li",
                                                  { staticClass: "mb-2" },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "icon-credit-card-line"
                                                    }),
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(item.wage) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "vacancy-card-body" },
                                          [
                                            _c("ul", [
                                              _c(
                                                "li",
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "fw-500 mb-2 mt-3"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      Обязанности:\n                    "
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "c-row",
                                                    [
                                                      _c(
                                                        "c-col",
                                                        {
                                                          staticClass:
                                                            "col-12 col-lg-10"
                                                        },
                                                        [
                                                          _c("div", {
                                                            staticClass:
                                                              "vacancy-card-body__text",
                                                            domProps: {
                                                              innerHTML: _vm._s(
                                                                item.responsibility
                                                              )
                                                            }
                                                          })
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "li",
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "fw-500 mb-2 mt-3"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      Требования:\n                    "
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "c-row",
                                                    [
                                                      _c(
                                                        "c-col",
                                                        {
                                                          staticClass:
                                                            "col-12 col-lg-10"
                                                        },
                                                        [
                                                          _c("div", {
                                                            staticClass:
                                                              "vacancy-card-body__text",
                                                            domProps: {
                                                              innerHTML: _vm._s(
                                                                item.requirements
                                                              )
                                                            }
                                                          })
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "li",
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "fw-500 mb-2 mt-3"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      Условия:\n                    "
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "c-row",
                                                    [
                                                      _c(
                                                        "c-col",
                                                        {
                                                          staticClass:
                                                            "col-12 col-lg-10"
                                                        },
                                                        [
                                                          _c("div", {
                                                            staticClass:
                                                              "vacancy-card-body__text",
                                                            domProps: {
                                                              innerHTML: _vm._s(
                                                                item.conditions
                                                              )
                                                            }
                                                          })
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ])
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "vacancy-card-footer mt-4"
                                          },
                                          [
                                            _c(
                                              "c-button",
                                              {
                                                on: {
                                                  click: function($event) {
                                                    return _vm.bSend(item)
                                                  }
                                                }
                                              },
                                              [_vm._v("Откликнуться")]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  })
                                : _c(
                                    "div",
                                    { staticClass: "alert alert-danger" },
                                    [
                                      _vm._v(
                                        "\n            Нет данных\n          "
                                      )
                                    ]
                                  )
                            ]
                      ],
                      2
                    )
                  ],
                  1
                )
              : _vm._e()
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.page === 2
        ? [
            _c("send-form", {
              ref: "sendForm",
              attrs: { currentModel: _vm.currentModelPage },
              on: {
                success: _vm.successEvent,
                error: _vm.errorEvent,
                loading: _vm.loadingEvent
              }
            })
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }