var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "ul",
      { staticClass: "navi navi-pills d-block d-md-flex" },
      _vm._l(_vm.items, function(item, index) {
        return (item.check
        ? item.check()
        : true)
          ? _c("li", { staticClass: "navi-item" }, [
              _c(
                "a",
                {
                  staticClass: "f-12 navi-link",
                  class: { active: item.active },
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      $event.preventDefault(), item.action()
                    }
                  }
                },
                [_vm._v(_vm._s(item.title))]
              )
            ])
          : _vm._e()
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }