var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "plusminus bg-gray-200",
      class: { "is-invalid": _vm.isInvalid }
    },
    [
      _c("div", { staticClass: "row mx-0 flex-nowrap" }, [
        _c("div", { staticClass: "col text-gray-400 va" }, [
          _c("div", { staticClass: "vam f-12" }, [
            _vm._v("\n        " + _vm._s(_vm.placeholder) + "\n      ")
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col col-auto p-0" }, [
          _c("div", { staticClass: "row mx-0" }, [
            _c("div", { staticClass: "col-auto p-0" }, [
              _c("div", {
                staticClass: "minus",
                class: { disabled: !_vm.canMinus },
                on: { click: _vm.minus }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-auto px-4 f-18 va" }, [
              _c("div", { staticClass: "vam" }, [
                _vm._v(
                  "\n            " + _vm._s(_vm.value || 0) + "\n          "
                )
              ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "col col-auto p-0",
                class: { disabled: !_vm.canPlus },
                on: { click: _vm.plus }
              },
              [_c("div", { staticClass: "plus" })]
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }