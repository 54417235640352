var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sidebar text-center text-md-left" },
    _vm._l(_vm.items, function(item, index) {
      return _c(
        "div",
        {
          key: index,
          staticClass: "d-inline-block menu-item border-gray-200 px-3"
        },
        [
          _c(
            "div",
            {
              staticClass: "fa py-2 hoverable",
              class: { "fw-500 text-primary": item.active },
              on: {
                click: function($event) {
                  return _vm.click(item)
                }
              }
            },
            [
              _c("i", { staticClass: "menu-icon", class: "icon-" + item.icon }),
              _c("span", { staticClass: "f-12 f-md-14 ml-2 ml-md-3" }, [
                _vm._v(_vm._s(item.title))
              ])
            ]
          )
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }