var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "back",
        { staticClass: "text-gray-300", attrs: { path: "/calculator" } },
        [_vm._v("Назад")]
      ),
      _vm.loading
        ? _c("preloader", { staticClass: "mt-5" })
        : [
            _c("div", { staticClass: "f-18 fw-500 text-gray-800 mt-2" }, [
              _vm._v("Сохраненные расчеты")
            ]),
            _c(
              "div",
              { staticClass: "mt-3" },
              [
                _c("paginate", {
                  ref: "list",
                  attrs: { load: _vm.load },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(ref) {
                        var item = ref.item
                        var index = ref.index
                        return _c("item-request-saved", {
                          staticClass: "mb-3 hoverable",
                          attrs: { item: item },
                          on: { show: _vm.show, remove: _vm.remove }
                        })
                      }
                    }
                  ])
                })
              ],
              1
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }