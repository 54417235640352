var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mb-5" },
    [
      _c("settings-menu"),
      _c(
        "c-row",
        { staticClass: "mt-3" },
        [
          _c("c-col", [
            _c("div", { staticClass: "f-18 fw-500 text-gray-800" }, [
              _vm._v("Доступные плательщики")
            ])
          ]),
          _c(
            "c-col",
            { staticClass: "col-auto floating-pane" },
            [
              _c(
                "c-button",
                {
                  staticClass: "w-100 w-md-auto",
                  attrs: { to: { name: "payers-create" } }
                },
                [_vm._v("Новый плательщик")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("paginate", {
        staticClass: "mt-3 mt-md-2",
        attrs: { load: _vm.load },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var item = ref.item
              var index = ref.index
              return _c(
                "div",
                {
                  staticClass:
                    "mx-n3 mx-md-0 mt-md-3 bg-md-gray-100 px-3 py-3 border-bottom border-top",
                  class: {
                    "border-md-bottom-0": !item.DefaultPayer,
                    "border-md-top-0": !item.DefaultPayer
                  },
                  on: {
                    click: function($event) {
                      return _vm.edit(item.AgentID)
                    }
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "mt-1 mb-4" },
                    [
                      _c(
                        "c-checkbox",
                        {
                          staticClass: "mt-2",
                          attrs: { disabled: item.mainPayer },
                          on: {
                            input: function($event) {
                              return _vm.changeMainPayer(item)
                            }
                          },
                          model: {
                            value: item.mainPayer,
                            callback: function($$v) {
                              _vm.$set(item, "mainPayer", $$v)
                            },
                            expression: "item.mainPayer"
                          }
                        },
                        [_vm._v("Основной плательщик")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "c-row",
                    { staticClass: "flex-nowrap" },
                    [
                      _c("c-col", [
                        _c(
                          "div",
                          {
                            staticClass:
                              "f-10 fw-500 text-muted text-uppercase d-none d-md-block"
                          },
                          [_vm._v("Название")]
                        ),
                        _c(
                          "div",
                          { staticClass: "mt-md-2 f-16 text-gray-800" },
                          [_vm._v(_vm._s(item.Name))]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "mt-1 f-14 fw-300 text-gray-800",
                            class: { "text-transparent": item.PassportType }
                          },
                          [_vm._v(_vm._s(item.Contact) + "  ")]
                        )
                      ]),
                      _c(
                        "c-col",
                        { staticClass: "col-auto d-block d-md-none" },
                        [
                          _c("div", { staticClass: "h-100 fa" }, [
                            _c("i", {
                              staticClass: "icon-arrow-chevron r-270 text-muted"
                            })
                          ])
                        ]
                      ),
                      _c("c-col", { staticClass: "col-3 d-none d-md-block" }, [
                        _c(
                          "div",
                          {
                            staticClass: "f-10 fw-500 text-muted text-uppercase"
                          },
                          [_vm._v("Способы связи")]
                        ),
                        item.Phone
                          ? _c(
                              "div",
                              { staticClass: "mt-2 f-14 text-gray-800" },
                              [_vm._v(_vm._s(item.Phone))]
                            )
                          : _vm._e(),
                        item.Email
                          ? _c(
                              "div",
                              { staticClass: "mt-1 f-12 fw-300 text-gray-800" },
                              [_vm._v(_vm._s(item.Email))]
                            )
                          : _vm._e()
                      ]),
                      _c(
                        "c-col",
                        { staticClass: "col-3 d-none d-md-block" },
                        [
                          item.PassportType
                            ? [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "f-10 fw-500 text-muted text-uppercase"
                                  },
                                  [_vm._v("Тип документа")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "mt-2 f-14 text-gray-800" },
                                  [_vm._v(_vm._s(item.PassportType))]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "mt-1 f-12 fw-300 text-gray-800"
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(item.PassportS) +
                                        " " +
                                        _vm._s(item.PassportN)
                                    )
                                  ]
                                )
                              ]
                            : [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "f-10 fw-500 text-muted text-uppercase"
                                  },
                                  [_vm._v("Данные")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "mt-2 f-14 text-gray-800" },
                                  [_vm._v("ИНН " + _vm._s(item.Inn))]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "mt-1 f-12 fw-300 text-gray-800"
                                  },
                                  [_vm._v("КПП " + _vm._s(item.Kpp))]
                                )
                              ]
                        ],
                        2
                      ),
                      _c(
                        "c-col",
                        { staticClass: "col-3 d-none d-md-block" },
                        [
                          item.AddEmail
                            ? [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "f-10 fw-500 text-muted text-uppercase"
                                  },
                                  [_vm._v("E-mail уведомлений")]
                                ),
                                _c(
                                  "c-checkbox",
                                  {
                                    staticClass: "mt-2",
                                    attrs: { disabled: true },
                                    model: {
                                      value: item.AddEmailActive,
                                      callback: function($$v) {
                                        _vm.$set(item, "AddEmailActive", $$v)
                                      },
                                      expression: "item.AddEmailActive"
                                    }
                                  },
                                  [_vm._v(_vm._s(item.AddEmail))]
                                )
                              ]
                            : _vm._e()
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _c(
                    "c-row",
                    { staticClass: "mt-3 flex-nowrap d-none d-md-flex" },
                    [
                      _c("c-col", [
                        _c(
                          "div",
                          {
                            staticClass: "f-10 fw-500 text-muted text-uppercase"
                          },
                          [_vm._v("Адрес")]
                        ),
                        _c("div", { staticClass: "mt-2 f-14 text-gray-800" }, [
                          _vm._v(_vm._s(item.TownName))
                        ]),
                        _c(
                          "div",
                          { staticClass: "mt-1 f-12 fw-300 text-gray-800" },
                          [_vm._v(_vm._s(item.Address))]
                        )
                      ]),
                      _c("c-col", { staticClass: "col-3" }, [
                        _c(
                          "div",
                          {
                            staticClass: "f-10 fw-500 text-muted text-uppercase"
                          },
                          [_vm._v("Форма собственности")]
                        ),
                        _c("div", { staticClass: "mt-2 f-14 text-gray-800" }, [
                          _vm._v(_vm._s(item.AgentForm))
                        ])
                      ]),
                      _c("c-col", { staticClass: "col-3" }, [
                        _c(
                          "div",
                          {
                            staticClass: "f-10 fw-500 text-muted text-uppercase"
                          },
                          [_vm._v("Форма оплаты")]
                        ),
                        _c("div", { staticClass: "mt-2 f-14 text-gray-800" }, [
                          _vm._v(_vm._s(item.PayForm))
                        ])
                      ]),
                      _c(
                        "c-col",
                        { staticClass: "col-3" },
                        [
                          item.AddPhone
                            ? [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "f-10 fw-500 text-muted text-uppercase"
                                  },
                                  [_vm._v("Телефон уведомлений")]
                                ),
                                _c(
                                  "c-checkbox",
                                  {
                                    staticClass: "mt-2",
                                    attrs: { disabled: true },
                                    model: {
                                      value: item.AddPhoneActive,
                                      callback: function($$v) {
                                        _vm.$set(item, "AddPhoneActive", $$v)
                                      },
                                      expression: "item.AddPhoneActive"
                                    }
                                  },
                                  [_vm._v(_vm._s(item.AddPhone))]
                                )
                              ]
                            : _vm._e()
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "text-right mr-2 d-none d-md-block" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "mt-2 pt-2 text-primary f-12 fw-500 text-nowrap hoverable",
                          on: {
                            click: function($event) {
                              return _vm.$router.push({
                                name: "payers-edit",
                                params: { id: item.AgentID }
                              })
                            }
                          }
                        },
                        [
                          _c("i", { staticClass: "icon-pencil mr-2" }),
                          _c(
                            "div",
                            { staticClass: "d-none d-md-inline-block" },
                            [_vm._v("Редактировать")]
                          )
                        ]
                      )
                    ]
                  )
                ],
                1
              )
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }