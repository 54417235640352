var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("settings-menu"),
      _c("div", { staticClass: "mt-3 mb-2 f-18 text-gray-800 fw-500" }, [
        _vm._v("Права пользователей")
      ]),
      _c(
        "c-table",
        { staticClass: "mt-3", attrs: { data: _vm.list } },
        [
          _c("template", { slot: "empty" }, [_vm._v("Список пуст.")]),
          _c("c-column", { attrs: { prop: "email", label: "Пользователь" } }),
          _c("c-column", {
            attrs: { prop: "rights", label: "Назначение прав пользователей" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("c-checkbox", {
                      staticClass: "d-inline-block d-md-block",
                      attrs: { disabled: !_vm.canEdit },
                      model: {
                        value: scope.row.right_to_edit_rights,
                        callback: function($$v) {
                          _vm.$set(scope.row, "right_to_edit_rights", $$v)
                        },
                        expression: "scope.row.right_to_edit_rights"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("c-column", {
            attrs: { prop: "request", label: "Создание новой заявки" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("c-checkbox", {
                      staticClass: "d-inline-block d-md-block",
                      attrs: { disabled: !_vm.canEdit },
                      model: {
                        value: scope.row.right_to_create_order,
                        callback: function($$v) {
                          _vm.$set(scope.row, "right_to_create_order", $$v)
                        },
                        expression: "scope.row.right_to_create_order"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("c-column", {
            attrs: { prop: "requestDocs", label: "Доступ к документам заявки" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("c-checkbox", {
                      staticClass: "d-inline-block d-md-block",
                      attrs: { disabled: !_vm.canEdit },
                      model: {
                        value: scope.row.right_to_see_order_docs,
                        callback: function($$v) {
                          _vm.$set(scope.row, "right_to_see_order_docs", $$v)
                        },
                        expression: "scope.row.right_to_see_order_docs"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("c-column", {
            attrs: { prop: "payers", label: "Добавление плательщиков" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("c-checkbox", {
                      staticClass: "d-inline-block d-md-block",
                      attrs: { disabled: !_vm.canEdit },
                      model: {
                        value: scope.row.right_to_add_payer,
                        callback: function($$v) {
                          _vm.$set(scope.row, "right_to_add_payer", $$v)
                        },
                        expression: "scope.row.right_to_add_payer"
                      }
                    })
                  ]
                }
              }
            ])
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }