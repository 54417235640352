var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-5" },
    [
      _c("h3", [_vm._v("Активация аккаунта")]),
      _c(
        "c-row",
        { staticClass: "mt-3" },
        [
          _c(
            "c-col",
            { staticClass: "col-12 col-lg-4 col-sm-6" },
            [
              _vm.loading
                ? _c("preloader", { staticClass: "mt-5" })
                : [
                    _c(
                      "div",
                      { staticClass: "mt-3" },
                      [
                        !_vm.status
                          ? [_vm._v(_vm._s(_vm.error))]
                          : [
                              _vm._v(
                                "Аккаунт успешно перенесен в организацию " +
                                  _vm._s(_vm.company)
                              ),
                              _c(
                                "c-button",
                                {
                                  staticClass: "mt-3 w-100",
                                  attrs: { to: { name: "login" } }
                                },
                                [_vm._v("Вход")]
                              )
                            ]
                      ],
                      2
                    )
                  ]
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }