import _ from 'lodash';

const isInvalid = function() {
  let field = getValidationField.call(this);
  if (field) {
    field.$touch();
  }
};

const getValidationField = function() {
  let validation = this.validation;
  let expr = getExpr.call(this);
  let field = _.get(validation, expr);
  return field;
}

const getExpr = function() {
  return this.accessor || _.get(this.$vnode, 'data.model.expression');
}

export default {
  props: {
    accessor: String,
    validate: [String, Object],
    name: String
  },
  data: () => ({
    // isInvalid: false
  }),
  computed: {
    expr() {
      return this.accessor || _.get(this.$vnode, 'data.model.expression');
    },
    isInvalid() {
      return this._isInvalid();
    }
  },
  methods: {
    _isInvalid() {
      let expr = getExpr.call(this);
      let field = getValidationField.call(this);

      // console.log(expr, this.$el);
      return _.get(field, '$invalid') ? field : null; // field ? /** field.$dirty && */ field.$invalid : false;
    }
  },
  watch: {
    value() {
      isInvalid.call(this);
      this.$forceUpdate();
    }
  }
}