<template>
  <div>
    <slot name="before"></slot>
    <c-select placeholder="Форма собственности" :options="options.ownershipTypes" v-model="value.agent_type"></c-select>
    <c-input class="mt-2 mt-md-0" v-if="value.agent_type == 1" placeholder="ФИО" v-model="value.agent_name"></c-input>
    <c-input class="mt-2 mt-md-0" v-if="~[2,3].indexOf(value.agent_type)" placeholder="Наименование" v-model="value.agent_name"></c-input>
    <template v-if="value.agent_type == 1">
      <c-select class="mt-2 mt-md-0" placeholder="Документ" :options="options.docTypes" v-model="value.agent_document_type_id"></c-select>
      <c-row class="mx-n1">
        <c-col class="col-12 col-md-6 px-1 mt-2 mt-md-0">
          <c-input placeholder="Серия" v-model="value.agent_document_series"></c-input>
        </c-col>
        <c-col class="col-12 col-md-6 px-1 mt-2 mt-md-0">
          <c-input placeholder="Номер" v-model="value.agent_document_number"></c-input>
        </c-col>
      </c-row>
    </template>
    <template v-if="~[2,3].indexOf(value.agent_type)">
      <c-input class="mt-2 mt-md-0" placeholder="ИНН" v-model="value.agent_inn"></c-input>
      <c-input class="mt-2 mt-md-0" placeholder="КПП" v-model="value.agent_kpp"></c-input>
      <c-input class="mt-2 mt-md-0" placeholder="Контактное лицо" v-model="value.agent_contact"></c-input>
    </template>
    <c-row class="mx-n1">
      <c-col class="col-12 col-md-6 px-1 mt-2 mt-md-0">
        <c-input placeholder="Телефон" v-model="value.agent_phone" v-mask="{mask: '+99999999999'}"></c-input>
      </c-col>
      <c-col class="col-12 col-md-6 px-1 mt-2 mt-md-0">
        <c-input placeholder="Добавочный" v-model="value.agent_extra_phone" v-mask="{mask: '9999'}"></c-input>
      </c-col>
    </c-row>
    <!-- <c-input v-else class="mt-2 mt-md-0" placeholder="Телефон" v-model="value.agent_phone" v-mask="{mask: '+99999999999'}"></c-input> -->
    <c-input class="mt-2 mt-md-0" placeholder="E-Mail" v-model="value.agent_email"></c-input>
    <div class="shadow-box mt-3" :class="{active: value.agent_is_informing_mail}" v-if="$v.agent_email.$model && !$v.agent_email.$invalid">
      <c-checkbox v-model="value.agent_is_informing_mail">
        <span class="fw-500 fw-md-normal">
          Оповещать по e-mail
        </span>
      </c-checkbox>
    </div>
    <div class="shadow-box mt-3" :class="{active: value.agent_is_informing_sms}" v-if="$v.agent_phone.$model && !$v.agent_phone.$invalid">
      <c-checkbox v-model="value.agent_is_informing_sms" v-if="!value.agent_extra_phone">
        <span class="fw-500 fw-md-normal">
          Оповещать по SMS
        </span>
      </c-checkbox>
      <c-checkbox :disabled="true" v-model="value.agent_is_informing_sms" v-else>
        <span class="fw-500 fw-md-normal">
          Оповещать по SMS
        </span>
        <c-tip class="tip-express ml-2 d-none d-md-inline-block">
          <template slot="subject">
              <i class="icon-help text-gray-300 f-16 lh-0"></i>
          </template>
          <div>
            Отправка уведомлений на телефон с добавочным номером невозможна
          </div>
        </c-tip>
      </c-checkbox>
    </div>
    <slot name="after">
    </slot>
  </div>
</template>

<script>
  export default {
    props: ['value', 'options'],
    inject: ['validation'],
    provide() {
      let index = _.findIndex(this.validation.agents.$model, item => item == this.value);
      return {
        validation: {
          value: this.validation.agents.$each[index]
        }
      }
    },
    computed: {
      $v(value) {
        let index = _.findIndex(this.validation.agents.$model, item => item == this.value);
        return this.validation.agents.$each[index];
      }
    },
    watch: {
      'value.agent_extra_phone': function (val) {
        if (val) this.value.agent_is_informing_sms = false
      },
      deep: true
    },
    mounted() {
    }
  };
</script>