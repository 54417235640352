<template>
  <div class="map page-2" :class="{'map-mobile': isMobile}">
    <h3 class="map__title">
      <span class="d-inline-block mb-3">
        {{title}}
      </span>
      <a href="javascript:void(0)" class="btn-back mb-2" @click="$router.push({ name: 'map' });">
        Все филиалы
      </a>
    </h3>
    <div v-if="error" class="alert alert-danger">
      {{error}}
    </div>
    <preloader over v-if="loading"></preloader>
    <template v-else>
      <c-row class="no-gutters">
        <c-col class="map-search__select">
          <c-select
            placeholder="Поиск по городу"
            :options="options.cities"
            v-model="model.city_id"
            :filter="filterCities"
            :getOptionLabel="getCityLabel"
            @input="inputSearch"
          ></c-select>
        </c-col>
        <!-- <c-col class="map-search__btn" v-if="!isMobile">
          <c-button :disabled="model.city_id ? false : true" @click="loadById(model.city_id)">Поиск</c-button>
        </c-col> -->
      </c-row>
    </template>
    <c-row class="mt-4" :class="{'d-none-error': error}">
      <c-col class="col-12 col-lg-4 map-sidebar">
        <template v-if="info">
          <h3 v-if="!isMobile"><span class="d-inline-block mb-3">{{info.TownName}}</span></h3>
          <div class="map-card-container scrollbar">
            <template v-if="info.sidebar && info.sidebar.length && info.isFilial">
              <div class="map-card" v-for="(item, index) in info.sidebar" :key="index" :class="{'map-card-body__list--active': item.active}">
                <div class="map-card-header map-card-header-mobile" v-if="isMobile && !item.active" @click="goToPlacemark(item.data)"> <!-- Если надо автоскроллинг goToPlacemark(item.data, { isMobile: isMobile }) -->
                  <div class="map-card-header__title">
                    <strong>{{item.data.FSName}}</strong>
                    <span>{{item.data.FSAddress}}</span>
                  </div>
                  <div class="map-card-header__point">
                    <a class="map-card-header__link" :class="{'map-card-header__link--empty': lodash.isEmpty(item.data.FSGeoLocation)}" href="javascript: void(0)">{{lodash.isEmpty(item.data.FSGeoLocation) ? 'Нет геоточки' : 'На карте'}} <i class="icon-placeholder"></i></a>
                  </div>
                </div>
                <div class="map-card-header">
                  <div class="map-card-header__title">
                    {{item.data.FSName}}
                  </div>
                  <div>
                    <a class="map-card-header__link" :class="{'map-card-header__link--empty': lodash.isEmpty(item.data.FSGeoLocation)}" href="javascript: void(0)" @click="goToPlacemark(item.data)">{{lodash.isEmpty(item.data.FSGeoLocation) ? 'Нет геоточки' : 'На карте'}} <i class="icon-placeholder"></i></a>
                  </div>
                </div>
                <div class="map-card-body">
                  <ul class="map-card-body__list" v-if="info.isFilial">
                    <li v-for="(el, i) in item.tpl" :key="i" :class="{'map-card-body__list--blue': el.extra, 'map-card-body__list--through': el.empty}">
                      <i :class="[el.icon]"></i>
                      <template v-if="lodash.isArray(el.value)">
                        <ul>
                          <li v-for="(subel, j) in el.value" :key="j">
                            <template v-if="lodash.isObject(subel)">
                              <div class="map-card-body__keyval">
                                <div><strong>{{subel.name}}</strong></div> <div>{{subel.value}}</div>
                              </div>
                            </template>
                            <template v-else>
                              <a :href="`tel:${subel}`" v-if="el.icon === 'icon-call'">{{subel}}</a>
                              <span v-else>
                                {{subel}}
                              </span>
                            </template>
                          </li>
                        </ul>
                      </template>
                      <template v-else>
                        {{el.value}}
                      </template>
                    </li>
                  </ul>
                </div>
              </div>
            </template>
            <div class="map-card" v-if="!info.isFilial">
              <div class="map-card-body map-card-body__isFilial">
                  <ul class="map-card-body__list">
                    <li class="map-card-body__list--blue" :class="{'map-card-body__list--through': !info.FSTermo}">
                      <i class="icon-thermometer" :class="{'map-card-body__list--gray': !info.FSTermo}"></i>
                      Возможна доставка грузов с терморежимом
                    </li>
                    <li class="map-card-body__list--blue" :class="{'map-card-body__list--through': !info.PossibleToSendTermo}">
                      <i class="icon-thermometer" :class="{'map-card-body__list--gray': !info.PossibleToSendTermo}"></i>
                      Возможна отправка грузов с терморежимом.
                    </li>
                  </ul>
                  <div class="text-center map-card-body__nextfs mt-4 mb-4" v-if="info.FSID">
                    <div>Ближайший терминал обслуживания - {{info.FSName}}</div>
                    <c-button :class="{white: !isMobile, 'w-100': isMobile}" @click="loadById(info.FSID)">Перейти</c-button>
                  </div>
                  <div class="text-left map-card-body__nextfs" v-else>
                    <div>Расчет стоимости и доставка в данный населенный пункт осуществляется по предварительному согласованию.</div>
                    <div>Пожалуйста, воспользуйтесь формой обратной связи или позвоните по телефону
                      <a href="callto:88002002591">8 800 200 25 91</a>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </template>
      </c-col>
      <c-col class="col-12 col-lg-8">
        <div id="js-map" class="map-area"></div>
      </c-col>
    </c-row>
  </div>
</template>

<script>
import mapBase from '../map-base/index'
export default {
  extends: mapBase
}
</script>
