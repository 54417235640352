<template lang="pug">
  div
    settings-menu
    c-row.mt-3
      c-col.col-12.col-md-6.order-1.order-md-2
        preloader.mt-5(v-if="loading")
        template(v-if="!loading && user")
          .mb-4.f-18.text-gray-800.fw-500
            | Профиль
          .mt-1.f-16.text-gray-800
            | {{user.full_name}}
          .mt-1.f-14.text-gray-400
            | {{user.company}}
          .mt-1.f-14.text-gray-400(v-if="user.phone")
            | {{user.phone}}
          .mt-1.f-14.text-gray-800
            | {{user.email}}
          .mt-1.f-14.text-gray-800
            | {{user.TB_ID}}
          .my-4.f-18.text-gray-800.fw-500
            | Права доступа
          .mt-1(v-if="user.right_to_edit_rights")
            | Назначение прав пользователей
          .mt-1(v-if="user.right_to_create_order")
            | Создание новой заявки
          .mt-1(v-if="user.right_to_see_order_docs")
            | Доступ к документам заявки
          .mt-1(v-if="user.right_to_add_payer")
            | Добавление плательщиков
          button.mt-4.btn.btn-outline-primary.px-4.py-2.f-14.fw-500(@click="$router.push({name: 'profile-edit'})")
            | Редактировать профиль
</template>

<script>
  import _ from 'lodash';
  import api from '@/js/api';
  export default {
    data: () => ({
      user: null,
      loading: true
    }),
    mounted() {
      // this.user = _.cloneDeep(this.$viewer.data);
      api.getProfile()
        .then(response => {
          this.$notify({
              group: 'foo',
              clean: true
            })
          this.user = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  };
</script>

<style lang="scss" scoped>
  .menu-item {
    &:hover {
      background-color: #fafafa;
    }
    &:first-child {
      border-radius: 4px 4px 0 0;
    }
    &:last-child {
      border-radius: 0 0 4px 4px;
    }
    & + & {
      border-top: none !important;
    }
    .icons {
      display: inline-block;
      width: 24px;
      font-size: 18px;
      line-height: 0;
    }
  }
</style>