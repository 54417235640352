var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("settings-menu"),
      _c(
        "c-row",
        { staticClass: "mt-3" },
        [
          _c(
            "c-col",
            { staticClass: "col-12 col-md-6 order-1 order-md-2" },
            [
              _vm.loading ? _c("preloader", { staticClass: "mt-5" }) : _vm._e(),
              !_vm.loading && _vm.user
                ? [
                    _c(
                      "div",
                      { staticClass: "mb-4 f-18 text-gray-800 fw-500" },
                      [_vm._v("Профиль")]
                    ),
                    _c("div", { staticClass: "mt-1 f-16 text-gray-800" }, [
                      _vm._v(_vm._s(_vm.user.full_name))
                    ]),
                    _c("div", { staticClass: "mt-1 f-14 text-gray-400" }, [
                      _vm._v(_vm._s(_vm.user.company))
                    ]),
                    _vm.user.phone
                      ? _c("div", { staticClass: "mt-1 f-14 text-gray-400" }, [
                          _vm._v(_vm._s(_vm.user.phone))
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "mt-1 f-14 text-gray-800" }, [
                      _vm._v(_vm._s(_vm.user.email))
                    ]),
                    _c("div", { staticClass: "mt-1 f-14 text-gray-800" }, [
                      _vm._v(_vm._s(_vm.user.TB_ID))
                    ]),
                    _c(
                      "div",
                      { staticClass: "my-4 f-18 text-gray-800 fw-500" },
                      [_vm._v("Права доступа")]
                    ),
                    _vm.user.right_to_edit_rights
                      ? _c("div", { staticClass: "mt-1" }, [
                          _vm._v("Назначение прав пользователей")
                        ])
                      : _vm._e(),
                    _vm.user.right_to_create_order
                      ? _c("div", { staticClass: "mt-1" }, [
                          _vm._v("Создание новой заявки")
                        ])
                      : _vm._e(),
                    _vm.user.right_to_see_order_docs
                      ? _c("div", { staticClass: "mt-1" }, [
                          _vm._v("Доступ к документам заявки")
                        ])
                      : _vm._e(),
                    _vm.user.right_to_add_payer
                      ? _c("div", { staticClass: "mt-1" }, [
                          _vm._v("Добавление плательщиков")
                        ])
                      : _vm._e(),
                    _c(
                      "button",
                      {
                        staticClass:
                          "mt-4 btn btn-outline-primary px-4 py-2 f-14 fw-500",
                        on: {
                          click: function($event) {
                            return _vm.$router.push({ name: "profile-edit" })
                          }
                        }
                      },
                      [_vm._v("Редактировать профиль")]
                    )
                  ]
                : _vm._e()
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }