var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "c-tip" }, [
    _c("div", { staticClass: "cont" }, [_vm._t("subject")], 2),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "c-tip__text", class: _vm.direction },
      [_vm._t("default")],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }