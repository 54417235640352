var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "c-checkbox",
      class: { disabled: _vm.disabled },
      on: { click: _vm.select }
    },
    [
      _c("div", { staticClass: "row mx-0" }, [
        _c(
          "div",
          { staticClass: "col col-auto order-2 order-md-1 px-0 mr-md-1" },
          [
            _c("i", {
              staticClass: "icon f-16 d-none d-md-inline-block",
              class: {
                "icon-checked text-primary": _vm.value,
                "icon-unchecked": !_vm.value
              }
            }),
            _vm._v(" "),
            _c("i", {
              staticClass: "icon f-22 d-inline-block d-md-none",
              class: {
                "icon-checked-round text-primary": _vm.value,
                "icon-unchecked-round": !_vm.value
              }
            })
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "col c-checkbox__title order-1 order-md-2 px-0 text-md-gray-800",
            class: { "text-primary": _vm.value }
          },
          [_vm._t("default")],
          2
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }