var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.error
        ? _c("div", { staticClass: "alert alert-danger" }, [
            _vm._v("\n    " + _vm._s(_vm.error) + "\n  ")
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.loading ? _c("preloader", { attrs: { over: "" } }) : _vm._e(),
      _vm._v(" "),
      _vm.success
        ? _c("div", { staticClass: "alert alert-success" }, [
            _vm._v("\n    " + _vm._s(_vm.success) + "\n  ")
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.loading && !_vm.success
        ? [
            _c(
              "c-row",
              [
                _c(
                  "c-col",
                  [
                    _c("c-input", {
                      staticClass: "mb-2",
                      attrs: { placeholder: "Имя" },
                      model: {
                        value: _vm.model.name,
                        callback: function($$v) {
                          _vm.$set(_vm.model, "name", $$v)
                        },
                        expression: "model.name"
                      }
                    }),
                    _vm._v(" "),
                    _c("c-input", {
                      staticClass: "mb-2",
                      attrs: { placeholder: "E-mail" },
                      model: {
                        value: _vm.model.email,
                        callback: function($$v) {
                          _vm.$set(_vm.model, "email", $$v)
                        },
                        expression: "model.email"
                      }
                    }),
                    _vm._v(" "),
                    _c("c-input", {
                      directives: [
                        {
                          name: "mask",
                          rawName: "v-mask",
                          value: { mask: "+7 (999) 999-99-99" },
                          expression: "{mask: '+7 (999) 999-99-99'}"
                        }
                      ],
                      staticClass: "mb-2",
                      attrs: { placeholder: "Телефон" },
                      model: {
                        value: _vm.model.phone,
                        callback: function($$v) {
                          _vm.$set(_vm.model, "phone", $$v)
                        },
                        expression: "model.phone"
                      }
                    }),
                    _vm._v(" "),
                    _c("c-input", {
                      staticClass: "mb-2",
                      attrs: { placeholder: "Город" },
                      model: {
                        value: _vm.model.city,
                        callback: function($$v) {
                          _vm.$set(_vm.model, "city", $$v)
                        },
                        expression: "model.city"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _vm.model.email && _vm.model.phone
              ? [
                  _c("h5", { staticClass: "mt-3" }, [
                    _vm._v("Предпочитаемый метод коммуникации")
                  ]),
                  _vm._v(" "),
                  _c(
                    "c-row",
                    { staticClass: "mt-3" },
                    [
                      _c("c-col", { staticClass: "col-6 col-md-6 col-lg-4" }, [
                        _c(
                          "div",
                          {
                            staticClass: "shadow-box mt-2",
                            class: { active: _vm.model.type == "email" }
                          },
                          [
                            _c(
                              "c-radio",
                              {
                                ref: "radio",
                                attrs: { type: "email" },
                                model: {
                                  value: _vm.model.type,
                                  callback: function($$v) {
                                    _vm.$set(_vm.model, "type", $$v)
                                  },
                                  expression: "model.type"
                                }
                              },
                              [_vm._v("E-mail")]
                            )
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("c-col", { staticClass: "col-6 col-md-6 col-lg-4" }, [
                        _c(
                          "div",
                          {
                            staticClass: "shadow-box mt-2",
                            class: { active: _vm.model.type == "phone" }
                          },
                          [
                            _c(
                              "c-radio",
                              {
                                ref: "radio",
                                attrs: { type: "phone" },
                                model: {
                                  value: _vm.model.type,
                                  callback: function($$v) {
                                    _vm.$set(_vm.model, "type", $$v)
                                  },
                                  expression: "model.type"
                                }
                              },
                              [_vm._v("Телефон")]
                            )
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  )
                ]
              : _vm._e(),
            _vm._v(" "),
            _c(
              "c-row",
              { staticClass: "mt-4" },
              [
                _c("c-col", [
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.model.message,
                        expression: "model.message"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { placeholder: "Сообщение" },
                    domProps: { value: _vm.model.message },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.model, "message", $event.target.value)
                      }
                    }
                  })
                ])
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "c-row",
              { staticClass: "mt-4" },
              [
                _c(
                  "c-col",
                  [
                    _c(
                      "el-upload",
                      {
                        ref: "upload",
                        attrs: {
                          action: _vm.api.uploudFileUrl,
                          limit: 3,
                          "on-success": _vm.handleSuccess,
                          "on-remove": _vm.handleRemove
                        }
                      },
                      [
                        _vm.model.files.length < 3
                          ? _c(
                              "span",
                              {
                                staticClass: "el-upload__btn",
                                attrs: { slot: "trigger" },
                                slot: "trigger"
                              },
                              [
                                _c("i", { staticClass: "icon-attach-file" }),
                                _vm._v(" Прикрепить файл")
                              ]
                            )
                          : _vm._e()
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "c-row",
              { staticClass: "mt-0 mb-5 mt-md-4" },
              [
                _c(
                  "c-col",
                  [
                    _c(
                      "c-button",
                      { staticClass: "w-100", on: { click: _vm.click } },
                      [_vm._v("Отправить")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }