var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$viewer.logged
    ? _c("div", { staticClass: "ml-2" }, [
        _vm._m(0),
        _c(
          "div",
          {
            staticClass: "h-menu dropdown-menu dropdown-menu-right f-14 mt-4",
            attrs: { "aria-labelledby": "headerUser" }
          },
          [
            _c(
              "div",
              {
                staticClass: "dropdown-item my-2 hoverable",
                on: {
                  click: function($event) {
                    return _vm.action("request-list", "/request/list")
                  }
                }
              },
              [_vm._m(1)]
            ),
            _c(
              "div",
              {
                staticClass: "dropdown-item my-2 hoverable",
                on: {
                  click: function($event) {
                    return _vm.action("report-list", "/report/list")
                  }
                }
              },
              [_vm._m(2)]
            ),
            _c(
              "div",
              {
                staticClass: "dropdown-item my-2 hoverable",
                on: {
                  click: function($event) {
                    return _vm.action("request-saved", "/request/saved")
                  }
                }
              },
              [_vm._m(3)]
            ),
            _c(
              "div",
              {
                staticClass: "dropdown-item my-2 hoverable",
                on: {
                  click: function($event) {
                    return _vm.action("profile-view", "/profile/view")
                  }
                }
              },
              [_vm._m(4)]
            ),
            _c(
              "div",
              {
                staticClass: "dropdown-item my-2 hoverable",
                on: {
                  click: function($event) {
                    return _vm.$viewer.logout()
                  }
                }
              },
              [_vm._m(5)]
            )
          ]
        )
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "f-14 fw-500 text-primary hoverable",
        attrs: {
          "data-toggle": "dropdown",
          "aria-haspopup": "true",
          "aria-expanded": "false",
          id: "headerUser"
        }
      },
      [
        _c("div", { staticClass: "fa" }, [
          _vm._v("Мои перевозки"),
          _c("i", { staticClass: "f-10 icon-angle-down ml-2" })
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "fa text-gray-400" }, [
      _c("div", { staticClass: "menu-icon mr-3" }, [
        _c("i", { staticClass: "f-22 icon-box lh-0" })
      ]),
      _vm._v("Заявки")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "fa text-gray-400" }, [
      _c("div", { staticClass: "menu-icon mr-3" }, [
        _c("i", { staticClass: "f-22 icon-file-2 lh-0" })
      ]),
      _vm._v("Отчеты")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "fa text-gray-400" }, [
      _c("div", { staticClass: "menu-icon mr-3" }, [
        _c("i", { staticClass: "f-22 icon-star lh-0" })
      ]),
      _vm._v("Сохраненные расчеты")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "fa text-gray-400" }, [
      _c("div", { staticClass: "menu-icon mr-3" }, [
        _c("i", { staticClass: "f-22 icon-settings lh-0" })
      ]),
      _vm._v("Настройки")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "fa text-gray-400" }, [
      _c("div", { staticClass: "menu-icon mr-3" }, [
        _c("i", { staticClass: "f-22 icon-logout lh-0" })
      ]),
      _vm._v("Выход")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }