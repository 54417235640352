<template lang="pug">
  div
    settings-menu
    preloader.mt-5(v-if="loading")
    c-row.mt-3(v-if="!loading")
      c-col.col-12.col-md-6.col-lg-4
        .mb-2.f-18.text-gray-800.fw-500
          | Добавление пользователей
        .mt-3.f-12.text-gray-800
          | Вы можете дать другим пользователям доступ к аккаунту вашей организации (клиентскoму аккаунту). После отправки приглашения пользователь получит письмо со ссылкой на страницу регистрации а также специальный код клиента.
        c-input.mt-3(placeholder="E-mail", v-model="email")
        .alert.alert-primary.mt-3(v-if="error")
          | {{error}}
        c-button.mt-3(@click="invite", :disabled="$v.email.$invalid || sending")
          | Пригласить
        .mt-4
          .mt-3.bg-gray-200.px-4.py-3(v-for="(item, index) in items")
            c-row.mb-3
              c-col
                .f-14.fw-500.text-gray-800
                  | Приглашение отправлено
              c-col.col-auto
                i.icon-close.hoverable(@click="remove(item)")
            c-row.f-12.fw-500
              c-col.col-2.text-gray-300
                | Адрес
              c-col.text-gray-800
                | {{item.invite_email}}
            c-row.mt-2.f-12.fw-500
              c-col.col-2.text-gray-300
                | Код
              c-col.text-gray-800
                | {{item.code}}
</template>

<script>
  import api from '@/js/api';
  import {required, email} from 'vuelidate/lib/validators';

  export default {
    data: () => ({
      items: [],
      loading: true,
      email: '',
      sending: false,
      error: null
    }),
    provide() {
      return {
        validation: this.$v
      }
    },
    validations() {
      return {
        email: {required, email}
      }
    },
    methods: {
      invite() {
        if (!this.sending) {
          this.sending = true;
          api.inviteUser({email: this.email})
            .then(({data}) => {
              // console.log(data);
              this.$notify({
                group: 'foo',
                clean: true
              })
              this.error = data.error || null;
              if (!data.error) {
                this.email = '';
              }
              this.loadList();
            }, err => {
            if (err.response.status === 504) {
              this.$notify({
                group: 'foo',
                type: 'error',
                duration: -1,
                title: 'Ошибка',
                text: 'Сервер временно недоступен'
              })
            }
          })
            .finally(() => {
              this.sending = false;
            });
        }
      },
      remove(item) {
        api.cancelInvite({email: item.invite_email})
          .then(() => {
            this.loadList();
            this.$notify({
              group: 'foo',
              clean: true
            })
          }, err => {
            if (err.response.status === 504) {
              this.$notify({
                group: 'foo',
                type: 'error',
                duration: -1,
                title: 'Ошибка',
                text: 'Сервер временно недоступен'
              })
            }
          });
      },
      loadList() {
        api.getInvites()
          .then(({data}) => {
            this.items = data;
            this.$notify({
              group: 'foo',
              clean: true
            })
          }, err => {
            if (err.response.status === 504) {
              this.$notify({
                group: 'foo',
                type: 'error',
                duration: -1,
                title: 'Ошибка',
                text: 'Сервер временно недоступен'
              })
            }
          });
      }
    },
    mounted() {
      this.loading = false;
      this.loadList();
    }
  }
</script>

