<template lang="pug">
  .mb-5
    .f-20.fw-500.text-gray-800
      | Заявки
    //- | {{filters}}
    c-row.mt-4
      c-col.col-12.col-md-8.col-lg-9(:class="{'r-hide': isFiltersOpened}")
        paginate(:load="load", ref="list")
          item-request.mb-3.hoverable(slot-scope="{item, index}", :item="item", @click.native="show(item)")
      c-col.col-4.col-lg-3.w-0
        .filter-button.px-3.d-block.d-md-none
          c-button.mt-3.w-100(@click="showFilters")
            | Фильтры
        .filter-window.border.border-2.border-gray-200(:class="{show: isFiltersOpened}")
          .bg-gray-100.px-3.py-3
            c-row
              c-col.f-16.fw-500.text-gray-800
                .h-100.fa
                  | Фильтры
              c-col.col-auto.f-12.text-gray-300
                .h-100.fa.hoverable.d-none.d-md-flex(@click="clearAll")
                  | Очистить все
                .p-2.lh-0.d-block.d-md-none(@click="hideFilters()")
                  i.icon-close
          .p-3
            c-datepicker(placeholder="Период", v-model="filters.period", :range="true")
            //- c-select.mt-2(placeholder="Статус заявки", v-model="filters.CurrCargoState")
            //- c-select.mt-2(placeholder="Статус заявки", v-model="filters.CurrCargoState", :options="options.status")
            c-select-list.mt-2(placeholder="Статус заявки", v-model="filters.CurrCargoState", :options="options.status")
            c-input.mt-2(placeholder="Номер заявки", v-model="filters.OrderShortNo")
            c-input.mt-2(placeholder="Номер заказа", v-model="filters.ClientOrderNo")
            c-input.mt-2(placeholder="№ Сопроводительных док-ов", v-model="filters.ClientDocsNo")
            //- c-input.mt-2(placeholder="Оплата", v-model="filters.PayResult")
            c-select.mt-2(placeholder="Оплата", v-model="filters.PayResult", :options="options.payed")
            c-select.mt-2(placeholder="Откуда", v-model="filters.SenderTownName", :options="options.cities", :filter="filterCities", :getOptionLabel="getOptionLabel", :clearable="true")
            c-select.mt-2(placeholder="Куда", v-model="filters.RecipTownName", :options="options.cities", :filter="filterCities", :getOptionLabel="getOptionLabel", :clearable="true")
            c-select.mt-2(placeholder="Отправитель", v-model="filters.SenderName", :options="options.sender", :clearable="true")
            c-select.mt-2(placeholder="Получатель", v-model="filters.RecipName", :options="options.receiver", :clearable="true")
            c-select.mt-2(placeholder="Плательщик", v-model="filters.PayerName", :options="options.payer", :clearable="true")
            //- c-input.mt-2(placeholder="Отправитель", v-model="filters.SenderName")
            //- c-input.mt-2(placeholder="Получатель", v-model="filters.RecipName")
            //- c-input.mt-2(placeholder="Плательщик", v-model="filters.PayerName")
            c-button.mt-3.w-100(@click="applyFilters")
              | Применить фильтры
            button.btn.btn-outline-primary.f-14.fw-500.w-100.mt-3.py-2.d-block.d-md-none(@click="clearAll")
              | Очистить
            button.btn.btn-outline-primary.f-14.fw-500.w-100.mt-3.py-2(v-if="$viewer.data.right_to_create_order", @click="openCalc()")
              | Новая заявка
</template>

<script>
  import _ from 'lodash';
  import api from '@/js/api';
  export default {
    data: () => ({
      items: [],
      options: {
        towns: [],
        cities: [],
        citiesAll: [],
        status: [],
        sender: [],
        receiver: [],
        payer: []
      },
      filters: {
        period: null,
        CurrCargoState: null,
        OrderShortNo: null,
        PayResult: null,
        SenderTownName: null,
        RecipTownName: null,
        SenderName: '',
        RecipName: '',
        PayerName: ''
      },
      appliedFilters: {},
      isFiltersOpened: false,
      currentPage: null
    }),
    provide() {
      return {validation: this.$v};
    },
    methods: {
      show(item) {
        this.saveFilters();
        this.$router.push({name: 'request-view', params: {id: item.OrderNo, filters: this.appliedFilters, page: this.$refs.list.pageInfo.currentPage}});
      },
      getFilters() {
        let baseFilters = this.appliedFilters;
        let filters = _.fromPairs(_.toPairs(_.omit(baseFilters, ['period'])).filter(value => value[1]));
        let StartDate = _.get(baseFilters, 'period[0]');
        let EndDate = _.get(baseFilters, 'period[1]');

        if (StartDate) {
          let start = new Date(StartDate)
          start.setHours(start.getHours() - start.getTimezoneOffset()/60)
          filters.StartDate = start.toISOString();
        }
        if (EndDate) {
          let end = new Date(EndDate)
          end.setHours(end.getHours() - end.getTimezoneOffset()/60)
          filters.EndDate = end.toISOString();
        }
        if (filters.CurrCargoState) {
          if (filters.CurrCargoState.length) {
            filters.CurrCargoState = filters.CurrCargoState.map(item => item.value).join(',');
          } else {
            delete filters.CurrCargoState;
          }
        }
        return filters;
      },
      load(page = 1) {
        let filters = this.getFilters();
        if (this.currentPage) {
          page = this.currentPage;
          this.currentPage = null;
        }
        return api.getOrders(page, filters, 40)
          .then(response => {
            // console.log(response);
            this.$notify({
              group: 'foo',
              clean: true
            })
            let data = _.get(response, 'data') || {};
            let items = _.get(data, 'Page') || [];

            return {
              items,
              pageInfo: {
                itemsCount: data.ItemsCount,
                pagesCount: data.PageCount,
                currentPage: data.CurrPage
              }
            };
          }, err => {
            if (err.response.status === 504) {
              this.$notify({
                group: 'foo',
                type: 'error',
                duration: -1,
                title: 'Ошибка',
                text: 'Сервер временно недоступен'
              })
            }
          });
      },
      openCalc() {
        window.open('/calculator', '_blank');
        // window.location.href = '/calculator';
      },
      clearAll() {
        _.each(this.filters, (value, key, arr) => {
          arr[key] = null;
        });
      },
      getOptionLabel(option) {
        return _.get(option, 'title') || option;
        // return _.get(this.options.towns[option], 'town_name');
      },
      filterCities(options, search) {
        return search ? _.filter(this.options.citiesAll, town => (town.title || '').toLowerCase().indexOf(search.toLowerCase()) == 0) : options;
      },
      filterOptions(options, search) {
        return search ? _.filter(this.options.citiesAll, item => (item.title || '').toLowerCase().indexOf(search.toLowerCase()) == 0) : options;
      },
      parseCity(city) {
        return city ? {
          value: city.town_name,
          title: city.town_name,
          subtitle: city.area_name
        } : city;
      },
      applyFilters() {
        this.appliedFilters = _.cloneDeep(this.filters);
        this.saveFilters();
        this.$refs.list.reload();
        this.hideFilters();
      },
      saveFilters() {
        let filters = _.cloneDeep(_.extend({}, this.filters, {page: this.$refs.list.pageInfo.currentPage}));
        this.$store.commit('saveRequestFilters', filters);
      },
      showFilters() {
        this.isFiltersOpened = true;
        document.body.classList.add('no-scroll');
      },
      hideFilters() {
        this.isFiltersOpened = false;
        document.body.classList.remove('no-scroll');
      }
    },
    beforeMount() {
      let backedPage = _.get(this.$route, 'params.filters');
      let filters = this.$store.getters['getRequestFilters'];
      let page = _.get(filters, 'page');
      if (filters) {
        this.appliedFilters = _.omit(filters, ['page']);
        this.filters = _.omit(_.cloneDeep(filters), ['page']);
        if (page && backedPage) {
          this.currentPage = page;
        }
      }
    },
    mounted() {
      Promise.all([
        api.getOrdersDics().then(response => _.get(response, 'data') || {}, err => {
            if (err.response.status === 504) {
              this.$notify({
                group: 'foo',
                type: 'error',
                duration: -1,
                title: 'Ошибка',
                text: 'Сервер временно недоступен'
              })
            }
          }),
        this.$store.dispatch('towns')
      ]).then(([dics, towns]) => {
        this.$notify({
          group: 'foo',
          clean: true
        })
        this.options.payed = _.map(dics.PayList, value => ({title: value, value}));
        this.options.status = _.map(dics.StatusList, value => ({title: value, value}));
        // this.options.towns = _.keyBy(towns, 'town_name');
        this.options.citiesAll = _.map(towns, this.parseCity);
        this.options.cities = _.take(this.options.citiesAll, 1000);

        this.options.sender = _.map(dics.SenderList, value => ({title: value, value}));
        this.options.receiver = _.map(dics.ReceiverList, value => ({title: value, value}));
        this.options.payer = _.map(dics.PayerList, value => ({title: value, value}));

        this.loading = false;
      });
    }
  }
</script>

<style lang="scss" scoped>
  .r-hide {
    display: none;
  }
  .filter-button {
    position: fixed;
    bottom: 64px;
    left: 0;
    right: 0;
  }
  .filter-window {
    @media (max-width: 767px) {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 10;
      background-color: #fff;
      overflow-x: hidden;
      overflow-y: auto;
      &.show {
        display: block;
      }
    }
  }
</style>