<template lang="pug">
  .ml-2(v-if="$viewer.logged")
    .f-14.fw-500.text-primary.hoverable(data-toggle='dropdown' aria-haspopup='true' aria-expanded='false' id="headerUser")
      .fa
        | Мои перевозки
        i.f-10.icon-angle-down.ml-2
    .h-menu.dropdown-menu.dropdown-menu-right.f-14.mt-4(aria-labelledby="headerUser")
      .dropdown-item.my-2.hoverable(@click="action('request-list', '/request/list')")
        .fa.text-gray-400
          .menu-icon.mr-3
            i.f-22.icon-box.lh-0
          | Заявки
      .dropdown-item.my-2.hoverable(@click="action('report-list', '/report/list')")
        .fa.text-gray-400
          .menu-icon.mr-3
            i.f-22.icon-file-2.lh-0
          | Отчеты
      .dropdown-item.my-2.hoverable(@click="action('request-saved', '/request/saved')")
        .fa.text-gray-400
          .menu-icon.mr-3
            i.f-22.icon-star.lh-0
          | Сохраненные расчеты
      .dropdown-item.my-2.hoverable(@click="action('profile-view', '/profile/view')")
        .fa.text-gray-400
          .menu-icon.mr-3
            i.f-22.icon-settings.lh-0
          | Настройки
      .dropdown-item.my-2.hoverable(@click="$viewer.logout()")
        .fa.text-gray-400
          .menu-icon.mr-3
            i.f-22.icon-logout.lh-0
          | Выход
</template>

<script>
  import App from '@/js/app';
  const PROFILE_URL = '/profile/view';
  export default {
    methods: {
      action(routename, path) {
        if (App.inst && App.current == App.cabinet) {
          this.$router.push({name: routename});
        } else {
          window.location.href = path;
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .menu-icon {
    width: 20px;
    text-align: center;
    line-height: 0;
  }
  .icon-logout {
    margin-left: 3px;
  }
  .h-menu {
    min-width: 240px;
    border: none;
    border-radius: 4px;
    box-shadow: 0 5px 10px 3px rgba(137, 145, 174, 0.2);
    background-color: #ffffff;
  }
</style>

