<template lang="pug">
  div
    h3
      | Смена пароля
    preloader.mt-5(v-if="!status")
    c-row.mt-3
      c-col.col-12.col-lg-4.col-sm-6
        template(v-if="status == 1")
          c-form
            c-input.mt-1(placeholder="Новый пароль", v-model="user.password1", :suffix="true", :input-type="showPass ? 'text' : 'password'")
              template(slot="suffix")
                div.vam.hoverable.f-0.pr-2.text-gray-400(@click="showPass = !showPass")
                  i.f-18.lh-0(:class="{'icon-eye': !showPass, 'icon-eye-disabled': showPass}")
            c-input.mt-1(placeholder="Подтверждение пароля", v-model="user.password2", :suffix="true", :input-type="showPass ? 'text' : 'password'")
              template(slot="suffix")
                div.vam.hoverable.f-0.pr-2.text-gray-400(@click="showPass = !showPass")
                  i.f-18.lh-0(:class="{'icon-eye': !showPass, 'icon-eye-disabled': showPass}")
            .alert.alert-danger.mb-0.f-12.d-block.mt-3(v-if="errors")
              div(v-for="(error, index) in errors", :key="index")
                | {{error}}
            c-button.mt-3.w-100(type="submit", :disabled="$v.user.$invalid || sending", @click="send")
              | Отправить
        template(v-if="status == 2")
          | Истек срок действия ссылки для восстановления пароля. Восстановите пароль еще раз.
        template(v-if="status == 3")
          | Пароль успешно изменен.
          c-button.mt-3.w-100(:to="{name: 'login'}")
            | Вход
</template>

<script>
  import {required, email} from 'vuelidate/lib/validators';
  import api from '@/js/api';
  export default {
    data: () => ({
      user: {
        user: null,
        password1: null,
        password2: null
      },
      sending: false,
      showPass: false,
      errors: null,
      status: null
    }),
    provide() {
      return {
        validation: this.$v
      };
    },
    validations() {
      return {
        user: {
          password1: {required, equal: value => value == this.user.password2},
          password2: {required, equal: value => value == this.user.password1},
        }
      }
    },
    methods: {
      send() {
        this.sending = true;
        let params = this.$route.params;
        api.resetConfirm(params.userid, params.tokenid, this.user)
          .then(response => {
            let data = _.get(response, 'data') || {};
            this.$notify({
              group: 'foo',
              clean: true
            })
            let errors = _.flatten(_.map(_.get(data, 'errors'), item => item));
            if (errors.length) {
              this.errors = errors;
            } else {
              this.errors = null;
            }
            if (data.status == 200) {
              this.status = 3;
              // console.log(111);
            }
          }, err => {
            if (err.response.status === 504) {
              this.$notify({
                group: 'foo',
                type: 'error',
                duration: -1,
                title: 'Ошибка',
                text: 'Сервер временно недоступен'
              })
            }
          })
          .finally(() => {
            this.sending = false;
          });
      }
    },
    mounted() {
      let params = this.$route.params;
      api.checkResetConfirmToken(params.userid, params.tokenid)
        .then(response => {
          let data = _.get(response, 'data') || {};
          this.$notify({
              group: 'foo',
              clean: true
            })
          if (data.user && data.status == 200) {
            this.user.user = data.user;
            this.status = 1;
          } else {
            this.status = 2;
          }
        }, () => {
          this.status = 2;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
</script>