<template>
  <div>
    <h3>
      <span class="d-inline-block mb-3">
        Инструкции
      </span>
    </h3>
    <preloader over v-if="loading"></preloader>
    <div v-if="error" class="alert alert-danger">
      {{error}}
    </div>
    <nav class="nav-mobile" role="nav" v-if="!error && !loading && navs.length != 0">
      <div class="nav-mobile-title">
        Категории
      </div>
      <ul v-if="navs && navs.length">
        <li v-for="item in navs" :key="item.id">
          <a href="javascript:void(0)" class="nav-mobile-item" @click="$router.push({ name: 'mobile.view', params: { category_id: item.id } })">
            <div class="nav-mobile-item__icon">
              <i class="icon-arrow-chevron rotate-left"></i>
            </div>
            <div class="nav-mobile-item__text">{{item.name || 'Нет названия'}}</div>
            <div class="nav-mobile-item__subtext">{{item.countInstrs || 0}} {{(item.countInstrs || 0) | plural({
              base: 'Инструкци',
              one: 'я',
              few: 'и',
              many: 'й',
              other: 'й'
            })}}</div>
          </a>
        </li>
      </ul>
      <div v-else class="alert alert-danger">
        Нет данных
      </div>
    </nav>
    <c-row v-else>
      <c-col class="col-12">
        <div class="instr-dummy">
          <i class="icon-archive instr-dummy-icon"></i>
          <p class="instr-dummy-title">Здесь еще нет инструкций</p>
          <p class="instr-dummy-greytext">Идет разработка данного раздела.<br>
            Пожалуйста, загляните позже.
          </p>
        </div>
      </c-col>
    </c-row>
  </div>
</template>

<script>
  import Base from '../base'
  import api from '@/js/api'
  import { isEmpty } from 'lodash'

  export default {
    extends: Base,
    data: () => ({
      navs: [],
      loading: true,
      error: ''
    }),
    methods: {
      load() {
        this.loading = true
        this.error = ''
        api.getInstrTypes().then(response => {
          // if (isEmpty(response.data)) {
          //   this.error = 'Нет данных'
          //   return false
          // }
          this.navs = response.data
        }, error => {
          this.error = error
        }).finally(() => {
          this.loading = false
        })
      }
    },
    mounted() {
      this.load()
    }
  }
</script>
<style lang="scss" scoped>
.instr{
  &-dummy{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    margin: 10vw 0;
    font-family: Roboto;

    p{
      margin: 0;
    }
    
    &-icon{
      font-size: 20vw;
      color: #aeb4bf;
      margin-bottom: 3vw;
    }
    
    &-title{
      font-size: 5vw;
      color: #393939;
    }
    
    &-greytext{
      font-size: 4vw;
      color: #aeb4bf;
      text-align: center;
      line-height: 1;
    }
  }
}
</style>
