<template lang="pug">
  .mt-5
    h3
      | Активация аккаунта
    c-row.mt-3
      c-col.col-12.col-lg-4.col-sm-6
        preloader.mt-5(v-if="loading")
        template(v-else)
          .mt-3
            template(v-if="!status")
              | {{error}}
            template(v-else)
              | Аккаунт успешно перенесен в организацию {{company}}
              c-button.mt-3.w-100(:to="{name: 'login'}")
                | Вход
</template>

<script>
  import {required, email} from 'vuelidate/lib/validators';
  import api from '@/js/api';
  export default {
    data: () => ({
      loading: true,
      status: false,
      company: ''
    }),
    mounted() {
      let params = this.$route.params;
      api.checkSigninConfirmToken(params.tokenid)
        .then(response => {
          let data = _.get(response, 'data') || {};
          if (data.status == 200) {
            this.status = true;
          }
          if (data.error) {
            this.error = data.error;
            this.status = false;
          }
          this.company = data.company
        }, () => {
          this.error = 'Произошла ошибка';
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
</script>

