<template>
<div class="page">
  <div class="stocks track container-full">
    <h3><span class="d-inline-block mb-3">
      Данные перевозки
    </span></h3>
    <div class="tracking">
      <div class="tracking__form row">
        <input v-if="dekstopCheck()" @keyup.enter="getOrder(number)" v-model="number" class="tracking__input" placeholder="Номер заявки или номер заказа клиента">
        <input v-else @keyup.enter="getOrder(number)" v-model="number" class="tracking__input" placeholder="Номер заявки">
        <button @click="getOrder(number)" class="tracking__submit">Отследить</button>
      </div>
      <preloader over height="480" v-if="loading"></preloader>
      <div v-else-if="not_found" class="tracking__dummy row">
        <div class="tracking__dummy-box">
          <i class="icon-archive tracking__dummy-icon"></i>
          <p class="tracking__dummy-title">Нет результатов</p>
          <p class="tracking__dummy-text">Измените запрос и попробуйте еще раз</p>
        </div>
      </div>
      <div class="tracking__container" v-else-if="this.order">
        <div class="tracking__info row">
          <ul class="tracking__list">
            <li class="tracking__item">
            <span class="tracking__greytext">Заявка</span>
            <span class="tracking__data">{{this.order.OrderShortNo}}</span>
            </li>
            <li class="tracking__item">
            <span class="tracking__greytext">Дата заявки</span>
            <span class="tracking__data">{{this.order.CrtDate}}</span>
            </li>
            <li class="tracking__item">
            <span class="tracking__greytext">Статус</span>
            <span class="tracking__data">{{this.order.CurrCargoState}}</span>
            </li>
            <li class="tracking__item">
            <span class="tracking__greytext">Характер груза</span>
            <span class="tracking__data">{{this.order.CargoContent}}</span>
            </li>
            <li class="tracking__item">
            <span class="tracking__greytext">Статус оплаты</span>
            <span class="tracking__data">{{this.order.PayState}}</span>
            </li>
          </ul>
          <ul class="tracking__list fact">
            <li class="tracking__item">
              <p class="tracking__fact" v-if="this.order.FactWeight != null"><img class="tracking__fact__icon  first" src="/static/img/icons/wheelbarrow.svg">{{this.order.FactWeight}} кг {{placesText()}}</p><p class="tracking__fact" v-if="this.order.FactVolume != null"><img class="tracking__fact__icon" src="/static/img/icons/arrows-out.svg"><span>{{this.order.FactVolume}} м<sup>3</sup></span></p>
            </li>
            <li class="tracking__item">
            <a href="/documents/" class="tracking__link">Список документов для отправки/получения</a>
            </li>
          </ul>
        </div>
        <template>
          <el-progress v-if="dekstopCheck() && canShow" :stroke-width="5" :show-text="false" :height="3" :percentage="this.progress" color="#ca1226"></el-progress>
          <div v-if="dekstopCheck() && canShow" class="tracking__display">
            <ul v-for="point in this.points" :key="point.city_name + ' ' + point.point_type" class="tracking__points">
              <li class="tracking__point-info">
              <img v-if="point.point_status !== 0 && point.point_type === 0" class="tracking__icon" src="/static/img/icons/house-red.svg">
              <img v-else-if="point.point_status !== 0 && point.point_type === 1" class="tracking__icon" src="/static/img/icons/boxes-red.svg">
              <img v-else-if="point.point_status === 0 && point.point_type === 0" class="tracking__icon" src="/static/img/icons/house.svg">
              <img v-else class="tracking__icon" src="/static/img/icons/boxes.svg">
              </li>
              <div>
              <li class="tracking__point-info">
                <p class="tracking__city">{{point.city_name}}</p>
              </li>
              <li class="tracking__point-info">
                <p class="tracking__status">{{point.point_message}}</p>
              </li>
              <li class="tracking__point-info">
                <p class="tracking__date">{{point.point_date | fulldate}}</p>
              </li>
              </div>
            </ul>
          </div>
          <div v-else-if="canShow">
            <el-steps :active="active" :space="80" direction="vertical">
              <el-step class="house" :icon="iconCheck(point)" v-for="point in this.points" :key="point.city_name + ' ' + point.point_type" :title="point.city_name" :description="`${point.point_message}\n${point.point_date}`"></el-step>
            </el-steps>
          </div>
        </template>
      </div>
      <div class="tracking__dummy row" v-else>
        <div class="tracking__dummy-box">
          <i class="icon-car_dummy tracking__dummy-icon"></i>
          <p class="tracking__dummy-text">Введите номер заявки или номер заказа клиента чтобы отследить статус перевозки</p>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import api from '../api.js';
import router from './router';
export default {
  router: router,
  data() {
    return {
      number: '',
      order: null,
      points: {},
      progress: 0,
      active: -1,
      loading: false,
      not_found: false
    }
  },
  computed: {
    canShow () {
      let can = function (item, index, array) {
        return item.point_type === 1 && item.point_status !== 0
      } 
      let arr = this.points.find(can)
      return arr
    }
  },
  methods: {
    getOrder(order_id) {
      this.loading = true
      this.not_found = false
      // console.log(this.loading)
      api.getTrackOrder(order_id).then(response => {
        if (response.data.order_tracking == null) {
          this.not_found = true
        }
        this.order = response.data.order_tracking
        this.points = response.data.points
        // for (let i=0;i<this.points.length;i++) {
        //   this.points[i].point_date = this.points[i].point_date
        // }
        this.progressCount()
        // console.log(this.points)
        this.carPaint()
        if (window.innerWidth < 768) {
        this.linePaint()
        this.checkDate()
        }
        this.loading = false
      })
    },
    placesText() {
      if (this.order.FactPlace == null) {
        return ''
      }
      if ((this.order.FactPlace > 9 && this.order.FactPlace < 21) || (this.order.FactPlace % 10 === 0)) {
        return `/ ${this.order.FactPlace} мест`
      } else if ((this.order.FactPlace % 10) < 2) {
          return `/ ${this.order.FactPlace} место`
      } else if ((this.order.FactPlace % 10) < 5) {
          return `/ ${this.order.FactPlace} места`
      } else if (this.order.FactPlace != null) {
          return `/ ${this.order.FactPlace} мест`
      }
    },
    progressCount() {
      let done = 0
      // console.log(this.points.length)
      for (let i = 1; i < this.points.length; i++) {
        if (this.points[i].point_status !== 0) {
          done++
          // console.log(done)
        }
        this.progress = (done / (this.points.length - 1)) * 100
        
        // console.log(this.progress)
        this.pointsCount()
      }
      return
    },
    carPaint() {
      setTimeout(() => {
        let array = document.getElementsByClassName('tracking__points')
        // console.log(this.points.length)
        if (this.points.length == 4 && array.length > 0) {
          // console.log(1111)
          for (let j = 0; j < array.length; j++) {
            array[j].style.right = '0px'
          }
          array[1].style.right = '-43px'
          array[2].style.right = '-86px'

        } else if (this.points.length == 3 && array.length > 0) {
          // console.log(111)
          for (let j = 0; j < array.length; j++) {
            array[j].style.right = '0px'
          }
          array[1].style.right = '-76px'

        } else if (array.length > 0) {
          // console.log(11)
          for (let j = 0; j < array.length; j++) {
            array[j].style.right = '0px'
          }
        }
        let point = document.getElementsByClassName('el-progress-bar__inner')[0]
        // console.log(point)
        for (let i=0; i<this.points.length; i++) {
          // console.log(this.points[i].point_status)
          if (this.points[i+1] && this.points[i].point_status === 2 & this.points[i+1].point_status === 0 && point) {
            this.progress += 50/(this.points.length-1)
            point.className += ' car_icon'
            return
          } else if (point) {
            point.className = 'el-progress-bar__inner'
          }
        }
      }, 150)
    },
    dekstopCheck() {
      if (window.innerWidth > 768) {
        return true
      }
    },
    pointsCount() {
      this.active = -1
      for (let i=0; i< this.points.length; i++) {
        if (this.points[i].point_status != 0) {
          this.active++
        }
      }
    },
    iconCheck(point) {
      if (point.point_type === 0) return "icon-house"
      else return "icon-boxes"
    },
    linePaint() {
      setTimeout(() => {
        let line = document.getElementsByClassName('is-process')
        let line1 = document.getElementsByClassName('is-wait')
        let line2 = document.getElementsByClassName('is-finish')
        for (let i=0; i<this.points.length; i++) {
          if (line[i]) line[i].style.borderColor = 'transparent'
          if (line1[i]) line1[i].style.borderColor = 'transparent'
          if (line2[i]) line2[i].style.borderColor = 'transparent'
        }
        for (let i=0; i<this.points.length; i++) {
          line[i].style.borderColor = ''
          if (this.points[i+1] && this.points[i].point_status === 2 & this.points[i+1].point_status === 0 && line) {
            // console.log(1111)
            line[0].style.borderColor = '#ca1226'
            return
          } else if (line) {
            line[0].style.borderColor = '#aeb4bf'
          }
        }
      }, 150)
    },
    checkDate() {
      for (let i=0;i<this.points.length;i++) {
        if (this.points[i].point_date == null || this.points[i].point_date == undefined || this.points[i].point_date == NaN) {
          this.points[i].point_date = ''
        }
      }
    },
  },
  mounted() {
      console.log(this.$route.params.order_id)
      this.loading = false
      if (this.$route.params.order_id) {
        this.getOrder(this.$route.params.order_id)
      }
  },
}
</script>
<style lang="scss" scoped>
.stocks {
  padding-top: 0
}
/deep/ .cont{
  height: 480px;
}
/deep/ .el-step__line-inner{
  height: 50% !important;
  border-width: 1px !important;
}
/deep/ .el-step{
  &__title, &__description{
    color: #393939 !important;
    font-weight: 500 !important;
  }

  &__main{
    padding-left: 30px !important;
  }

  &__description{
    white-space: pre-wrap;
  }

  &__head{
    margin-top: 5px;
  }
  
  &__icon{
    height: 30px;
    font-size: 30px;
  }

  &.is-vertical{
    padding-left: 5px;

    .el-step__line{
      top:33px;
    }
  }
}
/deep/ .is-finish{
    color: #ca1226;
    border-color: #ca1226;
    .el-step__line{
      background-color: #ca1226;
    }
}
/deep/.is-process{
    border-color: #aeb4bf;
    color: #ca1226;
    .el-step__line{
    background-color: #aeb4bf;
  }
}
/deep/ .el-progress-bar__inner{
  height: 100%;
  border-radius: 5px;

  &.car_icon{
    position: relative;

    &:before {
      content: "";
      position: absolute;
      right: 0;
      top: -50px;
      display: block;
      width: 65px;
      height: 45px;
      background: url(~src/img/icons/car.svg);
      background-size: cover;
    }
  }
}
/deep/ .el-progress-bar__outer{
  padding-top: 0.75px;
}
/deep/ div.el-progress{
  position: relative;
  top: 98px;
  padding: 0px 40px 0px;

  .el-progress-bar{
    background-color: lightgrey;
  }
}
</style>
