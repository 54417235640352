<template>
  <div>
    <h3>
      <span class="d-inline-block mb-3">
        Инструкции
      </span>
    </h3>
    <div class="mt-3"></div>
    <div v-if="success" class="alert alert-success">
      {{success}}
    </div>
    <div v-if="error" class="alert alert-danger">
      {{error}}
    </div>
    <preloader over v-if="loading"></preloader>
    <template v-if="page === 1">
      <c-row v-if="!loading && navs.length">
        <c-col class="col-12 col-lg-3 col-md-4">
          <nav-sidebar v-if="navs && navs.length" :items="navs" @nav-change="getContent"/>
          <div v-else class="alert alert-danger">
            Нет данных
          </div>
        </c-col>
        <c-col class="col-12 col-lg-9 col-md-8">
          <preloader v-if="loadingContent"></preloader>
          <template v-else>
            <h2 class="documents-box-title">{{activeCategory}}</h2>
            <div class="documents-box col-8" v-if="content && content.length">
              <h2 class="documents-box-title">{{title}}</h2>
              <youtube :height.sync="windowWidth/1.7" :width.sync="windowWidth" :video-id="getId(content[0].media_url)" ref="youtube"></youtube>
              <a class="documents-box-link instructions" :href="content[0].doc_url" download><i class="icon-download documents-box-link-icon"></i> Скачать pdf</a>
            </div>
          </template>
        </c-col>
      </c-row>
      <c-row v-else>
        <c-col class="col-12">
          <div class="instr-dummy">
            <i class="icon-archive instr-dummy-icon"></i>
            <p class="instr-dummy-title">Здесь еще нет инструкций</p>
            <p class="instr-dummy-greytext">Идет разработка данного раздела.<br>
            Пожалуйста, загляните позже.</p>
          </div>
        </c-col>
      </c-row>
    </template>
    <template v-if="page === 2">
      <send-form
        ref="sendForm"
        :currentModel="currentModelPage"
        @success="successEvent"
        @error="errorEvent"
        @loading="loadingEvent"
      />
    </template>
  </div>
</template>

<script>
import api from '@/js/api'
import _ from 'lodash'
import isMobile from '@/js/core/is-mobile'
import parseContent from '@/js/core/parse-content-simple'

export default {
  data() {
    return {
      api,
      page: 1,
      currentModelPage: {},
      error: '',
      loading: true,
      loadingContent: false,
      navs: [],
      content: [],
      success: '',
      activeCategory: '',
      windowWidth: undefined,
    }
  },
  methods: {
    successEvent(event) {
      this.success = event
      this.error = ''
    },
    errorEvent(event) {
      this.success = ''
      this.error = event
    },
    loadingEvent(event) {
      this.success = ''
      this.error = ''
      this.loading = event
    },
    setPage(page) {
      if (page === 1) {
        this.currentModelPage = {}
        this.$refs.sendForm.clear()
      }
      this.page = page
    },
    bSend(item) {
      this.setPage(2)
      this.currentModelPage = item
    },
    getContent(id) {
      this.error = ''
      this.loadingContent = true
      api.getInstrCategory(id).then(response => {
        this.activeCategory = response.data.name
      })
      api.getInstrList(id).then(response => {
        this.content = response.data.map(item => {
          item.doc_url = item.doc_url
          item.id = item.id
          item.doc_name = item.doc_name
          item.media_url = item.media_url
          return item
        })
      }, error => {
        this.error = error
      }).finally(() => {
        this.loadingContent = false
      })
    },
    getMenu() {
      this.loading = true
      this.error = ''
      return api.getInstrTypes().then(response => {
        // if (_.isEmpty(response.data)) {
        //   this.error = 'Нет данных'
        //   return false
        // }

        this.navs = response.data
        return this.navs
      }, error => {
        this.error = error
        return this.error
      }).finally(() => {
        this.loading = false
      })
    },
    getId (url) {
      return this.$youtube.getIdFromUrl(url)
    },
    getScreenWidth() {
      if (window.innerWidth < 768) {
        this.windowWidth = window.innerWidth * 0.9
      } else {
        this.windowWidth =  window.innerWidth * 0.5
      }
    },
  },
  mounted() {
    this.getScreenWidth()
    window.addEventListener('resize', (event) => {
      this.getScreenWidth()
      if (isMobile()) this.$router.push({ name: 'mobile.list' })
    })

    if (isMobile()) {
      this.$router.push({ name: 'mobile.list' })
      return false
    }
    this.getMenu().then(res => {
      if (!_.isEmpty(res)) this.getContent(res[0].id)
    })
  },
  computed: {
    player() {
      return this.$refs.youtube.player
    }
  }
}
</script>

<style lang="scss" scoped>
.instr{
  &-dummy{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    margin: 60px 0;
    font-family: Roboto;

    p{
      margin: 0;
    }
    
    &-icon{
      font-size: 120px;
      color: #aeb4bf;
      margin-bottom: 20px;
    }
    
    &-title{
      font-size: 30px;
      color: #393939;
    }
    
    &-greytext{
      font-size: 25px;
      color: #aeb4bf;
      text-align: center;
      line-height: 1;
    }
  }
}
</style>

