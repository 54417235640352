var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._m(0),
      _vm._v(" "),
      _vm.loading ? _c("preloader", { attrs: { over: "" } }) : _vm._e(),
      _vm._v(" "),
      _vm.error
        ? _c("div", { staticClass: "alert alert-danger" }, [
            _vm._v("\n    " + _vm._s(_vm.error) + "\n  ")
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.error && !_vm.loading
        ? [
            _c(
              "div",
              {
                staticClass: "nav-mobile-title mb-3",
                on: {
                  click: function($event) {
                    return _vm.$router.go(-1)
                  }
                }
              },
              [
                _c("div", { staticClass: "nav-mobile-title--sub" }, [
                  _vm._v("Категория")
                ]),
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.category.name || "Не названия") +
                    "\n    "
                )
              ]
            ),
            _vm._v(" "),
            _vm.content && _vm.content.length
              ? _vm._l(_vm.content, function(item) {
                  return _c(
                    "div",
                    {
                      key: item.id,
                      staticClass:
                        "vacancy-card vacancy-card--mobile vacancy-card-shadow"
                    },
                    [
                      _c("div", { staticClass: "vacancy-card-header" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "vacancy-card-header__name vacancy-card-header__name--bg f-18 f-md-16 fw-500 mb-3"
                          },
                          [_vm._v(_vm._s(item.name))]
                        ),
                        _vm._v(" "),
                        _c(
                          "ul",
                          { staticClass: "vacancy-card-header__actions" },
                          [
                            _c("li", [
                              _c("i", { staticClass: "icon-placeholder-copy" }),
                              _vm._v(
                                "\n              " +
                                  _vm._s(item.city_name) +
                                  "\n            "
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _c("i", { staticClass: "icon-time-line" }),
                              _vm._v(
                                "\n              " +
                                  _vm._s(item.schedule) +
                                  "\n            "
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _c("i", { staticClass: "icon-credit-card-line" }),
                              _vm._v(
                                "\n              " +
                                  _vm._s(item.wage) +
                                  "\n            "
                              )
                            ])
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "vacancy-card-body" }, [
                        _c("div", {
                          staticClass: "vacancy-card-body__text",
                          domProps: { innerHTML: _vm._s(item.responsibility) }
                        })
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "vacancy-card-footer" }, [
                        _c(
                          "a",
                          {
                            staticClass: "vacancy-card-footer__more",
                            attrs: { href: "javascript:void(0)" },
                            on: {
                              click: function($event) {
                                return _vm.$router.push({
                                  name: "mobile.vacancy",
                                  params: { id: item.id }
                                })
                              }
                            }
                          },
                          [
                            _vm._v("Подробнее "),
                            _c("i", {
                              staticClass: "icon-arrow-chevron rotate-left"
                            })
                          ]
                        )
                      ])
                    ]
                  )
                })
              : _c("div", { staticClass: "alert alert-danger" }, [
                  _vm._v("\n      Нет данных\n    ")
                ])
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", [
      _c("span", { staticClass: "d-inline-block mb-3" }, [
        _vm._v("\n      Вакансии\n    ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }