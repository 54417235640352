var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "hint-label f-12 fw-500 text-center text-pink",
        attrs: { tabindex: "1" }
      },
      [_vm._v(_vm._s(_vm.title))]
    ),
    _c(
      "div",
      { staticClass: "hint-text f-12 text-center text-white p-2" },
      [_vm._t("default")],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }