var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "mt-3" }),
      _vm._v(" "),
      _vm.success
        ? _c("div", { staticClass: "alert alert-success" }, [
            _vm._v("\n    " + _vm._s(_vm.success) + "\n  ")
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.error
        ? _c("div", { staticClass: "alert alert-danger" }, [
            _vm._v("\n    " + _vm._s(_vm.error) + "\n  ")
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.loading ? _c("preloader", { attrs: { over: "" } }) : _vm._e(),
      _vm._v(" "),
      _vm.page === 1 && !_vm.error
        ? [
            !_vm.loading
              ? _c(
                  "c-row",
                  [
                    _c(
                      "c-col",
                      { staticClass: "col-12 col-lg-3 col-md-4" },
                      [
                        _vm.navs && _vm.navs.length
                          ? _c("nav-sidebar", {
                              attrs: {
                                value: _vm.currentCategoryId,
                                items: _vm.navs
                              },
                              on: { "nav-change": _vm.getContent }
                            })
                          : _c("div", { staticClass: "alert alert-danger" }, [
                              _vm._v("\n          Нет данных\n        ")
                            ])
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "c-col",
                      { staticClass: "col-12 col-lg-9 col-md-8" },
                      [
                        _vm.loadingContent
                          ? _c("preloader")
                          : [
                              _vm.content && _vm.content.length
                                ? _c(
                                    "table",
                                    { staticClass: "documents-table" },
                                    _vm._l(_vm.content, function(item) {
                                      return _c(
                                        "tr",
                                        {
                                          key: item.doc_name,
                                          staticClass: "documents-table-row"
                                        },
                                        [
                                          _c(
                                            "td",
                                            {
                                              staticClass:
                                                "documents-table-namecell"
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "icon-file documents-box-icon"
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "documents-box-filename"
                                                },
                                                [_vm._v(_vm._s(item.doc_name))]
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "td",
                                            {
                                              staticClass:
                                                "documents-table-linkcell"
                                            },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "documents-box-link",
                                                  attrs: {
                                                    href: item.doc_url,
                                                    download: ""
                                                  }
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "icon-download documents-box-link-icon"
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    { staticClass: "desktop" },
                                                    [_vm._v("Скачать")]
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    }),
                                    0
                                  )
                                : _vm._e()
                            ]
                      ],
                      2
                    )
                  ],
                  1
                )
              : _vm._e()
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.page === 2
        ? [
            _c("send-form", {
              ref: "sendForm",
              attrs: { currentModel: _vm.currentModelPage },
              on: {
                success: _vm.successEvent,
                error: _vm.errorEvent,
                loading: _vm.loadingEvent
              }
            })
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", [
      _c("span", { staticClass: "d-inline-block mb-3" }, [
        _vm._v("\n      Документы\n    ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }