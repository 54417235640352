var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.navs && _vm.navs.length
    ? _c("nav", { staticClass: "nav-sidebar mb-3", attrs: { role: "nav" } }, [
        _c(
          "ul",
          _vm._l(_vm.navs, function(item, index) {
            return _c(
              "li",
              {
                key: index,
                staticClass: "nav-sidebar-item",
                class: { "nav-sidebar-item--active": item.active }
              },
              [
                _c(
                  "a",
                  {
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function($event) {
                        return _vm.click(item.id)
                      }
                    }
                  },
                  [_vm._v(_vm._s(item.name))]
                )
              ]
            )
          }),
          0
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }