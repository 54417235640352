var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pb-4" },
    [
      _c(
        "back",
        { staticClass: "text-gray-300", attrs: { name: "request-list" } },
        [_vm._v("Назад к списку")]
      ),
      _vm.loading
        ? _c("preloader", { staticClass: "mt-5" })
        : [
            _c("div", { staticClass: "f-20 fw-500 text-gray-800 mt-2" }, [
              _vm._v("Заявки")
            ]),
            _c("item-request", {
              staticClass: "mt-3",
              attrs: { full: "", item: _vm.item, view: true }
            }),
            _c("div", { staticClass: "f-18 fw-500 text-gray-800 mt-3" }, [
              _vm._v("Грузовые места")
            ]),
            _c(
              "el-table",
              {
                staticClass: "mt-3 d-none d-md-block",
                staticStyle: { width: "100%" },
                attrs: {
                  data: _vm.item.Cargo,
                  "header-cell-class-name":
                    "f-10 lh-1 text-muted py-2 text-uppercase border-top border-gray-200",
                  "cell-class-name": "f-14 lh-1 text-gray-800 border-gray-200"
                }
              },
              [
                _c("template", { slot: "empty" }, [_vm._v("Список пуст.")]),
                _c("el-table-column", {
                  attrs: { prop: "Weight", label: "Вес грузового места, кг" }
                }),
                _c(
                  "el-table-column",
                  { attrs: { prop: "Volume" } },
                  [
                    _c("template", { slot: "header" }, [
                      _vm._v("Объем грузового места, м"),
                      _c("sup", [_vm._v("3")])
                    ])
                  ],
                  2
                ),
                _c("el-table-column", {
                  attrs: {
                    prop: "dimentions",
                    label: "Размеры грузового места, см"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("div", { staticClass: "text-nowrap" }, [
                            _vm._v(
                              _vm._s(scope.row.Length) +
                                " x " +
                                _vm._s(scope.row.Width) +
                                " x " +
                                _vm._s(scope.row.Height)
                            )
                          ])
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: { prop: "PlaceCount", label: "Мест в грузовом месте" }
                }),
                _c("el-table-column", {
                  attrs: { prop: "_services", label: "Услуги" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return _vm._l(scope.row._services, function(
                          service,
                          index
                        ) {
                          return _c(
                            "div",
                            { key: index, staticClass: "f-14 mt-1" },
                            [_vm._v(_vm._s(service))]
                          )
                        })
                      }
                    }
                  ])
                })
              ],
              2
            ),
            _c("div", { staticClass: "f-18 fw-500 text-gray-800 mt-3" }, [
              _vm._v("Данные водителей")
            ]),
            _c(
              "el-table",
              {
                staticClass: "mt-3 d-none d-md-block",
                staticStyle: { width: "100%" },
                attrs: {
                  data: _vm.item.Drivers,
                  "header-cell-class-name":
                    "f-10 lh-1 text-muted py-2 text-uppercase border-top border-gray-200",
                  "cell-class-name": "f-14 lh-1 text-gray-800 border-gray-200"
                }
              },
              [
                _c("template", { slot: "empty" }, [_vm._v("Список пуст.")]),
                _c("el-table-column", {
                  attrs: { prop: "ServiceName", label: "Наименование услуги" }
                }),
                _c(
                  "el-table-column",
                  { attrs: { prop: "DateTrans" } },
                  [
                    _c("template", { slot: "header" }, [
                      _vm._v("Дата оказания услуги")
                    ])
                  ],
                  2
                ),
                _c("el-table-column", {
                  attrs: { prop: "TruckerName", label: "ФИО водителя" }
                }),
                _c("el-table-column", {
                  attrs: { prop: "TruckName", label: "Марка автомобиля" }
                }),
                _c("el-table-column", {
                  attrs: { prop: "TruckNo", label: "Гос. номер" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return _vm._l(scope.row._services, function(
                          service,
                          index
                        ) {
                          return _c(
                            "div",
                            { key: index, staticClass: "f-14 mt-1" },
                            [_vm._v(_vm._s(service))]
                          )
                        })
                      }
                    }
                  ])
                })
              ],
              2
            ),
            _c(
              "div",
              { staticClass: "mt-3 d-block d-md-none" },
              _vm._l(_vm.item.Cargo, function(item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "m-item" },
                  [
                    _c(
                      "c-row",
                      [
                        _c("c-col", { staticClass: "fw-500 f-12" }, [
                          _c("div", { staticClass: "fa" }, [
                            _c("i", {
                              staticClass:
                                "f-20 lh-0 icon-cargo text-gray-300 mr-2"
                            }),
                            _c("span", [_vm._v(_vm._s(item.Weight) + " кг")])
                          ])
                        ]),
                        _c("c-col", { staticClass: "text-right f-12" }, [
                          _vm._v(
                            _vm._s(item.PlaceCount) +
                              " " +
                              _vm._s(
                                _vm._f("plural")(
                                  item.PlaceCount,
                                  _vm.pluralPlaces
                                )
                              )
                          )
                        ])
                      ],
                      1
                    ),
                    _c(
                      "c-row",
                      { staticClass: "mt-3" },
                      [
                        _c("c-col", { staticClass: "fw-500 f-12" }, [
                          _c("div", { staticClass: "fa" }, [
                            _c("i", {
                              staticClass:
                                "f-16 lh-0 icon-fullscreen text-gray-300 mr-2"
                            }),
                            _c("span", { staticClass: "f-12" }, [
                              _vm._v(_vm._s(item.Volume) + " м"),
                              _c("sup", [_vm._v("3")])
                            ])
                          ])
                        ]),
                        _c("c-col", { staticClass: "text-right f-12" }, [
                          _vm._v(
                            _vm._s(item.Length) +
                              " x " +
                              _vm._s(item.Width) +
                              " x " +
                              _vm._s(item.Height) +
                              " см"
                          )
                        ])
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "mt-3" },
                      _vm._l(item._services, function(service, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "f-12 mt-1 text-gray-400"
                          },
                          [_vm._v(_vm._s(service))]
                        )
                      }),
                      0
                    )
                  ],
                  1
                )
              }),
              0
            ),
            _c("div", { staticClass: "f-18 fw-500 text-gray-800 mt-3" }, [
              _vm._v("Документы")
            ]),
            _c(
              "div",
              {
                staticClass: "f-14 fw-500 tracking__link mt-1",
                on: {
                  click: function($event) {
                    return _vm.openArchive()
                  }
                }
              },
              [_vm._v("Скачать все документы архивом")]
            ),
            _c(
              "div",
              { staticClass: "mt-3" },
              _vm._l(_vm.item.Docs, function(doc, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "mt-2",
                    on: {
                      click: function($event) {
                        return _vm.openDoc(doc)
                      }
                    }
                  },
                  [
                    _c(
                      "c-row",
                      { staticClass: "mx-0" },
                      [
                        _c(
                          "c-col",
                          {
                            staticClass: "col-auto px-0",
                            class: { hoverable: doc.FileLink }
                          },
                          [
                            _c("c-icon-file", {
                              attrs: { title: _vm.getFileExt(doc) }
                            })
                          ],
                          1
                        ),
                        _c(
                          "c-col",
                          {
                            staticClass: "col-md-4 pl-2 align-self-center",
                            class: { hoverable: doc.FileLink }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "f-12 text-gray-800 text-md-gray-400"
                              },
                              [_vm._v(_vm._s(doc.FileName))]
                            )
                          ]
                        ),
                        doc.FileLink
                          ? _c(
                              "c-col",
                              {
                                staticClass:
                                  "col-auto align-self-center hoverable"
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "f-12 fw-500 tracking__link mt-1"
                                  },
                                  [_vm._v("Скачать файл")]
                                )
                              ]
                            )
                          : _c(
                              "c-col",
                              { staticClass: "col-auto align-self-center" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "f-12 text-gray-400 mt-1" },
                                  [_vm._v("Файл отсутствует")]
                                )
                              ]
                            )
                      ],
                      1
                    )
                  ],
                  1
                )
              }),
              0
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }