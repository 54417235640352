<template lang="pug">
  div
    ul.navi.navi-pills.d-block.d-md-flex
      li.navi-item(v-for="(item, index) in items", v-if="item.check ? item.check() : true")
        a.f-12.navi-link(href='#', @click="$event.preventDefault(), item.action()", :class="{active: item.active}")
          | {{item.title}}
</template>

<script>
  import _ from 'lodash';
  export default {
    data() {
      let self = this;
      return {
        items: [
          {
            title: 'Профиль',
            get active() {
              return /^\/profile\//.test(self.$route.path);
            },
            action() {
              self.$router.push({name: 'profile-view'});
            }
          },
          {
            title: 'Добавление пользователей',
            get active() {
              return /^\/users\//.test(self.$route.path);
            },
            action() {
              self.$router.push({name: 'user-list'});
            }
          },
          {
            title: 'Права пользователей',
            check() {
              return _.get(self, '$viewer.data.right_to_edit_rights');
            },
            get active() {
              return /^\/user-rights\//.test(self.$route.path);
            },
            action() {
              self.$router.push({name: 'user-rights-list'});
            }
          },
          {
            title: 'Доступные плательщики',
            check() {
              return _.get(self, '$viewer.data.right_to_add_payer');
            },
            get active() {
              return /^\/payers\//.test(self.$route.path);
            },
            action() {
              self.$router.push({name: 'payers-list'});
            }
          },
          // {
          //   title: 'Настройки API',
          //   get active() {
          //     return /^\/api-settings\//.test(self.$route.path);
          //   },
          //   action() {
          //     self.$router.push({name: 'api-settings'});
          //   }
          // },
          {
            title: 'Выход',
            get active() {
              return /^\/logout\//.test(self.$route.path);
            },
            action() {
              self.$viewer.logout();
            }
          }
        ]
      }
    },
    mounted() {
      this.$root.$on('loggedin', () => {
        this.$forceUpdate();
      });
    }
  }
</script>
