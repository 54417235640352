<template lang="pug">
  .d-inline-block.f-0.hoverable(@click="click")
    .fa
      i.icon-arrow-simple.r-180.f-12.lh-0
      .f-12.lh-1.ml-2
        slot
          | Назад
</template>

<script>
  export default {
    props: ['name', 'path', 'to'],
    methods: {
      click() {
        let params = this.$route.params;
        if (this.to) {
          this.$router.push(to);
        } else if (this.path) {
          window.location.href = this.path;
        } else {
          this.$router.push({name: this.name, params});
        }
      }
    }
  }
</script>