<template lang="pug">
  form(novalidate, @submit="submit")
    slot
</template>

<script>
  export default {
    methods: {
      submit($event) {
        $event.preventDefault();
        // this.$emit('submit');
      }
    }
  }
</script>
