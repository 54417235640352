import _ from 'lodash';
import Router from 'vue-router';
import Viewer from '@/js/core/classes/viewer';

let loadPage = path => require(`./pages/${path}/page.vue`).default;

const PAGES = {
  empty: loadPage('empty'),
  container: loadPage('container'),
  login: loadPage('login'),
  logout: loadPage('logout'),
  forgotPassword: loadPage('forgot-password'),
  register: loadPage('register'),
  resetConfirm: loadPage('reset-confirm'),
  signupConfirm: loadPage('signup-confirm'),
  signinConfirm: loadPage('signin-confirm'),
  request: {
    list: loadPage('request/list'),
    view: loadPage('request/view'),
    saved: loadPage('request/saved')
  },
  report: {
    list: loadPage('report/list')
  },
  profile: {
    view: loadPage('profile/view'),
    edit: loadPage('profile/edit')
  },
  userRights: {
    list: loadPage('user-rights/list'),
  },
  payers: {
    list: loadPage('payers/list'),
    edit: loadPage('payers/edit'),
    create: loadPage('payers/create')
  },
  users: {
    list: loadPage('users/list')
  },
  dev: loadPage('dev')
};

const CONFIG = {
  mode: 'history',
  scrollBehavior: () => ({
    x: 0,
    y: 0
  }),
  linkActiveClass: 'active',
  routes: [{
      path: '/login',
      name: 'login',
      component: PAGES.login
    },
    {
      path: '/logout',
      name: 'logout',
      component: PAGES.logout
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: PAGES.forgotPassword
    },
    {
      path: '/reset-confirm/:userid/:tokenid',
      component: PAGES.resetConfirm
    },
    {
      path: '/signup-confirm/:tokenid',
      component: PAGES.signupConfirm
    },
    {
      path: '/signin-confirm/:tokenid',
      component: PAGES.signinConfirm
    },
    {
      path: '/register',
      component: PAGES.container,
      children: [
        {
          path: '',
          name: 'register',
          component: PAGES.register
        },
        {
          path: ':tokenid',
          name: 'register-with-token',
          component: PAGES.register
        }
      ]
    },
    {
      path: '/request',
      component: PAGES.container,
      children: [
        {
          path: 'list',
          name: 'request-list',
          meta: {menu: true, auth: true},
          component: PAGES.request.list
        },
        {
          path: 'saved',
          name: 'request-saved',
          meta: {menu: true, auth: true},
          component: PAGES.request.saved
        },
        {
          path: ':id',
          name: 'request-view',
          meta: {menu: true, auth: true},
          component: PAGES.request.view
        }
      ]
    },
    {
      path: '/profile',
      component: PAGES.container,
      children: [
        {
          path: 'view',
          name: 'profile-view',
          meta: {menu: true, auth: true},
          component: PAGES.profile.view
        },
        {
          path: 'edit',
          name: 'profile-edit',
          meta: {menu: true, auth: true},
          component: PAGES.profile.edit
        }
      ]
    },
    {
      path: '/user-rights',
      component: PAGES.container,
      children: [
        {
          path: 'list',
          name: 'user-rights-list',
          meta: {menu: true, auth: true},
          component: PAGES.userRights.list
        },
        {
          path: ':id',
          name: 'user-rights-edit',
          meta: {menu: true, auth: true},
          component: PAGES.dev
        },
      ]
    },
    {
      path: '/report',
      component: PAGES.container,
      children: [{
          path: 'list',
          name: 'report-list',
          meta: {menu: true, auth: true},
          component: PAGES.report.list
        }
      ]
    },
    {
      path: '/payers',
      component: PAGES.container,
      children: [
        {
          path: 'list',
          name: 'payers-list',
          meta: {menu: true, auth: true},
          component: PAGES.payers.list
        },
        {
          path: 'create',
          name: 'payers-create',
          meta: {menu: true, auth: true},
          component: PAGES.payers.create
        },
        {
          path: ':id',
          name: 'payers-edit',
          meta: {menu: true, auth: true},
          component: PAGES.payers.edit
        }
      ]
    },
    {
      path: '/users',
      component: PAGES.container,
      children: [
        {
          path: 'list',
          name: 'user-list',
          meta: {menu: true, auth: true},
          component: PAGES.users.list
        },
        {
          path: 'create',
          name: 'user-create',
          meta: {menu: true, auth: true},
          component: PAGES.dev
        },
      ]
    },
    {
      path: '/api-settings',
      name: 'api-settings',
      meta: {menu: true, auth: true},
      component: PAGES.dev
    },
    {
      path: '*',
      component: PAGES.empty
    }
  ]
};

let router = new Router(CONFIG);

router.beforeEach((to, from, next) => {
  let needAuth = _.get(to, 'meta.auth') == true;
  let noNeedAuth = _.get(to, 'meta.auth') == false;
  if (needAuth) {
    let viewer = new Viewer();
    new Promise((resolve, reject) => {
      if (viewer.logged) {
        resolve(true)
      } else {
        viewer.loadUser()
          .then(() => {
            if (viewer.logged) {
              resolve()
            } else {
              reject();
            }
          })
          .catch(() => {
            reject();
          });
      }
    }).then(() => {
      next();
    }).catch(() => {
      next('/login');
    });
  } else {
    next();
  }
});

export default router;