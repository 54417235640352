<template>
  <labeled :label="label" class="c-input__cont" :class="{'is-invalid': isInvalid, disabled: disabled}">
    <div class="row mx-0" :class="{'flex-nowrap': suffix}">
      <div class="col px-0" :class="{'flex-shrink': suffix}">
        <input class="c-input" :type="inputType" :class="subclass" :placeholder="placeholder" @keydown="keydown" :value="value" @input="input" @change="input" :disabled="disabled" :min="min" :max="max" />
      </div>
      <div class="col col-auto px-0 va" v-if="suffix">
        <slot name="suffix">
          <span class="vam pr-3 c-input__suffix f-12 f-md-14 fw-md-500" v-html="suffix"></span>
        </slot>
      </div>
    </div>
  </labeled>
</template>

<script>
  import _ from 'lodash';
  import ValidateMixin from './mixin-validate';

  const ALLOWED_KEYS = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '-', '.', ','];
  const MAX_NUM_LENGTH = 12;

  export default {
    mixins: [ValidateMixin],
    inject: ['validation'],
    props: {
      value: [String, Number, Boolean],
      suffix: [String, Boolean],
      placeholder: String,
      inputType: {
        type: String,
        default: 'text'
      },
      type: String,
      disabled: Boolean,
      subclass: String,
      min: Number,
      max: Number,
      int: Boolean
    },
    computed: {
      label() {
        return this.value ? this.placeholder : '';
      }
    },
    methods: {
      input($event) {
        let value = $event.target.value;
        if (this.type == 'number') {
          value = ('' + value).replace(',', '.');
          if (_.isNaN(Number(value))) {
            $event.target.value = this.min;
            value = this.min
          }else if (Math.abs(parseFloat(value)) > 0) {
            if (value <= this.min || _.isNaN(Number(value))) {
              $event.target.value = this.min;
              value = this.min;
            }
            if (this.int) {
              value = parseInt(value);
            } else {
              value = parseFloat(value);
            }
          }
        }
        // if (this.type == 'number') {
        //   value = Number(value);
        //   if (value <= this.min || _.isNaN(Number(value))) {
        //     $event.target.value = this.min;
        //     value = this.min;
        //   }
        // }
        this.$emit('input', value);
      },
      keydown($event) {
        // console.log($event);
        // console.log($event.key, $event.key.length, ~ALLOWED_KEYS.indexOf($event.key));
        if (this.type == 'number') {
          if (!$event.ctrlKey && !$event.shiftKey && !$event.altKey) {
            if ($event.key.length == 1 && ('' + this.value).length > MAX_NUM_LENGTH) {
              $event.preventDefault();
            }
            if ($event.key.length == 1) {
              if (!~ALLOWED_KEYS.indexOf($event.key)) {
                $event.preventDefault();
              } else {
                if (this.int && $event.key == '.') {
                  $event.preventDefault();
                }
              }
            }
          }
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>