<template>
  <div class="c-checkbox" @click="select" :class="{disabled: disabled}">
    <div class="row mx-0">
      <div class="col col-auto order-2 order-md-1 px-0 mr-md-1">
        <i class="icon f-16 d-none d-md-inline-block" :class="{'icon-checked text-primary': value, 'icon-unchecked': !value}"></i>
        <i class="icon f-22 d-inline-block d-md-none" :class="{'icon-checked-round text-primary': value, 'icon-unchecked-round': !value}"></i>
      </div>
      <div class="col c-checkbox__title order-1 order-md-2 px-0 text-md-gray-800" :class="{'text-primary': value}">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
  import ValidateMixin from './mixin-validate';
  export default {
    mixins: [ValidateMixin],
    inject: ['validation'],
    props: {
      value: [Boolean, Number],
      disabled: [Boolean, Number, String]
    },
    methods: {
      select() {
        if (!this.disabled) {
          let value;
          if (typeof this.value == 'number') {
            value = Number(!this.value);
          } else {
            value = !this.value;
          }
          this.$emit('input', value);
        }
      }
    }
  }
</script>