<template>
  <div class="c-radio" @click="select" :class="{disabled: disabled}">
    <div class="row mx-0">
      <div class="col col-auto order-2 order-md-1 px-0 mr-md-2">
        <div class="icon" :class="{active: active}">
          <svg width="16" height="16" viewBox="0 0 16 16">
            <circle r="7" cx="8" cy="8" />
            <path fill="none" stroke="#fff" stroke-width="1.8" d="M2.3 7l4.2 4.2l6.5 -6.5" />
          </svg>
        </div>
      </div>
      <div class="col c-radio__title order-1 order-md-2 px-0 text-md-gray-800" :class="{'text-primary': active}">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
  import _ from 'lodash';
  window._ = _;
  export default {
    props: {
      group: Object,
      value: [Object, String, Number, Boolean],
      type: [Object, String, Number, Boolean],
      disabled: [Boolean, Number, String]
    },
    computed: {
      active() {
        if (this.group) {
          return this.group && this.group[this.value];
        }
        return this.value == this.type;
      }
    },
    methods: {
      select() {
        if (!this.disabled) {
          if (this.group) {
            let prevKey = _.findKey(this.group, item => item);
            if (prevKey) {
              this.$set(this.group, prevKey, false);
            }
            this.$set(this.group, this.value, true);
            this.$emit('change', this.value);
          } else {
            this.$emit('input', this.type);
          }
        }
      }
    }
  }
</script>