var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h3", [_vm._v("Регистрация")]),
      _c(
        "c-row",
        { staticClass: "mt-5 mt-md-3" },
        [
          _c(
            "c-col",
            { staticClass: "col-12 col-lg-4 col-sm-6" },
            [
              _vm.sending ? _c("preloader", { attrs: { over: "" } }) : _vm._e(),
              !_vm.success
                ? [
                    _c(
                      "c-form",
                      [
                        _c("c-input", {
                          staticClass: "mt-1",
                          attrs: { placeholder: "ФИО" },
                          model: {
                            value: _vm.user.full_name,
                            callback: function($$v) {
                              _vm.$set(_vm.user, "full_name", $$v)
                            },
                            expression: "user.full_name"
                          }
                        }),
                        _c("c-input", {
                          staticClass: "mt-1",
                          attrs: { placeholder: "Наименование организации" },
                          model: {
                            value: _vm.user.company,
                            callback: function($$v) {
                              _vm.$set(_vm.user, "company", $$v)
                            },
                            expression: "user.company"
                          }
                        }),
                        _c("c-input", {
                          staticClass: "mt-1",
                          attrs: { placeholder: "Email" },
                          model: {
                            value: _vm.user.email,
                            callback: function($$v) {
                              _vm.$set(_vm.user, "email", $$v)
                            },
                            expression: "user.email"
                          }
                        }),
                        _c(
                          "c-input",
                          {
                            staticClass: "mt-1",
                            attrs: {
                              placeholder: "Пароль",
                              suffix: true,
                              "input-type": _vm.showPass ? "text" : "password"
                            },
                            model: {
                              value: _vm.user.password,
                              callback: function($$v) {
                                _vm.$set(_vm.user, "password", $$v)
                              },
                              expression: "user.password"
                            }
                          },
                          [
                            _c("template", { slot: "suffix" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "vam hoverable f-0 pr-2 text-gray-400",
                                  on: {
                                    click: function($event) {
                                      _vm.showPass = !_vm.showPass
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "f-18 lh-0",
                                    class: {
                                      "icon-eye": !_vm.showPass,
                                      "icon-eye-disabled": _vm.showPass
                                    }
                                  })
                                ]
                              )
                            ])
                          ],
                          2
                        ),
                        _c(
                          "c-input",
                          {
                            staticClass: "mt-1",
                            attrs: {
                              placeholder: "Подтверждение пароля",
                              suffix: true,
                              "input-type": _vm.showPass ? "text" : "password"
                            },
                            model: {
                              value: _vm.user.password2,
                              callback: function($$v) {
                                _vm.$set(_vm.user, "password2", $$v)
                              },
                              expression: "user.password2"
                            }
                          },
                          [
                            _c("template", { slot: "suffix" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "vam hoverable f-0 pr-2 text-gray-400",
                                  on: {
                                    click: function($event) {
                                      _vm.showPass = !_vm.showPass
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "f-18 lh-0",
                                    class: {
                                      "icon-eye": !_vm.showPass,
                                      "icon-eye-disabled": _vm.showPass
                                    }
                                  })
                                ]
                              )
                            ])
                          ],
                          2
                        ),
                        _c("div", { staticClass: "text-center mt-3 f-0" }, [
                          _c(
                            "div",
                            { staticClass: "d-inline-block" },
                            [
                              _c("vue-recaptcha", {
                                ref: "captcha",
                                attrs: {
                                  sitekey:
                                    "6LehNK0UAAAAAClNzy4YgJMi-BFlDJNRjhuEQuPN"
                                },
                                on: { verify: _vm.captchaVerified }
                              })
                            ],
                            1
                          )
                        ]),
                        _c(
                          "div",
                          { staticClass: "shadow-box mt-3" },
                          [
                            _c(
                              "c-checkbox",
                              {
                                model: {
                                  value: _vm.user.agree,
                                  callback: function($$v) {
                                    _vm.$set(_vm.user, "agree", $$v)
                                  },
                                  expression: "user.agree"
                                }
                              },
                              [
                                _vm._v("Я соглашаюсь с "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "d-inline-block text-primary",
                                    on: {
                                      click: function($event) {
                                        return _vm.openAgreement()
                                      }
                                    }
                                  },
                                  [_vm._v("условиями")]
                                ),
                                _vm._v(" сервиса")
                              ]
                            )
                          ],
                          1
                        ),
                        _vm.errors
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "alert alert-danger mb-0 f-12 d-block mt-3"
                              },
                              _vm._l(_vm.errors, function(error, index) {
                                return _c("div", { key: index }, [
                                  _vm._v(_vm._s(error))
                                ])
                              }),
                              0
                            )
                          : _vm._e(),
                        _c(
                          "c-button",
                          {
                            staticClass: "mt-3 w-100",
                            attrs: {
                              type: "submit",
                              disabled: _vm.$v.user.$invalid || _vm.sending
                            },
                            on: { click: _vm.register }
                          },
                          [_vm._v("Зарегистрироваться")]
                        ),
                        _c(
                          "div",
                          { staticClass: "mt-3 text-center" },
                          [
                            _vm._v("У вас уже есть аккаунт? "),
                            _c(
                              "router-link",
                              { attrs: { to: { name: "login" } } },
                              [_vm._v("Войти.")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                : _vm._e(),
              _vm.success
                ? [
                    _c("div", { staticClass: "alert alert-success d-block" }, [
                      _c("div", { staticClass: "fw-500 mb-2" }, [
                        _vm._v("Вы успешно зарегистрированы")
                      ]),
                      _vm._v(
                        "На указанный Email вам направлено письмо со ссылкой и инструкциями для активации вашей учетной записи."
                      )
                    ]),
                    _c(
                      "c-button",
                      {
                        staticClass: "mt-2 w-100",
                        attrs: { to: { name: "login" } }
                      },
                      [_vm._v("Войти")]
                    )
                  ]
                : _vm._e()
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }