<template lang="pug">
  div
    preloader.mt-5(v-if="loading")
    c-row(v-if="!loading")
      c-col.col-12.col-md-6.col-lg-4
        .mb-2.f-18.text-gray-800.fw-500
          | Профиль
        c-input.mt-1(placeholder="ФИО", v-model="user.full_name")
        c-input.mt-1(placeholder="Наименование организации", v-model="user.company")
        c-input.mt-1(placeholder="Номер телефона", v-model="user.phone", v-mask="{mask: '+99999999999'}")
        c-input.mt-1(placeholder="Email", v-model="user.email")
        .alert.alert-success.mb-0.f-12.d-block.mt-3(v-if="profileSuccess")
          | {{profileSuccess}}
        .alert.alert-danger.mb-0.f-12.d-block.mt-3(v-if="profileError")
          | {{profileError}}
        c-button.mt-3(@click="saveProfile", :disabled="$v.user.$invalid || savingProfile")
          | Сохранить
        .mt-4.mt-md-5.mb-2.f-16.text-gray-800.fw-500
          | Изменение пароля
        c-input.mt-1(placeholder="Старый пароль", v-model="pass.old_password", input-type="password")
        c-input.mt-1(placeholder="Новый пароль", v-model="pass.password1", input-type="password")
        c-input.mt-1(placeholder="Подтверждение пароля", v-model="pass.password2", input-type="password")
        .alert.alert-success.mb-0.f-12.d-block.mt-3(v-if="passwordSuccess")
          | {{passwordSuccess}}
        .alert.alert-danger.mb-0.f-12.d-block.mt-3(v-if="passwordError")
          | {{passwordError}}
        c-button.mt-3(@click="changePassword", :disabled="$v.pass.$invalid || savingPassword")
          | Сохранить
      c-col.mt-4.mt-md-0
        .d-md-inline-block.rounded.bg-gray-200.py-3.px-4
          .mb-3.f-16.text-gray-800.fw-500
            | Права доступа
          .f-12.text-gray-400
            .mt-1(v-if="user.right_to_edit_rights")
              | Назначение прав пользователей
            .mt-1(v-if="user.right_to_create_order")
              | Создание новой заявки
            .mt-1(v-if="user.right_to_see_order_docs")
              | Доступ к документам заявки
            .mt-1(v-if="user.right_to_add_payer")
              | Добавление плательщиков
</template>

<script>
  import _ from 'lodash';
  import api from '@/js/api';
  import {required} from 'vuelidate/lib/validators';

  const phoneRegExp = /^[+]?\d{11,12}$/;
  const phoneCheck = value => phoneRegExp.test(('' + value || '').replace(/[\!\-\(\)\{\} ]/g, ''));

  export default {
    data: () => ({
      loading: true,
      user: {
        full_name: '',
        company: '',
        email: '',
        phone: ''
      },
      pass: {
        old_password: '',
        password1: '',
        password2: ''
      },
      savingProfile: false,
      savingPassword: false,
      profileError: false,
      passwordError: false,
      passwordSuccess: false,
      profileSuccess: false
    }),
    provide() {
      return {
        validation: this.$v
      };
    },
    validations() {
      return {
        user: {
          full_name: {required},
          company: {required},
          email: {required},
          phone: {check: value => !value || phoneCheck(value)}
        },
        pass: {
          old_password: {required},
          password1: {required, equal: value => value == this.pass.password2},
          password2: {required, equal: value => value == this.pass.password1}
        }
      }
    },
    watch: {
      user: {
        handler() {
          this.profileError = null;
          this.profileSuccess = null;
        },
        deep: true
      },
      pass: {
        handler() {
          this.passwordError = null;
          this.passwordSuccess = null;
        },
        deep: true
      }
    },
    methods: {
      saveProfile() {
        if (!this.savingProfile) {
          this.savingProfile = true;
          api.changeProfile(this.user)
            .then(({data}) => {
              this.$viewer.loadUser();
              if (data.error) {
                this.profileSuccess = null;
                this.profileError = data.error;
              } else {
                this.profileSuccess = 'Успешно сохранено.';
                this.profileError = null;
              }
            })
            .finally(() => {
              this.savingProfile = false;
            });
        }
      },
      changePassword() {
        if (!this.savingPassword) {
          this.savingPassword = true;
          api.changePassword(this.pass)
            .then(({data}) => {
              if (data.error) {
                this.passwordSuccess = null;
                this.passwordError = data.error;
              } else {
                this.passwordSuccess = 'Успешно сохранено.';
                this.passwordError = null;
              }
            })
            .finally(() => {
              this.savingPassword = false;
            });
        }
      }
    },
    mounted() {
      api.getProfile()
        .then(response => {
          this.user = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  };
</script>