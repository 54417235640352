<template>
  <div>
    <div v-if="error" class="alert alert-danger">
      {{error}}
    </div>
    <preloader over v-if="loading"></preloader>
    <div v-if="success" class="alert alert-success">
      {{success}}
    </div>
    <template v-if="!loading && !success">
      <c-row>
        <c-col>
          <c-input class="mb-2" placeholder="Имя" v-model="model.name" />
          <c-input class="mb-2" placeholder="E-mail" v-model="model.email" />
          <c-input class="mb-2" placeholder="Телефон" v-model="model.phone" v-mask="{mask: '+7 (999) 999-99-99'}" />
          <c-input class="mb-2" placeholder="Город" v-model="model.city" />
        </c-col>
      </c-row>
      <!-- <c-row v-if="isEntity" class="mt-3 mb-3">
        <c-col>
          <el-tooltip class="item" effect="dark" content="Если вы являетесь юридическим лицом, наши специалисты самостоятельно произведут необходимый расчет и сделают специальное предложение в кратчайшие сроки." placement="top">
            <c-checkbox class="w-100" v-model='model.is_entity'>
              Вы являетесь Юридическим лицом?
            </c-checkbox>
          </el-tooltip>
        </c-col>
      </c-row> -->
      <template v-if="model.email && model.phone">
        <h5 class="mt-3">Предпочитаемый метод коммуникации</h5>
        <c-row class="mt-3">
          <c-col class="col-6 col-md-6 col-lg-4">
            <div class="shadow-box mt-2" :class="{active: model.type == 'email'}">
              <c-radio ref="radio" v-model="model.type" type='email'>E-mail</c-radio>
            </div>
          </c-col>
          <c-col class="col-6 col-md-6 col-lg-4">
            <div class="shadow-box mt-2" :class="{active: model.type == 'phone'}">
              <c-radio ref="radio" v-model="model.type" type='phone'>Телефон</c-radio>
            </div>
          </c-col>
        </c-row>
      </template>
      <c-row class="mt-4">
        <c-col>
          <textarea v-model="model.message" class="form-control" placeholder="Сообщение"></textarea>
        </c-col>
      </c-row>
      <c-row class="mt-4">
        <c-col>
          <el-upload
            :action="api.uploudFileUrl"
            :limit="3"
            ref="upload"
            :on-success="handleSuccess"
            :on-remove="handleRemove"
            >
            <span class="el-upload__btn" v-if="model.files.length < 3" slot="trigger"><i class="icon-attach-file"></i> Прикрепить файл</span>
          </el-upload>
        </c-col>
      </c-row>
      <c-row class="mt-0 mb-5 mt-md-4">
        <c-col>
          <c-button class="w-100" @click="click">Отправить</c-button>
        </c-col>
      </c-row>
    </template>
  </div>
</template>

<script>
import api from '@/js/api.js';
import { required, email } from 'vuelidate/lib/validators';
import { clone } from 'lodash'

const phoneRegExp = /^[+]?\d{11,12}$/;
var validPhone = (value) => {
  return phoneRegExp.test(('' + value || '').replace(/[\!\-\(\)\{\} ]/g, ''));
}

const MODEL = {
  name: '',
  email: '',
  phone: '',
  city: '',
  type: 'email',
  message: '',
  files: [],
  is_entity: false
}

export default {
  data() {
    return {
      api,
      loading: true,
      success: '',
      error: '',
      model: MODEL,
      isEntity: false,
      validationsRules: {
        phone: { required, phone: (value) => (validPhone(value)) },
        email: { email }
      }
    }
  },
  provide() {
    return {
      validation: this.$v
    }
  },
  validations() {
    return {
      model: this.validationsRules
    }
  },
  methods: {
    convertFileList() {
      return this.$refs.upload.uploadFiles.map(item => ({ uid: item.response.uid, name: item.name }))
    },
    handleSuccess(res, file) {
      this.model.files = this.convertFileList()
    },
    handleRemove(file, fileList) {
      api.deleteFile(file.response.uid).then(res => {
        if (res.status === 200) {
          this.error = ''
          this.model.files = this.convertFileList()
        } else {
          this.error = 'Ошибка, удаления файла'
        }
      }, error => {
        this.error = error
      })
    },
    click() {
      this.$v.model.$touch();
      if (this.$v.model.$invalid) return false
      this.loading = true

      // Сорри за костыль, по другому мутация в компоненте радиобатонов ломалась
      let query = clone(this.model)

      if (query.email && !query.phone) query.type = 'email'
      if (query.phone && !query.email) query.type = 'phone'
      if (query.phone && query.email) query.type = 'email'

      api.feedbackForm(query).then(res => {
        if (res.status === 200) {
          this.error = ''
          // this.success = 'Сообщение отправлено'
          window.location.href = `/feedbackConfirm${DEBUG ? '.html' : ''}`
        } else {
          this.error = 'Ошибка, сообщение не отправлено.'
        }
      }, error => {
        this.error = error
      }).finally(() => {
        this.loading = false
      })
    }
  },
  watch: {
    model: {
      handler(model) {
        if (model.email && !model.phone) {
          // this.model.type = 'email'
          this.validationsRules = {
            email: {required, email}
          }
        }
        if (model.phone && !model.email) {
          // this.model.type = 'phone'
          this.validationsRules = {
            phone: {required, phone: (value) => (validPhone(value))},
            email: {email}
          }
        }
        if (model.phone && model.email) {
          // this.model.type = 'email'
          this.validationsRules = {
            email: {required, email},
            phone: {required, phone: (value) => (validPhone(value))}
          }
        }
        this.$v.model.$touch();
      },
      deep: true
    }
  },
  mounted() {
    setTimeout(() => {
      this.loading = false
    })
    let params = new URLSearchParams(window.location.search)
    if (params.has('check')) {
      this.isEntity = true
      this.model.is_entity = true
      let elCheck = document.getElementById('js-feedback-check')
      if (elCheck) elCheck.style.display = 'block'
    } else {
      let elCheck = document.getElementById('js-feedback-desk')
      if (elCheck) elCheck.style.display = 'block'
    }
  }
}
</script>
