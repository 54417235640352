<script>
  import isMobile from '@/js/core/is-mobile'
  export default {
    mounted() {
      window.addEventListener('resize', (event) => {
        if (!isMobile()) this.$router.push({ name: 'desktop.list' })
      })

      if (!isMobile()) {
        this.$router.push({ name: 'desktop.list' })
        return false
      }
    }
  };
</script>