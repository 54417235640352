<template>
  <labeled :label="label" class="d-block c-datepicker" :class="{'is-invalid': isInvalid}">
    <date-picker
      :value="value"
      @change="input"
      lang="ru"
      format="DD.MM.YYYY"
      :clearable="false"
      :placeholder="placeholder"
      :disabled-days="disabledDays"
      :first-day-of-week="1"
      :range="range"
      :shortcuts="false">
      <template slot="calendar-icon">
        <i class="icon-calendar text-gray-300"></i>
      </template>
      <template v-if="range" slot="header">
        <div class="d-md-flex mt-2 d-none">
          <div class="col-6 text-center">
            <span>Начало периода</span>
          </div>
          <div class="col-6 text-center">
            <span>Конец периода</span>
          </div>
        </div>
      </template>
    </date-picker>
  </labeled>
</template>

<script>
  import _ from 'lodash';
  import moment from 'moment';
  import ValidateMixin from './mixin-validate';
  export default {
    mixins: [ValidateMixin],
    inject: ['validation'],
    props: {
      value: [String, Date, Array],
      placeholder: String,
      dates: {
        type: Array,
        default: () => []
      },
      range: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      label() {
        return this.value ? this.placeholder : '';
      }
    },
    methods: {
      input(value) {
        this.$emit('input', value);
      },
      disabledDays(value) {
        // console.log(date);
        let date = moment(value);
        return _.get(this.dates, 'length') ? !this.dates.filter(item => item.isSame(date, 'd')).length : false;
      }
    }
  }
</script>

<style lang="scss" scoped>
  .c-datepicker {
    /deep/ {
      .mx-datepicker {
        width: 100%;
      }
      .mx-input {
        border: none;
        box-shadow: none;
      }
      .mx-input-append {
        width: auto;
      }
      .icon-calendar {
        margin-top: 4px;
        margin-right: 2px;
      }
      @media (max-width: 768px) {
        .mx-datepicker-popup {
          left: 0 !important;
        }
        .mx-range-wrapper {
          width: 248px !important;
        }
      }
    }
  }
</style>