<template lang="pug">
  .sidebar.text-center.text-md-left
    .d-inline-block.menu-item.border-gray-200.px-3(v-for="(item, index) in items", :key="index")
      .fa.py-2.hoverable(:class="{'fw-500 text-primary': item.active}", @click="click(item)")
        i.menu-icon(:class="'icon-' + item.icon")
        span.f-12.f-md-14.ml-2.ml-md-3
          | {{item.title}}
</template>

<script>
  export default {
    props: {
      items: {
        type: Array,
        default: () => []
      }
    },
    methods: {
      click(item) {
        if (item && item.action) {
          item.action();
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .menu-icon {
    font-size: 20px;
    @media (max-width: 768px) {
      font-size: 16px;
    }
  }
  .menu-item {
    border: 1px solid;
    &:first-child {
      border-radius: 4px 0 0 4px;
    }
    &:last-child {
      border-radius: 0 4px 4px 0;
    }
    & + & {
      border-left: none;
    }
  }
</style>