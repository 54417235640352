<template>
  <div>
    <h3>
      <span class="d-inline-block mb-3">
        Инструкции
      </span>
    </h3>
    <preloader over v-if="loading"></preloader>
    <div v-if="error" class="alert alert-danger">
      {{error}}
    </div>
    <template v-if="!error && !loading">
      <div class="nav-mobile-title mb-3" @click="$router.go(-1)">
        <div class="nav-mobile-title--sub">Категория</div>
        {{category.name || 'Не названия'}}
      </div>
      <template v-if="content && content.length">
        <!-- <h2 class="documents-box-title">{{activeCategory}}</h2> -->
        <div class="documents-box col-8" v-if="content && content.length">
          <h2 class="documents-box-title">{{category.name}}</h2>
          <youtube :height.sync="windowWidth/1.7" :width.sync="windowWidth" :video-id="getId(content[0].media_url)" ref="youtube"></youtube>
          <a class="documents-box-link instructions" :href="content[0].doc_url" download><i class="icon-download documents-box-link-icon"></i> Скачать pdf</a>
        </div>
      </template>
      <div v-else class="alert alert-danger">
        Нет данных
      </div>
    </template>
  </div>
</template>

<script>
  import Base from '../base'
  import api from '@/js/api'
  import _ from 'lodash'
  import parseContent from '@/js/core/parse-content-simple'

  export default {
    extends: Base,
    data: () => ({
      category: {},
      loading: false,
      error: '',
      content: [],
      windowWidth: undefined,
    }),
    methods: {
      getId (url) {
        return this.$youtube.getIdFromUrl(url)
      },
      getScreenWidth() {
        if (window.innerWidth < 768) {
          this.windowWidth = window.innerWidth * 0.85
        } else {
          this.windowWidth =  window.innerWidth * 0.5
        }
      },
    },
    mounted() {
      this.getScreenWidth()
      window.addEventListener('resize', (event) => {
        this.getScreenWidth()
      })
      this.loading = true,
      Promise.all([
        api.getInstrCategory(this.$route.params.category_id),
        api.getInstrList(this.$route.params.category_id)
			]).then(([category, content]) => {
        this.category = category.data
        this.content = content.data
        // .map(item => {
        //   item.doc_url = item.doc_url
        //   item.id = item.id
        //   item.doc_name = item.doc_name
        //   return item
        // })
			}).catch(error => {
				this.error = error
			}).finally(() => {
        this.loading = false
      });
    },
    computed: {
      player() {
        return this.$refs.youtube.player
      }
    }
  };
</script>