var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._m(0),
      _vm._v(" "),
      _vm.loading ? _c("preloader", { attrs: { over: "" } }) : _vm._e(),
      _vm._v(" "),
      _vm.error
        ? _c("div", { staticClass: "alert alert-danger" }, [
            _vm._v("\n    " + _vm._s(_vm.error) + "\n  ")
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.error && !_vm.loading
        ? [
            _c(
              "div",
              {
                staticClass: "nav-mobile-title mb-3",
                on: {
                  click: function($event) {
                    return _vm.$router.go(-1)
                  }
                }
              },
              [
                _c("div", { staticClass: "nav-mobile-title--sub" }, [
                  _vm._v("Категория")
                ]),
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.category.name || "Не названия") +
                    "\n    "
                )
              ]
            ),
            _vm._v(" "),
            _vm.content && _vm.content.length
              ? [
                  _vm.content && _vm.content.length
                    ? _c(
                        "div",
                        { staticClass: "documents-box col-8" },
                        [
                          _c("h2", { staticClass: "documents-box-title" }, [
                            _vm._v(_vm._s(_vm.category.name))
                          ]),
                          _vm._v(" "),
                          _c("youtube", {
                            ref: "youtube",
                            attrs: {
                              height: _vm.windowWidth / 1.7,
                              width: _vm.windowWidth,
                              "video-id": _vm.getId(_vm.content[0].media_url)
                            },
                            on: {
                              "update:height": function($event) {
                                return _vm.$set(
                                  _vm.windowWidth / 1,
                                  "7",
                                  $event
                                )
                              },
                              "update:width": function($event) {
                                _vm.windowWidth = $event
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "documents-box-link instructions",
                              attrs: {
                                href: _vm.content[0].doc_url,
                                download: ""
                              }
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "icon-download documents-box-link-icon"
                              }),
                              _vm._v(" Скачать pdf")
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ]
              : _c("div", { staticClass: "alert alert-danger" }, [
                  _vm._v("\n      Нет данных\n    ")
                ])
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", [
      _c("span", { staticClass: "d-inline-block mb-3" }, [
        _vm._v("\n      Инструкции\n    ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }