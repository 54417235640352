<template lang="pug">
  .pagination.f-14.f-md-12.f-500.text-gray-400
    .prev.d-inline-block.hoverable.px-2(@click="prev", :class="{active: value > 1}", v-if="count > diff * 2")
    .d-inline-block(v-for="(item, index) in items", :key="index")
      .d-inline-block.px-2.hoverable(@click="click(item)", :class="{'text-primary fw-500': item == value}")
        | {{item}}
      .d-inline-block.px-2(v-if="items[index + 1] && items[index + 1] - item > 1")
        | ...
    .next.d-inline-block.hoverable.px-2(@click="next", :class="{active: value < count}", v-if="count > diff * 2")
</template>

<script>
  import _ from 'lodash';
  export default {
    props: {
      count: Number,
      value: Number,
      diff: {
        type: Number,
        default: 2
      }
    },
    computed: {
      items() {
        let items = _.range(this.count).map(value => value + 1).filter(pos => {
          if (pos <= this.diff) {
            return true;
          }
          if (pos > this.count - this.diff) {
            return true;
          }
          if (pos > this.value - this.diff && pos < this.value + this.diff) {
            return true;
          }
        });
        return items;
      }
    },
    methods: {
      prev() {
        let value = this.value - 1;
        if (value > 0) {
          this.click(value);
        }
      },
      next() {
        let value = this.value + 1;
        if (value <= this.count) {
          this.click(value);
        }
      },
      click(index) {
        if (index != this.value) {
          this.$emit('input', index);
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .pagination > * {
    vertical-align: middle;
    user-select: none;
  }
  .prev, .next {
    height: 15px;
    width: 24px;
    padding-top: 2px;
    &:before, &:after {
      content: '';
      display: block;
      position: relative;
      height: 5px;
      width: 1px;
      background: #bdbdbd;
    }
    &.active {
      &:before, &:after {
        background-color: #6f6f6f;
      }
    }
    @media (max-width: 768px) {
      height: 21px;
      &:before, &:after {
        height: 8px;
        width: 2px;
      }
    }
  }
  .prev, .next {
    &:before {
      transform-origin: 0 100%;
      transform: rotate(45deg);
    }
    &:after {
      transform-origin: 0 0;
      transform: rotate(-45deg);
    }
  }
  .next {
    transform: rotate(180deg);
    padding-top: 3px;
  }
</style>