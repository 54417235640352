<script>
  import {Table} from 'element-ui';
  export default {
    extends: Table,
    props: {
      headerCellClassName: {
        type: String,
        default: 'f-10 lh-1 text-muted py-2 text-uppercase border-top border-gray-200'
      },
      cellClassName: {
        type: String,
        default: 'f-14 lh-1 text-gray-800 border-gray-200'
      },
      'empty-text': {
        type: String,
        default: ''
      }
    }
  };
</script>