var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    _c("div", { staticClass: "stocks track container-full" }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "tracking" },
        [
          _c("div", { staticClass: "tracking__form row" }, [
            _vm.dekstopCheck()
              ? _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.number,
                      expression: "number"
                    }
                  ],
                  staticClass: "tracking__input",
                  attrs: {
                    placeholder: "Номер заявки или номер заказа клиента"
                  },
                  domProps: { value: _vm.number },
                  on: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.getOrder(_vm.number)
                    },
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.number = $event.target.value
                    }
                  }
                })
              : _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.number,
                      expression: "number"
                    }
                  ],
                  staticClass: "tracking__input",
                  attrs: { placeholder: "Номер заявки" },
                  domProps: { value: _vm.number },
                  on: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.getOrder(_vm.number)
                    },
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.number = $event.target.value
                    }
                  }
                }),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "tracking__submit",
                on: {
                  click: function($event) {
                    return _vm.getOrder(_vm.number)
                  }
                }
              },
              [_vm._v("Отследить")]
            )
          ]),
          _vm._v(" "),
          _vm.loading
            ? _c("preloader", { attrs: { over: "", height: "480" } })
            : _vm.not_found
            ? _c("div", { staticClass: "tracking__dummy row" }, [
                _c("div", { staticClass: "tracking__dummy-box" }, [
                  _c("i", { staticClass: "icon-archive tracking__dummy-icon" }),
                  _vm._v(" "),
                  _c("p", { staticClass: "tracking__dummy-title" }, [
                    _vm._v("Нет результатов")
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "tracking__dummy-text" }, [
                    _vm._v("Измените запрос и попробуйте еще раз")
                  ])
                ])
              ])
            : this.order
            ? _c(
                "div",
                { staticClass: "tracking__container" },
                [
                  _c("div", { staticClass: "tracking__info row" }, [
                    _c("ul", { staticClass: "tracking__list" }, [
                      _c("li", { staticClass: "tracking__item" }, [
                        _c("span", { staticClass: "tracking__greytext" }, [
                          _vm._v("Заявка")
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "tracking__data" }, [
                          _vm._v(_vm._s(this.order.OrderShortNo))
                        ])
                      ]),
                      _vm._v(" "),
                      _c("li", { staticClass: "tracking__item" }, [
                        _c("span", { staticClass: "tracking__greytext" }, [
                          _vm._v("Дата заявки")
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "tracking__data" }, [
                          _vm._v(_vm._s(this.order.CrtDate))
                        ])
                      ]),
                      _vm._v(" "),
                      _c("li", { staticClass: "tracking__item" }, [
                        _c("span", { staticClass: "tracking__greytext" }, [
                          _vm._v("Статус")
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "tracking__data" }, [
                          _vm._v(_vm._s(this.order.CurrCargoState))
                        ])
                      ]),
                      _vm._v(" "),
                      _c("li", { staticClass: "tracking__item" }, [
                        _c("span", { staticClass: "tracking__greytext" }, [
                          _vm._v("Характер груза")
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "tracking__data" }, [
                          _vm._v(_vm._s(this.order.CargoContent))
                        ])
                      ]),
                      _vm._v(" "),
                      _c("li", { staticClass: "tracking__item" }, [
                        _c("span", { staticClass: "tracking__greytext" }, [
                          _vm._v("Статус оплаты")
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "tracking__data" }, [
                          _vm._v(_vm._s(this.order.PayState))
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("ul", { staticClass: "tracking__list fact" }, [
                      _c("li", { staticClass: "tracking__item" }, [
                        this.order.FactWeight != null
                          ? _c("p", { staticClass: "tracking__fact" }, [
                              _c("img", {
                                staticClass: "tracking__fact__icon  first",
                                attrs: {
                                  src: "/static/img/icons/wheelbarrow.svg"
                                }
                              }),
                              _vm._v(
                                _vm._s(this.order.FactWeight) +
                                  " кг " +
                                  _vm._s(_vm.placesText())
                              )
                            ])
                          : _vm._e(),
                        this.order.FactVolume != null
                          ? _c("p", { staticClass: "tracking__fact" }, [
                              _c("img", {
                                staticClass: "tracking__fact__icon",
                                attrs: {
                                  src: "/static/img/icons/arrows-out.svg"
                                }
                              }),
                              _c("span", [
                                _vm._v(_vm._s(this.order.FactVolume) + " м"),
                                _c("sup", [_vm._v("3")])
                              ])
                            ])
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("li", { staticClass: "tracking__item" }, [
                        _c(
                          "a",
                          {
                            staticClass: "tracking__link",
                            attrs: { href: "/documents/" }
                          },
                          [_vm._v("Список документов для отправки/получения")]
                        )
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  [
                    _vm.dekstopCheck() && _vm.canShow
                      ? _c("el-progress", {
                          attrs: {
                            "stroke-width": 5,
                            "show-text": false,
                            height: 3,
                            percentage: this.progress,
                            color: "#ca1226"
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.dekstopCheck() && _vm.canShow
                      ? _c(
                          "div",
                          { staticClass: "tracking__display" },
                          _vm._l(this.points, function(point) {
                            return _c(
                              "ul",
                              {
                                key: point.city_name + " " + point.point_type,
                                staticClass: "tracking__points"
                              },
                              [
                                _c(
                                  "li",
                                  { staticClass: "tracking__point-info" },
                                  [
                                    point.point_status !== 0 &&
                                    point.point_type === 0
                                      ? _c("img", {
                                          staticClass: "tracking__icon",
                                          attrs: {
                                            src:
                                              "/static/img/icons/house-red.svg"
                                          }
                                        })
                                      : point.point_status !== 0 &&
                                        point.point_type === 1
                                      ? _c("img", {
                                          staticClass: "tracking__icon",
                                          attrs: {
                                            src:
                                              "/static/img/icons/boxes-red.svg"
                                          }
                                        })
                                      : point.point_status === 0 &&
                                        point.point_type === 0
                                      ? _c("img", {
                                          staticClass: "tracking__icon",
                                          attrs: {
                                            src: "/static/img/icons/house.svg"
                                          }
                                        })
                                      : _c("img", {
                                          staticClass: "tracking__icon",
                                          attrs: {
                                            src: "/static/img/icons/boxes.svg"
                                          }
                                        })
                                  ]
                                ),
                                _vm._v(" "),
                                _c("div", [
                                  _c(
                                    "li",
                                    { staticClass: "tracking__point-info" },
                                    [
                                      _c(
                                        "p",
                                        { staticClass: "tracking__city" },
                                        [_vm._v(_vm._s(point.city_name))]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "li",
                                    { staticClass: "tracking__point-info" },
                                    [
                                      _c(
                                        "p",
                                        { staticClass: "tracking__status" },
                                        [_vm._v(_vm._s(point.point_message))]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "li",
                                    { staticClass: "tracking__point-info" },
                                    [
                                      _c(
                                        "p",
                                        { staticClass: "tracking__date" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("fulldate")(
                                                point.point_date
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ])
                              ]
                            )
                          }),
                          0
                        )
                      : _vm.canShow
                      ? _c(
                          "div",
                          [
                            _c(
                              "el-steps",
                              {
                                attrs: {
                                  active: _vm.active,
                                  space: 80,
                                  direction: "vertical"
                                }
                              },
                              _vm._l(this.points, function(point) {
                                return _c("el-step", {
                                  key: point.city_name + " " + point.point_type,
                                  staticClass: "house",
                                  attrs: {
                                    icon: _vm.iconCheck(point),
                                    title: point.city_name,
                                    description:
                                      point.point_message +
                                      "\n" +
                                      point.point_date
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                ],
                2
              )
            : _c("div", { staticClass: "tracking__dummy row" }, [
                _c("div", { staticClass: "tracking__dummy-box" }, [
                  _c("i", {
                    staticClass: "icon-car_dummy tracking__dummy-icon"
                  }),
                  _vm._v(" "),
                  _c("p", { staticClass: "tracking__dummy-text" }, [
                    _vm._v(
                      "Введите номер заявки или номер заказа клиента чтобы отследить статус перевозки"
                    )
                  ])
                ])
              ])
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", [
      _c("span", { staticClass: "d-inline-block mb-3" }, [
        _vm._v("\n      Данные перевозки\n    ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }