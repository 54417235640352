<template lang="pug">
  div
    c-select.mt-2(:placeholder="placeholder", v-model="selected", :options="options")
    div
      .select-list-item.d-inline-block.text-gray-800.py-1.px-2.mt-2(v-for="(item, index) in itemsArray")
        | {{item.title}}
        .d-inline-block.text-gray-400.f-10.ml-2.hoverable(@click="remove(index)")
          i.icon-close
</template>

<script>
  import _ from 'lodash';
  export default {
    props: {
      value: {
        type: Array,
        default: () => []
      },
      options: Array,
      placeholder: String
    },
    data: () => ({
      itemsArray: [],
      selected: null
    }),
    watch: {
      value(value) {
        this.checkValue(value)
      },
      options(value) {
        this.checkValue(this.value)
      },
      selected(value) {
        let item = _.find(this.options, item => item.value == value);
        let found = _.find(this.itemsArray, item => item.value == value);
        if (item && !found) {
          this.itemsArray = this.itemsArray.concat([item]);
          this.$emit('input', this.itemsArray);
          this.$nextTick(() => {
            this.selected = null;
          });
        }
      }
    },
    methods: {
      remove(index) {
        this.itemsArray = _.filter(this.itemsArray, (item, itemIndex) => itemIndex != index);
        this.$emit('input', this.itemsArray);
      },
      checkValue(value) {
        this.itemsArray = _.map(value, item => _.find(this.options, oitem => oitem.value == item.value)).filter(item => item);
      }
    },
    mounted() {
      this.checkValue(this.value)
    }
  };
</script>

<style lang="scss" scoped>
  .select-list-item {
    background-color: #eceff3;
    border-radius: 2px;
  }
</style>