import moment from "moment";
import _ from "lodash";

export const agentDebug = {
    agent_role: null,
    agent_type: 1,
    agent_name: 'Ivanov Ivan',
    agent_document_type_id: 11,
    agent_document_series: 1,
    agent_document_number: 1234567890,
    agent_contact: 'Fedor Fedorov',
    agent_phone: '+79211084447',
    agent_email: 'mail@mail.ru',
    agent_inn: 'inn',
    agent_kpp: 'kpp',
    agent_is_informing_sms: true,
    agent_is_informing_mail: false,
    type_pay: null
};

export const agent = {
    agent_role: null,
    agent_type: null,
    agent_name: '',
    agent_document_type_id: null,
    agent_document_series: null,
    agent_document_number: null,
    agent_contact: null,
    agent_phone: '',
    agent_email: '',
    agent_inn: '',
    agent_kpp: '',
    agent_is_informing_sms: false,
    agent_is_informing_mail: false,
    type_pay: null
};

export const CARGO = {
    length: 1,
    weight: 1,
    amount: 1,
    measure_LWH: 1,
    height: 1,
    width: 1,
    is_dimensional: false
}

export const DEMO = {
    calc_fields: {
        "city_from_id": 4220,
        "cargo_pickup": false,
        "cargo_pickup_date": moment().format('DD.MM.YYYY'),
        "cargo_pickup_id": 0,
        "cargo_pickup_adress": null,

        "city_to_id": 4225,
        "cargo_delivery": false,
        "cargo_delivery_date": moment().format('DD.MM.YYYY'),
        "cargo_delivery_id": 0,
        "cargo_delivery_adress": null,

        "express_service": false,

        "calc_type": "common",
        "common_cargo_weight": 50,
        "common_cargo_volume": 0.2,
        "common_cargo_amount": 1,
        "is_overall": false,

        "cargo_kind_id": 118,

        // упаковка
        "palletizing_service": false,
        "stretch_service": false,
        "bubble_service": false,
        "pallet_board": false,
        "rigid_packing_service": false,
        "other_pack_service_check": false,
        "other_pack_service": 0,

        // доп. услуги
        "insurance_service": false,
        "cargo_cost": 0,
        "docs_service_check": false,
        "docs_service": 1,
        "thermo_service": false,

        "sender_loading": false,
        "sender_loading_weight": 2,
        "sender_loading_floor": -1,

        "receiver_loading": false,
        "receiver_loading_floor": -1,
        "receiver_loading_weight": 2,

        //
        "backlog_phone": null,
        "order_type": 1, // 1 - online, 0 - manager

        "payer_id": 0,
        "sender_id": 0,
        "recip_id": 0,
        "creator_id": 0
    },
    cargo_fields: [_.cloneDeep(CARGO)],
    agents: [],
    response: {
        "pickup_cost": null,
        "transport_cost": null,
        "cargo_processing_cost": null,
        "delivery_cost": null,
        "services_cost": null,
        "packing_cost": null,
        "thermo_cost": null,
        "total_cost": null,

        "city_from": "",
        "city_from_adresses": [],
        "city_from_dates": ["2019-04-16", "2019-04-19"],
        "city_from_times": ["08:00", "15:00", "17:00"],
        "city_to": "",
        "city_to_adresses": [],
        "city_to_dates": ["2019-04-16", "2019-04-19"],
        "city_to_times": ["08:00", "15:00", "17:00"],

        "cargo_pickup_adress": "",
        "cargo_delivery_adress": "",
        "cargo_pickup_date": "",
        "cargo_delivery_date": "",

        "cargo_weight": null,
        "cargo_volume": null,
        "cargo_amount": null,
        "is_overall": "",

        "receiver_service_loading_cost": 500,
        "sender_service_loading_cost": 500,
        "thermo_service_cost": 500,
        "express_service_cost": 500,
        "palletizing_service_cost": 500,
        "pallet_board_service_cost": 500,
        "stretch_service_cost": 500,
        "bubble_service_cost": 500,
        "rigid_packings_service_cost": 500,
        "insurance_service_cost": 500,
        "docs_service_cost": 500,
        "pickup_service_cost": 500,
        "delivery_service_cost": 500,
        "pickup_time_service_cost": 500,
        "delivery_time_service_cost": 500,
        "other_service_cost": 500,

        "is_calc_standart": 1,
        "is_calc_express": 1,
        "is_calc_termo": 1
    }
};

export const initialData = {
    tmpCargoName: null,
    options: {
        cities: [],
        offices: [],
        officesFrom: [],
        officesTo: [],
        datesFrom: [],
        datesTo: [],
        units: [
            {
                value: 1,
                title: 'см'
            },
            {
                value: 2,
                title: 'м'
            }
        ],
        cargoKinds: [],
        cargoKindMOptions: [],
        weights: [
            {
                value: 0,
                title: 'Нет'
            },
            {
                value: 1,
                title: 'До 30 кг/место'
            },
            {
                value: 2,
                title: 'До 200 кг/место'
            }
        ],
        floors: [
            // {
            //   value: 0,
            //   title: 'Нет'
            // },
            {
                value: -1,
                title: 'Есть лифт'
            },
            ...(_.range(98).map(value => ({value: value + 1, title: '' + (value + 1) + ' этаж'})))
        ],
        ownershipTypes: [
            {
                value: 1,
                title: 'Физическое лицо'
            },
            {
                value: 2,
                title: 'Индивидуальный предприниматель'
            },
            {
                value: 3,
                title: 'Юридическое лицо'
            }
        ],
        docTypes: [],
        paymentTypes: [
            // {
            //   value: 0,
            //   title: 'Для отправителя/получателя'
            // },
            {
                value: 1,
                title: 'Безналичный расчет'
            },
            {
                value: 2,
                title: 'Касса'
            },
            {
                value: 3,
                title: 'Квитанция'
            },
        ],
        packService: [
            {
                value: 0,
                title: 'Не выбрано'
            },
            {
                value: 1,
                title: 'Герметичный пакет с пломбой'
            },
            {
                value: 2,
                title: 'Упаковка в маленькие коробки'
            },
            {
                value: 3,
                title: 'Упаковка в большие упаковки'
            }
        ],
        docsService: [
            // {
            //   value: 0,
            //   title: 'Не выбрано'
            // },
            {
                value: 1,
                title: 'Доставка документов'
            },
            {
                value: 2,
                title: 'Возврат документов с проверкой корректности'
            },
            {
                value: 3,
                title: 'Возврат документов без проверки корректности'
            }
        ],
        timeFrom: [],
        timeTo: [],
        payers: [],
        senders: [],
        receivers: [],
        payerTypes: [
            {value: 'sender', title: 'Отправитель'},
            {value: 'receiver', title: 'Получатель'},
            {value: 'third', title: 'Третье лицо'}
        ]
    },
    blocks: {
        calc: {
            show: true,
            filled: true
        },
        sender: {
            show: false,
            filled: false
        },
        receiver: {
            show: false,
            filled: false
        },
        payer: {
            show: false,
            filled: false
        }
    },
    calc: {
        cargo_pickup: 0,
        cargo_delivery: 0,
        city_from_id: null,
        city_to_id: null,
        sender_id: null,
        recip_id: null,
        payer_id: 0,
    },
    cargo: [],
    agents: [],
    payer_type: null,
    response: {},
    loaded: false,
    loading: false,
    sending: false,
    backlog: false,
    nonStandart: false,
    showBill: false,
    PDAgree: true,
    saved: false,
    orderError: null,
    errors: false,
    modals: {
        badConnection: false
    },
    customOrder: false,
    order_id: null,
    isExpressApplied: false,
    isStartDateChanged: false,
    TownID: null,
    Address: null,
    temp: {}
}

export const necessaryCalcFieldsList =[
    'city_from',
    'city_from_id',
    'city_to_id',
    'city_to',
    'payer_id',
    'sender_id',
    'recip_id',
    'cargo_pickup',
    'cargo_pickup_date',
    'cargo_pickup_time',
    'cargo_pickup_id',
    'cargo_pickup_adress',
    'cargo_delivery',
    'cargo_delivery_date',
    'cargo_delivery_time',
    'cargo_delivery_id',
    'cargo_delivery_adress',
    'express_service',
    'calc_type',
    'common_cargo_weight',
    'common_cargo_volume',
    'common_cargo_amount',
    'is_overall',
    'cargo_kind_id',
    'cargo_name',
    'insurance_service',
    'cargo_cost',
    'docs_service_check',
    'docs_service',
    'thermo_service',
    'sender_loading',
    'sender_loading_floor',
    'receiver_loading',
    'receiver_loading_floor',
    'palletizing_service',
    'pallet_board',
    'stretch_service',
    'bubble_service',
    'rigid_packing_service',
    'other_pack_service_check',
    'other_pack_service',
    'order_type',
    'backlog_phone',
    'creator_id',
]