var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c(
        "container",
        { staticClass: "calc pt-md-2 pb-4" },
        [
          _vm.loaded
            ? _c(
                "c-row",
                [
                  _c(
                    "c-col",
                    { staticClass: "col-12 col-md-8" },
                    [
                      _c(
                        "c-row",
                        {
                          staticClass: "blocks-list",
                          class: { authorized: _vm.$viewer.logged }
                        },
                        [
                          _c(
                            "c-col",
                            {
                              staticClass: "col-12 block",
                              class: { "text-muted": !_vm.blocks.calc.filled }
                            },
                            [
                              _c(
                                "div",
                                {
                                  class: {
                                    "d-none d-md-inline-block": !_vm.blocks.calc
                                      .show
                                  }
                                },
                                [
                                  _c(
                                    "h3",
                                    {
                                      class: {
                                        "text-muted": !_vm.blocks.calc.filled
                                      }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "d-inline-block mr-3" },
                                        [
                                          _vm._v(
                                            "Направление и параметры груза"
                                          )
                                        ]
                                      ),
                                      !_vm.blocks.calc.show &&
                                      _vm.blocks.calc.filled
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "mb-2 d-inline-block text-primary f-14 hoverable",
                                              on: {
                                                click: function($event) {
                                                  return _vm.editBlock("calc")
                                                }
                                              }
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "icon-pencil mr-2"
                                              }),
                                              !_vm.isBlockInvalid("calc")
                                                ? _c("span", [
                                                    _vm._v("Редактировать")
                                                  ])
                                                : _vm._e(),
                                              _vm.isBlockInvalid("calc")
                                                ? _c("span", [
                                                    _vm._v(
                                                      "Продолжить заполнение"
                                                    )
                                                  ])
                                                : _vm._e()
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  ),
                                  !_vm.blocks.calc.show &&
                                  _vm.blocks.calc.filled &&
                                  _vm.isBlockInvalid("calc")
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "mt-2 text-purple fw-500"
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "icon-warning mr-2"
                                          }),
                                          _vm._v("Не все данные заполнены")
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              ),
                              !_vm.blocks.calc.show && _vm.blocks.calc.filled
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "d-block d-md-none",
                                      on: {
                                        click: function($event) {
                                          return _vm.editBlock("calc")
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "w-100 btn btn-outline-primary py-2 px-4 text-left"
                                        },
                                        [
                                          _c(
                                            "c-row",
                                            {
                                              staticClass:
                                                "flex-nowrap align-items-center"
                                            },
                                            [
                                              _c(
                                                "c-col",
                                                { staticClass: "f-14 fw-500" },
                                                [_vm._v("Направление")]
                                              ),
                                              _c(
                                                "c-col",
                                                { staticClass: "col-auto" },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "icon-angle-down f-10"
                                                  })
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      !_vm.blocks.calc.show &&
                                      _vm.blocks.calc.filled &&
                                      _vm.isBlockInvalid("calc")
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "py-2 bg-gray-200 text-gray-400 fa justify-content-center"
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "icon-warning bg-gray-400 mr-2"
                                              }),
                                              _vm._v("Не все данные заполнены")
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                : _vm._e(),
                              _vm.blocks.calc.show
                                ? _c(
                                    "div",
                                    { staticClass: "mt-4 p-relative" },
                                    [
                                      _c(
                                        "c-row",
                                        [
                                          _c(
                                            "c-col",
                                            { staticClass: "col-12 col-md" },
                                            [
                                              _c("c-select", {
                                                attrs: {
                                                  placeholder: "Откуда",
                                                  options: _vm.options.cities,
                                                  filter: _vm.filterCities,
                                                  getOptionLabel:
                                                    _vm.getCityLabel
                                                },
                                                model: {
                                                  value: _vm.calc.city_from_id,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.calc,
                                                      "city_from_id",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "calc.city_from_id"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "c-col",
                                            {
                                              staticClass:
                                                "px-3 col-12 col-md-auto px-md-0 text-center"
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "fa my-3 my-md-0 justify-content-center shadow-box py-2 py-md-0",
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.swapLocation()
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-inline-block button-swap va"
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "icon-swap text-primary f-16 vam"
                                                      })
                                                    ]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "text-primary fw-500 d-inline-block d-md-none ml-2 f-12"
                                                    },
                                                    [_vm._v("Поменять местами")]
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _c(
                                            "c-col",
                                            { staticClass: "col-12 col-md-6" },
                                            [
                                              _c("c-select", {
                                                attrs: {
                                                  placeholder: "Куда",
                                                  options: _vm.options.cities,
                                                  filter: _vm.filterCities,
                                                  getOptionLabel:
                                                    _vm.getCityLabel
                                                },
                                                model: {
                                                  value: _vm.calc.city_to_id,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.calc,
                                                      "city_to_id",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "calc.city_to_id"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm.response.is_calc_express
                                        ? _c(
                                            "c-row",
                                            [
                                              _c("c-col", [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "shadow-box mt-3 mt-md-0 p-relative"
                                                  },
                                                  [
                                                    _c(
                                                      "c-checkbox",
                                                      {
                                                        staticClass: "mt-md-3",
                                                        attrs: {
                                                          disabled:
                                                            _vm.response
                                                              .express_service_cost ==
                                                            null
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.calc
                                                              .express_service,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.calc,
                                                              "express_service",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "calc.express_service"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          { staticClass: "fa" },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "fw-500 fw-md-400"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Экспресс доставка"
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "c-tip",
                                                              {
                                                                staticClass:
                                                                  "tip-express ml-2 d-none d-md-inline-block"
                                                              },
                                                              [
                                                                _c(
                                                                  "template",
                                                                  {
                                                                    slot:
                                                                      "subject"
                                                                  },
                                                                  [
                                                                    _c("i", {
                                                                      staticClass:
                                                                        "icon-help text-gray-300 f-16 lh-0"
                                                                    })
                                                                  ]
                                                                ),
                                                                _c("div", [
                                                                  _vm._v(
                                                                    "Ускоренный график при условии:"
                                                                  )
                                                                ]),
                                                                _c("div", [
                                                                  _vm._v(
                                                                    "Забор груза до 15:00"
                                                                  )
                                                                ]),
                                                                _c("div", [
                                                                  _vm._v(
                                                                    "Сдача груза до 18:00 на склад филиала отправления"
                                                                  )
                                                                ])
                                                              ],
                                                              2
                                                            ),
                                                            _vm.response
                                                              .express_service_cost
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "text-gray-400 ml-2"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "+ " +
                                                                        _vm._s(
                                                                          _vm._f(
                                                                            "parts"
                                                                          )(
                                                                            _vm._f(
                                                                              "abs"
                                                                            )(
                                                                              _vm
                                                                                .response
                                                                                .express_service_cost
                                                                            )
                                                                          )
                                                                        ) +
                                                                        " ₽"
                                                                    )
                                                                  ]
                                                                )
                                                              : _vm._e()
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-block d-md-none"
                                                      },
                                                      [
                                                        _c("div", {
                                                          staticClass:
                                                            "separator solid my-3 shadow-inside"
                                                        }),
                                                        _c(
                                                          "c-hint",
                                                          {
                                                            attrs: {
                                                              title: "ЧТО ЭТО?"
                                                            }
                                                          },
                                                          [
                                                            _c("div", [
                                                              _vm._v(
                                                                "Ускоренный график при условии:"
                                                              )
                                                            ]),
                                                            _c("div", [
                                                              _vm._v(
                                                                "Забор груза до 15:00"
                                                              )
                                                            ]),
                                                            _c("div", [
                                                              _vm._v(
                                                                "Сдача груза до 18:00 на склад филиала отправления"
                                                              )
                                                            ])
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "c-row",
                                        [
                                          _c(
                                            "c-col",
                                            { staticClass: "col-12 col-md-6" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "f-18 f-md-14 text-gray-800 text-md-muted fw-500 my-3"
                                                },
                                                [_vm._v("Отправление")]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "shadow-box mt-2",
                                                  class: {
                                                    active:
                                                      _vm.calc.cargo_pickup == 0
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "c-radio",
                                                    {
                                                      attrs: {
                                                        type: 0,
                                                        disabled:
                                                          (_vm.options
                                                            .officesFrom &&
                                                            !_vm.options
                                                              .officesFrom
                                                              .length) ||
                                                          _vm.getTownInfo(
                                                            _vm.calc
                                                              .city_from_id
                                                          ).is_filial == false
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.calc.cargo_pickup,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.calc,
                                                            "cargo_pickup",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "calc.cargo_pickup"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "fw-500 fw-md-400"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Сдать груз в отделение"
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "shadow-box mt-2",
                                                  class: {
                                                    active:
                                                      _vm.calc.cargo_pickup == 1
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "c-radio",
                                                    {
                                                      attrs: { type: 1 },
                                                      model: {
                                                        value:
                                                          _vm.calc.cargo_pickup,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.calc,
                                                            "cargo_pickup",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "calc.cargo_pickup"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "fw-500 fw-md-400"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Забрать по адресу "
                                                          ),
                                                          _vm.response
                                                            .pickup_service_cost
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "d-block d-md-inline-block text-gray-300 fw-400 mt-2 mt-md-0"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "+ " +
                                                                      _vm._s(
                                                                        _vm._f(
                                                                          "parts"
                                                                        )(
                                                                          _vm._f(
                                                                            "abs"
                                                                          )(
                                                                            _vm
                                                                              .response
                                                                              .pickup_service_cost
                                                                          )
                                                                        )
                                                                      ) +
                                                                      " ₽"
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e()
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              ),
                                              !_vm.calc.cargo_pickup
                                                ? _c("c-select", {
                                                    staticClass: "mt-4 mt-md-2",
                                                    attrs: {
                                                      placeholder:
                                                        "Адрес отделения",
                                                      options:
                                                        _vm.options.officesFrom
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.calc
                                                          .cargo_pickup_id,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.calc,
                                                          "cargo_pickup_id",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "calc.cargo_pickup_id"
                                                    }
                                                  })
                                                : _c("c-input", {
                                                    staticClass: "mt-2",
                                                    attrs: {
                                                      placeholder: "Адрес"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.calc
                                                          .cargo_pickup_adress,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.calc,
                                                          "cargo_pickup_adress",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "calc.cargo_pickup_adress"
                                                    }
                                                  }),
                                              _c(
                                                "c-row",
                                                { staticClass: "mt-2 mx-n1" },
                                                [
                                                  _c(
                                                    "c-col",
                                                    {
                                                      staticClass:
                                                        "px-1 col-12 col-md-6"
                                                    },
                                                    [
                                                      _c("c-datepicker", {
                                                        attrs: {
                                                          placeholder: "Дата",
                                                          dates:
                                                            _vm.options
                                                              .datesFrom
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.calc
                                                              .cargo_pickup_date,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.calc,
                                                              "cargo_pickup_date",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "calc.cargo_pickup_date"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _vm.calc.cargo_pickup == 1
                                                    ? _c(
                                                        "c-col",
                                                        {
                                                          staticClass:
                                                            "px-1 col-12 col-md-6"
                                                        },
                                                        [
                                                          _c(
                                                            "c-select",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "mask",
                                                                  rawName:
                                                                    "v-mask",
                                                                  value: {
                                                                    mask:
                                                                      "[99:99-99:99]",
                                                                    placeholder:
                                                                      "_",
                                                                    showMaskOnFocus: false
                                                                  },
                                                                  expression:
                                                                    "{mask: '[99:99-99:99]', placeholder: '_', showMaskOnFocus: false}"
                                                                }
                                                              ],
                                                              staticClass:
                                                                "mt-2 mt-md-0",
                                                              attrs: {
                                                                placeholder:
                                                                  "Время",
                                                                options:
                                                                  _vm.options
                                                                    .timeFrom,
                                                                suffix: true,
                                                                taggable: true,
                                                                filter:
                                                                  _vm.filterTime,
                                                                "value-as-title": true
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.calc
                                                                    .cargo_pickup_time,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.calc,
                                                                    "cargo_pickup_time",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "calc.cargo_pickup_time"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "template",
                                                                {
                                                                  slot: "suffix"
                                                                },
                                                                [
                                                                  _c("i", {
                                                                    staticClass:
                                                                      "icon-clock vam mr-2 text-gray-300"
                                                                  })
                                                                ]
                                                              )
                                                            ],
                                                            2
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              ),
                                              _vm.response
                                                .pickup_time_service_cost &&
                                              _vm.calc.cargo_pickup == 1
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "mt-2 text-gray-300 f-14"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Доставка ко времени + " +
                                                          _vm._s(
                                                            _vm._f("parts")(
                                                              _vm._f("abs")(
                                                                _vm.response
                                                                  .pickup_time_service_cost
                                                              )
                                                            )
                                                          ) +
                                                          " ₽"
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm.calc.cargo_pickup == 1
                                                ? _c(
                                                    "c",
                                                    {
                                                      staticClass:
                                                        "row mt-2 mx-n1"
                                                    },
                                                    [
                                                      _c(
                                                        "c-col",
                                                        {
                                                          staticClass:
                                                            "px-1 col-12 text-gray-800"
                                                        },
                                                        [
                                                          _c("c-textarea", {
                                                            attrs: {
                                                              placeholder:
                                                                "Комментарий водителю перед отправкой"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.calc
                                                                  .pickup_comment,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.calc,
                                                                  "pickup_comment",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "calc.pickup_comment"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          ),
                                          _c(
                                            "c-col",
                                            { staticClass: "col-12 col-md-6" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "f-18 f-md-14 text-gray-800 text-md-muted fw-500 my-3"
                                                },
                                                [_vm._v("Получение")]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "shadow-box mt-2",
                                                  class: {
                                                    active:
                                                      _vm.calc.cargo_delivery ==
                                                      0
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "c-radio",
                                                    {
                                                      attrs: {
                                                        type: 0,
                                                        disabled:
                                                          (_vm.options
                                                            .officesTo &&
                                                            !_vm.options
                                                              .officesTo
                                                              .length) ||
                                                          _vm.getTownInfo(
                                                            _vm.calc.city_to_id
                                                          ).is_filial == false
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.calc
                                                            .cargo_delivery,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.calc,
                                                            "cargo_delivery",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "calc.cargo_delivery"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "fw-500 fw-md-400"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Получить груз в отделении"
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "shadow-box mt-2",
                                                  class: {
                                                    active:
                                                      _vm.calc.cargo_delivery ==
                                                      0
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "c-radio",
                                                    {
                                                      attrs: { type: 1 },
                                                      model: {
                                                        value:
                                                          _vm.calc
                                                            .cargo_delivery,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.calc,
                                                            "cargo_delivery",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "calc.cargo_delivery"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Доставить по адресу "
                                                      ),
                                                      _vm.response
                                                        .delivery_service_cost
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "d-block d-md-inline-block text-gray-300 fw-400 mt-2 mt-md-0"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "+ " +
                                                                  _vm._s(
                                                                    _vm._f(
                                                                      "parts"
                                                                    )(
                                                                      _vm._f(
                                                                        "abs"
                                                                      )(
                                                                        _vm
                                                                          .response
                                                                          .delivery_service_cost
                                                                      )
                                                                    )
                                                                  ) +
                                                                  " ₽"
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  )
                                                ],
                                                1
                                              ),
                                              !_vm.calc.cargo_delivery
                                                ? _c("c-select", {
                                                    staticClass: "mt-2",
                                                    attrs: {
                                                      placeholder:
                                                        "Адрес отделения",
                                                      options:
                                                        _vm.options.officesTo
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.calc
                                                          .cargo_delivery_id,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.calc,
                                                          "cargo_delivery_id",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "calc.cargo_delivery_id"
                                                    }
                                                  })
                                                : _c("c-input", {
                                                    staticClass: "mt-2",
                                                    attrs: {
                                                      placeholder: "Адрес"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.calc
                                                          .cargo_delivery_adress,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.calc,
                                                          "cargo_delivery_adress",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "calc.cargo_delivery_adress"
                                                    }
                                                  }),
                                              _c(
                                                "c-row",
                                                { staticClass: "mt-2 mx-n1" },
                                                [
                                                  _c(
                                                    "c-col",
                                                    {
                                                      staticClass:
                                                        "px-1 col-12 col-md-6"
                                                    },
                                                    [
                                                      _c("c-datepicker", {
                                                        attrs: {
                                                          placeholder: "Дата",
                                                          dates:
                                                            _vm.options.datesTo
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.calc
                                                              .cargo_delivery_date,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.calc,
                                                              "cargo_delivery_date",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "calc.cargo_delivery_date"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _vm.calc.cargo_delivery == 1
                                                    ? _c(
                                                        "c-col",
                                                        {
                                                          staticClass:
                                                            "px-1 col-12 col-md-6"
                                                        },
                                                        [
                                                          _c(
                                                            "c-select",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "mask",
                                                                  rawName:
                                                                    "v-mask",
                                                                  value: {
                                                                    mask:
                                                                      "[99:99-99:99]",
                                                                    placeholder:
                                                                      "_",
                                                                    showMaskOnFocus: false
                                                                  },
                                                                  expression:
                                                                    "{mask: '[99:99-99:99]', placeholder: '_', showMaskOnFocus: false}"
                                                                }
                                                              ],
                                                              staticClass:
                                                                "mt-2 mt-md-0",
                                                              attrs: {
                                                                placeholder:
                                                                  "Время",
                                                                options:
                                                                  _vm.options
                                                                    .timeTo,
                                                                suffix: true,
                                                                taggable: true,
                                                                filter:
                                                                  _vm.filterTime,
                                                                "value-as-title": true
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.calc
                                                                    .cargo_delivery_time,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.calc,
                                                                    "cargo_delivery_time",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "calc.cargo_delivery_time"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "template",
                                                                {
                                                                  slot: "suffix"
                                                                },
                                                                [
                                                                  _c("i", {
                                                                    staticClass:
                                                                      "icon-clock vam mr-2 text-gray-300"
                                                                  })
                                                                ]
                                                              )
                                                            ],
                                                            2
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              ),
                                              _vm.response
                                                .delivery_time_service_cost &&
                                              _vm.calc.cargo_delivery == 1
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "mt-2 text-gray-300 f-14"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Доставка ко времени + " +
                                                          _vm._s(
                                                            _vm._f("parts")(
                                                              _vm._f("abs")(
                                                                _vm.response
                                                                  .delivery_time_service_cost
                                                              )
                                                            )
                                                          ) +
                                                          " ₽"
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm.calc.cargo_delivery == 1
                                                ? _c(
                                                    "c",
                                                    {
                                                      staticClass:
                                                        "row mt-2 mx-n1"
                                                    },
                                                    [
                                                      _c(
                                                        "c-col",
                                                        {
                                                          staticClass:
                                                            "px-1 col-12 text-gray-800"
                                                        },
                                                        [
                                                          _c("c-textarea", {
                                                            attrs: {
                                                              placeholder:
                                                                "Комментарий водителю перед получением"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.calc
                                                                  .delivery_comment,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.calc,
                                                                  "delivery_comment",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "calc.delivery_comment"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "c-row",
                                        { staticClass: "mt-4" },
                                        [
                                          _c(
                                            "c-col",
                                            { staticClass: "col" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "f-18 f-md-16 fw-500"
                                                },
                                                [_vm._v("Параметры груза")]
                                              ),
                                              _c(
                                                "c-tab-list",
                                                {
                                                  staticClass: "mt-2",
                                                  attrs: {
                                                    labels: {
                                                      common:
                                                        "Указать общий вес/объем",
                                                      extra:
                                                        "Указать с габаритами"
                                                    }
                                                  },
                                                  model: {
                                                    value: _vm.calc.calc_type,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.calc,
                                                        "calc_type",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "calc.calc_type"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "template",
                                                    { slot: "label-common" },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-none d-md-block"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Указать общий вес/объем"
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-block d-md-none"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Общий вес/объем"
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "template",
                                                    { slot: "label-extra" },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-none d-md-block"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Указать с габаритами"
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-block d-md-none"
                                                        },
                                                        [_vm._v("Габариты")]
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "template",
                                                    { slot: "tab-common" },
                                                    [
                                                      _c(
                                                        "c-row",
                                                        {
                                                          staticClass: "mx-n1"
                                                        },
                                                        [
                                                          _c(
                                                            "c-col",
                                                            {
                                                              staticClass:
                                                                "px-1 col-12 col-md"
                                                            },
                                                            [
                                                              _c("c-input", {
                                                                attrs: {
                                                                  type:
                                                                    "number",
                                                                  placeholder:
                                                                    "Вес груза",
                                                                  suffix: "кг",
                                                                  min: 0
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.calc
                                                                      .common_cargo_weight,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.calc,
                                                                      "common_cargo_weight",
                                                                      _vm._n(
                                                                        $$v
                                                                      )
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "calc.common_cargo_weight"
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "c-col",
                                                            {
                                                              staticClass:
                                                                "px-1 col-12 col-md mt-2 mt-md-0"
                                                            },
                                                            [
                                                              _c("c-input", {
                                                                attrs: {
                                                                  type:
                                                                    "number",
                                                                  placeholder:
                                                                    "Объем груза",
                                                                  suffix:
                                                                    "м<sup>3</sup>",
                                                                  min: 0
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.calc
                                                                      .common_cargo_volume,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.calc,
                                                                      "common_cargo_volume",
                                                                      _vm._n(
                                                                        $$v
                                                                      )
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "calc.common_cargo_volume"
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "c-col",
                                                            {
                                                              staticClass:
                                                                "px-1 col-12 col-md mt-2 mt-md-0"
                                                            },
                                                            [
                                                              _c("c-input", {
                                                                staticClass:
                                                                  "d-none d-md-block",
                                                                attrs: {
                                                                  type:
                                                                    "number",
                                                                  placeholder:
                                                                    "Кол-во мест",
                                                                  min: 0,
                                                                  int: true
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.calc
                                                                      .common_cargo_amount,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.calc,
                                                                      "common_cargo_amount",
                                                                      _vm._n(
                                                                        $$v
                                                                      )
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "calc.common_cargo_amount"
                                                                }
                                                              }),
                                                              _c(
                                                                "c-plusminus",
                                                                {
                                                                  staticClass:
                                                                    "my-2 d-block d-md-none",
                                                                  attrs: {
                                                                    placeholder:
                                                                      "Кол-во мест",
                                                                    min: 1
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.calc
                                                                        .common_cargo_amount,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.calc,
                                                                        "common_cargo_amount",
                                                                        _vm._n(
                                                                          $$v
                                                                        )
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "calc.common_cargo_amount"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "c-col",
                                                            {
                                                              staticClass:
                                                                "px-1 col-12 col-md"
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "shadow-box mt-3 p-relative"
                                                                },
                                                                [
                                                                  _c(
                                                                    "c-checkbox",
                                                                    {
                                                                      staticClass:
                                                                        "ml-md-2",
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .calc
                                                                            .is_overall,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.calc,
                                                                            "is_overall",
                                                                            $$v
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "calc.is_overall"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "fw-500 fw-md-400"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Негабарит"
                                                                          ),
                                                                          _c(
                                                                            "c-tip",
                                                                            {
                                                                              staticClass:
                                                                                "d-none d-md-inline-block tip-express ml-2 align-middle"
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "template",
                                                                                {
                                                                                  slot:
                                                                                    "subject"
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "i",
                                                                                    {
                                                                                      staticClass:
                                                                                        "icon-help ml-1 f-16 lh-0 text-gray-300"
                                                                                    }
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "div",
                                                                                [
                                                                                  _vm._v(
                                                                                    "Грузовое место попадает под одно из условий:"
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "div",
                                                                                [
                                                                                  _vm._v(
                                                                                    "Вес места > 900 кг;"
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "div",
                                                                                [
                                                                                  _vm._v(
                                                                                    "Cторона > 2,4 м;"
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "div",
                                                                                [
                                                                                  _vm._v(
                                                                                    "Д+Ш+В > 4,2 м."
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            2
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "d-block d-md-none"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "separator solid my-3 shadow-inside"
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "c-hint",
                                                                        {
                                                                          attrs: {
                                                                            title:
                                                                              "ЧТО ЭТО?"
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            [
                                                                              _vm._v(
                                                                                "Грузовое место попадает под одно из условий:"
                                                                              )
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            [
                                                                              _vm._v(
                                                                                "Вес места > 900 кг;"
                                                                              )
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            [
                                                                              _vm._v(
                                                                                "Cторона > 2,4 м;"
                                                                              )
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            [
                                                                              _vm._v(
                                                                                "Д+Ш+В > 4,2 м."
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "template",
                                                    { slot: "tab-extra" },
                                                    [
                                                      _vm._l(
                                                        _vm.$v.cargo.$model,
                                                        function(item, index) {
                                                          return _c(
                                                            "c-row",
                                                            {
                                                              key: index,
                                                              staticClass:
                                                                "mx-n1 mb-1"
                                                            },
                                                            [
                                                              _c(
                                                                "c-col",
                                                                {
                                                                  staticClass:
                                                                    "px-1 col-4 col-md"
                                                                },
                                                                [
                                                                  _c(
                                                                    "c-input",
                                                                    {
                                                                      attrs: {
                                                                        type:
                                                                          "number",
                                                                        placeholder:
                                                                          "Длина",
                                                                        accessor:
                                                                          "cargo.$each." +
                                                                          index +
                                                                          ".length",
                                                                        min: 0
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          item.length,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            item,
                                                                            "length",
                                                                            _vm._n(
                                                                              $$v
                                                                            )
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "item.length"
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "c-col",
                                                                {
                                                                  staticClass:
                                                                    "px-1 col-4 col-md"
                                                                },
                                                                [
                                                                  _c(
                                                                    "c-input",
                                                                    {
                                                                      attrs: {
                                                                        type:
                                                                          "number",
                                                                        placeholder:
                                                                          "Ширина",
                                                                        accessor:
                                                                          "cargo.$each." +
                                                                          index +
                                                                          ".width",
                                                                        min: 0
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          item.width,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            item,
                                                                            "width",
                                                                            _vm._n(
                                                                              $$v
                                                                            )
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "item.width"
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "c-col",
                                                                {
                                                                  staticClass:
                                                                    "px-1 col-4 col-md"
                                                                },
                                                                [
                                                                  _c(
                                                                    "c-input",
                                                                    {
                                                                      attrs: {
                                                                        type:
                                                                          "number",
                                                                        placeholder:
                                                                          "Высота",
                                                                        accessor:
                                                                          "cargo.$each." +
                                                                          index +
                                                                          ".height",
                                                                        min: 0
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          item.height,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            item,
                                                                            "height",
                                                                            _vm._n(
                                                                              $$v
                                                                            )
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "item.height"
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "c-col",
                                                                {
                                                                  staticClass:
                                                                    "px-1 col-6 col-md mt-2 mt-md-0"
                                                                },
                                                                [
                                                                  _c(
                                                                    "c-select",
                                                                    {
                                                                      attrs: {
                                                                        placeholder:
                                                                          "Ед. изм.",
                                                                        options:
                                                                          _vm
                                                                            .options
                                                                            .units,
                                                                        accessor:
                                                                          "cargo.$each." +
                                                                          index +
                                                                          ".measure_LWH"
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          item.measure_LWH,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            item,
                                                                            "measure_LWH",
                                                                            $$v
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "item.measure_LWH"
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "c-col",
                                                                {
                                                                  staticClass:
                                                                    "px-1 col-6 col-md mt-2 mt-md-0"
                                                                },
                                                                [
                                                                  _c(
                                                                    "c-input",
                                                                    {
                                                                      attrs: {
                                                                        type:
                                                                          "number",
                                                                        placeholder:
                                                                          "Вес",
                                                                        suffix:
                                                                          "кг",
                                                                        accessor:
                                                                          "cargo.$each." +
                                                                          index +
                                                                          ".weight",
                                                                        min: 0
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          item.weight,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            item,
                                                                            "weight",
                                                                            _vm._n(
                                                                              $$v
                                                                            )
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "item.weight"
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "c-col",
                                                                {
                                                                  staticClass:
                                                                    "px-1 col-12 col-md"
                                                                },
                                                                [
                                                                  _c(
                                                                    "c-input",
                                                                    {
                                                                      staticClass:
                                                                        "d-none d-md-block",
                                                                      attrs: {
                                                                        type:
                                                                          "number",
                                                                        placeholder:
                                                                          "Таких мест",
                                                                        accessor:
                                                                          "cargo.$each." +
                                                                          index +
                                                                          ".amount",
                                                                        min: 0,
                                                                        int: ""
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          item.amount,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            item,
                                                                            "amount",
                                                                            _vm._n(
                                                                              $$v
                                                                            )
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "item.amount"
                                                                      }
                                                                    }
                                                                  ),
                                                                  _c(
                                                                    "c-plusminus",
                                                                    {
                                                                      staticClass:
                                                                        "mt-3 d-block d-md-none",
                                                                      attrs: {
                                                                        placeholder:
                                                                          "Кол-во таких мест",
                                                                        min: 1
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          item.amount,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            item,
                                                                            "amount",
                                                                            _vm._n(
                                                                              $$v
                                                                            )
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "item.amount"
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              ),
                                                              _vm.cargo.length >
                                                              1
                                                                ? _c(
                                                                    "c-col",
                                                                    {
                                                                      staticClass:
                                                                        "px-1 col-12 col-md-auto order-8 order-md-7 mt-3 mt-md-0"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "add-cargo h-100 fa hoverable shadow-box p-md-2 pt-md-3 text-pink text-md-gray-300",
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.removeCargo(
                                                                                index
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "i",
                                                                            {
                                                                              staticClass:
                                                                                "icon-trash text-pink text-md-gray-300"
                                                                            }
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "ml-2 fw-500 d-md-none"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "Удалить"
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              _c(
                                                                "c-col",
                                                                {
                                                                  staticClass:
                                                                    "px-1 col-12 col-md-auto order-7 order-md-8 mt-3 mt-md-0",
                                                                  class: {
                                                                    "d-none d-md-block": !item.is_dimensional
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "pt-2 h-100 text-center d-none d-md-inline-block",
                                                                      class: {
                                                                        invisible: !item.is_dimensional
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "h-100 fa"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "c-tip",
                                                                            {
                                                                              staticClass:
                                                                                "tip-express ml-2"
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "template",
                                                                                {
                                                                                  slot:
                                                                                    "subject"
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "i",
                                                                                    {
                                                                                      staticClass:
                                                                                        "icon-warning f-20 lh-0 danger hoverable"
                                                                                    }
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "text-left"
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "div",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Негабаритный груз."
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _c(
                                                                                    "div",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Грузовое место попадает под одно из условий:"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _c(
                                                                                    "div",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Вес места > 900 кг;"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _c(
                                                                                    "div",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Cторона > 2,4 м;"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _c(
                                                                                    "div",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Д+Ш+В > 4,2 м."
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            2
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  ),
                                                                  item.is_dimensional
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "d-block d-md-none text-gray-300 shadow-box p-relative"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "c-row",
                                                                            {
                                                                              staticClass:
                                                                                "align-items-center"
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "c-col",
                                                                                [
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "fa"
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "i",
                                                                                        {
                                                                                          staticClass:
                                                                                            "icon-warning bg-gray-300 mb-1"
                                                                                        }
                                                                                      ),
                                                                                      _c(
                                                                                        "span",
                                                                                        {
                                                                                          staticClass:
                                                                                            "f-12 ml-2"
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "Негабаритный груз"
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "c-col",
                                                                                {
                                                                                  staticClass:
                                                                                    "col-auto p-static"
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "c-hint",
                                                                                    {
                                                                                      attrs: {
                                                                                        title:
                                                                                          "ЧТО ЭТО?"
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "div",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "Грузовое место попадает под одно из условий:"
                                                                                          )
                                                                                        ]
                                                                                      ),
                                                                                      _c(
                                                                                        "div",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "Вес места > 900 кг;"
                                                                                          )
                                                                                        ]
                                                                                      ),
                                                                                      _c(
                                                                                        "div",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "Cторона > 2,4 м;"
                                                                                          )
                                                                                        ]
                                                                                      ),
                                                                                      _c(
                                                                                        "div",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "Д+Ш+В > 4,2 м."
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e()
                                                                ]
                                                              ),
                                                              _vm.$v.cargo
                                                                .$model.length >
                                                                1 &&
                                                              index !=
                                                                _vm.$v.cargo
                                                                  .$model
                                                                  .length -
                                                                  1
                                                                ? _c(
                                                                    "c-col",
                                                                    {
                                                                      staticClass:
                                                                        "col-12 order-9 d-block d-md-none px-1 mt-3 mb-2"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "separator"
                                                                        }
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e()
                                                            ],
                                                            1
                                                          )
                                                        }
                                                      ),
                                                      _c("div", {
                                                        staticClass:
                                                          "separator d-block d-md-none my-3"
                                                      }),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-block d-md-inline-block text-primary fw-500 mt-md-2 mb-md-3 hoverable shadow-box text-center",
                                                          on: {
                                                            click: _vm.addCargo
                                                          }
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "icon-plus mr-2 text-primary"
                                                          }),
                                                          _vm._v(
                                                            "Добавить грузовое место"
                                                          )
                                                        ]
                                                      ),
                                                      _c("div", {
                                                        staticClass:
                                                          "separator d-block d-md-none my-3"
                                                      })
                                                    ],
                                                    2
                                                  )
                                                ],
                                                2
                                              ),
                                              _c(
                                                "c-row",
                                                {
                                                  staticClass:
                                                    "mt-4 mt-md-2 mx-n1"
                                                },
                                                [
                                                  _c(
                                                    "c-col",
                                                    {
                                                      staticClass:
                                                        "px-1 col-12 col-md-9"
                                                    },
                                                    [
                                                      _c(
                                                        "c-keep-alive",
                                                        [
                                                          _c("c-select", {
                                                            attrs: {
                                                              placeholder:
                                                                "Характер груза",
                                                              options:
                                                                _vm.options
                                                                  .cargoKinds,
                                                              taggable: true,
                                                              filter:
                                                                _vm.filterCargoType,
                                                              moptions:
                                                                _vm.options
                                                                  .cargoKindMOptions
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.calc
                                                                  .cargo_kind_id,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.calc,
                                                                  "cargo_kind_id",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "calc.cargo_kind_id"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "c-row",
                                                {
                                                  staticClass:
                                                    "mt-4 mt-md-2 mx-n1"
                                                },
                                                [
                                                  _c(
                                                    "c-col",
                                                    {
                                                      staticClass:
                                                        "px-1 col-12 col-md-9"
                                                    },
                                                    [
                                                      _c(
                                                        "c-keep-alive",
                                                        [
                                                          _c("c-input", {
                                                            attrs: {
                                                              type: "number",
                                                              placeholder:
                                                                "Стоимость груза",
                                                              suffix: "₽",
                                                              min: 0
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.calc
                                                                  .cargo_cost,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.calc,
                                                                  "cargo_cost",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "calc.cargo_cost"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "c-row",
                                        { staticClass: "mt-4" },
                                        [
                                          _c(
                                            "c-col",
                                            { staticClass: "col" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "f-18 f-md-16 fw-500"
                                                },
                                                [_vm._v("Упаковка")]
                                              ),
                                              _c(
                                                "c-row",
                                                {
                                                  staticClass:
                                                    "mt-2 mt-md-3 mx-n1"
                                                },
                                                [
                                                  _vm.response
                                                    .palletizing_service_cost
                                                    ? _c(
                                                        "c-col",
                                                        {
                                                          staticClass:
                                                            "px-1 mt-2 col-12 col-md-6"
                                                        },
                                                        [
                                                          _c(
                                                            "card",
                                                            {
                                                              staticClass:
                                                                "shadow-box",
                                                              class: {
                                                                active:
                                                                  _vm.calc
                                                                    .palletizing_service
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "c-checkbox",
                                                                {
                                                                  staticClass:
                                                                    "fw-500",
                                                                  model: {
                                                                    value:
                                                                      _vm.calc
                                                                        .palletizing_service,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.calc,
                                                                        "palletizing_service",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "calc.palletizing_service"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Палетирование"
                                                                  )
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "cont mt-1"
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "text-gray-400",
                                                                      class: {
                                                                        "text-muted": !_vm
                                                                          .calc
                                                                          .palletizing_service
                                                                      }
                                                                    },
                                                                    [
                                                                      !_vm.calc
                                                                        .palletizing_service
                                                                        ? _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                "+ "
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm._f(
                                                                            "parts"
                                                                          )(
                                                                            _vm._f(
                                                                              "abs"
                                                                            )(
                                                                              _vm
                                                                                .response
                                                                                .palletizing_service_cost
                                                                            )
                                                                          )
                                                                        ) + " ₽"
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm.response
                                                    .stretch_service_cost
                                                    ? _c(
                                                        "c-col",
                                                        {
                                                          staticClass:
                                                            "px-1 mt-2 col-12 col-md-6"
                                                        },
                                                        [
                                                          _c(
                                                            "card",
                                                            {
                                                              staticClass:
                                                                "shadow-box",
                                                              class: {
                                                                active:
                                                                  _vm.calc
                                                                    .stretch_service
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "c-checkbox",
                                                                {
                                                                  staticClass:
                                                                    "fw-500",
                                                                  model: {
                                                                    value:
                                                                      _vm.calc
                                                                        .stretch_service,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.calc,
                                                                        "stretch_service",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "calc.stretch_service"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Стрейч пленка"
                                                                  )
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "cont mt-1"
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "text-gray-400",
                                                                      class: {
                                                                        "text-muted": !_vm
                                                                          .calc
                                                                          .stretch_service
                                                                      }
                                                                    },
                                                                    [
                                                                      !_vm.calc
                                                                        .stretch_service
                                                                        ? _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                "+"
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm._f(
                                                                            "parts"
                                                                          )(
                                                                            _vm._f(
                                                                              "abs"
                                                                            )(
                                                                              _vm
                                                                                .response
                                                                                .stretch_service_cost
                                                                            )
                                                                          )
                                                                        ) + " ₽"
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm.response
                                                    .bubble_service_cost
                                                    ? _c(
                                                        "c-col",
                                                        {
                                                          staticClass:
                                                            "px-1 mt-2 col-12 col-md-6"
                                                        },
                                                        [
                                                          _c(
                                                            "card",
                                                            {
                                                              staticClass:
                                                                "shadow-box",
                                                              class: {
                                                                active:
                                                                  _vm.calc
                                                                    .bubble_service
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "c-checkbox",
                                                                {
                                                                  staticClass:
                                                                    "fw-500",
                                                                  model: {
                                                                    value:
                                                                      _vm.calc
                                                                        .bubble_service,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.calc,
                                                                        "bubble_service",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "calc.bubble_service"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Воздушно-пузырьковая пленка"
                                                                  )
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "cont mt-1"
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "text-gray-400",
                                                                      class: {
                                                                        "text-muted": !_vm
                                                                          .calc
                                                                          .bubble_service
                                                                      }
                                                                    },
                                                                    [
                                                                      !_vm.calc
                                                                        .bubble_service
                                                                        ? _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                "+ "
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm._f(
                                                                            "parts"
                                                                          )(
                                                                            _vm._f(
                                                                              "abs"
                                                                            )(
                                                                              _vm
                                                                                .response
                                                                                .bubble_service_cost
                                                                            )
                                                                          )
                                                                        ) + " ₽"
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm.response
                                                    .pallet_board_service_cost
                                                    ? _c(
                                                        "c-col",
                                                        {
                                                          staticClass:
                                                            "px-1 mt-2 col-12 col-md-6"
                                                        },
                                                        [
                                                          _c(
                                                            "card",
                                                            {
                                                              staticClass:
                                                                "shadow-box",
                                                              class: {
                                                                active:
                                                                  _vm.calc
                                                                    .pallet_board
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "c-checkbox",
                                                                {
                                                                  staticClass:
                                                                    "fw-500",
                                                                  model: {
                                                                    value:
                                                                      _vm.calc
                                                                        .pallet_board,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.calc,
                                                                        "pallet_board",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "calc.pallet_board"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Палетный борт"
                                                                  )
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "cont mt-1"
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "text-gray-400",
                                                                      class: {
                                                                        "text-muted": !_vm
                                                                          .calc
                                                                          .pallet_board
                                                                      }
                                                                    },
                                                                    [
                                                                      !_vm.calc
                                                                        .pallet_board
                                                                        ? _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                "+ "
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm._f(
                                                                            "parts"
                                                                          )(
                                                                            _vm._f(
                                                                              "abs"
                                                                            )(
                                                                              _vm
                                                                                .response
                                                                                .pallet_board_service_cost
                                                                            )
                                                                          )
                                                                        ) + " ₽"
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm.response
                                                    .other_service_cost
                                                    ? _c(
                                                        "c-col",
                                                        {
                                                          staticClass:
                                                            "px-1 mt-2 col-12 col-md-6"
                                                        },
                                                        [
                                                          _c(
                                                            "card",
                                                            {
                                                              staticClass:
                                                                "shadow-box",
                                                              class: {
                                                                active:
                                                                  _vm.calc
                                                                    .other_pack_service_check
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "c-checkbox",
                                                                {
                                                                  staticClass:
                                                                    "fw-500",
                                                                  model: {
                                                                    value:
                                                                      _vm.calc
                                                                        .other_pack_service_check,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.calc,
                                                                        "other_pack_service_check",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "calc.other_pack_service_check"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Прочие услуги"
                                                                  )
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "cont mt-1"
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "text-gray-400",
                                                                      class: {
                                                                        "text-muted": !_vm
                                                                          .calc
                                                                          .other_pack_service_check
                                                                      }
                                                                    },
                                                                    [
                                                                      !_vm.calc
                                                                        .other_pack_service_check
                                                                        ? _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                "+ "
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm._f(
                                                                            "parts"
                                                                          )(
                                                                            _vm._f(
                                                                              "abs"
                                                                            )(
                                                                              _vm
                                                                                .response
                                                                                .other_service_cost
                                                                            )
                                                                          )
                                                                        ) + " ₽"
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "c-select",
                                                                    {
                                                                      staticClass:
                                                                        "mt-2",
                                                                      attrs: {
                                                                        disabled: !_vm
                                                                          .calc
                                                                          .other_pack_service_check,
                                                                        placeholder:
                                                                          "Упаковка",
                                                                        options:
                                                                          _vm
                                                                            .options
                                                                            .packService
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .calc
                                                                            .other_pack_service,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.calc,
                                                                            "other_pack_service",
                                                                            $$v
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "calc.other_pack_service"
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm.response
                                                    .rigid_packings_service_cost
                                                    ? _c(
                                                        "c-col",
                                                        {
                                                          staticClass:
                                                            "px-1 mt-2 col-12 col-md-6"
                                                        },
                                                        [
                                                          _c(
                                                            "card",
                                                            {
                                                              staticClass:
                                                                "shadow-box",
                                                              class: {
                                                                active:
                                                                  _vm.calc
                                                                    .rigid_packing_service
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "c-checkbox",
                                                                {
                                                                  staticClass:
                                                                    "fw-500",
                                                                  model: {
                                                                    value:
                                                                      _vm.calc
                                                                        .rigid_packing_service,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.calc,
                                                                        "rigid_packing_service",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "calc.rigid_packing_service"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Жесткая упаковка"
                                                                  )
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "cont mt-1"
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "text-gray-400",
                                                                      class: {
                                                                        "text-muted": !_vm
                                                                          .calc
                                                                          .rigid_packing_service
                                                                      }
                                                                    },
                                                                    [
                                                                      !_vm.calc
                                                                        .rigid_packing_service
                                                                        ? _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                "+ "
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm._f(
                                                                            "parts"
                                                                          )(
                                                                            _vm._f(
                                                                              "abs"
                                                                            )(
                                                                              _vm
                                                                                .response
                                                                                .rigid_packings_service_cost
                                                                            )
                                                                          )
                                                                        ) + " ₽"
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "c-row",
                                        { staticClass: "mt-4" },
                                        [
                                          _c(
                                            "c-col",
                                            { staticClass: "col" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "f-18 f-md-16 fw-500"
                                                },
                                                [
                                                  _vm._v(
                                                    "Дополнительные услуги"
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "c-row",
                                                {
                                                  staticClass:
                                                    "mt-2 mt-md-3 mx-n1"
                                                },
                                                [
                                                  _vm.response
                                                    .sender_service_loading_cost &&
                                                  _vm.calc.cargo_pickup
                                                    ? _c(
                                                        "c-col",
                                                        {
                                                          staticClass:
                                                            "px-1 mt-2 col-12 col-md-6"
                                                        },
                                                        [
                                                          _c(
                                                            "card",
                                                            {
                                                              staticClass:
                                                                "shadow-box",
                                                              class: {
                                                                active:
                                                                  _vm.calc
                                                                    .sender_loading
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "c-checkbox",
                                                                {
                                                                  staticClass:
                                                                    "fw-500",
                                                                  model: {
                                                                    value:
                                                                      _vm.calc
                                                                        .sender_loading,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.calc,
                                                                        "sender_loading",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "calc.sender_loading"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Погрузочные работы"
                                                                  ),
                                                                  _c(
                                                                    "c-tip",
                                                                    {
                                                                      staticClass:
                                                                        "d-none d-md-inline-block tip-express ml-2 align-middle"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "template",
                                                                        {
                                                                          slot:
                                                                            "subject"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "i",
                                                                            {
                                                                              staticClass:
                                                                                "icon-help ml-1 f-16 lh-0 text-gray-300"
                                                                            }
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        [
                                                                          _vm._v(
                                                                            "Расчет ПРР производится"
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        [
                                                                          _vm._v(
                                                                            "для ГМ"
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        [
                                                                          _vm._v(
                                                                            "весом < 25 кг;"
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        [
                                                                          _vm._v(
                                                                            "длиной < 70 см."
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    2
                                                                  )
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "cont mt-1"
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "f-12 f-md-14 text-muted"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "У отправителя"
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "text-gray-400 mt-2",
                                                                      class: {
                                                                        "text-muted": !_vm
                                                                          .calc
                                                                          .sender_loading
                                                                      }
                                                                    },
                                                                    [
                                                                      !_vm.calc
                                                                        .sender_loading
                                                                        ? _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                "+ "
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm._f(
                                                                            "parts"
                                                                          )(
                                                                            _vm._f(
                                                                              "abs"
                                                                            )(
                                                                              _vm
                                                                                .response
                                                                                .sender_service_loading_cost
                                                                            )
                                                                          )
                                                                        ) + " ₽"
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "c-select",
                                                                    {
                                                                      staticClass:
                                                                        "mt-2",
                                                                      attrs: {
                                                                        disabled: !_vm
                                                                          .calc
                                                                          .sender_loading,
                                                                        placeholder:
                                                                          "Спуск/подъем на этаж",
                                                                        options:
                                                                          _vm
                                                                            .options
                                                                            .floors
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .calc
                                                                            .sender_loading_floor,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.calc,
                                                                            "sender_loading_floor",
                                                                            $$v
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "calc.sender_loading_floor"
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "d-block d-md-none"
                                                                },
                                                                [
                                                                  _c("div", {
                                                                    staticClass:
                                                                      "separator solid my-3 shadow-inside"
                                                                  }),
                                                                  _c(
                                                                    "c-hint",
                                                                    {
                                                                      attrs: {
                                                                        title:
                                                                          "УСЛОВИЯ"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        [
                                                                          _vm._v(
                                                                            "Расчет ПРР производится для ГМ"
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        [
                                                                          _vm._v(
                                                                            "весом < 25 кг;"
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        [
                                                                          _vm._v(
                                                                            "длиной < 70 см."
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm.response
                                                    .receiver_service_loading_cost &&
                                                  _vm.calc.cargo_delivery
                                                    ? _c(
                                                        "c-col",
                                                        {
                                                          staticClass:
                                                            "px-1 mt-2 col-12 col-md-6"
                                                        },
                                                        [
                                                          _c(
                                                            "card",
                                                            {
                                                              staticClass:
                                                                "shadow-box",
                                                              class: {
                                                                active:
                                                                  _vm.calc
                                                                    .receiver_loading
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "c-checkbox",
                                                                {
                                                                  staticClass:
                                                                    "fw-500",
                                                                  model: {
                                                                    value:
                                                                      _vm.calc
                                                                        .receiver_loading,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.calc,
                                                                        "receiver_loading",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "calc.receiver_loading"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Погрузочные работы"
                                                                  ),
                                                                  _c(
                                                                    "c-tip",
                                                                    {
                                                                      staticClass:
                                                                        "d-none d-md-inline-block tip-express ml-2 align-middle"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "template",
                                                                        {
                                                                          slot:
                                                                            "subject"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "i",
                                                                            {
                                                                              staticClass:
                                                                                "icon-help ml-1 f-16 lh-0 text-gray-300"
                                                                            }
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        [
                                                                          _vm._v(
                                                                            "Расчет ПРР производится для ГМ"
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        [
                                                                          _vm._v(
                                                                            "весом < 25 кг;"
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        [
                                                                          _vm._v(
                                                                            "длиной < 70 см."
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    2
                                                                  )
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "cont mt-1"
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "f-12 f-md-14 text-muted"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "У получателя"
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "text-gray-400 mt-2",
                                                                      class: {
                                                                        "text-muted": !_vm
                                                                          .calc
                                                                          .receiver_loading
                                                                      }
                                                                    },
                                                                    [
                                                                      !_vm.calc
                                                                        .receiver_loading
                                                                        ? _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                "+ "
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm._f(
                                                                            "parts"
                                                                          )(
                                                                            _vm._f(
                                                                              "abs"
                                                                            )(
                                                                              _vm
                                                                                .response
                                                                                .receiver_service_loading_cost
                                                                            )
                                                                          )
                                                                        ) + " ₽"
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "c-select",
                                                                    {
                                                                      staticClass:
                                                                        "mt-2",
                                                                      attrs: {
                                                                        disabled: !_vm
                                                                          .calc
                                                                          .receiver_loading,
                                                                        placeholder:
                                                                          "Спуск/подъем на этаж",
                                                                        options:
                                                                          _vm
                                                                            .options
                                                                            .floors
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .calc
                                                                            .receiver_loading_floor,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.calc,
                                                                            "receiver_loading_floor",
                                                                            $$v
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "calc.receiver_loading_floor"
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "d-block d-md-none"
                                                                },
                                                                [
                                                                  _c("div", {
                                                                    staticClass:
                                                                      "separator solid my-3 shadow-inside"
                                                                  }),
                                                                  _c(
                                                                    "c-hint",
                                                                    {
                                                                      attrs: {
                                                                        title:
                                                                          "УСЛОВИЯ"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        [
                                                                          _vm._v(
                                                                            "Расчет ПРР производится для ГМ"
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        [
                                                                          _vm._v(
                                                                            "весом < 25 кг;"
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        [
                                                                          _vm._v(
                                                                            "длиной < 70 см."
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm.response
                                                    .insurance_service_cost
                                                    ? _c(
                                                        "c-col",
                                                        {
                                                          staticClass:
                                                            "px-1 mt-2 col-12 col-md-6"
                                                        },
                                                        [
                                                          _c(
                                                            "card",
                                                            {
                                                              staticClass:
                                                                "shadow-box",
                                                              class: {
                                                                active:
                                                                  _vm.calc
                                                                    .insurance_service
                                                              }
                                                            },
                                                            [
                                                              _c("c-checkbox", {
                                                                staticClass:
                                                                  "hidden fw-500",
                                                                model: {
                                                                  value:
                                                                    _vm.calc
                                                                      .insurance_service,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.calc,
                                                                      "insurance_service",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "calc.insurance_service"
                                                                }
                                                              }),
                                                              _vm._v(
                                                                "Страхование груза"
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "cont mt-2"
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "text-gray-400",
                                                                      class: {
                                                                        "text-muted": !_vm
                                                                          .calc
                                                                          .insurance_service
                                                                      }
                                                                    },
                                                                    [
                                                                      !_vm.calc
                                                                        .insurance_service
                                                                        ? _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                "+ "
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm._f(
                                                                            "parts"
                                                                          )(
                                                                            _vm._f(
                                                                              "abs"
                                                                            )(
                                                                              _vm
                                                                                .response
                                                                                .insurance_service_cost
                                                                            )
                                                                          )
                                                                        ) + " ₽"
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "fieldset",
                                                                    {
                                                                      staticClass:
                                                                        "mt-2",
                                                                      attrs: {
                                                                        disabled: !_vm
                                                                          .calc
                                                                          .insurance_service
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "c-input",
                                                                        {
                                                                          attrs: {
                                                                            type:
                                                                              "number",
                                                                            placeholder:
                                                                              "Стоимость груза",
                                                                            suffix:
                                                                              "₽",
                                                                            min: 0
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm
                                                                                .calc
                                                                                .cargo_cost,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.calc,
                                                                                "cargo_cost",
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "calc.cargo_cost"
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm.response.docs_service_cost
                                                    ? _c(
                                                        "c-col",
                                                        {
                                                          staticClass:
                                                            "px-1 mt-2 col-12 col-md-6"
                                                        },
                                                        [
                                                          _c(
                                                            "card",
                                                            {
                                                              staticClass:
                                                                "shadow-box",
                                                              class: {
                                                                active:
                                                                  _vm.calc
                                                                    .docs_service_check
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "c-checkbox",
                                                                {
                                                                  staticClass:
                                                                    "fw-500",
                                                                  model: {
                                                                    value:
                                                                      _vm.calc
                                                                        .docs_service_check,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.calc,
                                                                        "docs_service_check",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "calc.docs_service_check"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Перевозка документов"
                                                                  )
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "cont mt-2"
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "text-gray-400",
                                                                      class: {
                                                                        "text-muted": !_vm
                                                                          .calc
                                                                          .docs_service_check
                                                                      }
                                                                    },
                                                                    [
                                                                      !_vm.calc
                                                                        .docs_service_check
                                                                        ? _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                "+ "
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm._f(
                                                                            "parts"
                                                                          )(
                                                                            _vm._f(
                                                                              "abs"
                                                                            )(
                                                                              _vm
                                                                                .response
                                                                                .docs_service_cost
                                                                            )
                                                                          )
                                                                        ) + " ₽"
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "c-row",
                                                                    [
                                                                      _c(
                                                                        "c-col",
                                                                        [
                                                                          _c(
                                                                            "c-select",
                                                                            {
                                                                              staticClass:
                                                                                "mt-2",
                                                                              attrs: {
                                                                                disabled: !_vm
                                                                                  .calc
                                                                                  .docs_service_check,
                                                                                options:
                                                                                  _vm
                                                                                    .options
                                                                                    .docsService
                                                                              },
                                                                              model: {
                                                                                value:
                                                                                  _vm
                                                                                    .calc
                                                                                    .docs_service,
                                                                                callback: function(
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.calc,
                                                                                    "docs_service",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                                expression:
                                                                                  "calc.docs_service"
                                                                              }
                                                                            }
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm.response
                                                    .thermo_service_cost &&
                                                  _vm.response.is_calc_termo
                                                    ? _c(
                                                        "c-col",
                                                        {
                                                          staticClass:
                                                            "px-1 mt-2 col-12 col-md-6"
                                                        },
                                                        [
                                                          _c(
                                                            "card",
                                                            {
                                                              staticClass:
                                                                "shadow-box",
                                                              class: {
                                                                active:
                                                                  _vm.calc
                                                                    .thermo_service
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "c-checkbox",
                                                                {
                                                                  staticClass:
                                                                    "fw-500",
                                                                  model: {
                                                                    value:
                                                                      _vm.calc
                                                                        .thermo_service,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.calc,
                                                                        "thermo_service",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "calc.thermo_service"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Терморежим +5С - +25С"
                                                                  )
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "cont mt-2"
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "text-gray-400",
                                                                      class: {
                                                                        "text-muted": !_vm
                                                                          .calc
                                                                          .thermo_service
                                                                      }
                                                                    },
                                                                    [
                                                                      !_vm.calc
                                                                        .thermo_service
                                                                        ? _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                "+ "
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm._f(
                                                                            "parts"
                                                                          )(
                                                                            _vm._f(
                                                                              "abs"
                                                                            )(
                                                                              _vm
                                                                                .response
                                                                                .thermo_service_cost
                                                                            )
                                                                          )
                                                                        ) + " ₽"
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "mt-4 mb-4 text-center text-md-left"
                                        },
                                        [
                                          _c(
                                            "c-button",
                                            {
                                              staticClass: "w-100 w-md-auto",
                                              attrs: {
                                                type: "submit",
                                                id: "calczayavka"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.check("calc")
                                                }
                                              }
                                            },
                                            [
                                              _vm.blocks.sender.filled
                                                ? _c("span", [
                                                    _vm._v("Продолжить")
                                                  ])
                                                : _c("span", [
                                                    _vm._v("Оформить заявку")
                                                  ])
                                            ]
                                          ),
                                          !_vm.backlog
                                            ? _c(
                                                "c-button",
                                                {
                                                  staticClass:
                                                    "d-none d-md-inline-block white ml-3",
                                                  on: {
                                                    click: function($event) {
                                                      _vm.backlog = true
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "Поручить оформление менеджеру"
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.backlog
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "backlog p-4 mt-4"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "text-center f-16"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Поручите оформление менеджеру."
                                                      ),
                                                      _c("div", [
                                                        _vm._v(
                                                          "Укажите Ваш телефон, и мы вам обязательно перезвоним в течение 10 минут."
                                                        )
                                                      ])
                                                    ]
                                                  ),
                                                  _c(
                                                    "c-row",
                                                    { staticClass: "mt-3" },
                                                    [
                                                      _c(
                                                        "c-col",
                                                        [
                                                          _c("c-input", {
                                                            directives: [
                                                              {
                                                                name: "mask",
                                                                rawName:
                                                                  "v-mask",
                                                                value: {
                                                                  mask:
                                                                    "+79999999999"
                                                                },
                                                                expression:
                                                                  "{mask: '+79999999999'}"
                                                              }
                                                            ],
                                                            attrs: {
                                                              subclass: "large",
                                                              placeholder:
                                                                "Телефон"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.calc
                                                                  .backlog_phone,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.calc,
                                                                  "backlog_phone",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "calc.backlog_phone"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "c-col",
                                                        {
                                                          staticClass:
                                                            "col-auto"
                                                        },
                                                        [
                                                          _c(
                                                            "c-button",
                                                            {
                                                              staticClass:
                                                                "backlog-button",
                                                              attrs: {
                                                                disabled:
                                                                  _vm.$v.backlog
                                                                    .$invalid
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.sendBacklog()
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Отправить"
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              !_vm.blocks.calc.show && _vm.blocks.calc.filled
                                ? _c(
                                    "div",
                                    { staticClass: "mt-3 d-none d-md-block" },
                                    [
                                      _c(
                                        "c-row",
                                        [
                                          _c(
                                            "c-col",
                                            { staticClass: "col-6" },
                                            [
                                              !_vm.calc.cargo_pickup
                                                ? [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "text-muted fw-500"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Сдача груза в отделение"
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "mt-2 f-14"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f("dic")(
                                                              _vm.calc
                                                                .city_from_id,
                                                              _vm.options
                                                                .citiesAll
                                                            )
                                                          ) +
                                                            ", " +
                                                            _vm._s(
                                                              _vm._f("dic")(
                                                                _vm.calc
                                                                  .cargo_pickup_id,
                                                                _vm.options
                                                                  .officesFrom
                                                              )
                                                            )
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                : _vm._e(),
                                              _vm.calc.cargo_pickup
                                                ? [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "text-muted fw-500"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Забрать по адресу"
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "mt-2 f-14"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f("dic")(
                                                              _vm.calc
                                                                .city_from_id,
                                                              _vm.options
                                                                .citiesAll
                                                            )
                                                          ) +
                                                            ", " +
                                                            _vm._s(
                                                              _vm.calc
                                                                .cargo_pickup_adress
                                                            )
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                : _vm._e()
                                            ],
                                            2
                                          ),
                                          _c(
                                            "c-col",
                                            { staticClass: "col-6" },
                                            [
                                              !_vm.calc.cargo_delivery
                                                ? [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "text-muted fw-500"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Получить груз в отделении"
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "mt-2 f-14"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f("dic")(
                                                              _vm.calc
                                                                .city_to_id,
                                                              _vm.options
                                                                .citiesAll
                                                            )
                                                          ) +
                                                            ", " +
                                                            _vm._s(
                                                              _vm._f("dic")(
                                                                _vm.calc
                                                                  .cargo_delivery_id,
                                                                _vm.options
                                                                  .officesTo
                                                              )
                                                            )
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                : _vm._e(),
                                              _vm.calc.cargo_delivery
                                                ? [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "text-muted fw-500"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Доставить по адресу"
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "mt-2 f-14"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f("dic")(
                                                              _vm.calc
                                                                .city_to_id,
                                                              _vm.options
                                                                .citiesAll
                                                            )
                                                          ) +
                                                            ", " +
                                                            _vm._s(
                                                              _vm.calc
                                                                .cargo_delivery_adress
                                                            )
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                : _vm._e()
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      ),
                                      _vm.calc.calc_type == "common"
                                        ? [
                                            _c("div", { staticClass: "mt-3" }, [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "text-muted fw-500"
                                                },
                                                [_vm._v("Груз:")]
                                              ),
                                              _c(
                                                "span",
                                                [
                                                  _vm.calc.common_cargo_weight
                                                    ? [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.calc
                                                                .common_cargo_weight
                                                            ) +
                                                            " кг"
                                                        )
                                                      ]
                                                    : _vm._e(),
                                                  _vm.calc.common_cargo_volume
                                                    ? [
                                                        _vm._v(
                                                          ", " +
                                                            _vm._s(
                                                              _vm.calc
                                                                .common_cargo_volume
                                                            ) +
                                                            " м"
                                                        ),
                                                        _c("sup"),
                                                        _vm._v("3")
                                                      ]
                                                    : _vm._e(),
                                                  _vm.calc.common_cargo_amount
                                                    ? [
                                                        _vm._v(
                                                          ", " +
                                                            _vm._s(
                                                              _vm.calc
                                                                .common_cargo_amount
                                                            ) +
                                                            " место"
                                                        )
                                                      ]
                                                    : _vm._e()
                                                ],
                                                2
                                              )
                                            ])
                                          ]
                                        : _vm._e(),
                                      _vm.calc.calc_type == "extra"
                                        ? [
                                            _c("div", { staticClass: "mt-3" }, [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "text-muted fw-500"
                                                },
                                                [_vm._v("Груз:")]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "pl-5" },
                                                _vm._l(_vm.cargo, function(
                                                  item,
                                                  index
                                                ) {
                                                  return _c(
                                                    "div",
                                                    { key: index },
                                                    [
                                                      item.weight
                                                        ? [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.weight
                                                              ) +
                                                                " кг,\n " +
                                                                _vm._s(
                                                                  item.length
                                                                ) +
                                                                "x" +
                                                                _vm._s(
                                                                  item.width
                                                                ) +
                                                                "x" +
                                                                _vm._s(
                                                                  item.height
                                                                ) +
                                                                " " +
                                                                _vm._s(
                                                                  _vm._f("dic")(
                                                                    item.measure_LWH,
                                                                    _vm.options
                                                                      .units
                                                                  )
                                                                )
                                                            ),
                                                            _c("sup", [
                                                              _vm._v("3")
                                                            ]),
                                                            _vm._v(
                                                              ",\n " +
                                                                _vm._s(
                                                                  item.amount
                                                                ) +
                                                                " место"
                                                            )
                                                          ]
                                                        : _vm._e()
                                                    ],
                                                    2
                                                  )
                                                }),
                                                0
                                              )
                                            ])
                                          ]
                                        : _vm._e(),
                                      _vm.isPackaging
                                        ? [
                                            _c("h4", { staticClass: "my-3" }, [
                                              _vm._v("Упаковка")
                                            ]),
                                            _vm.calc.palletizing_service
                                              ? _c(
                                                  "div",
                                                  { staticClass: "mt-2" },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-muted fw-500"
                                                      },
                                                      [_vm._v("Палетирование")]
                                                    ),
                                                    _c(
                                                      "span",
                                                      { staticClass: "ml-2" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f("parts")(
                                                              _vm._f("abs")(
                                                                _vm.response
                                                                  .palletizing_service_cost
                                                              )
                                                            )
                                                          ) + " ₽"
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.calc.stretch_service
                                              ? _c(
                                                  "div",
                                                  { staticClass: "mt-2" },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-muted fw-500"
                                                      },
                                                      [_vm._v("Стрейч пленка")]
                                                    ),
                                                    _c(
                                                      "span",
                                                      { staticClass: "ml-2" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f("parts")(
                                                              _vm._f("abs")(
                                                                _vm.response
                                                                  .stretch_service_cost
                                                              )
                                                            )
                                                          ) + " ₽"
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.calc.bubble_service
                                              ? _c(
                                                  "div",
                                                  { staticClass: "mt-2" },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-muted fw-500"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Воздушно-пузырьковая пленка"
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      { staticClass: "ml-2" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f("parts")(
                                                              _vm._f("abs")(
                                                                _vm.response
                                                                  .bubble_service_cost
                                                              )
                                                            )
                                                          ) + " ₽"
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.calc.pallet_board
                                              ? _c(
                                                  "div",
                                                  { staticClass: "mt-2" },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-muted fw-500"
                                                      },
                                                      [_vm._v("Палетный борт")]
                                                    ),
                                                    _c(
                                                      "span",
                                                      { staticClass: "ml-2" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f("parts")(
                                                              _vm._f("abs")(
                                                                _vm.response
                                                                  .pallet_board_service_cost
                                                              )
                                                            )
                                                          ) + " ₽"
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.calc.other_pack_service_check
                                              ? _c(
                                                  "div",
                                                  { staticClass: "mt-2" },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-muted fw-500"
                                                      },
                                                      [_vm._v("Прочие услуги")]
                                                    ),
                                                    _c(
                                                      "span",
                                                      { staticClass: "ml-2" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f("parts")(
                                                              _vm._f("abs")(
                                                                _vm.response
                                                                  .other_service_cost
                                                              )
                                                            )
                                                          ) + " ₽"
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.calc.rigid_packing_service
                                              ? _c(
                                                  "div",
                                                  { staticClass: "mt-2" },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-muted fw-500"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Жесткая упаковка"
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      { staticClass: "ml-2" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f("parts")(
                                                              _vm._f("abs")(
                                                                _vm.response
                                                                  .rigid_packings_service_cost
                                                              )
                                                            )
                                                          ) + " ₽"
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ]
                                        : _vm._e(),
                                      _vm.isOtherOptions
                                        ? [
                                            _c("h4", { staticClass: "my-3" }, [
                                              _vm._v("Дополнительные услуги")
                                            ]),
                                            _vm.calc.insurance_service
                                              ? _c(
                                                  "div",
                                                  { staticClass: "mt-2" },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-muted fw-500"
                                                      },
                                                      [_vm._v("Страхование")]
                                                    ),
                                                    _c(
                                                      "span",
                                                      { staticClass: "ml-2" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f("parts")(
                                                              _vm._f("abs")(
                                                                _vm.response
                                                                  .insurance_service_cost
                                                              )
                                                            )
                                                          ) + " ₽"
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.calc.docs_service_check
                                              ? _c(
                                                  "div",
                                                  { staticClass: "mt-2" },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-muted fw-500"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Перевозка документов"
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      { staticClass: "ml-2" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f("parts")(
                                                              _vm._f("abs")(
                                                                _vm.response
                                                                  .docs_service_cost
                                                              )
                                                            )
                                                          ) + " ₽"
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.calc.sender_loading
                                              ? _c(
                                                  "div",
                                                  { staticClass: "mt-2" },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-muted fw-500"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Погрузочные работы у отправителя"
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      { staticClass: "ml-2" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f("parts")(
                                                              _vm._f("abs")(
                                                                _vm.response
                                                                  .sender_service_loading_cost
                                                              )
                                                            )
                                                          ) + " ₽"
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.calc.receiver_loading
                                              ? _c(
                                                  "div",
                                                  { staticClass: "mt-2" },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-muted fw-500"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Погрузочные работы у получателя"
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      { staticClass: "ml-2" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f("parts")(
                                                              _vm._f("abs")(
                                                                _vm.response
                                                                  .receiver_service_loading_cost
                                                              )
                                                            )
                                                          ) + " ₽"
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.calc.thermo_service
                                              ? _c(
                                                  "div",
                                                  { staticClass: "mt-2" },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-muted fw-500"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Терморежим +5С - +25С"
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      { staticClass: "ml-2" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f("parts")(
                                                              _vm._f("abs")(
                                                                _vm.response
                                                                  .thermo_service_cost
                                                              )
                                                            )
                                                          ) + " ₽"
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ]
                                        : _vm._e()
                                    ],
                                    2
                                  )
                                : _vm._e()
                            ]
                          ),
                          _c("c-col", { staticClass: "col-12 block" }, [
                            _c(
                              "div",
                              {
                                class: {
                                  "d-none d-md-inline-block": !_vm.blocks.sender
                                    .show
                                }
                              },
                              [
                                _c(
                                  "h3",
                                  {
                                    staticClass: "mt-4",
                                    class: {
                                      "text-muted": !_vm.blocks.sender.filled
                                    }
                                  },
                                  [
                                    _vm._v("Отправитель"),
                                    !_vm.blocks.sender.show &&
                                    _vm.blocks.sender.filled
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-inline-block text-primary f-14 ml-3 hoverable",
                                            on: {
                                              click: function($event) {
                                                return _vm.editBlock("sender")
                                              }
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "icon-pencil mr-2"
                                            }),
                                            !_vm.isBlockInvalid("sender")
                                              ? _c("span", [
                                                  _vm._v("Редактировать")
                                                ])
                                              : _vm._e(),
                                            _vm.isBlockInvalid("sender")
                                              ? _c("span", [
                                                  _vm._v(
                                                    "Продолжить заполнение"
                                                  )
                                                ])
                                              : _vm._e()
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                ),
                                !_vm.blocks.sender.show &&
                                _vm.blocks.sender.filled &&
                                _vm.isBlockInvalid("sender")
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "mt-3 text-purple fw-500"
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "icon-warning mr-2"
                                        }),
                                        _vm._v("Не все данные заполнены")
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            ),
                            !_vm.blocks.sender.show && _vm.blocks.sender.filled
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "mt-3 d-block d-md-none",
                                    on: {
                                      click: function($event) {
                                        return _vm.editBlock("sender")
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "w-100 btn btn-outline-primary py-2 px-4 text-left"
                                      },
                                      [
                                        _c(
                                          "c-row",
                                          {
                                            staticClass:
                                              "flex-nowrap align-items-center"
                                          },
                                          [
                                            _c(
                                              "c-col",
                                              { staticClass: "f-14 fw-500" },
                                              [_vm._v("Отправитель")]
                                            ),
                                            _c(
                                              "c-col",
                                              { staticClass: "col-auto" },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "icon-angle-down f-10"
                                                })
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    !_vm.blocks.calc.show &&
                                    _vm.blocks.calc.filled &&
                                    _vm.isBlockInvalid("sender")
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "py-2 bg-gray-200 text-gray-400 fa justify-content-center"
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "icon-warning bg-gray-400 mr-2"
                                            }),
                                            _vm._v("Не все данные заполнены")
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                )
                              : _vm._e(),
                            _vm.blocks.sender.show
                              ? _c(
                                  "div",
                                  { staticClass: "mt-4" },
                                  [
                                    _c(
                                      "c-row",
                                      [
                                        _c(
                                          "c-col",
                                          { staticClass: "col-12 col-md-6" },
                                          [
                                            _vm.options.senders.length > 1
                                              ? [
                                                  _c("c-select", {
                                                    staticClass: "mt-3 mt-md-2",
                                                    attrs: {
                                                      placeholder:
                                                        "Отправитель",
                                                      options:
                                                        _vm.options.senders
                                                    },
                                                    model: {
                                                      value: _vm.calc.sender_id,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.calc,
                                                          "sender_id",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "calc.sender_id"
                                                    }
                                                  }),
                                                  _vm.calc.sender_id == 0
                                                    ? [
                                                        _c("c-subject", {
                                                          staticClass:
                                                            "mt-2 mt-md-0",
                                                          attrs: {
                                                            options: _vm.options
                                                          },
                                                          model: {
                                                            value: _vm.sender,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.sender = $$v
                                                            },
                                                            expression: "sender"
                                                          }
                                                        })
                                                      ]
                                                    : _vm._e()
                                                ]
                                              : [
                                                  _c("c-subject", {
                                                    attrs: {
                                                      options: _vm.options
                                                    },
                                                    model: {
                                                      value: _vm.sender,
                                                      callback: function($$v) {
                                                        _vm.sender = $$v
                                                      },
                                                      expression: "sender"
                                                    }
                                                  })
                                                ]
                                          ],
                                          2
                                        )
                                      ],
                                      1
                                    ),
                                    !_vm.$viewer.logged
                                      ? _c("div", {
                                          staticClass: "shadow-box mt-4"
                                        })
                                      : _vm._e(),
                                    _c(
                                      "div",
                                      { staticClass: "mt-4 mb-4" },
                                      [
                                        _c(
                                          "c-button",
                                          {
                                            staticClass: "w-100 w-md-auto",
                                            on: {
                                              click: function($event) {
                                                return _vm.check("sender")
                                              }
                                            }
                                          },
                                          [_vm._v("Продолжить")]
                                        ),
                                        !_vm.backlog
                                          ? _c(
                                              "c-button",
                                              {
                                                staticClass:
                                                  "d-none d-md-inline-block white ml-3",
                                                on: {
                                                  click: function($event) {
                                                    _vm.backlog = true
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "Поручить оформление менеджеру"
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.backlog
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "backlog p-4 mt-4"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "text-center f-16"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Поручите оформление менеджеру."
                                                    ),
                                                    _c("div", [
                                                      _vm._v(
                                                        "Укажите Ваш телефон, и мы вам обязательно перезвоним в течение 10 минут."
                                                      )
                                                    ])
                                                  ]
                                                ),
                                                _c(
                                                  "c-row",
                                                  { staticClass: "mt-3" },
                                                  [
                                                    _c(
                                                      "c-col",
                                                      [
                                                        _c("c-input", {
                                                          directives: [
                                                            {
                                                              name: "mask",
                                                              rawName: "v-mask",
                                                              value: {
                                                                mask:
                                                                  "+79999999999"
                                                              },
                                                              expression:
                                                                "{mask: '+79999999999'}"
                                                            }
                                                          ],
                                                          attrs: {
                                                            subclass: "large",
                                                            placeholder:
                                                              "Телефон"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.calc
                                                                .backlog_phone,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.calc,
                                                                "backlog_phone",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "calc.backlog_phone"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "c-col",
                                                      {
                                                        staticClass: "col-auto"
                                                      },
                                                      [
                                                        _c(
                                                          "c-button",
                                                          {
                                                            staticClass:
                                                              "backlog-button",
                                                            attrs: {
                                                              disabled:
                                                                _vm.$v.backlog
                                                                  .$invalid
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.sendBacklog()
                                                              }
                                                            }
                                                          },
                                                          [_vm._v("Отправить")]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            !_vm.blocks.sender.show && _vm.blocks.sender.filled
                              ? _c(
                                  "div",
                                  { staticClass: "mt-3 d-none d-md-block" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.sender.agent_name) +
                                        ", " +
                                        _vm._s(_vm.sender.agent_phone) +
                                        " "
                                    ),
                                    _vm.sender.agent_extra_phone
                                      ? _c("span", [
                                          _vm._v(
                                            "(доб. " +
                                              _vm._s(
                                                _vm.sender.agent_extra_phone
                                              ) +
                                              " )"
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm.sender.agent_type == 1
                                      ? _c("div", { staticClass: "mt-1" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("dic")(
                                                _vm.sender
                                                  .agent_document_type_id,
                                                _vm.options.docTypes
                                              )
                                            ) +
                                              " " +
                                              _vm._s(
                                                _vm.sender.agent_document_series
                                              ) +
                                              " " +
                                              _vm._s(
                                                _vm.sender.agent_document_number
                                              )
                                          )
                                        ])
                                      : _vm._e(),
                                    ~[2, 3].indexOf(_vm.sender.agent_type)
                                      ? _c("div", [
                                          _c("div", { staticClass: "mt-1" }, [
                                            _vm._v(
                                              "ИНН " +
                                                _vm._s(_vm.sender.agent_inn) +
                                                ", КПП " +
                                                _vm._s(_vm.sender.agent_kpp)
                                            )
                                          ]),
                                          _c("div", { staticClass: "mt-1" }, [
                                            _vm._v(
                                              _vm._s(_vm.sender.agent_contact)
                                            )
                                          ])
                                        ])
                                      : _vm._e()
                                  ]
                                )
                              : _vm._e()
                          ]),
                          _c("c-col", { staticClass: "col-12 block" }, [
                            _c(
                              "div",
                              {
                                class: {
                                  "d-none d-md-inline-block": !_vm.blocks
                                    .receiver.show
                                }
                              },
                              [
                                _c(
                                  "h3",
                                  {
                                    staticClass: "mt-4",
                                    class: {
                                      "text-muted": !_vm.blocks.receiver.filled
                                    }
                                  },
                                  [
                                    _vm._v("Получатель"),
                                    !_vm.blocks.receiver.show &&
                                    _vm.blocks.receiver.filled
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-inline-block text-primary f-14 ml-3 hoverable",
                                            on: {
                                              click: function($event) {
                                                return _vm.editBlock("receiver")
                                              }
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "icon-pencil mr-2"
                                            }),
                                            !_vm.isBlockInvalid("receiver")
                                              ? _c("span", [
                                                  _vm._v("Редактировать")
                                                ])
                                              : _vm._e(),
                                            _vm.isBlockInvalid("receiver")
                                              ? _c("span", [
                                                  _vm._v(
                                                    "Продолжить заполнение"
                                                  )
                                                ])
                                              : _vm._e()
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                ),
                                !_vm.blocks.receiver.show &&
                                _vm.blocks.receiver.filled &&
                                _vm.isBlockInvalid("receiver")
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "mt-3 text-purple fw-500"
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "icon-warning mr-2"
                                        }),
                                        _vm._v("Не все данные заполнены")
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            ),
                            !_vm.blocks.receiver.show &&
                            _vm.blocks.receiver.filled
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "mt-3 d-block d-md-none",
                                    on: {
                                      click: function($event) {
                                        return _vm.editBlock("receiver")
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "w-100 btn btn-outline-primary py-2 px-4 text-left"
                                      },
                                      [
                                        _c(
                                          "c-row",
                                          {
                                            staticClass:
                                              "flex-nowrap align-items-center"
                                          },
                                          [
                                            _c(
                                              "c-col",
                                              { staticClass: "f-14 fw-500" },
                                              [_vm._v("Получатель")]
                                            ),
                                            _c(
                                              "c-col",
                                              { staticClass: "col-auto" },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "icon-angle-down f-10"
                                                })
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    !_vm.blocks.calc.show &&
                                    _vm.blocks.calc.filled &&
                                    _vm.isBlockInvalid("receiver")
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "py-2 bg-gray-200 text-gray-400 fa justify-content-center"
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "icon-warning bg-gray-400 mr-2"
                                            }),
                                            _vm._v("Не все данные заполнены")
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                )
                              : _vm._e(),
                            _vm.blocks.receiver.show
                              ? _c(
                                  "div",
                                  { staticClass: "mt-4" },
                                  [
                                    _c(
                                      "c-row",
                                      [
                                        _c(
                                          "c-col",
                                          { staticClass: "col-12 col-md-6" },
                                          [
                                            _vm.options.receivers.length > 1
                                              ? [
                                                  _c("c-select", {
                                                    staticClass: "mt-3 mt-md-2",
                                                    attrs: {
                                                      placeholder: "Получатель",
                                                      options:
                                                        _vm.options.receivers
                                                    },
                                                    model: {
                                                      value: _vm.calc.recip_id,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.calc,
                                                          "recip_id",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "calc.recip_id"
                                                    }
                                                  }),
                                                  _vm.calc.recip_id == 0
                                                    ? [
                                                        _c("c-subject", {
                                                          staticClass:
                                                            "mt-2 mt-md-0",
                                                          attrs: {
                                                            options: _vm.options
                                                          },
                                                          model: {
                                                            value: _vm.receiver,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.receiver = $$v
                                                            },
                                                            expression:
                                                              "receiver"
                                                          }
                                                        })
                                                      ]
                                                    : _vm._e()
                                                ]
                                              : [
                                                  _c("c-subject", {
                                                    attrs: {
                                                      options: _vm.options
                                                    },
                                                    model: {
                                                      value: _vm.receiver,
                                                      callback: function($$v) {
                                                        _vm.receiver = $$v
                                                      },
                                                      expression: "receiver"
                                                    }
                                                  })
                                                ],
                                            _c(
                                              "c-checkbox",
                                              {
                                                staticClass:
                                                  "shadow-box d-md-inline-block mt-3",
                                                staticStyle: { width: "350px" },
                                                model: {
                                                  value: _vm.customOrder,
                                                  callback: function($$v) {
                                                    _vm.customOrder = $$v
                                                  },
                                                  expression: "customOrder"
                                                }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "fw-500 fw-md-normal"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Хочу указать свой номер заказа"
                                                    ),
                                                    _c(
                                                      "c-tip",
                                                      {
                                                        staticClass:
                                                          "d-none d-md-inline-block tip-express ml-2 align-middle"
                                                      },
                                                      [
                                                        _c(
                                                          "template",
                                                          { slot: "subject" },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "icon-help ml-1 f-16 lh-0 text-gray-300"
                                                            })
                                                          ]
                                                        ),
                                                        _c("div", [
                                                          _vm._v(
                                                            "Вы можете присвоить свой идентификатор для данной заявки. Например, номер заявки вашего получателя"
                                                          )
                                                        ])
                                                      ],
                                                      2
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            ),
                                            _vm.customOrder
                                              ? _c("c-input", {
                                                  staticClass: "mt-2 mt-md-0",
                                                  attrs: {
                                                    placeholder: "Номер заказа"
                                                  },
                                                  model: {
                                                    value: _vm.order_id,
                                                    callback: function($$v) {
                                                      _vm.order_id = $$v
                                                    },
                                                    expression: "order_id"
                                                  }
                                                })
                                              : _vm._e()
                                          ],
                                          2
                                        )
                                      ],
                                      1
                                    ),
                                    _vm.$viewer.logged
                                      ? _c(
                                          "div",
                                          { staticClass: "shadow-box mt-4" },
                                          [
                                            _c(
                                              "c-checkbox",
                                              {
                                                model: {
                                                  value: _vm.PDAgree,
                                                  callback: function($$v) {
                                                    _vm.PDAgree = $$v
                                                  },
                                                  expression: "PDAgree"
                                                }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "fw-500 fw-md-400"
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-inline-block text-primary",
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.openAgreement()
                                                          }
                                                        }
                                                      },
                                                      [_vm._v("Согласен(-на)")]
                                                    ),
                                                    _vm._v(
                                                      " на обработку персональных данных"
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "div",
                                      { staticClass: "mt-4 mb-4" },
                                      [
                                        _vm.$viewer.logged && _vm.orderError
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "alert alert-danger mb-0 f-12 d-block my-4"
                                              },
                                              [_vm._v(_vm._s(_vm.orderError))]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "c-button",
                                          {
                                            staticClass: "w-100 w-md-auto",
                                            attrs: { disabled: _vm.sending },
                                            on: {
                                              click: function($event) {
                                                return _vm.check("receiver")
                                              }
                                            }
                                          },
                                          [
                                            !_vm.$viewer.logged
                                              ? [_vm._v("Продолжить")]
                                              : [_vm._v("Отправить заявку")]
                                          ],
                                          2
                                        ),
                                        !_vm.backlog
                                          ? _c(
                                              "c-button",
                                              {
                                                staticClass:
                                                  "d-none d-md-inline-block white ml-3",
                                                on: {
                                                  click: function($event) {
                                                    _vm.backlog = true
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "Поручить оформление менеджеру"
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.backlog
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "backlog p-4 mt-4"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "text-center f-16"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Поручите оформление менеджеру."
                                                    ),
                                                    _c("div", [
                                                      _vm._v(
                                                        "Укажите Ваш телефон, и мы вам обязательно перезвоним в течение 10 минут."
                                                      )
                                                    ])
                                                  ]
                                                ),
                                                _c(
                                                  "c-row",
                                                  { staticClass: "mt-3" },
                                                  [
                                                    _c(
                                                      "c-col",
                                                      [
                                                        _c("c-input", {
                                                          directives: [
                                                            {
                                                              name: "mask",
                                                              rawName: "v-mask",
                                                              value: {
                                                                mask:
                                                                  "+79999999999"
                                                              },
                                                              expression:
                                                                "{mask: '+79999999999'}"
                                                            }
                                                          ],
                                                          attrs: {
                                                            subclass: "large",
                                                            placeholder:
                                                              "Телефон"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.calc
                                                                .backlog_phone,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.calc,
                                                                "backlog_phone",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "calc.backlog_phone"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "c-col",
                                                      {
                                                        staticClass: "col-auto"
                                                      },
                                                      [
                                                        _c(
                                                          "c-button",
                                                          {
                                                            staticClass:
                                                              "backlog-button",
                                                            attrs: {
                                                              disabled:
                                                                _vm.$v.backlog
                                                                  .$invalid
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.sendBacklog()
                                                              }
                                                            }
                                                          },
                                                          [_vm._v("Отправить")]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            !_vm.blocks.receiver.show &&
                            _vm.blocks.receiver.filled
                              ? _c(
                                  "div",
                                  { staticClass: "mt-3 d-none d-md-block" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.receiver.agent_name) +
                                        ", " +
                                        _vm._s(_vm.receiver.agent_phone)
                                    ),
                                    _vm.receiver.agent_extra_phone
                                      ? _c("span", [
                                          _vm._v(
                                            "(доб. " +
                                              _vm._s(
                                                _vm.receiver.agent_extra_phone
                                              ) +
                                              " )"
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm.receiver.agent_type == 1
                                      ? _c("div", { staticClass: "mt-1" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("dic")(
                                                _vm.receiver
                                                  .agent_document_type_id,
                                                _vm.options.docTypes
                                              )
                                            ) +
                                              " " +
                                              _vm._s(
                                                _vm.receiver
                                                  .agent_document_series
                                              ) +
                                              " " +
                                              _vm._s(
                                                _vm.receiver
                                                  .agent_document_number
                                              )
                                          )
                                        ])
                                      : _vm._e(),
                                    ~[2, 3].indexOf(_vm.receiver.agent_type)
                                      ? _c("div", [
                                          _c("div", { staticClass: "mt-1" }, [
                                            _vm._v(
                                              "ИНН " +
                                                _vm._s(_vm.receiver.agent_inn) +
                                                ", КПП " +
                                                _vm._s(_vm.receiver.agent_kpp)
                                            )
                                          ]),
                                          _c("div", { staticClass: "mt-1" }, [
                                            _vm._v(
                                              _vm._s(_vm.receiver.agent_contact)
                                            )
                                          ])
                                        ])
                                      : _vm._e()
                                  ]
                                )
                              : _vm._e()
                          ]),
                          _c("c-col", { staticClass: "col-12 block payer" }, [
                            _c(
                              "div",
                              {
                                class: {
                                  "d-none d-md-inline-block": !_vm.blocks.payer
                                    .show
                                }
                              },
                              [
                                _c(
                                  "h3",
                                  {
                                    staticClass: "mt-4",
                                    class: {
                                      "text-muted": !_vm.blocks.payer.filled
                                    }
                                  },
                                  [
                                    _vm._v("Плательщик"),
                                    !_vm.blocks.payer.show &&
                                    _vm.blocks.payer.filled
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-inline-block text-primary f-14 ml-3 hoverable",
                                            on: {
                                              click: function($event) {
                                                return _vm.editBlock("payer")
                                              }
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "icon-pencil mr-2"
                                            }),
                                            !_vm.isBlockInvalid("payer")
                                              ? _c("span", [
                                                  _vm._v("Редактировать")
                                                ])
                                              : _vm._e(),
                                            _vm.isBlockInvalid("payer")
                                              ? _c("span", [
                                                  _vm._v(
                                                    "Продолжить заполнение"
                                                  )
                                                ])
                                              : _vm._e()
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                ),
                                !_vm.blocks.payer.show &&
                                _vm.blocks.payer.filled &&
                                _vm.isBlockInvalid("payer")
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "mt-3 text-purple fw-500"
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "icon-warning mr-2"
                                        }),
                                        _vm._v("Не все данные заполнены")
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            ),
                            !_vm.blocks.payer.show && _vm.blocks.payer.filled
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "mt-3 d-block d-md-none",
                                    on: {
                                      click: function($event) {
                                        return _vm.editBlock("payer")
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "w-100 btn btn-outline-primary py-2 px-4 text-left"
                                      },
                                      [
                                        _c(
                                          "c-row",
                                          {
                                            staticClass:
                                              "flex-nowrap align-items-center"
                                          },
                                          [
                                            _c(
                                              "c-col",
                                              { staticClass: "f-14 fw-500" },
                                              [_vm._v("Плательщик")]
                                            ),
                                            _c(
                                              "c-col",
                                              { staticClass: "col-auto" },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "icon-angle-down f-10"
                                                })
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    !_vm.blocks.calc.show &&
                                    _vm.blocks.calc.filled &&
                                    _vm.isBlockInvalid("payer")
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "py-2 bg-gray-200 text-gray-400 fa justify-content-center"
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "icon-warning bg-gray-400 mr-2"
                                            }),
                                            _vm._v("Не все данные заполнены")
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                )
                              : _vm._e(),
                            _vm.blocks.payer.show
                              ? _c(
                                  "div",
                                  { staticClass: "mt-4" },
                                  [
                                    _c(
                                      "c-row",
                                      [
                                        _c(
                                          "c-col",
                                          { staticClass: "col-12 col-md-6" },
                                          [
                                            !_vm.$viewer.logged
                                              ? [
                                                  _c("c-select", {
                                                    staticClass: "mt-3 mt-md-2",
                                                    attrs: {
                                                      placeholder: "Плательщик",
                                                      options:
                                                        _vm.options.payerTypes
                                                    },
                                                    model: {
                                                      value: _vm.payer_type,
                                                      callback: function($$v) {
                                                        _vm.payer_type = $$v
                                                      },
                                                      expression: "payer_type"
                                                    }
                                                  }),
                                                  _c("c-select", {
                                                    staticClass: "mt-3 mt-md-2",
                                                    attrs: {
                                                      placeholder:
                                                        "Форма оплаты",
                                                      options:
                                                        _vm.options
                                                          .paymentTypes,
                                                      accessor:
                                                        "agents.$each[2].type_pay"
                                                    },
                                                    model: {
                                                      value: _vm.payer.type_pay,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.payer,
                                                          "type_pay",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "payer.type_pay"
                                                    }
                                                  }),
                                                  _vm.payer_type == "third"
                                                    ? _c("c-subject", {
                                                        staticClass:
                                                          "mt-2 mt-md-0",
                                                        attrs: {
                                                          options: _vm.options
                                                        },
                                                        model: {
                                                          value: _vm.payer,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.payer = $$v
                                                          },
                                                          expression: "payer"
                                                        }
                                                      })
                                                    : _vm._e()
                                                ]
                                              : [
                                                  _vm.calc.payer_id === null
                                                    ? _c("c-select", {
                                                        staticClass:
                                                          "mt-3 mt-md-2",
                                                        attrs: {
                                                          placeholder:
                                                            "Выберите плательшика",
                                                          options:
                                                            _vm.options.payers
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.calc.payer_id,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.calc,
                                                              "payer_id",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "calc.payer_id"
                                                        }
                                                      })
                                                    : _c("c-select", {
                                                        staticClass:
                                                          "mt-3 mt-md-2",
                                                        attrs: {
                                                          placeholder:
                                                            "Плательшик",
                                                          options:
                                                            _vm.options.payers
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.calc.payer_id,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.calc,
                                                              "payer_id",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "calc.payer_id"
                                                        }
                                                      }),
                                                  _vm.calc.payer_id == 0
                                                    ? [
                                                        _c("c-select", {
                                                          attrs: {
                                                            placeholder:
                                                              "Город",
                                                            options:
                                                              _vm.options
                                                                .cities,
                                                            filter:
                                                              _vm.filterCities,
                                                            getOptionLabel:
                                                              _vm.getCityLabel,
                                                            accessor: "TownID"
                                                          },
                                                          model: {
                                                            value: _vm.TownID,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.TownID = $$v
                                                            },
                                                            expression: "TownID"
                                                          }
                                                        }),
                                                        _c("c-input", {
                                                          staticClass:
                                                            "mt-2 mt-md-0",
                                                          attrs: {
                                                            placeholder:
                                                              "Адрес",
                                                            accessor: "Address"
                                                          },
                                                          model: {
                                                            value: _vm.Address,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.Address = $$v
                                                            },
                                                            expression:
                                                              "Address"
                                                          }
                                                        }),
                                                        _c("c-select", {
                                                          staticClass:
                                                            "mt-3 mt-md-2",
                                                          attrs: {
                                                            placeholder:
                                                              "Форма оплаты",
                                                            options:
                                                              _vm.options
                                                                .paymentTypes,
                                                            accessor:
                                                              "agents.$each[2].type_pay"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.payer
                                                                .type_pay,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.payer,
                                                                "type_pay",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "payer.type_pay"
                                                          }
                                                        }),
                                                        _c("c-subject", {
                                                          staticClass:
                                                            "mt-2 mt-md-0",
                                                          attrs: {
                                                            options: _vm.options
                                                          },
                                                          model: {
                                                            value: _vm.payer,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.payer = $$v
                                                            },
                                                            expression: "payer"
                                                          }
                                                        })
                                                      ]
                                                    : _vm._e()
                                                ]
                                          ],
                                          2
                                        )
                                      ],
                                      1
                                    ),
                                    !_vm.$viewer.logged
                                      ? _c(
                                          "div",
                                          { staticClass: "shadow-box mt-4" },
                                          [
                                            _c(
                                              "c-checkbox",
                                              {
                                                model: {
                                                  value: _vm.PDAgree,
                                                  callback: function($$v) {
                                                    _vm.PDAgree = $$v
                                                  },
                                                  expression: "PDAgree"
                                                }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "fw-500 fw-md-400"
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-inline-block text-primary",
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.openAgreement()
                                                          }
                                                        }
                                                      },
                                                      [_vm._v("Согласен(-на)")]
                                                    ),
                                                    _vm._v(
                                                      " на обработку персональных данных"
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "div",
                                      { staticClass: "mt-4 mb-4" },
                                      [
                                        !_vm.$viewer.logged && _vm.orderError
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "alert alert-danger mb-0 f-12 d-block my-4"
                                              },
                                              [_vm._v(_vm._s(_vm.orderError))]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "c-button",
                                          {
                                            staticClass: "w-100 w-md-auto",
                                            attrs: { disabled: _vm.sending },
                                            on: {
                                              click: function($event) {
                                                return _vm.check("payer")
                                              }
                                            }
                                          },
                                          [
                                            _vm.$viewer.logged
                                              ? [_vm._v("Продолжить")]
                                              : [_vm._v("Отправить заявку")]
                                          ],
                                          2
                                        ),
                                        !_vm.backlog
                                          ? _c(
                                              "c-button",
                                              {
                                                staticClass:
                                                  "d-none d-md-inline-block white ml-3",
                                                on: {
                                                  click: function($event) {
                                                    _vm.backlog = true
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "Поручить оформление менеджеру"
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.backlog
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "backlog p-4 mt-4"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "text-center f-16"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Поручите оформление менеджеру."
                                                    ),
                                                    _c("div", [
                                                      _vm._v(
                                                        "Укажите Ваш телефон, и мы вам обязательно перезвоним в течение 10 минут."
                                                      )
                                                    ])
                                                  ]
                                                ),
                                                _c(
                                                  "c-row",
                                                  { staticClass: "mt-3" },
                                                  [
                                                    _c(
                                                      "c-col",
                                                      [
                                                        _c("c-input", {
                                                          directives: [
                                                            {
                                                              name: "mask",
                                                              rawName: "v-mask",
                                                              value: {
                                                                mask:
                                                                  "+79999999999"
                                                              },
                                                              expression:
                                                                "{mask: '+79999999999'}"
                                                            }
                                                          ],
                                                          attrs: {
                                                            subclass: "large",
                                                            placeholder:
                                                              "Телефон"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.calc
                                                                .backlog_phone,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.calc,
                                                                "backlog_phone",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "calc.backlog_phone"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "c-col",
                                                      {
                                                        staticClass: "col-auto"
                                                      },
                                                      [
                                                        _c(
                                                          "c-button",
                                                          {
                                                            staticClass:
                                                              "backlog-button",
                                                            attrs: {
                                                              disabled:
                                                                _vm.$v.backlog
                                                                  .$invalid
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.sendBacklog()
                                                              }
                                                            }
                                                          },
                                                          [_vm._v("Отправить")]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            !_vm.blocks.payer.show && _vm.blocks.payer.filled
                              ? _c(
                                  "div",
                                  { staticClass: "mt-4 d-none d-md-block" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.payer.agent_name) +
                                        ", " +
                                        _vm._s(_vm.payer.agent_phone) +
                                        " "
                                    ),
                                    _vm.payer.agent_extra_phone
                                      ? _c("span", [
                                          _vm._v(
                                            "(доб. " +
                                              _vm._s(
                                                _vm.payer.agent_extra_phone
                                              ) +
                                              " )"
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm.payer.agent_type == 1
                                      ? _c("div", { staticClass: "mt-1" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("dic")(
                                                _vm.payer
                                                  .agent_document_type_id,
                                                _vm.options.docTypes
                                              )
                                            ) +
                                              " " +
                                              _vm._s(
                                                _vm.payer.agent_document_series
                                              ) +
                                              " " +
                                              _vm._s(
                                                _vm.payer.agent_document_number
                                              )
                                          )
                                        ])
                                      : _vm._e(),
                                    ~[2, 3].indexOf(_vm.payer.agent_type)
                                      ? _c("div", [
                                          _c("div", { staticClass: "mt-1" }, [
                                            _vm._v(
                                              "ИНН " +
                                                _vm._s(_vm.payer.agent_inn) +
                                                ", КПП " +
                                                _vm._s(_vm.payer.agent_kpp)
                                            )
                                          ]),
                                          _c("div", { staticClass: "mt-1" }, [
                                            _vm._v(
                                              _vm._s(_vm.payer.agent_contact)
                                            )
                                          ])
                                        ])
                                      : _vm._e()
                                  ]
                                )
                              : _vm._e()
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("c-col", { staticClass: "col-12 col-md-4" }, [
                    _c(
                      "div",
                      {
                        staticClass: "total-cont",
                        class: { show: _vm.showBill }
                      },
                      [
                        _c("div", {
                          staticClass: "spacer",
                          on: { click: _vm.closeBar }
                        }),
                        _c(
                          "div",
                          {
                            staticClass: "total-button d-block d-md-none",
                            class: { show: !_vm.showBill },
                            on: { click: _vm.openBar }
                          },
                          [
                            _c(
                              "c-row",
                              {
                                staticClass:
                                  "flex-nowrap alig-items-center bg-primary p-3"
                              },
                              [
                                _c(
                                  "c-col",
                                  {
                                    staticClass: "fw-bold f-10 text-uppercase"
                                  },
                                  [
                                    _c("div", { staticClass: "text-white" }, [
                                      _vm._v("Общая стоимость")
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "click-to-order" },
                                      [_vm._v("Нажмите чтобы заказать")]
                                    )
                                  ]
                                ),
                                _c(
                                  "c-col",
                                  {
                                    staticClass:
                                      "col-auto text-white fw-bold text-right"
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "h-100 fa f-14" },
                                      [
                                        _c("div", [
                                          _c("span", { staticClass: "f-12" }, [
                                            _vm._v("₽")
                                          ]),
                                          _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("parts")(
                                                    _vm._f("abs")(
                                                      _vm.response.total_cost
                                                    )
                                                  )
                                                )
                                            )
                                          ])
                                        ])
                                      ]
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c("div", { staticClass: "d-block d-md-none grads" }, [
                          _c("div", { staticClass: "top" }),
                          _c("div", { staticClass: "bottom" })
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "info-cont pt-md-4",
                            class: { show: _vm.showBill }
                          },
                          [
                            _vm.sending
                              ? _c("preloader", { attrs: { over: "" } })
                              : _vm._e(),
                            _c("div", { staticClass: "info-head mx-0 f-0" }, [
                              _c("div", { staticClass: "center" }),
                              _c("div", {
                                staticClass: "area-close",
                                on: { click: _vm.closeBar }
                              })
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "info-data px-4 py-3",
                                on: { scroll: _vm.scrollInfoData }
                              },
                              [
                                _vm.response.is_calc_standart
                                  ? _c(
                                      "div",
                                      { staticClass: "info" },
                                      [
                                        _c(
                                          "c-row",
                                          { staticClass: "flex-nowrap" },
                                          [
                                            _c("c-col", [
                                              !_vm.errors
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "f-16 fw-500 text-gray-300"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Предварительный расчет"
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ]),
                                            _vm.saved
                                              ? _c(
                                                  "c-col",
                                                  { staticClass: "col-auto" },
                                                  [
                                                    _c(
                                                      "c-tip",
                                                      {
                                                        attrs: {
                                                          direction: "left"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "template",
                                                          { slot: "subject" },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "icon-star bg-primary"
                                                            })
                                                          ]
                                                        ),
                                                        _vm._v(
                                                          "Сохраненный расчет " +
                                                            _vm._s(
                                                              _vm._f("date")(
                                                                _vm.calc
                                                                  .create_date
                                                              )
                                                            )
                                                        )
                                                      ],
                                                      2
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        ),
                                        _vm.response.total_cost && !_vm.errors
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "total text-primary mt-3 pt-1"
                                              },
                                              [
                                                _vm._v(
                                                  "₽ " +
                                                    _vm._s(
                                                      _vm._f("parts")(
                                                        _vm._f("abs")(
                                                          _vm.response
                                                            .total_cost
                                                        )
                                                      )
                                                    )
                                                )
                                              ]
                                            )
                                          : _vm.errors
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "total mt-3 pt-1 mb-4 f-14 lh-1-4"
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "icon-warning2 calc-warning mb-4"
                                                }),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "calc-warning-text"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Для расчета стоимости заполните, пожалуйста, все обязательные поля"
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        !_vm.errors
                                          ? [
                                              _vm.calc.city_from_id &&
                                              _vm.calc.city_to_id
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "path mt-3 py-1"
                                                    },
                                                    [
                                                      _c(
                                                        "c-row",
                                                        [
                                                          _c(
                                                            "c-col",
                                                            {
                                                              staticClass:
                                                                "pr-0 left",
                                                              attrs: {
                                                                cols: "auto"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "text-center"
                                                                },
                                                                [
                                                                  _c("div", {
                                                                    staticClass:
                                                                      "red-circle"
                                                                  }),
                                                                  _c("div", [
                                                                    _c("div", {
                                                                      staticClass:
                                                                        "path-line"
                                                                    })
                                                                  ]),
                                                                  _c("div", {
                                                                    staticClass:
                                                                      "red-circle"
                                                                  })
                                                                ]
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "c-col",
                                                            {
                                                              staticClass:
                                                                "pl-2 right"
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "city"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm._f(
                                                                        "dic"
                                                                      )(
                                                                        _vm.calc
                                                                          .city_from_id,
                                                                        _vm
                                                                          .options
                                                                          .citiesAll
                                                                      )
                                                                    )
                                                                  )
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "dates"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm._f(
                                                                        "date"
                                                                      )(
                                                                        _vm.calc
                                                                          .cargo_pickup_date
                                                                      )
                                                                    ) +
                                                                      " - " +
                                                                      _vm._s(
                                                                        _vm._f(
                                                                          "date"
                                                                        )(
                                                                          _vm
                                                                            .calc
                                                                            .cargo_delivery_date
                                                                        )
                                                                      )
                                                                  )
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "city"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm._f(
                                                                        "dic"
                                                                      )(
                                                                        _vm.calc
                                                                          .city_to_id,
                                                                        _vm
                                                                          .options
                                                                          .citiesAll
                                                                      )
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "inverted dimensions mx-n4 px-4 py-3 mt-3 fa fw-500"
                                                },
                                                [
                                                  _vm.response.cargo_weight
                                                    ? _c("i", {
                                                        staticClass:
                                                          "icon-cargo text-gray-300"
                                                      })
                                                    : _vm._e(),
                                                  _vm.response.cargo_weight
                                                    ? _c(
                                                        "span",
                                                        { staticClass: "ml-2" },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.response
                                                                  .cargo_weight
                                                              ) +
                                                              " кг"
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm.response.cargo_volume
                                                    ? _c("i", {
                                                        staticClass:
                                                          "icon-fullscreen text-gray-300 ml-3"
                                                      })
                                                    : _vm._e(),
                                                  _vm.response.cargo_volume
                                                    ? _c(
                                                        "span",
                                                        { staticClass: "ml-2" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.response
                                                                .cargo_volume
                                                            ) + " м"
                                                          ),
                                                          _c("sup", [
                                                            _vm._v("3")
                                                          ])
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "mt-3" },
                                                [
                                                  _vm.response.pickup_cost > 0
                                                    ? _c(
                                                        "c-row",
                                                        { staticClass: "my-2" },
                                                        [
                                                          _c(
                                                            "c-col",
                                                            {
                                                              staticClass:
                                                                "text-gray-300 fw-500"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Забрать груз"
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "c-col",
                                                            {
                                                              staticClass:
                                                                "text-primary fw-500",
                                                              attrs: {
                                                                cols: "auto"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "₽ " +
                                                                  _vm._s(
                                                                    _vm._f(
                                                                      "parts"
                                                                    )(
                                                                      _vm._f(
                                                                        "abs"
                                                                      )(
                                                                        _vm
                                                                          .response
                                                                          .pickup_cost
                                                                      )
                                                                    )
                                                                  )
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm.response.transport_cost >
                                                  0
                                                    ? _c(
                                                        "c-row",
                                                        { staticClass: "my-2" },
                                                        [
                                                          _c(
                                                            "c-col",
                                                            {
                                                              staticClass:
                                                                "text-gray-300 fw-500"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Перевозка"
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "c-col",
                                                            {
                                                              staticClass:
                                                                "text-primary fw-500",
                                                              attrs: {
                                                                cols: "auto"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "₽ " +
                                                                  _vm._s(
                                                                    _vm._f(
                                                                      "parts"
                                                                    )(
                                                                      _vm._f(
                                                                        "abs"
                                                                      )(
                                                                        _vm
                                                                          .response
                                                                          .transport_cost
                                                                      )
                                                                    )
                                                                  )
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm.response
                                                    .cargo_processing_cost > 0
                                                    ? _c(
                                                        "c-row",
                                                        { staticClass: "my-2" },
                                                        [
                                                          _c(
                                                            "c-col",
                                                            {
                                                              staticClass:
                                                                "text-gray-300 fw-500"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Обработка груза"
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "c-col",
                                                            {
                                                              staticClass:
                                                                "text-primary fw-500",
                                                              attrs: {
                                                                cols: "auto"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "₽ " +
                                                                  _vm._s(
                                                                    _vm._f(
                                                                      "parts"
                                                                    )(
                                                                      _vm._f(
                                                                        "abs"
                                                                      )(
                                                                        _vm
                                                                          .response
                                                                          .cargo_processing_cost
                                                                      )
                                                                    )
                                                                  )
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm.response.delivery_cost > 0
                                                    ? _c(
                                                        "c-row",
                                                        { staticClass: "my-2" },
                                                        [
                                                          _c(
                                                            "c-col",
                                                            {
                                                              staticClass:
                                                                "text-gray-300 fw-500"
                                                            },
                                                            [_vm._v("Доставка")]
                                                          ),
                                                          _c(
                                                            "c-col",
                                                            {
                                                              staticClass:
                                                                "text-primary fw-500",
                                                              attrs: {
                                                                cols: "auto"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "₽ " +
                                                                  _vm._s(
                                                                    _vm._f(
                                                                      "parts"
                                                                    )(
                                                                      _vm._f(
                                                                        "abs"
                                                                      )(
                                                                        _vm
                                                                          .response
                                                                          .delivery_cost
                                                                      )
                                                                    )
                                                                  )
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm.calcServicesCost > 0
                                                    ? _c(
                                                        "c-row",
                                                        { staticClass: "my-2" },
                                                        [
                                                          _c(
                                                            "c-col",
                                                            {
                                                              staticClass:
                                                                "text-gray-300 fw-500"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Доп. услуги"
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "c-col",
                                                            {
                                                              staticClass:
                                                                "text-primary fw-500",
                                                              attrs: {
                                                                cols: "auto"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "₽ " +
                                                                  _vm._s(
                                                                    _vm._f(
                                                                      "parts"
                                                                    )(
                                                                      _vm._f(
                                                                        "abs"
                                                                      )(
                                                                        _vm.calcServicesCost
                                                                      )
                                                                    )
                                                                  )
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  Math.abs(
                                                    _vm.response
                                                      .insurance_service_cost
                                                  ) > 0
                                                    ? _c(
                                                        "c-row",
                                                        { staticClass: "my-2" },
                                                        [
                                                          _c(
                                                            "c-col",
                                                            {
                                                              staticClass:
                                                                "text-gray-300 fw-500"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Страхование груза"
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "c-col",
                                                            {
                                                              staticClass:
                                                                "text-primary fw-500",
                                                              attrs: {
                                                                cols: "auto"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "₽ " +
                                                                  _vm._s(
                                                                    _vm._f(
                                                                      "parts"
                                                                    )(
                                                                      _vm._f(
                                                                        "abs"
                                                                      )(
                                                                        _vm
                                                                          .response
                                                                          .insurance_service_cost
                                                                      )
                                                                    )
                                                                  )
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm.response.packing_cost > 0
                                                    ? _c(
                                                        "c-row",
                                                        { staticClass: "my-2" },
                                                        [
                                                          _c(
                                                            "c-col",
                                                            {
                                                              staticClass:
                                                                "text-gray-300 fw-500"
                                                            },
                                                            [_vm._v("Упаковка")]
                                                          ),
                                                          _c(
                                                            "c-col",
                                                            {
                                                              staticClass:
                                                                "text-primary fw-500",
                                                              attrs: {
                                                                cols: "auto"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "₽ " +
                                                                  _vm._s(
                                                                    _vm._f(
                                                                      "parts"
                                                                    )(
                                                                      _vm._f(
                                                                        "abs"
                                                                      )(
                                                                        _vm
                                                                          .response
                                                                          .packing_cost
                                                                      )
                                                                    )
                                                                  )
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm.response.thermo_cost >= 0
                                                    ? _c(
                                                        "c-row",
                                                        { staticClass: "my-2" },
                                                        [
                                                          _c(
                                                            "c-col",
                                                            {
                                                              staticClass:
                                                                "text-gray-300 fw-500"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Терморежим +5С - +25С"
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "c-col",
                                                            {
                                                              staticClass:
                                                                "fw-500",
                                                              class: {
                                                                "text-primary":
                                                                  _vm.response
                                                                    .thermo_cost >
                                                                  0,
                                                                "text-gray-300":
                                                                  _vm.response
                                                                    .thermo_cost <=
                                                                  0
                                                              },
                                                              attrs: {
                                                                cols: "auto"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "₽ " +
                                                                  _vm._s(
                                                                    _vm._f(
                                                                      "parts"
                                                                    )(
                                                                      _vm._f(
                                                                        "abs"
                                                                      )(
                                                                        _vm
                                                                          .response
                                                                          .thermo_cost
                                                                      )
                                                                    )
                                                                  )
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            ]
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          { staticClass: "d-none d-md-block" },
                                          [
                                            _c(
                                              "c-button",
                                              {
                                                staticClass: "white w-100 mt-3",
                                                on: { click: _vm.getSpec }
                                              },
                                              [
                                                _vm._v(
                                                  "Получить спецпредложение"
                                                )
                                              ]
                                            ),
                                            _vm.blocks.calc.filled &&
                                            !_vm.isBlockInvalid("calc")
                                              ? _c(
                                                  "c-button",
                                                  {
                                                    staticClass:
                                                      "white w-100 mt-3",
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.getPrintForm()
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("Скачать расчет")]
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "d-block d-md-none" },
                                          [
                                            _c(
                                              "c-button",
                                              {
                                                staticClass: "w-100 mt-3",
                                                attrs: {
                                                  disabled:
                                                    !_vm.isFormValid ||
                                                    _vm.sending
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.check("payer")
                                                  }
                                                }
                                              },
                                              [
                                                !_vm.isFormValid
                                                  ? _c("span", [
                                                      _vm._v(
                                                        "Чтобы продолжить, заполните данные"
                                                      )
                                                    ])
                                                  : _c("span", [
                                                      _vm._v("Отправить заявку")
                                                    ])
                                              ]
                                            ),
                                            !_vm.backlog
                                              ? _c(
                                                  "c-button",
                                                  {
                                                    staticClass:
                                                      "w-100 white mt-3",
                                                    on: {
                                                      click: function($event) {
                                                        _vm.backlog = true
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Поручить оформление менеджеру"
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.backlog
                                              ? _c(
                                                  "div",
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "mt-3" },
                                                      [
                                                        _c("c-input", {
                                                          directives: [
                                                            {
                                                              name: "mask",
                                                              rawName: "v-mask",
                                                              value: {
                                                                mask:
                                                                  "+79999999999"
                                                              },
                                                              expression:
                                                                "{mask: '+79999999999'}"
                                                            }
                                                          ],
                                                          attrs: {
                                                            subclass: "large",
                                                            placeholder:
                                                              "Телефон"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.calc
                                                                .backlog_phone,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.calc,
                                                                "backlog_phone",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "calc.backlog_phone"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "c-button",
                                                      {
                                                        staticClass:
                                                          "w-100 mt-3 backlog-button",
                                                        attrs: {
                                                          disabled:
                                                            _vm.$v.backlog
                                                              .$invalid
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.sendBacklog()
                                                          }
                                                        }
                                                      },
                                                      [_vm._v("Отправить")]
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        ),
                                        _vm.$viewer.logged
                                          ? [
                                              _c(
                                                "c-button",
                                                {
                                                  staticClass: "w-100 mt-3",
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.saveRequest()
                                                    }
                                                  }
                                                },
                                                [_vm._v("Сохранить расчет")]
                                              ),
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "btn btn-link f-14 fw-500 w-100 mt-3",
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.openSavedRequests()
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "К списку сохраненных расчетов"
                                                  )
                                                ]
                                              )
                                            ]
                                          : _vm._e()
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                                !_vm.response.is_calc_standart
                                  ? _c(
                                      "div",
                                      { staticClass: "info" },
                                      [
                                        _c(
                                          "c-row",
                                          [
                                            _c(
                                              "c-col",
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "text-center"
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "icon-support text-gray-300"
                                                    })
                                                  ]
                                                ),
                                                _c(
                                                  "h4",
                                                  {
                                                    staticClass:
                                                      "text-center mt-3"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Нестандартная перевозка"
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "text-center text-muted mt-3 f-14"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "По указанным данным требуется индивидуальный расчёт. Пожалуйста, свяжитесь с менеджером для расчета стоимости доставки."
                                                    )
                                                  ]
                                                ),
                                                _c("c-input", {
                                                  directives: [
                                                    {
                                                      name: "mask",
                                                      rawName: "v-mask",
                                                      value: {
                                                        mask: "+79999999999"
                                                      },
                                                      expression:
                                                        "{mask: '+79999999999'}"
                                                    }
                                                  ],
                                                  staticClass: "mt-3",
                                                  attrs: {
                                                    subclass: "large",
                                                    placeholder:
                                                      "Оставьте ваш телефон здесь"
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.calc.backlog_phone,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.calc,
                                                        "backlog_phone",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "calc.backlog_phone"
                                                  }
                                                }),
                                                _c(
                                                  "c-button",
                                                  {
                                                    staticClass: "w-100 mt-3",
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.sendBacklog()
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("Отправить запрос")]
                                                ),
                                                _c(
                                                  "c-button",
                                                  {
                                                    staticClass:
                                                      "white w-100 mt-3",
                                                    on: { click: _vm.getSpec }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Получить спецпредложение"
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ])
                ],
                1
              )
            : _c("preloader", { staticClass: "py-5 my-5" }),
          _vm.modals.badConnection
            ? _c(
                "div",
                {
                  staticClass: "modal fade",
                  class: { show: _vm.modals.badConnection },
                  attrs: {
                    tabindex: "-1",
                    role: "dialog",
                    "data-backdrop": "false",
                    "data-keyboard": "false"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "modal-dialog-centered modal-bad-connection",
                      attrs: { role: "document" }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "modal-content connectionerr-container mx-auto"
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "modal-body connectionerr-box" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "connectionerr-title mb-2 mt-4"
                                },
                                [_vm._v("Нет соединения")]
                              ),
                              _c(
                                "div",
                                { staticClass: "connectionerr-text mb-4" },
                                [_vm._v("Пожалуйста, повторите запрос")]
                              ),
                              _c("i", {
                                staticClass:
                                  "icon-connection-error connectionerr-icon mb-4"
                              }),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary mt-3 mb-4",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function($event) {
                                      _vm.modals.badConnection = false
                                      _vm.calculate()
                                    }
                                  }
                                },
                                [_vm._v("Повторить запрос")]
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  )
                ]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }