<template lang="pug">
  div
    preloader.mt-3(v-if="loading")
    template(v-else)
      c-row.my-2.align-items-center
        c-col.col-auto.f-14(v-if="pageInfo.itemsCount > 0")
          | {{pageInfo.itemsCount}} {{pageInfo.itemsCount | plural(pluralResults)}}
        c-col.text-right.d-none.d-md-block(v-if="pageInfo.pagesCount > 1")
          pagination(:count="pageInfo.pagesCount", v-model="pageInfo.currentPage")
      .mt-3
        slot(name="list", :items="items")
          list(:items="items")
            slot(slot-scope="{item, index}", :item="item", :index="index")
        template(v-if="!items.length")
          .text-center
            | Список пуст.
      .my-3.shadow-box.d-block.d-md-none.text-center(v-if="pageInfo.pagesCount > 1")
        pagination(:count="pageInfo.pagesCount", v-model="pageInfo.currentPage")
</template>

<script>
  import _ from 'lodash';
  const PLURAL_RESULTS = {
    base: 'результат',
    one: '',
    few: 'а',
    many: 'ов',
    other: 'а'
  };
  let defaultPageInfo = {
    itemsCount: 0,
    pagesCount: 1,
    currentPage: 1
  };
  export default {
    props: {
      perPage: {
        type: Number,
        default: 20
      },
      load: {
        type: Function,
        default() {
          return new Promise(resolve => resolve([]));
        }
      }
    },
    data: () => ({
      items: [],
      pageInfo: _.cloneDeep(defaultPageInfo),
      loading: false,
      pluralResults: PLURAL_RESULTS
    }),
    watch: {
      'pageInfo.currentPage': function(value) {
        this.loadItems();
      }
    },
    methods: {
      loadItems() {
        if (!this.loading) {
          this.loading = true;
          Promise.resolve(this.load(this.pageInfo.currentPage))
            .then(({items, pageInfo}) => {
              this.items = items || [];
              this.pageInfo = pageInfo || _.cloneDeep(defaultPageInfo);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      },
      reload() {
        this.pageInfo.currentPage = 1;
        this.loadItems();
      },
      remove(item) {
        this.items = _.filter(this.items, fitem => fitem != item);
        this.pageInfo.itemsCount--;
      }
    },
    mounted() {
      this.loadItems();
    }
  };
</script>