var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("settings-menu"),
      _c(
        "back",
        { staticClass: "mt-3 text-gray-300", attrs: { name: "payers-list" } },
        [_vm._v("Назад к списку")]
      ),
      _vm.loading
        ? _c("preloader", { staticClass: "mt-5" })
        : [
            _c("div", { staticClass: "f-18 fw-500 text-gray-800 mt-2" }, [
              _vm._v("Редактирование плательщика")
            ]),
            _c(
              "c-row",
              [
                _c(
                  "c-col",
                  { staticClass: "col-12 col-md-6 col-lg-4" },
                  [
                    _c(
                      "div",
                      { staticClass: "mt-4 mb-2 f-16 text-gray-800 fw-500" },
                      [_vm._v("Общая информация")]
                    ),
                    _vm.error
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "alert alert-danger mb-0 f-12 d-block mt-3"
                          },
                          [_vm._v(_vm._s(_vm.error))]
                        )
                      : _vm._e(),
                    _c("c-input", {
                      staticClass: "mt-1",
                      attrs: {
                        placeholder: "Наименование плательщика",
                        disabled: true
                      },
                      model: {
                        value: _vm.payer.Name,
                        callback: function($$v) {
                          _vm.$set(_vm.payer, "Name", $$v)
                        },
                        expression: "payer.Name"
                      }
                    }),
                    _c("c-input", {
                      staticClass: "mt-1",
                      attrs: { placeholder: "Город", disabled: true },
                      model: {
                        value: _vm.payer.TownName,
                        callback: function($$v) {
                          _vm.$set(_vm.payer, "TownName", $$v)
                        },
                        expression: "payer.TownName"
                      }
                    }),
                    _c("c-input", {
                      staticClass: "mt-1",
                      attrs: {
                        placeholder: "Контактное лицо плательщика",
                        disabled: true
                      },
                      model: {
                        value: _vm.payer.Contact,
                        callback: function($$v) {
                          _vm.$set(_vm.payer, "Contact", $$v)
                        },
                        expression: "payer.Contact"
                      }
                    }),
                    _c("c-input", {
                      staticClass: "mt-1",
                      attrs: { placeholder: "Телефон", disabled: true },
                      model: {
                        value: _vm.payer.Phone,
                        callback: function($$v) {
                          _vm.$set(_vm.payer, "Phone", $$v)
                        },
                        expression: "payer.Phone"
                      }
                    }),
                    _c("c-input", {
                      staticClass: "mt-1",
                      attrs: { placeholder: "Адрес", disabled: true },
                      model: {
                        value: _vm.payer.Address,
                        callback: function($$v) {
                          _vm.$set(_vm.payer, "Address", $$v)
                        },
                        expression: "payer.Address"
                      }
                    }),
                    _c("c-input", {
                      staticClass: "mt-1",
                      attrs: { placeholder: "E-mail", disabled: true },
                      model: {
                        value: _vm.payer.Email,
                        callback: function($$v) {
                          _vm.$set(_vm.payer, "Email", $$v)
                        },
                        expression: "payer.Email"
                      }
                    }),
                    _c(
                      "div",
                      { staticClass: "mt-4 mb-2 f-16 text-gray-800 fw-500" },
                      [_vm._v("Собственность")]
                    ),
                    _c("c-input", {
                      staticClass: "mt-1",
                      attrs: {
                        placeholder: "Форма собственности",
                        disabled: true
                      },
                      model: {
                        value: _vm.payer.AgentForm,
                        callback: function($$v) {
                          _vm.$set(_vm.payer, "AgentForm", $$v)
                        },
                        expression: "payer.AgentForm"
                      }
                    }),
                    _c("c-input", {
                      staticClass: "mt-1",
                      attrs: { placeholder: "Форма оплаты", disabled: true },
                      model: {
                        value: _vm.payer.PayForm,
                        callback: function($$v) {
                          _vm.$set(_vm.payer, "PayForm", $$v)
                        },
                        expression: "payer.PayForm"
                      }
                    }),
                    _c(
                      "div",
                      { staticClass: "mt-4 mb-2 f-16 text-gray-800 fw-500" },
                      [_vm._v("Настройка информирования")]
                    ),
                    _c("c-input", {
                      directives: [
                        {
                          name: "mask",
                          rawName: "v-mask",
                          value: { mask: "+99999999999" },
                          expression: "{mask: '+99999999999'}"
                        }
                      ],
                      staticClass: "mt-1",
                      attrs: { placeholder: "Телефон для информирования" },
                      model: {
                        value: _vm.payer.AddPhone,
                        callback: function($$v) {
                          _vm.$set(_vm.payer, "AddPhone", $$v)
                        },
                        expression: "payer.AddPhone"
                      }
                    }),
                    _c(
                      "div",
                      { staticClass: "shadow-box mt-3" },
                      [
                        _c(
                          "c-checkbox",
                          {
                            model: {
                              value: _vm.payer.AddPhoneActive,
                              callback: function($$v) {
                                _vm.$set(_vm.payer, "AddPhoneActive", $$v)
                              },
                              expression: "payer.AddPhoneActive"
                            }
                          },
                          [_vm._v("Включить уведомления")]
                        )
                      ],
                      1
                    ),
                    _c("c-input", {
                      staticClass: "mt-3 mt-md-2",
                      attrs: { placeholder: "E-mail для информирования" },
                      model: {
                        value: _vm.payer.AddEmail,
                        callback: function($$v) {
                          _vm.$set(_vm.payer, "AddEmail", $$v)
                        },
                        expression: "payer.AddEmail"
                      }
                    }),
                    _c(
                      "div",
                      { staticClass: "shadow-box mt-3" },
                      [
                        _c(
                          "c-checkbox",
                          {
                            model: {
                              value: _vm.payer.AddEmailActive,
                              callback: function($$v) {
                                _vm.$set(_vm.payer, "AddEmailActive", $$v)
                              },
                              expression: "payer.AddEmailActive"
                            }
                          },
                          [_vm._v("Включить уведомления")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "c-button",
                      {
                        staticClass: "mt-4 w-100 w-md-auto",
                        attrs: {
                          disabled: _vm.$v.payer.$invalid || _vm.saving
                        },
                        on: { click: _vm.save }
                      },
                      [_vm._v("Сохранить")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }