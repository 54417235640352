var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "labeled",
    {
      staticClass: "d-block c-datepicker",
      class: { "is-invalid": _vm.isInvalid },
      attrs: { label: _vm.label }
    },
    [
      _c(
        "date-picker",
        {
          attrs: {
            value: _vm.value,
            lang: "ru",
            format: "DD.MM.YYYY",
            clearable: false,
            placeholder: _vm.placeholder,
            "disabled-days": _vm.disabledDays,
            "first-day-of-week": 1,
            range: _vm.range,
            shortcuts: false
          },
          on: { change: _vm.input }
        },
        [
          _c("template", { slot: "calendar-icon" }, [
            _c("i", { staticClass: "icon-calendar text-gray-300" })
          ]),
          _vm._v(" "),
          _vm.range
            ? _c("template", { slot: "header" }, [
                _c("div", { staticClass: "d-md-flex mt-2 d-none" }, [
                  _c("div", { staticClass: "col-6 text-center" }, [
                    _c("span", [_vm._v("Начало периода")])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-6 text-center" }, [
                    _c("span", [_vm._v("Конец периода")])
                  ])
                ])
              ])
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }