import _ from 'lodash';
import Router from 'vue-router';

let loadPage = path => require(`./pages/${path}/page.vue`).default;

const PAGES = {
  container: loadPage('container'),
  mobile: {
    list: loadPage('mobile/list'),
    view: loadPage('mobile/view'),
  },
  desktop: {
    list: loadPage('desktop/list')
  }
};

const CONFIG = {
  mode: 'history',
  scrollBehavior: () => ({
    x: 0,
    y: 0
  }),
  linkActiveClass: 'active',
  routes: [
    {
      path: '/instructions',
      component: PAGES.container,
      children: [
        {
          path: '',
          name: 'desktop.list',
          component: PAGES.desktop.list,
        },
        {
          path: 'mobile',
          name: 'instructions-mobile',
          component: PAGES.container,
          children: [
            {
              path: 'list',
              name: 'mobile.list',
              component: PAGES.mobile.list,
            },
            {
              path: 'instructions',
              name: 'instructions-category-container',
              component: PAGES.container,
              children: [
                {
                  path: ':id',
                  name: 'mobile.instructions',
                  component: PAGES.mobile.instructions
                }
              ]
            },
            {
              path: ':category_id',
              name: 'mobile.view',
              component: PAGES.mobile.view
            }
          ]
        }
      ]
    },
    {
      path: '*',
      component: PAGES.empty
    }
  ]
};

let router = new Router(CONFIG);

export default router;