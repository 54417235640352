<template>
  <labeled :label="label" class="c-input__cont" :class="{'is-invalid': isInvalid, disabled: disabled}">
    <div class="row mx-0">
      <div class="col px-0">
        <textarea :value="value" class="c-input" :class="subclass" :placeholder="placeholder" @input="input" @change="input" :disabled="disabled"></textarea>
      </div>
    </div>
  </labeled>
</template>

<script>
  import _ from 'lodash';
  import ValidateMixin from './mixin-validate';

  export default {
    mixins: [ValidateMixin],
    inject: ['validation'],
    props: {
      value: [String, Number, Boolean],
      placeholder: String,
      disabled: Boolean,
      subclass: String
    },
    computed: {
      label() {
        return this.value ? this.placeholder : '';
      }
    },
    methods: {
      input($event) {
        let value = $event.target.value;
        this.$emit('input', value);
      }
    }
  }
</script>