<template lang="pug">
  div
    h3
      | Восстановление пароля
    c-row.mt-3
      c-col.col-12.col-lg-4.col-sm-6
        template(v-if="!success")
          div
            | Если вы забыли пароль, введите логин или E-mail.
          div
            | Контрольная строка для смены пароля, а также ваши регистрационные данные, будут высланы вам по E-mail.
          c-input.mt-3(placeholder="E-mail", v-model="email")
          .alert.alert-danger.mb-0.f-12.d-block.mt-3(v-if="errors")
            | {{errors}}
          c-button.w-100.mt-3(:disabled="$v.email.$invalid || sending", @click="send")
            | Восстановить
        template(v-else)
          div
            | Пароль отправлен на email адрес.
          c-button.w-100.mt-3(:to="{name: 'login'}")
            | Назад
</template>

<script>
  import _ from 'lodash';
  import {required, email} from 'vuelidate/lib/validators';
  import api from '@/js/api';
  export default {
    data: () => ({
      email: null,
      sending: false,
      success: false,
      errors: false
    }),
    provide() {
      return {
        validation: this.$v
      };
    },
    validations: {
        email: {required, email}
    },
    methods: {
      send() {
        this.sending = true;
        api.resetPassword({email: this.email})
          .then(response => {
            let data = _.get(response, 'data') || {};
            this.$notify({
              group: 'foo',
              clean: true
            })
            if (data.status == 200) {
              this.success = true;
            }
            if (data.error) {
              this.errors = data.error;
            }
          }, err => {
            if (err.response.status === 504) {
              this.$notify({
                group: 'foo',
                type: 'error',
                duration: -1,
                title: 'Ошибка',
                text: 'Сервер временно недоступен'
              })
            }
          })
          .finally(() => {
            this.sending = false;
          });
      }
    }
  }
</script>