<template lang="pug">
  div
    back.text-gray-300(path="/calculator")
      | Назад
    preloader.mt-5(v-if="loading")
    template(v-else)
      .f-18.fw-500.text-gray-800.mt-2
        | Сохраненные расчеты
      .mt-3
        paginate(:load="load", ref="list")
          item-request-saved.mb-3.hoverable(slot-scope="{item, index}", :item="item", @show="show", @remove="remove")
</template>

<script>
  import _ from 'lodash';
  import api from '@/js/api';

  export default {
    data: () => ({
      loading: false
    }),
    methods: {
      load(page = 1) {
        return api.getCalcList(page, 4)
          .then(response => {
            this.$notify({
              group: 'foo',
              clean: true
            })
            let data = _.get(response, 'data') || {};
            let items = _.get(data, 'Page') || [];

            return {
              items,
              pageInfo: {
                itemsCount: data.ItemsCount,
                pagesCount: data.PageCount,
                currentPage: data.CurrPage
              }
            };
          }, err => {
            if (err.response.status === 504) {
              this.$notify({
                group: 'foo',
                type: 'error',
                duration: -1,
                title: 'Ошибка',
                text: 'Сервер временно недоступен'
              })
            }
          });
      },
      show(item) {
        window.location.href = `/calculator/${item.calc_fields.id}`;
      },
      remove(item) {
        api.deleteCalc(item.calc_fields.id)
          .then(({data}) => {
            // console.log(data);
            this.$refs.list.remove(item);
          });
      }
    },
    mounted() {
      
    }
  }
</script>
