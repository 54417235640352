<template lang="pug">
  div.pb-4
    .f-18.fw-500.text-gray-800
      | Отчеты
    preloader.mt-3(v-if="loading")
    c-row.mt-4(v-else)
      c-col.col-12.col-md-8.col-lg-8.d-none.d-md-block
        paginate(:load="load", ref="list")
          template(slot="list", slot-scope="{items}")
            c-table(:data="items", v-if="items.length")
              template(slot="empty")
                | Список пуст.
              c-column(prop="OrderShortNo", label="Номер заявки", v-if="appliedColumns.OrderShortNo")
              c-column(prop="CrtDate", label="Дата заявки", v-if="appliedColumns.CrtDate")
                template(slot-scope="{row}")
                  | {{row.CrtDate | date('DD.MM.YY')}}
              c-column(prop="ClientDocsNo", label="№ сопроводительных документов", v-if="appliedColumns.ClientDocsNo")
              c-column(prop="ClientOrderNo", label="Номер заказа", v-if="appliedColumns.ClientOrderNo")
              c-column(prop="CurrCargoState", label="Статус", v-if="appliedColumns.CurrCargoState")
              c-column(prop="SenderTownName", label="Город отправки", v-if="appliedColumns.SenderTownName")
              c-column(prop="RecipTownName", label="Город получения", v-if="appliedColumns.RecipTownName")
              c-column(prop="CargoContent", label="Наименование груза", v-if="appliedColumns.CargoContent")
              c-column(prop="FactWeight", label="Общий вес", v-if="appliedColumns.FactWeight")
              c-column(prop="FactVolume", label="Общий вес", v-if="appliedColumns.FactVolume")
                template(slot-scope="{row}")
                  | {{row.FactVolume | toFixed}}
              c-column(prop="FactPlace", label="Количество мест", v-if="appliedColumns.FactPlace")
              c-column(prop="PickAddress", label="Адрес забора", v-if="appliedColumns.PickAddress", width="200")
              c-column(prop="DelivAddress", label="Адрес доставки", v-if="appliedColumns.DelivAddress", width="200")
              c-column(prop="SenderName", label="Отправитель", v-if="appliedColumns.SenderName")
              c-column(prop="RecipName", label="Получатель", v-if="appliedColumns.RecipName")
              c-column(prop="PayerName", label="Плательщик", v-if="appliedColumns.PayerName")
              c-column(prop="PayResult", label="Статус оплаты", v-if="appliedColumns.PayResult")
              c-column(prop="InsuranceValue", label="Стоимость", v-if="appliedColumns.InsuranceValue")
                template(slot-scope="{row}")
                  | {{row.InsuranceValue | parts}} ₽
              c-column(prop="TransCost", label="Стоимость", v-if="appliedColumns.TransCost")
                template(slot-scope="{row}")
                  | {{row.TransCost | parts}} ₽
              c-column(prop="CompleteDate", label="Дата выдачи", v-if="appliedColumns.CompleteDate")
                template(slot-scope="{row}")
                  | {{row.CompleteDate | date('DD.MM.YY')}}
      c-col.col-12.col-md-4.col-lg-4
        .border.border-2.border-gray-200
          .bg-gray-100.px-3.py-3
            c-row.mx-0
              c-col.p-0.f-16.fw-500.text-gray-800
                | Настройки
              c-col.p-0.col-auto.f-12.text-gray-300
                .h-100.fa.hoverable(@click="clearFilters")
                  | Очистить все
          .p-3
            c-datepicker(placeholder="Период", v-model="filters.period", :range="true")
            //- c-select.mt-2(placeholder="Статус заявки", v-model="filters.CurrCargoState", :options="options.StatusList")
            c-select-list.mt-2(placeholder="Статус заявки", v-model="filters.CurrCargoState", :options="options.StatusList")
            hr.border.mt-3.mx-n3.border-gray-200.d-none.d-md-block
            .mt-3.mb-2.f-14.f-md-12.fw-500.text-gray-800
              | Поля
            .shadow-box.mt-3.mt-md-1
              c-checkbox(v-model="columns.OrderShortNo")
                .fw-500.fw-md-400
                  | Номер заявки
            .shadow-box.mt-3.mt-md-1
              c-checkbox(v-model="columns.CrtDate")
                .fw-500.fw-md-400
                  | Дата заявки
            .shadow-box.mt-3.mt-md-1
              c-checkbox(v-model="columns.ClientDocsNo")
                .fw-500.fw-md-400
                  | № Сопроводительных документов
            .shadow-box.mt-3.mt-md-1
              c-checkbox(v-model="columns.ClientOrderNo")
                .fw-500.fw-md-400
                  | Номер заказа
            .shadow-box.mt-3.mt-md-1
              c-checkbox(v-model="columns.CurrCargoState")
                .fw-500.fw-md-400
                  | Статус
            .shadow-box.mt-3.mt-md-1
              c-checkbox(v-model="columns.SenderTownName")
                .fw-500.fw-md-400
                  | Город отправки
            .shadow-box.mt-3.mt-md-1
              c-checkbox(v-model="columns.RecipTownName")
                .fw-500.fw-md-400
                  | Город получения
            .shadow-box.mt-3.mt-md-1
              c-checkbox(v-model="columns.CargoContent")
                .fw-500.fw-md-400
                  | Наименование груза
            .shadow-box.mt-3.mt-md-1
              c-checkbox(v-model="columns.FactWeight")
                .fw-500.fw-md-400
                  | Общий вес
            .shadow-box.mt-3.mt-md-1
              c-checkbox(v-model="columns.FactVolume")
                .fw-500.fw-md-400
                  | Общий объем
            .shadow-box.mt-3.mt-md-1
              c-checkbox(v-model="columns.FactPlace")
                .fw-500.fw-md-400
                  | Количество мест
            .shadow-box.mt-3.mt-md-1
              c-checkbox(v-model="columns.PickAddress")
                .fw-500.fw-md-400
                  | Адрес забора
            .shadow-box.mt-3.mt-md-1
              c-checkbox(v-model="columns.DelivAddress")
                .fw-500.fw-md-400
                  | Адрес доставки
            .shadow-box.mt-3.mt-md-1
              c-checkbox(v-model="columns.SenderName")
                .fw-500.fw-md-400
                  | Отправитель
            .shadow-box.mt-3.mt-md-1
              c-checkbox(v-model="columns.RecipName")
                .fw-500.fw-md-400
                  | Получатель
            .shadow-box.mt-3.mt-md-1
              c-checkbox(v-model="columns.PayerName")
                .fw-500.fw-md-400
                  | Плательщик
            .shadow-box.mt-3.mt-md-1
              c-checkbox(v-model="columns.PayResult")
                .fw-500.fw-md-400
                  | Статус оплаты
            .shadow-box.mt-3.mt-md-1
              c-checkbox(v-model="columns.TransCost")
                .fw-500.fw-md-400
                  | Стоимость
            .shadow-box.mt-3.mt-md-1
              c-checkbox(v-model="columns.InsuranceValue")
                .fw-500.fw-md-400
                  | Страховка
            .shadow-box.mt-3.mt-md-1
              c-checkbox(v-model="columns.CompleteDate")
                .fw-500.fw-md-400
                  | Дата выдачи
            hr.border.mx-n3.border-gray-200.d-none.d-md-block
            .mt-3.mb-2.f-14.f-md-12.fw-500.text-gray-800
              | Группировка
            .shadow-box.mt-3.mt-md-1(v-if="columns.CurrCargoState")
              c-checkbox(v-model="groupBy.CurrCargoState")
                .fw-500.fw-md-400
                  | Статус
            .shadow-box.mt-3.mt-md-1(v-if="columns.SenderTownName")
              c-checkbox(v-model="groupBy.SenderTownName")
                .fw-500.fw-md-400
                  | Город отправки
            .shadow-box.mt-3.mt-md-1(v-if="columns.RecipTownName")
              c-checkbox(v-model="groupBy.RecipTownName")
                .fw-500.fw-md-400
                  | Город получения
            .shadow-box.mt-3.mt-md-1(v-if="columns.CargoContent")
              c-checkbox(v-model="groupBy.CargoContent")
                .fw-500.fw-md-400
                  | Наименование груза
            .shadow-box.mt-3.mt-md-1(v-if="columns.PickAddress")
              c-checkbox(v-model="groupBy.PickAddress")
                .fw-500.fw-md-400
                  | Адрес забора
            .shadow-box.mt-3.mt-md-1(v-if="columns.DelivAddress")
              c-checkbox(v-model="groupBy.DelivAddress")
                .fw-500.fw-md-400
                  | Адрес доставки
            .shadow-box.mt-3.mt-md-1(v-if="columns.SenderName")
              c-checkbox(v-model="groupBy.SenderName")
                .fw-500.fw-md-400
                  | Отправитель
            .shadow-box.mt-3.mt-md-1(v-if="columns.RecipName")
              c-checkbox(v-model="groupBy.RecipName")
                .fw-500.fw-md-400
                  | Получатель
            .shadow-box.mt-3.mt-md-1(v-if="columns.PayerName")
              c-checkbox(v-model="groupBy.PayerName")
                .fw-500.fw-md-400
                  | Плательщик
            .shadow-box.mt-3.mt-md-1(v-if="columns.PayResult")
              c-checkbox(v-model="groupBy.PayResult")
                .fw-500.fw-md-400
                  | Статус оплаты
            hr.border.mx-n3.border-gray-200.d-none.d-md-block
            .mt-3.mb-2.f-14.f-md-12.fw-500.text-gray-800
              | Фильтры
            c-select.mt-1(placeholder="Статус", v-model="filters.CurrCargoState", :options="options.StatusList", v-if="columns.CurrCargoState", :clearable="true")
            c-select.mt-1(placeholder="Город отправки", v-model="filters.SenderTownName", :options="options.SenderTownNameList", v-if="columns.SenderTownName", :clearable="true")
            c-select.mt-1(placeholder="Город получения", v-model="filters.RecipTownName", :options="options.RecipTownName", v-if="columns.RecipTownName", :clearable="true")
            c-select.mt-1(placeholder="Наименование груза", v-model="filters.CargoContent", :options="options.CargoContentList", v-if="columns.CargoContent", :clearable="true")
            c-select.mt-1(placeholder="Адрес забора", v-model="filters.PickAddress", :options="options.PickAddressList", v-if="columns.PickAddress", :clearable="true")
            c-select.mt-1(placeholder="Адрес доставки", v-model="filters.DelivAddress", :options="options.DelivAddressList", v-if="columns.DelivAddress", :clearable="true")
            c-select.mt-1(placeholder="Отправитель", v-model="filters.SenderName", :options="options.SenderList", v-if="columns.SenderName", :clearable="true")
            c-select.mt-1(placeholder="Получатель", v-model="filters.RecipName", :options="options.RecipList", v-if="columns.RecipName", :clearable="true")
            c-select.mt-1(placeholder="Плательщик", v-model="filters.PayerName", :options="options.PayerList", v-if="columns.PayerName", :clearable="true")
            c-select.mt-1(placeholder="Статус оплаты", v-model="filters.PayResult", :options="options.PayList", v-if="columns.PayResult", :clearable="true")
            
            c-button.mt-3.w-100.d-none.d-md-block(@click="applyFilters")
              | Сформировать отчет
            button.btn.btn-outline-primary.f-14.fw-500.w-100.mt-3.py-2(@click="createReport", :disabled="loadingReport")
              | Сохранить в Excel
            
</template>

<script>
  import _ from 'lodash';
  import api from '@/js/api';

  export default {
    data: () => ({
      items: [],
      error: null,
      options: {},
      filters: {
        period: null,
        CurrCargoState: null
      },
      appliedFilters: {},
      appliedColumns: {},
      columns: {
        OrderShortNo: true,
        CrtDate: true,
        ClientDocsNo: false,
        ClientOrderNo: false,
        CurrCargoState: false,
        SenderTownName: true,
        RecipTownName: true,
        CargoContent: false,
        FactWeight: false,
        FactPlace: false,
        FactVolume: false,
        PickAddress: false,
        DelivAddress: false,
        SenderName: false,
        RecipName: false,
        PayerName: false,
        PayResult: false,
        TransCost: true,
        InsuranceValue: false,
        CompleteDate: false
      },
      groupBy: {
        SenderTownName: false,
        RecipTownName: false,
        CargoContent: false,
        PickAddress: false,
        DelivAddress: false,
        SenderName: false,
        RecipName: false,
        PayerName: false,
        PayResult: false,
        CurrCargoState: false
      },
      loading: true,
      loadingReport: false
    }),
    provide() {
      return {validation: this.$v};
    },
    methods: {
      getFilters() {
        let baseFilters = this.appliedFilters;
        let filters = _.fromPairs(_.toPairs(_.omit(baseFilters, ['period'])).filter(value => value[1]));
        let StartDate = _.get(baseFilters, 'period[0]');
        let EndDate = _.get(baseFilters, 'period[1]');
        
        if (StartDate) {
          StartDate.setHours(StartDate.getHours() - StartDate.getTimezoneOffset()/60)
          filters.StartDate = StartDate;
        }
        if (EndDate) {
          EndDate.setHours(EndDate.getHours() - EndDate.getTimezoneOffset()/60)
          filters.EndDate = EndDate;
        }
        return filters;
      },
      getGroupBy() {
        return _.toPairs(this.columns)
          .filter(item => item[1])
          .map(item => [item[0], this.groupBy[item[0]]])
          .filter(item => item[1])
          .map(item => item[0]);
      },
      load(page = 1) {
        let filters = this.getFilters();
        let groupBy = this.getGroupBy();
        // console.log(groupBy);
        if (groupBy.length) {
          filters.groupBy = groupBy.join(',');
        }
        if (filters.CurrCargoState) {
          if (filters.CurrCargoState.length) {
            filters.CurrCargoState = filters.CurrCargoState.map(item => item.value).join(',');
          } else {
            delete filters.CurrCargoState;
          }
        }
        return api.getOrders(page, filters, 40)
          .then(response => {
            // console.log(response);
            let data = _.get(response, 'data') || {};
            let items = _.get(data, 'Page') || [];

            return {
              items,
              pageInfo: {
                itemsCount: data.ItemsCount,
                pagesCount: data.PageCount,
                currentPage: data.CurrPage
              }
            };
          }, err => {
            if (err.response.status === 504) {
              this.$notify({
                group: 'foo',
                type: 'error',
                duration: -1,
                title: 'Ошибка',
                text: 'Сервер временно недоступен'
              })
            }
          });
      },
      applyFilters() {
        this.appliedColumns = _.cloneDeep(this.columns);
        this.appliedFilters = _.cloneDeep(this.filters);
        this.$refs.list.reload();
      },
      clearFilters() {
        _.each(this.filters, (value, key) => this.filters[key] = null);
        _.each(this.groupBy, (value, key) => this.groupBy[key] = false);
      },
      createReport() {
        if (!this.loadingReport) {
          this.loadingReport = true;
          let filters = this.getFilters();
          let fields = _.filter(_.toPairs(this.columns), item => item[1]).map(item => item[0]);
          let groupBy = this.getGroupBy();
          api.createReport(filters, fields, groupBy)
            .then(({data}) => {
              this.$notify({
                group: 'foo',
                clean: true
              })
              let filename = data.filename;
              window.open(filename, '_blank');
            }, err => {
            if (err.response.status === 504) {
              this.$notify({
                group: 'foo',
                type: 'error',
                duration: -1,
                title: 'Ошибка',
                text: 'Сервер временно недоступен'
              })
            }
          })
            .finally(() => {
              this.loadingReport = false;
            });
        }
      }
    },
    mounted() {
      this.appliedColumns = _.cloneDeep(this.columns);
      this.appliedFilters = _.cloneDeep(this.filters);
      Promise.all([
        api.getOrdersDics().then(response => _.get(response, 'data') || {}),
      ]).then(([dics]) => {
        this.$notify({
          group: 'foo',
          clean: true
        })
        _.each(dics, (list, key) => {
          this.options[key] = _.map(list, value => ({title: value, value}));
        });
        this.loading = false;
      }, err => {
        if (err.response.status === 504) {
          this.$notify({
                group: 'foo',
                type: 'error',
                duration: -1,
                title: 'Ошибка',
                text: 'Сервер временно недоступен'
              })
        }
      });
    }
  }
</script>