var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pb-4" },
    [
      _c("div", { staticClass: "f-18 fw-500 text-gray-800" }, [
        _vm._v("Отчеты")
      ]),
      _vm.loading
        ? _c("preloader", { staticClass: "mt-3" })
        : _c(
            "c-row",
            { staticClass: "mt-4" },
            [
              _c(
                "c-col",
                { staticClass: "col-12 col-md-8 col-lg-8 d-none d-md-block" },
                [
                  _c("paginate", {
                    ref: "list",
                    attrs: { load: _vm.load },
                    scopedSlots: _vm._u([
                      {
                        key: "list",
                        fn: function(ref) {
                          var items = ref.items
                          return [
                            items.length
                              ? _c(
                                  "c-table",
                                  { attrs: { data: items } },
                                  [
                                    _c("template", { slot: "empty" }, [
                                      _vm._v("Список пуст.")
                                    ]),
                                    _vm.appliedColumns.OrderShortNo
                                      ? _c("c-column", {
                                          attrs: {
                                            prop: "OrderShortNo",
                                            label: "Номер заявки"
                                          }
                                        })
                                      : _vm._e(),
                                    _vm.appliedColumns.CrtDate
                                      ? _c("c-column", {
                                          attrs: {
                                            prop: "CrtDate",
                                            label: "Дата заявки"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function(ref) {
                                                  var row = ref.row
                                                  return [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("date")(
                                                          row.CrtDate,
                                                          "DD.MM.YY"
                                                        )
                                                      )
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        })
                                      : _vm._e(),
                                    _vm.appliedColumns.ClientDocsNo
                                      ? _c("c-column", {
                                          attrs: {
                                            prop: "ClientDocsNo",
                                            label:
                                              "№ сопроводительных документов"
                                          }
                                        })
                                      : _vm._e(),
                                    _vm.appliedColumns.ClientOrderNo
                                      ? _c("c-column", {
                                          attrs: {
                                            prop: "ClientOrderNo",
                                            label: "Номер заказа"
                                          }
                                        })
                                      : _vm._e(),
                                    _vm.appliedColumns.CurrCargoState
                                      ? _c("c-column", {
                                          attrs: {
                                            prop: "CurrCargoState",
                                            label: "Статус"
                                          }
                                        })
                                      : _vm._e(),
                                    _vm.appliedColumns.SenderTownName
                                      ? _c("c-column", {
                                          attrs: {
                                            prop: "SenderTownName",
                                            label: "Город отправки"
                                          }
                                        })
                                      : _vm._e(),
                                    _vm.appliedColumns.RecipTownName
                                      ? _c("c-column", {
                                          attrs: {
                                            prop: "RecipTownName",
                                            label: "Город получения"
                                          }
                                        })
                                      : _vm._e(),
                                    _vm.appliedColumns.CargoContent
                                      ? _c("c-column", {
                                          attrs: {
                                            prop: "CargoContent",
                                            label: "Наименование груза"
                                          }
                                        })
                                      : _vm._e(),
                                    _vm.appliedColumns.FactWeight
                                      ? _c("c-column", {
                                          attrs: {
                                            prop: "FactWeight",
                                            label: "Общий вес"
                                          }
                                        })
                                      : _vm._e(),
                                    _vm.appliedColumns.FactVolume
                                      ? _c("c-column", {
                                          attrs: {
                                            prop: "FactVolume",
                                            label: "Общий вес"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function(ref) {
                                                  var row = ref.row
                                                  return [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("toFixed")(
                                                          row.FactVolume
                                                        )
                                                      )
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        })
                                      : _vm._e(),
                                    _vm.appliedColumns.FactPlace
                                      ? _c("c-column", {
                                          attrs: {
                                            prop: "FactPlace",
                                            label: "Количество мест"
                                          }
                                        })
                                      : _vm._e(),
                                    _vm.appliedColumns.PickAddress
                                      ? _c("c-column", {
                                          attrs: {
                                            prop: "PickAddress",
                                            label: "Адрес забора",
                                            width: "200"
                                          }
                                        })
                                      : _vm._e(),
                                    _vm.appliedColumns.DelivAddress
                                      ? _c("c-column", {
                                          attrs: {
                                            prop: "DelivAddress",
                                            label: "Адрес доставки",
                                            width: "200"
                                          }
                                        })
                                      : _vm._e(),
                                    _vm.appliedColumns.SenderName
                                      ? _c("c-column", {
                                          attrs: {
                                            prop: "SenderName",
                                            label: "Отправитель"
                                          }
                                        })
                                      : _vm._e(),
                                    _vm.appliedColumns.RecipName
                                      ? _c("c-column", {
                                          attrs: {
                                            prop: "RecipName",
                                            label: "Получатель"
                                          }
                                        })
                                      : _vm._e(),
                                    _vm.appliedColumns.PayerName
                                      ? _c("c-column", {
                                          attrs: {
                                            prop: "PayerName",
                                            label: "Плательщик"
                                          }
                                        })
                                      : _vm._e(),
                                    _vm.appliedColumns.PayResult
                                      ? _c("c-column", {
                                          attrs: {
                                            prop: "PayResult",
                                            label: "Статус оплаты"
                                          }
                                        })
                                      : _vm._e(),
                                    _vm.appliedColumns.InsuranceValue
                                      ? _c("c-column", {
                                          attrs: {
                                            prop: "InsuranceValue",
                                            label: "Стоимость"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function(ref) {
                                                  var row = ref.row
                                                  return [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("parts")(
                                                          row.InsuranceValue
                                                        )
                                                      ) + " ₽"
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        })
                                      : _vm._e(),
                                    _vm.appliedColumns.TransCost
                                      ? _c("c-column", {
                                          attrs: {
                                            prop: "TransCost",
                                            label: "Стоимость"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function(ref) {
                                                  var row = ref.row
                                                  return [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("parts")(
                                                          row.TransCost
                                                        )
                                                      ) + " ₽"
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        })
                                      : _vm._e(),
                                    _vm.appliedColumns.CompleteDate
                                      ? _c("c-column", {
                                          attrs: {
                                            prop: "CompleteDate",
                                            label: "Дата выдачи"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function(ref) {
                                                  var row = ref.row
                                                  return [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("date")(
                                                          row.CompleteDate,
                                                          "DD.MM.YY"
                                                        )
                                                      )
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        })
                                      : _vm._e()
                                  ],
                                  2
                                )
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _c("c-col", { staticClass: "col-12 col-md-4 col-lg-4" }, [
                _c("div", { staticClass: "border border-2 border-gray-200" }, [
                  _c(
                    "div",
                    { staticClass: "bg-gray-100 px-3 py-3" },
                    [
                      _c(
                        "c-row",
                        { staticClass: "mx-0" },
                        [
                          _c(
                            "c-col",
                            { staticClass: "p-0 f-16 fw-500 text-gray-800" },
                            [_vm._v("Настройки")]
                          ),
                          _c(
                            "c-col",
                            { staticClass: "p-0 col-auto f-12 text-gray-300" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "h-100 fa hoverable",
                                  on: { click: _vm.clearFilters }
                                },
                                [_vm._v("Очистить все")]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "p-3" },
                    [
                      _c("c-datepicker", {
                        attrs: { placeholder: "Период", range: true },
                        model: {
                          value: _vm.filters.period,
                          callback: function($$v) {
                            _vm.$set(_vm.filters, "period", $$v)
                          },
                          expression: "filters.period"
                        }
                      }),
                      _c("c-select-list", {
                        staticClass: "mt-2",
                        attrs: {
                          placeholder: "Статус заявки",
                          options: _vm.options.StatusList
                        },
                        model: {
                          value: _vm.filters.CurrCargoState,
                          callback: function($$v) {
                            _vm.$set(_vm.filters, "CurrCargoState", $$v)
                          },
                          expression: "filters.CurrCargoState"
                        }
                      }),
                      _c("hr", {
                        staticClass:
                          "border mt-3 mx-n3 border-gray-200 d-none d-md-block"
                      }),
                      _c(
                        "div",
                        {
                          staticClass:
                            "mt-3 mb-2 f-14 f-md-12 fw-500 text-gray-800"
                        },
                        [_vm._v("Поля")]
                      ),
                      _c(
                        "div",
                        { staticClass: "shadow-box mt-3 mt-md-1" },
                        [
                          _c(
                            "c-checkbox",
                            {
                              model: {
                                value: _vm.columns.OrderShortNo,
                                callback: function($$v) {
                                  _vm.$set(_vm.columns, "OrderShortNo", $$v)
                                },
                                expression: "columns.OrderShortNo"
                              }
                            },
                            [
                              _c("div", { staticClass: "fw-500 fw-md-400" }, [
                                _vm._v("Номер заявки")
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "shadow-box mt-3 mt-md-1" },
                        [
                          _c(
                            "c-checkbox",
                            {
                              model: {
                                value: _vm.columns.CrtDate,
                                callback: function($$v) {
                                  _vm.$set(_vm.columns, "CrtDate", $$v)
                                },
                                expression: "columns.CrtDate"
                              }
                            },
                            [
                              _c("div", { staticClass: "fw-500 fw-md-400" }, [
                                _vm._v("Дата заявки")
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "shadow-box mt-3 mt-md-1" },
                        [
                          _c(
                            "c-checkbox",
                            {
                              model: {
                                value: _vm.columns.ClientDocsNo,
                                callback: function($$v) {
                                  _vm.$set(_vm.columns, "ClientDocsNo", $$v)
                                },
                                expression: "columns.ClientDocsNo"
                              }
                            },
                            [
                              _c("div", { staticClass: "fw-500 fw-md-400" }, [
                                _vm._v("№ Сопроводительных документов")
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "shadow-box mt-3 mt-md-1" },
                        [
                          _c(
                            "c-checkbox",
                            {
                              model: {
                                value: _vm.columns.ClientOrderNo,
                                callback: function($$v) {
                                  _vm.$set(_vm.columns, "ClientOrderNo", $$v)
                                },
                                expression: "columns.ClientOrderNo"
                              }
                            },
                            [
                              _c("div", { staticClass: "fw-500 fw-md-400" }, [
                                _vm._v("Номер заказа")
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "shadow-box mt-3 mt-md-1" },
                        [
                          _c(
                            "c-checkbox",
                            {
                              model: {
                                value: _vm.columns.CurrCargoState,
                                callback: function($$v) {
                                  _vm.$set(_vm.columns, "CurrCargoState", $$v)
                                },
                                expression: "columns.CurrCargoState"
                              }
                            },
                            [
                              _c("div", { staticClass: "fw-500 fw-md-400" }, [
                                _vm._v("Статус")
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "shadow-box mt-3 mt-md-1" },
                        [
                          _c(
                            "c-checkbox",
                            {
                              model: {
                                value: _vm.columns.SenderTownName,
                                callback: function($$v) {
                                  _vm.$set(_vm.columns, "SenderTownName", $$v)
                                },
                                expression: "columns.SenderTownName"
                              }
                            },
                            [
                              _c("div", { staticClass: "fw-500 fw-md-400" }, [
                                _vm._v("Город отправки")
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "shadow-box mt-3 mt-md-1" },
                        [
                          _c(
                            "c-checkbox",
                            {
                              model: {
                                value: _vm.columns.RecipTownName,
                                callback: function($$v) {
                                  _vm.$set(_vm.columns, "RecipTownName", $$v)
                                },
                                expression: "columns.RecipTownName"
                              }
                            },
                            [
                              _c("div", { staticClass: "fw-500 fw-md-400" }, [
                                _vm._v("Город получения")
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "shadow-box mt-3 mt-md-1" },
                        [
                          _c(
                            "c-checkbox",
                            {
                              model: {
                                value: _vm.columns.CargoContent,
                                callback: function($$v) {
                                  _vm.$set(_vm.columns, "CargoContent", $$v)
                                },
                                expression: "columns.CargoContent"
                              }
                            },
                            [
                              _c("div", { staticClass: "fw-500 fw-md-400" }, [
                                _vm._v("Наименование груза")
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "shadow-box mt-3 mt-md-1" },
                        [
                          _c(
                            "c-checkbox",
                            {
                              model: {
                                value: _vm.columns.FactWeight,
                                callback: function($$v) {
                                  _vm.$set(_vm.columns, "FactWeight", $$v)
                                },
                                expression: "columns.FactWeight"
                              }
                            },
                            [
                              _c("div", { staticClass: "fw-500 fw-md-400" }, [
                                _vm._v("Общий вес")
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "shadow-box mt-3 mt-md-1" },
                        [
                          _c(
                            "c-checkbox",
                            {
                              model: {
                                value: _vm.columns.FactVolume,
                                callback: function($$v) {
                                  _vm.$set(_vm.columns, "FactVolume", $$v)
                                },
                                expression: "columns.FactVolume"
                              }
                            },
                            [
                              _c("div", { staticClass: "fw-500 fw-md-400" }, [
                                _vm._v("Общий объем")
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "shadow-box mt-3 mt-md-1" },
                        [
                          _c(
                            "c-checkbox",
                            {
                              model: {
                                value: _vm.columns.FactPlace,
                                callback: function($$v) {
                                  _vm.$set(_vm.columns, "FactPlace", $$v)
                                },
                                expression: "columns.FactPlace"
                              }
                            },
                            [
                              _c("div", { staticClass: "fw-500 fw-md-400" }, [
                                _vm._v("Количество мест")
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "shadow-box mt-3 mt-md-1" },
                        [
                          _c(
                            "c-checkbox",
                            {
                              model: {
                                value: _vm.columns.PickAddress,
                                callback: function($$v) {
                                  _vm.$set(_vm.columns, "PickAddress", $$v)
                                },
                                expression: "columns.PickAddress"
                              }
                            },
                            [
                              _c("div", { staticClass: "fw-500 fw-md-400" }, [
                                _vm._v("Адрес забора")
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "shadow-box mt-3 mt-md-1" },
                        [
                          _c(
                            "c-checkbox",
                            {
                              model: {
                                value: _vm.columns.DelivAddress,
                                callback: function($$v) {
                                  _vm.$set(_vm.columns, "DelivAddress", $$v)
                                },
                                expression: "columns.DelivAddress"
                              }
                            },
                            [
                              _c("div", { staticClass: "fw-500 fw-md-400" }, [
                                _vm._v("Адрес доставки")
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "shadow-box mt-3 mt-md-1" },
                        [
                          _c(
                            "c-checkbox",
                            {
                              model: {
                                value: _vm.columns.SenderName,
                                callback: function($$v) {
                                  _vm.$set(_vm.columns, "SenderName", $$v)
                                },
                                expression: "columns.SenderName"
                              }
                            },
                            [
                              _c("div", { staticClass: "fw-500 fw-md-400" }, [
                                _vm._v("Отправитель")
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "shadow-box mt-3 mt-md-1" },
                        [
                          _c(
                            "c-checkbox",
                            {
                              model: {
                                value: _vm.columns.RecipName,
                                callback: function($$v) {
                                  _vm.$set(_vm.columns, "RecipName", $$v)
                                },
                                expression: "columns.RecipName"
                              }
                            },
                            [
                              _c("div", { staticClass: "fw-500 fw-md-400" }, [
                                _vm._v("Получатель")
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "shadow-box mt-3 mt-md-1" },
                        [
                          _c(
                            "c-checkbox",
                            {
                              model: {
                                value: _vm.columns.PayerName,
                                callback: function($$v) {
                                  _vm.$set(_vm.columns, "PayerName", $$v)
                                },
                                expression: "columns.PayerName"
                              }
                            },
                            [
                              _c("div", { staticClass: "fw-500 fw-md-400" }, [
                                _vm._v("Плательщик")
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "shadow-box mt-3 mt-md-1" },
                        [
                          _c(
                            "c-checkbox",
                            {
                              model: {
                                value: _vm.columns.PayResult,
                                callback: function($$v) {
                                  _vm.$set(_vm.columns, "PayResult", $$v)
                                },
                                expression: "columns.PayResult"
                              }
                            },
                            [
                              _c("div", { staticClass: "fw-500 fw-md-400" }, [
                                _vm._v("Статус оплаты")
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "shadow-box mt-3 mt-md-1" },
                        [
                          _c(
                            "c-checkbox",
                            {
                              model: {
                                value: _vm.columns.TransCost,
                                callback: function($$v) {
                                  _vm.$set(_vm.columns, "TransCost", $$v)
                                },
                                expression: "columns.TransCost"
                              }
                            },
                            [
                              _c("div", { staticClass: "fw-500 fw-md-400" }, [
                                _vm._v("Стоимость")
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "shadow-box mt-3 mt-md-1" },
                        [
                          _c(
                            "c-checkbox",
                            {
                              model: {
                                value: _vm.columns.InsuranceValue,
                                callback: function($$v) {
                                  _vm.$set(_vm.columns, "InsuranceValue", $$v)
                                },
                                expression: "columns.InsuranceValue"
                              }
                            },
                            [
                              _c("div", { staticClass: "fw-500 fw-md-400" }, [
                                _vm._v("Страховка")
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "shadow-box mt-3 mt-md-1" },
                        [
                          _c(
                            "c-checkbox",
                            {
                              model: {
                                value: _vm.columns.CompleteDate,
                                callback: function($$v) {
                                  _vm.$set(_vm.columns, "CompleteDate", $$v)
                                },
                                expression: "columns.CompleteDate"
                              }
                            },
                            [
                              _c("div", { staticClass: "fw-500 fw-md-400" }, [
                                _vm._v("Дата выдачи")
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _c("hr", {
                        staticClass:
                          "border mx-n3 border-gray-200 d-none d-md-block"
                      }),
                      _c(
                        "div",
                        {
                          staticClass:
                            "mt-3 mb-2 f-14 f-md-12 fw-500 text-gray-800"
                        },
                        [_vm._v("Группировка")]
                      ),
                      _vm.columns.CurrCargoState
                        ? _c(
                            "div",
                            { staticClass: "shadow-box mt-3 mt-md-1" },
                            [
                              _c(
                                "c-checkbox",
                                {
                                  model: {
                                    value: _vm.groupBy.CurrCargoState,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.groupBy,
                                        "CurrCargoState",
                                        $$v
                                      )
                                    },
                                    expression: "groupBy.CurrCargoState"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "fw-500 fw-md-400" },
                                    [_vm._v("Статус")]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.columns.SenderTownName
                        ? _c(
                            "div",
                            { staticClass: "shadow-box mt-3 mt-md-1" },
                            [
                              _c(
                                "c-checkbox",
                                {
                                  model: {
                                    value: _vm.groupBy.SenderTownName,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.groupBy,
                                        "SenderTownName",
                                        $$v
                                      )
                                    },
                                    expression: "groupBy.SenderTownName"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "fw-500 fw-md-400" },
                                    [_vm._v("Город отправки")]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.columns.RecipTownName
                        ? _c(
                            "div",
                            { staticClass: "shadow-box mt-3 mt-md-1" },
                            [
                              _c(
                                "c-checkbox",
                                {
                                  model: {
                                    value: _vm.groupBy.RecipTownName,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.groupBy,
                                        "RecipTownName",
                                        $$v
                                      )
                                    },
                                    expression: "groupBy.RecipTownName"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "fw-500 fw-md-400" },
                                    [_vm._v("Город получения")]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.columns.CargoContent
                        ? _c(
                            "div",
                            { staticClass: "shadow-box mt-3 mt-md-1" },
                            [
                              _c(
                                "c-checkbox",
                                {
                                  model: {
                                    value: _vm.groupBy.CargoContent,
                                    callback: function($$v) {
                                      _vm.$set(_vm.groupBy, "CargoContent", $$v)
                                    },
                                    expression: "groupBy.CargoContent"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "fw-500 fw-md-400" },
                                    [_vm._v("Наименование груза")]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.columns.PickAddress
                        ? _c(
                            "div",
                            { staticClass: "shadow-box mt-3 mt-md-1" },
                            [
                              _c(
                                "c-checkbox",
                                {
                                  model: {
                                    value: _vm.groupBy.PickAddress,
                                    callback: function($$v) {
                                      _vm.$set(_vm.groupBy, "PickAddress", $$v)
                                    },
                                    expression: "groupBy.PickAddress"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "fw-500 fw-md-400" },
                                    [_vm._v("Адрес забора")]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.columns.DelivAddress
                        ? _c(
                            "div",
                            { staticClass: "shadow-box mt-3 mt-md-1" },
                            [
                              _c(
                                "c-checkbox",
                                {
                                  model: {
                                    value: _vm.groupBy.DelivAddress,
                                    callback: function($$v) {
                                      _vm.$set(_vm.groupBy, "DelivAddress", $$v)
                                    },
                                    expression: "groupBy.DelivAddress"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "fw-500 fw-md-400" },
                                    [_vm._v("Адрес доставки")]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.columns.SenderName
                        ? _c(
                            "div",
                            { staticClass: "shadow-box mt-3 mt-md-1" },
                            [
                              _c(
                                "c-checkbox",
                                {
                                  model: {
                                    value: _vm.groupBy.SenderName,
                                    callback: function($$v) {
                                      _vm.$set(_vm.groupBy, "SenderName", $$v)
                                    },
                                    expression: "groupBy.SenderName"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "fw-500 fw-md-400" },
                                    [_vm._v("Отправитель")]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.columns.RecipName
                        ? _c(
                            "div",
                            { staticClass: "shadow-box mt-3 mt-md-1" },
                            [
                              _c(
                                "c-checkbox",
                                {
                                  model: {
                                    value: _vm.groupBy.RecipName,
                                    callback: function($$v) {
                                      _vm.$set(_vm.groupBy, "RecipName", $$v)
                                    },
                                    expression: "groupBy.RecipName"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "fw-500 fw-md-400" },
                                    [_vm._v("Получатель")]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.columns.PayerName
                        ? _c(
                            "div",
                            { staticClass: "shadow-box mt-3 mt-md-1" },
                            [
                              _c(
                                "c-checkbox",
                                {
                                  model: {
                                    value: _vm.groupBy.PayerName,
                                    callback: function($$v) {
                                      _vm.$set(_vm.groupBy, "PayerName", $$v)
                                    },
                                    expression: "groupBy.PayerName"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "fw-500 fw-md-400" },
                                    [_vm._v("Плательщик")]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.columns.PayResult
                        ? _c(
                            "div",
                            { staticClass: "shadow-box mt-3 mt-md-1" },
                            [
                              _c(
                                "c-checkbox",
                                {
                                  model: {
                                    value: _vm.groupBy.PayResult,
                                    callback: function($$v) {
                                      _vm.$set(_vm.groupBy, "PayResult", $$v)
                                    },
                                    expression: "groupBy.PayResult"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "fw-500 fw-md-400" },
                                    [_vm._v("Статус оплаты")]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("hr", {
                        staticClass:
                          "border mx-n3 border-gray-200 d-none d-md-block"
                      }),
                      _c(
                        "div",
                        {
                          staticClass:
                            "mt-3 mb-2 f-14 f-md-12 fw-500 text-gray-800"
                        },
                        [_vm._v("Фильтры")]
                      ),
                      _vm.columns.CurrCargoState
                        ? _c("c-select", {
                            staticClass: "mt-1",
                            attrs: {
                              placeholder: "Статус",
                              options: _vm.options.StatusList,
                              clearable: true
                            },
                            model: {
                              value: _vm.filters.CurrCargoState,
                              callback: function($$v) {
                                _vm.$set(_vm.filters, "CurrCargoState", $$v)
                              },
                              expression: "filters.CurrCargoState"
                            }
                          })
                        : _vm._e(),
                      _vm.columns.SenderTownName
                        ? _c("c-select", {
                            staticClass: "mt-1",
                            attrs: {
                              placeholder: "Город отправки",
                              options: _vm.options.SenderTownNameList,
                              clearable: true
                            },
                            model: {
                              value: _vm.filters.SenderTownName,
                              callback: function($$v) {
                                _vm.$set(_vm.filters, "SenderTownName", $$v)
                              },
                              expression: "filters.SenderTownName"
                            }
                          })
                        : _vm._e(),
                      _vm.columns.RecipTownName
                        ? _c("c-select", {
                            staticClass: "mt-1",
                            attrs: {
                              placeholder: "Город получения",
                              options: _vm.options.RecipTownName,
                              clearable: true
                            },
                            model: {
                              value: _vm.filters.RecipTownName,
                              callback: function($$v) {
                                _vm.$set(_vm.filters, "RecipTownName", $$v)
                              },
                              expression: "filters.RecipTownName"
                            }
                          })
                        : _vm._e(),
                      _vm.columns.CargoContent
                        ? _c("c-select", {
                            staticClass: "mt-1",
                            attrs: {
                              placeholder: "Наименование груза",
                              options: _vm.options.CargoContentList,
                              clearable: true
                            },
                            model: {
                              value: _vm.filters.CargoContent,
                              callback: function($$v) {
                                _vm.$set(_vm.filters, "CargoContent", $$v)
                              },
                              expression: "filters.CargoContent"
                            }
                          })
                        : _vm._e(),
                      _vm.columns.PickAddress
                        ? _c("c-select", {
                            staticClass: "mt-1",
                            attrs: {
                              placeholder: "Адрес забора",
                              options: _vm.options.PickAddressList,
                              clearable: true
                            },
                            model: {
                              value: _vm.filters.PickAddress,
                              callback: function($$v) {
                                _vm.$set(_vm.filters, "PickAddress", $$v)
                              },
                              expression: "filters.PickAddress"
                            }
                          })
                        : _vm._e(),
                      _vm.columns.DelivAddress
                        ? _c("c-select", {
                            staticClass: "mt-1",
                            attrs: {
                              placeholder: "Адрес доставки",
                              options: _vm.options.DelivAddressList,
                              clearable: true
                            },
                            model: {
                              value: _vm.filters.DelivAddress,
                              callback: function($$v) {
                                _vm.$set(_vm.filters, "DelivAddress", $$v)
                              },
                              expression: "filters.DelivAddress"
                            }
                          })
                        : _vm._e(),
                      _vm.columns.SenderName
                        ? _c("c-select", {
                            staticClass: "mt-1",
                            attrs: {
                              placeholder: "Отправитель",
                              options: _vm.options.SenderList,
                              clearable: true
                            },
                            model: {
                              value: _vm.filters.SenderName,
                              callback: function($$v) {
                                _vm.$set(_vm.filters, "SenderName", $$v)
                              },
                              expression: "filters.SenderName"
                            }
                          })
                        : _vm._e(),
                      _vm.columns.RecipName
                        ? _c("c-select", {
                            staticClass: "mt-1",
                            attrs: {
                              placeholder: "Получатель",
                              options: _vm.options.RecipList,
                              clearable: true
                            },
                            model: {
                              value: _vm.filters.RecipName,
                              callback: function($$v) {
                                _vm.$set(_vm.filters, "RecipName", $$v)
                              },
                              expression: "filters.RecipName"
                            }
                          })
                        : _vm._e(),
                      _vm.columns.PayerName
                        ? _c("c-select", {
                            staticClass: "mt-1",
                            attrs: {
                              placeholder: "Плательщик",
                              options: _vm.options.PayerList,
                              clearable: true
                            },
                            model: {
                              value: _vm.filters.PayerName,
                              callback: function($$v) {
                                _vm.$set(_vm.filters, "PayerName", $$v)
                              },
                              expression: "filters.PayerName"
                            }
                          })
                        : _vm._e(),
                      _vm.columns.PayResult
                        ? _c("c-select", {
                            staticClass: "mt-1",
                            attrs: {
                              placeholder: "Статус оплаты",
                              options: _vm.options.PayList,
                              clearable: true
                            },
                            model: {
                              value: _vm.filters.PayResult,
                              callback: function($$v) {
                                _vm.$set(_vm.filters, "PayResult", $$v)
                              },
                              expression: "filters.PayResult"
                            }
                          })
                        : _vm._e(),
                      _c(
                        "c-button",
                        {
                          staticClass: "mt-3 w-100 d-none d-md-block",
                          on: { click: _vm.applyFilters }
                        },
                        [_vm._v("Сформировать отчет")]
                      ),
                      _c(
                        "button",
                        {
                          staticClass:
                            "btn btn-outline-primary f-14 fw-500 w-100 mt-3 py-2",
                          attrs: { disabled: _vm.loadingReport },
                          on: { click: _vm.createReport }
                        },
                        [_vm._v("Сохранить в Excel")]
                      )
                    ],
                    1
                  )
                ])
              ])
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }