<template>
  <div class="map page-1" :class="{'map-mobile': isMobile}">
    <h3 class="map__title">
      <span class="d-inline-block mb-3">
        {{title}}
      </span>
    </h3>
    <div v-if="error" class="alert alert-danger">
      {{error}}
    </div>
    <preloader over v-if="loading"></preloader>
    <template v-if="!loading && !error">
      <c-row class="no-gutters">
        <c-col class="map-search__select">
          <c-select
            placeholder="Поиск по городу"
            :options="options.cities"
            v-model="model.city_id"
            :filter="filterCities"
            :getOptionLabel="getCityLabel"
            @input="inputSearch"
          ></c-select>
        </c-col>
        <!-- <c-col class="map-search__btn" v-if="!isMobile">
          <c-button :disabled="model.city_id ? false : true" @click="loadById(model.city_id)">Поиск</c-button>
        </c-col> -->
      </c-row>
    </template>
    <c-row class="mt-4" :class="{'d-none-error': error}">
      <c-col class="d-flex justify-content-center">
        <!-- <div id="js-map" class="map-area"></div> -->
        <c-row class="small col-xs-12 col-sm-12 col-md-12 col-11 justify-content-between">
          <c-col class="col-xs-12 col-sm-12 col-md-12 col-lg-5 small">
            <div v-for="(town, index) in firstArray" :key="index">
              <ul class="list-unstyled">
                <li>
                  <c-row class="justify-content-between">
                    <ul class="w-50">
                      <li>
                        <router-link :to="{path: `/contacts/${town.TownID}`}" class="town-name">{{town.FSName}}</router-link>
                      </li>
                      <li>
                        <div class="town-adress" v-if="town._data.FSAddress">{{town._data.FSAddress}}</div>
                        <div class="town-adress" v-else>Адрес не указан</div>
                      </li>
                    </ul>
                    <c-col class="w-50">
                      <ul>
                        <li>
                          <c-col class="town-phone" v-if="town._data.FSPhone"><a class="town-link" :href="`tel:${town._data.FSPhone}`">{{town._data.FSPhone}}</a></c-col>
                          <c-col class="town-phone" v-else>Телефон не указан</c-col>
                        </li>
                        <li>
                          <c-col class="town-phone" v-if="town._data.FSGeoLocation && isMobile"><a :href="`yandexnavi://build_route_on_map?lat_to=${town._data.FSGeoLocation.replace(/\s/g, '').split(',')[0]}&lon_to=${town._data.FSGeoLocation.replace(/\s/g, '').split(',')[1]}`">Построить маршрут</a></c-col>
                        </li>
                      </ul>
                    </c-col>
                  </c-row>
                </li>
              </ul>
            </div>
          </c-col>
          <c-col class="col-xs-12 col-sm-12 col-md-12 col-lg-5 small">
            <div v-for="(town, index) in secondArray" :key="index">
              <ul class="list-unstyled">
                <li>
                  <c-row class="justify-content-between">
                    <ul class="w-50">
                      <li>
                        <router-link :to="{path: `/contacts/${town.TownID}`}" class="town-name">{{town.FSName}}</router-link>
                      </li>
                      <li>
                        <div class="town-adress" v-if="town._data.FSAddress">{{town._data.FSAddress}}</div>
                        <div class="town-adress" v-else>Адрес не указан</div>
                      </li>
                    </ul>
                    <c-col class="w-50">
                      <ul>
                        <li>
                          <c-col class="town-phone" v-if="town._data.FSPhone"><a class="town-link" :href="`tel:${town._data.FSPhone}`">{{town._data.FSPhone}}</a></c-col>
                          <c-col class="town-phone" v-else>Телефон не указан</c-col>
                        </li>
                        <li>
                          <c-col class="town-phone" v-if="town._data.FSGeoLocation && isMobile"><a :href="`yandexnavi://build_route_on_map?lat_to=${town._data.FSGeoLocation.replace(/\s/g, '').split(',')[0]}&lon_to=${town._data.FSGeoLocation.replace(/\s/g, '').split(',')[1]}`">Построить маршрут</a></c-col>
                        </li>
                      </ul>
                    </c-col>
                  </c-row>
                </li>
              </ul>
            </div>
          </c-col>
        </c-row>
      </c-col>
    </c-row>
  </div>
</template>

<script>
import mapBase from '../map-base/index'
export default {
  extends: mapBase,
  data () {
    return {
      firstArray: [],
      secondArray: []
    }
  },
  methods: {
    splitTowns() {
      let array = [ ...this.options.map ]
      this.firstArray = array.splice(0, (array.length / 2))
      this.secondArray = array
    }
  },
  watch: {
    loading(val) {
      if (!val) this.splitTowns()
    }
  }
}
</script>

<style lang="scss" scoped>
  .col-5{
    max-width: 38.66667%;
  }
  .row{
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .town{
    &-name{
      font-size: 16px;
      font-family: SourceSansPro-Semibold;
      color: #393939 !important;
      word-wrap: break-word;
      width: 50%;

      &:hover{
        color: #ca1226 !important;
        cursor: pointer;
      }
    }
    &-phone{
      font-size: 13px;
      line-height: 1.8;
      font-family: SourceSansPro-Semibold;
      text-align: right;
    }
    &-adress{
      font-size: 12px;
      font-family: SourceSansPro-Light;
    }
    &-link{
      color: #393939;
    }
  }
  @media screen and (max-width: 575px) {
    .small{
      flex: 0 0 100% !important;
      max-width: 100% !important;
      padding: 0
    }
  }
</style>