import _ from 'lodash';
import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import api from './../api';

Vue.use(Vuex);

const MINUTE = 60 * 1000;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;

let invoke = (fn, names) => {
  return _.reduce(names, (result, name) => _.extend(result, fn(name)), {});
}

let prepareDictionaryState = name => {
  return {
    [name]: {
      ts: null,
      value: null
    }
  }
};

let prepareDictionaryActions = name => {
  return {
    [name]: ({
      state,
      commit
    }) => {
      let namedState = state[name];
      return new Promise((resolve, reject) => {
        if (+new Date() - namedState.ts < 10 * MINUTE && _.isArray(namedState.value)) {
          resolve(namedState.value);
        } else {
          api[name]()
            .then(response => {
              if (_.isArray(response.data)) {
                commit(`store-${name}`, response.data);
              }
            })
            .catch(() => {

            })
            .finally(() => {
              state[`${name}Loading`] = false;
              state[`${name}Loaded`] = true;
              resolve(namedState.value);
            });
        }
      });
    }
  }
};

let prepareDictionaryMutations = name => {
  return {
    [`store-${name}`]: (state, value) => {
      let namedState = state[name];
      namedState.value = value;
      namedState.ts = +new Date();
    }
  }
};

const DICTIONARIES = ['towns', 'cargoKinds', 'docTypes'];

const store = Vue.$store = Vue.prototype.$store = new Vuex.Store({
  plugins: [createPersistedState({
    paths: ['requestFilters', 'calculator', ...DICTIONARIES]
  })],
  state: {
    requestFilters: {},
    calculator: null,
    ...invoke(prepareDictionaryState, DICTIONARIES)
  },
  actions: {
    ...invoke(prepareDictionaryActions, DICTIONARIES)
  },
  mutations: {
    saveRequestFilters(state, value) {
      state.requestFilters = value;
    },
    saveCalc(state, value) {
      state.calculator = value;
    },
    ...invoke(prepareDictionaryMutations, DICTIONARIES)
  },
  getters: {
    getRequestFilters(state) {
      return state.requestFilters;
    },
    getCalc(state) {
      return state.calculator;
    }
  }
});

export default store;