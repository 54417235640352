var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h4", { staticClass: "text-gray-2 " }, [
        _vm._v("\n    Рассчет стоимости\n  ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "total text-red mt-3 pt-1" }, [
        _vm._v("\n    ₽ 25997,97\n  ")
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "path mt-3 py-1" },
        [
          _c(
            "c-row",
            [
              _c(
                "c-col",
                { staticClass: "pr-0 left", attrs: { cols: "auto" } },
                [
                  _c("div", { staticClass: "text-center" }, [
                    _c("div", { staticClass: "red-circle" }),
                    _vm._v(" "),
                    _c("div", [_c("div", { staticClass: "path-line" })]),
                    _c("div", { staticClass: "red-circle" })
                  ])
                ]
              ),
              _vm._v(" "),
              _c("c-col", { staticClass: "pl-2 right" }, [
                _c("div", { staticClass: "city" }, [
                  _vm._v("\n          Москва\n        ")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "dates" }, [
                  _vm._v("\n          20.05.19 - 14.06.19\n        ")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "city" }, [
                  _vm._v("\n            Санкт-Петербург\n        ")
                ])
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt-3" },
        [
          _c(
            "c-row",
            { staticClass: "my-2" },
            [
              _c("c-col", { staticClass: "text-gray-2 fw-500" }, [
                _vm._v("\n        Забрать груз\n      ")
              ]),
              _vm._v(" "),
              _c(
                "c-col",
                { staticClass: "text-red fw-500", attrs: { cols: "auto" } },
                [_vm._v("\n        ₽ 500\n      ")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "c-row",
            { staticClass: "my-2" },
            [
              _c("c-col", { staticClass: "text-gray-2 fw-500" }, [
                _vm._v("\n        Перевозка\n      ")
              ]),
              _vm._v(" "),
              _c(
                "c-col",
                { staticClass: "text-red fw-500", attrs: { cols: "auto" } },
                [_vm._v("\n        ₽ 500\n      ")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "c-row",
            { staticClass: "my-2" },
            [
              _c("c-col", { staticClass: "text-gray-2 fw-500" }, [
                _vm._v("\n        Доставить груз\n      ")
              ]),
              _vm._v(" "),
              _c(
                "c-col",
                { staticClass: "text-red fw-500", attrs: { cols: "auto" } },
                [_vm._v("\n        ₽ 500\n      ")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "c-row",
            { staticClass: "my-2" },
            [
              _c("c-col", { staticClass: "text-gray-2 fw-500" }, [
                _vm._v("\n        Упаковка\n      ")
              ]),
              _vm._v(" "),
              _c(
                "c-col",
                { staticClass: "text-red fw-500", attrs: { cols: "auto" } },
                [_vm._v("\n        ₽ 500\n      ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("c-button", { staticClass: "white w-100 mt-3" }, [
        _vm._v("\n    Получить спецпредложение\n  ")
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "inverted dimensions mx-n4 px-4 py-3 mt-3 fa fw-500" },
      [
        _c("div", { staticClass: "icon-cargo" }),
        _vm._v(" "),
        _c("span", { staticClass: "ml-2" }, [_vm._v("\n      50 кг\n    ")]),
        _vm._v(" "),
        _c("div", { staticClass: "icon-fullscreen ml-3" }),
        _vm._v(" "),
        _c("span", { staticClass: "ml-2" }, [
          _vm._v("\n      50 м"),
          _c("sup", [_vm._v("3")])
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }