var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "mt-3" }),
      _vm._v(" "),
      _vm.success
        ? _c("div", { staticClass: "alert alert-success" }, [
            _vm._v("\n    " + _vm._s(_vm.success) + "\n  ")
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.error
        ? _c("div", { staticClass: "alert alert-danger" }, [
            _vm._v("\n    " + _vm._s(_vm.error) + "\n  ")
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.loading ? _c("preloader", { attrs: { over: "" } }) : _vm._e(),
      _vm._v(" "),
      _vm.page === 1
        ? [
            !_vm.loading && _vm.navs.length
              ? _c(
                  "c-row",
                  [
                    _c(
                      "c-col",
                      { staticClass: "col-12 col-lg-3 col-md-4" },
                      [
                        _vm.navs && _vm.navs.length
                          ? _c("nav-sidebar", {
                              attrs: { items: _vm.navs },
                              on: { "nav-change": _vm.getContent }
                            })
                          : _c("div", { staticClass: "alert alert-danger" }, [
                              _vm._v("\n          Нет данных\n        ")
                            ])
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "c-col",
                      { staticClass: "col-12 col-lg-9 col-md-8" },
                      [
                        _vm.loadingContent
                          ? _c("preloader")
                          : [
                              _c("h2", { staticClass: "documents-box-title" }, [
                                _vm._v(_vm._s(_vm.activeCategory))
                              ]),
                              _vm._v(" "),
                              _vm.content && _vm.content.length
                                ? _c(
                                    "div",
                                    { staticClass: "documents-box col-8" },
                                    [
                                      _c(
                                        "h2",
                                        { staticClass: "documents-box-title" },
                                        [_vm._v(_vm._s(_vm.title))]
                                      ),
                                      _vm._v(" "),
                                      _c("youtube", {
                                        ref: "youtube",
                                        attrs: {
                                          height: _vm.windowWidth / 1.7,
                                          width: _vm.windowWidth,
                                          "video-id": _vm.getId(
                                            _vm.content[0].media_url
                                          )
                                        },
                                        on: {
                                          "update:height": function($event) {
                                            return _vm.$set(
                                              _vm.windowWidth / 1,
                                              "7",
                                              $event
                                            )
                                          },
                                          "update:width": function($event) {
                                            _vm.windowWidth = $event
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "a",
                                        {
                                          staticClass:
                                            "documents-box-link instructions",
                                          attrs: {
                                            href: _vm.content[0].doc_url,
                                            download: ""
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "icon-download documents-box-link-icon"
                                          }),
                                          _vm._v(" Скачать pdf")
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]
                      ],
                      2
                    )
                  ],
                  1
                )
              : _c(
                  "c-row",
                  [
                    _c("c-col", { staticClass: "col-12" }, [
                      _c("div", { staticClass: "instr-dummy" }, [
                        _c("i", {
                          staticClass: "icon-archive instr-dummy-icon"
                        }),
                        _vm._v(" "),
                        _c("p", { staticClass: "instr-dummy-title" }, [
                          _vm._v("Здесь еще нет инструкций")
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "instr-dummy-greytext" }, [
                          _vm._v("Идет разработка данного раздела."),
                          _c("br"),
                          _vm._v("\n          Пожалуйста, загляните позже.")
                        ])
                      ])
                    ])
                  ],
                  1
                )
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.page === 2
        ? [
            _c("send-form", {
              ref: "sendForm",
              attrs: { currentModel: _vm.currentModelPage },
              on: {
                success: _vm.successEvent,
                error: _vm.errorEvent,
                loading: _vm.loadingEvent
              }
            })
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", [
      _c("span", { staticClass: "d-inline-block mb-3" }, [
        _vm._v("\n      Инструкции\n    ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }