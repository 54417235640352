var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._m(0),
      _vm._v(" "),
      _vm.loading ? _c("preloader", { attrs: { over: "" } }) : _vm._e(),
      _vm._v(" "),
      _vm.error
        ? _c("div", { staticClass: "alert alert-danger" }, [
            _vm._v("\n    " + _vm._s(_vm.error) + "\n  ")
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.error && !_vm.loading
        ? [
            _c(
              "div",
              {
                staticClass: "nav-mobile-title mb-3",
                on: {
                  click: function($event) {
                    return _vm.$router.go(-1)
                  }
                }
              },
              [
                _c("div", { staticClass: "nav-mobile-title--sub" }, [
                  _vm._v("Категория")
                ]),
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.category.name || "Не названия") +
                    "\n    "
                )
              ]
            ),
            _vm._v(" "),
            _vm.content && _vm.content.length
              ? _c(
                  "table",
                  { staticClass: "documents-table" },
                  _vm._l(_vm.content, function(item) {
                    return _c(
                      "tr",
                      {
                        key: item.doc_name,
                        staticClass: "documents-table-row"
                      },
                      [
                        _c("td", { staticClass: "documents-table-namecell" }, [
                          _c("i", {
                            staticClass: "icon-file documents-box-icon"
                          }),
                          _vm._v(" "),
                          _c("p", { staticClass: "documents-box-filename" }, [
                            _vm._v(_vm._s(item.doc_name))
                          ])
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "documents-table-linkcell" }, [
                          _c(
                            "a",
                            {
                              staticClass: "documents-box-link",
                              attrs: { href: item.doc_url, download: "" }
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "icon-download documents-box-link-icon"
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "desktop" }, [
                                _vm._v("Скачать")
                              ])
                            ]
                          )
                        ])
                      ]
                    )
                  }),
                  0
                )
              : _c("div", { staticClass: "alert alert-danger" }, [
                  _vm._v("\n      Нет данных\n    ")
                ])
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", [
      _c("span", { staticClass: "d-inline-block mb-3" }, [
        _vm._v("\n      Документы\n    ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }