import _ from 'lodash';
import Vue from 'vue';
import api from '@/js/api';
import App from '@/js/app';

let viewer;

function getCookie(name) {
  var value = "; " + document.cookie;
  var parts = value.split("; " + name + "=");
  if (parts.length == 2) return parts.pop().split(";").shift();
  return '';
}

function getFullName() {
  return window.decodeURIComponent(getCookie('full_name')) || '';
}

class Viewer {
  constructor() {
    if (viewer) {
      return viewer;
    }
    viewer = this;
    this.data = {};
    this._logged = false;
    this.loadUser();

    this.initHeader();
    this.initNews();
  }

  initHeader() {
    setTimeout(() => {
      let $loginPanel = document.querySelector('.login-panel');

      let $inside = document.createElement('div');
      $inside.id = "login-user";
      $loginPanel.appendChild($inside);
      
      this.headerApp = new Vue({
        el: $inside,
        template: '<header-user :key="$viewer.logged && $viewer.data.full_name" />',
        router: App.cabinet.router
      });
      window.ha = this.headerApp
    });
  }
  initNews() {
    setTimeout(() => {
      let $header = document.querySelector('#header');

      let $inside = document.createElement('div');
      $inside.id = "newsElement";
      $header.insertBefore($inside, $header.firstChild);

      this.newsApp = new Vue({
        el: $inside,
        template: '<news-element />',
        router: App.cabinet.router
      });
      window.ha = this.newsApp
    });
  }

  login(credentials) {
    return api.login(credentials)
      .then(response => {
        let data = response.data;
        if (data.status == 200) {
          this.loadUser();
        }
        return response;
      });
  }

  logout() {
    this.logged = false;
    return api.logout()
      .finally(() => {
        Vue.$store.commit('saveCalc', null);
        window.location.href = '/login';
      });
  }

  loadUser() {
    return api.getProfile()
      .then(response => {
        // console.log('response',response);
        let data = response.data;
        
        if (data.full_name) {
          _.extend(this.data, data);
          this.logged = true;
          this.updateHeader();
          if (App.inst) {
            App.inst.$root.$emit('loggedin');
          }
        } else {
          // throw 'not autorized';
        }
        return response;
      }, error => {
        this.logged = false;
        return error;
      });
  }

  updateHeader() {
    if (this.headerApp) {
      this.headerApp.$forceUpdate();
    }
  }

  get fullName() {
    return _.get(this, 'data.full_name') || getFullName();
  }

  get logged() {
    let fullName = getFullName();
    if (fullName) return true;
    return this._logged;
  }

  set logged(value) {
    this._logged = value;
    let $loginPanel = document.querySelector('.login-panel');
    let children = [].slice.call($loginPanel.childNodes).filter(node => node.nodeType == 1);
    if (value) {
      let $headerApp = _.get(this, 'headerApp.$el');
      _.each(children, child => {
        if (child != $headerApp) {
          child.classList.add('d-none');
          // child.style.display = "none !important";
        }
      });
    } else {
      _.each(children, child => {
        child.classList.remove('d-none');
        // child.style.display = "";
      });
    }
    this.updateHeader();
  }
}

viewer = new Viewer();

Object.defineProperty(Vue.prototype, '$viewer', {
  get() {
    return new Viewer();
  }
});

export default Viewer;