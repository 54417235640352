<template>
  <div>
    <slot v-for="(item, index) in items" :item="item" :index="index"></slot>
  </div>
</template>

<script>
  export default {
    props: {
      items: {
        type: Array,
        default: () => []
      }
    }
  }
</script>