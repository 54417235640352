import _ from "lodash";
import {helpers} from "vuelidate/lib/validators";
import moment from "moment";

export const isOther = name => function () {
    return _.get(this, name)
};
export const isEqual = (name, value) => function () {
    return _.isEqual(_.get(this, name), value)
};
export const notEmpty = value => !!value;

const phoneRegExp = /^[+]?\d{11,12}$/;
export const phoneCheck = value => phoneRegExp.test(('' + value || '').replace(/[\!\-\(\)\{\} ]/g, ''));

export const skip = (check, validators, name) => {
    // let params = {skip: {value: false}};
    return helpers.withParams(function (add) {
        return function (value, model) {
            let params = {skip: {value: false}};
            add(params);

            let result;
            if (check.call(this, value, model)) {
                params.skip.value = false;
                // console.log('no skip', name, value, params.skip.value)
                if (_.isFunction(validators)) {
                    return validators.call(this, value, model)
                }
                if (_.isPlainObject(validators) || _.isArray(validators)) {
                    return _.reduce(validators, (s, validator) => s && validator.call(this, value, model), true);
                }
                return false;
            }
            params.skip.value = true;
            // console.log('skip', name, value, params.skip.value)
            return true;
        };
    })
    // return helpers.withParams(params, function(value, model) {
    //   let result;
    //   if (check.call(this, value, model)) {
    //     params.skip.value = false;
    //     // console.log('no skip', name, value, params.skip.value)
    //     if (_.isFunction(validators)) {
    //       return validators.call(this, value, model)
    //     }
    //     if (_.isPlainObject(validators) || _.isArray(validators)) {
    //       return _.reduce(validators, (s, validator) => s && validator.call(this, value, model), true);
    //     }
    //     return false;
    //   }
    //   params.skip.value = true;
    //   // console.log('skip', name, value, params.skip.value)
    //   return true;
    // });
};
export const isDate = format => value => moment(value, format).isValid();
export const keep = () => true;
export const isTimeRange = value => {
    if (value) {
        let [start, end] = ('' + value).split('-').map(value => moment(value, 'hh:mm'));
        // console.log(start, end);
        // console.log(start.isValid(), end.isValid());
        return !~('' + value).indexOf('_') && start.isValid() && end.isValid() && end.isSameOrAfter(start);
    }
    return false;
};

export const swapProperties = (object, prop1, prop2) => {
    let swap = _.get(object, prop1);
    _.set(object, prop1, _.get(object, prop2));
    _.set(object, prop2, swap);
}

export const focusHandler = function (newValue, oldValue) {
    let calc = this.calc;
    let cargoName = calc.cargo_name;
    if (cargoName) {
        this.tmpCargoName = cargoName
    }
    let nCargoName = _.get(_.find(this.options.cargoKindMOptions, item => item.value == calc.cargo_kind_id), 'title');
    if (nCargoName && cargoName != nCargoName) {
        this.calc.cargo_name = nCargoName;
    } else if (cargoName) {
        this.calc.cargo_name = cargoName
    } else {
        this.calc.cargo_name = this.tmpCargoName
    }

    if (this.$viewer.logged) {
        this.processPayer(this.payer_type);
    }

    let el = document.activeElement;

    let calculate = () => {
        // console.log('blur calculate');
        if (this.debouncedCalculate) this.debouncedCalculate();
        if (el) {
            el.removeEventListener('blur', calculate);
        }
        this.$v.cargo.$touch();
        this.$v.calc.$touch();
        // if (!this.$v.cargo.$invalid && !this.$v.calc.$invalid) {
        //   if (this.debouncedCalculate) this.debouncedCalculate();
        //   if (el) {
        //     el.removeEventListener('blur', calculate);
        //   }
        // } else {
        //   this.$v.cargo.$touch();
        //   this.$v.calc.$touch();
        // }
    };

    this.saveCalcRequest();
    if (el && el != document.body) {
        el.addEventListener('blur', calculate);
    } else {
        calculate();
    }
};