var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "border border-0 border-md-2 border-gray-200 rounded bg-md-gray-100 py-3 px-3 text-gray-400 shadow-box bg-white"
    },
    [
      _c(
        "c-row",
        { staticClass: "mx-n3 mx-md-n2 items-cols" },
        [
          _c("c-col", { staticClass: "col-6 col-md-3 pr-0" }, [
            _c(
              "div",
              { staticClass: "f-10 text-muted fw-500 text-uppercase" },
              [_vm._v("Отправление")]
            ),
            _c(
              "div",
              { staticClass: "f-14 text-gray-800 mt-2" },
              [
                _c(
                  "c-row",
                  { staticClass: "mx-0" },
                  [
                    _c("c-col", { staticClass: "col-auto p-0 pr-2" }, [
                      _vm._v(_vm._s(_vm.item.calc_fields.city_from))
                    ]),
                    _c(
                      "c-col",
                      { staticClass: "p-0" },
                      [
                        _c(
                          "c-row",
                          { staticClass: "m-0 mt-1 order-path" },
                          [
                            _c("c-col", { staticClass: "col-auto p-0" }, [
                              _c("div", { staticClass: "circle" })
                            ]),
                            _c("c-col", { staticClass: "p-0 px-1" }, [
                              _c("div", { staticClass: "path" })
                            ]),
                            _c("c-col", { staticClass: "col-auto p-0" }, [
                              _c("div", { staticClass: "circle" })
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm.item.calc_fields.cargo_pickup_adress
              ? _c(
                  "div",
                  { staticClass: "f-12 mt-1 fw-300 d-none d-md-block" },
                  [_vm._v(_vm._s(_vm.item.calc_fields.cargo_delivery_adress))]
                )
              : _vm._e(),
            _c("div", { staticClass: "f-12 mt-1 text-gray-800" }, [
              _vm._v(
                _vm._s(
                  _vm._f("date")(
                    _vm.item.calc_fields.cargo_pickup_date,
                    "DD.MM.YY"
                  )
                )
              )
            ])
          ]),
          _c("c-col", { staticClass: "col-6 col-md-3 px-3 px-md-2" }, [
            _c(
              "div",
              { staticClass: "f-10 text-muted fw-500 text-uppercase" },
              [_vm._v("Получение")]
            ),
            _c("div", { staticClass: "f-14 text-gray-800 mt-2" }, [
              _vm._v(_vm._s(_vm.item.calc_fields.city_to))
            ]),
            _vm.item.calc_fields.cargo_delivery_adress
              ? _c(
                  "div",
                  { staticClass: "f-12 mt-1 fw-300 d-none d-md-block" },
                  [_vm._v(_vm._s(_vm.item.calc_fields.cargo_delivery_adress))]
                )
              : _vm._e(),
            _c("div", { staticClass: "f-12 mt-1" }, [
              _c("span", { staticClass: "text-gray-800" }, [
                _vm._v(
                  _vm._s(
                    _vm._f("date")(_vm.item.calc_fields.cargo_delivery_date)
                  )
                )
              ])
            ])
          ]),
          _c(
            "c-col",
            {
              staticClass:
                "col-12 col-md text-center text-md-right mt-3 mt-md-0"
            },
            [
              _c("div", { staticClass: "d-inline-block" }, [
                _c("div", { staticClass: "f-12 fw-500 text-gray-800 fa" }, [
                  _c("div", { staticClass: "text-nowrap fa" }, [
                    _c("i", {
                      staticClass: "f-16 lh-0 icon-cargo text-gray-300 mr-1"
                    }),
                    _vm._v(
                      _vm._s(_vm.item.calc_response.cargo_weight) +
                        " кг / " +
                        _vm._s(_vm.item.calc_response.cargo_amount) +
                        " " +
                        _vm._s(
                          _vm._f("plural")(
                            _vm.item.calc_response.cargo_amount,
                            _vm.pluralPlaces
                          )
                        )
                    )
                  ]),
                  _c("div", { staticClass: "text-nowrap ml-3 fa" }, [
                    _c("i", {
                      staticClass:
                        "f-16 lh-0 icon-fullscreen text-gray-300 mr-1"
                    }),
                    _vm._v(
                      _vm._s(
                        _vm._f("toFixed")(_vm.item.calc_response.cargo_volume)
                      ) + " м"
                    ),
                    _c("sup", [_vm._v("3")])
                  ])
                ])
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "f-14 mt-2 fw-300 text-gray-800 d-none d-md-block"
                },
                [_vm._v(_vm._s(_vm.item.calc_fields.cargo_name))]
              )
            ]
          ),
          _c(
            "c-col",
            { staticClass: "col-12 col-md-auto text-center text-md-left" },
            [
              _c("div", {
                staticClass:
                  "border-top border-2 border-gray-200 mx-n3 my-3 d-block d-md-none"
              }),
              _c(
                "div",
                {
                  staticClass:
                    "d-inline-block d-md-block my-0 my-md-0 mx-3 mx-md-0 py-1 f-12 hoverable",
                  on: { click: _vm.show }
                },
                [
                  _c("i", { staticClass: "mr-2 icon-arrow-simple" }),
                  _vm._v("Показать")
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "d-inline-block d-md-block my-0 my-md-0 mx-3 mx-md-0 py-1 f-12 hoverable",
                  on: { click: _vm.remove }
                },
                [_c("i", { staticClass: "mr-2 icon-close" }), _vm._v("Удалить")]
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }