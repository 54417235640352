var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("c-select", {
        staticClass: "mt-2",
        attrs: { placeholder: _vm.placeholder, options: _vm.options },
        model: {
          value: _vm.selected,
          callback: function($$v) {
            _vm.selected = $$v
          },
          expression: "selected"
        }
      }),
      _c(
        "div",
        _vm._l(_vm.itemsArray, function(item, index) {
          return _c(
            "div",
            {
              staticClass:
                "select-list-item d-inline-block text-gray-800 py-1 px-2 mt-2"
            },
            [
              _vm._v(_vm._s(item.title)),
              _c(
                "div",
                {
                  staticClass:
                    "d-inline-block text-gray-400 f-10 ml-2 hoverable",
                  on: {
                    click: function($event) {
                      return _vm.remove(index)
                    }
                  }
                },
                [_c("i", { staticClass: "icon-close" })]
              )
            ]
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }