import { render, staticRenderFns } from "./c-datepicker.vue?vue&type=template&id=21b4f50c&scoped=true&"
import script from "./c-datepicker.vue?vue&type=script&lang=js&"
export * from "./c-datepicker.vue?vue&type=script&lang=js&"
import style0 from "./c-datepicker.vue?vue&type=style&index=0&id=21b4f50c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21b4f50c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/d2/innoseti/skif-cargo/skif-cargo-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('21b4f50c')) {
      api.createRecord('21b4f50c', component.options)
    } else {
      api.reload('21b4f50c', component.options)
    }
    module.hot.accept("./c-datepicker.vue?vue&type=template&id=21b4f50c&scoped=true&", function () {
      api.rerender('21b4f50c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/js/core/components/c-datepicker.vue"
export default component.exports