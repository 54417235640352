var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "c-radio",
      class: { disabled: _vm.disabled },
      on: { click: _vm.select }
    },
    [
      _c("div", { staticClass: "row mx-0" }, [
        _c(
          "div",
          { staticClass: "col col-auto order-2 order-md-1 px-0 mr-md-2" },
          [
            _c("div", { staticClass: "icon", class: { active: _vm.active } }, [
              _c(
                "svg",
                { attrs: { width: "16", height: "16", viewBox: "0 0 16 16" } },
                [
                  _c("circle", { attrs: { r: "7", cx: "8", cy: "8" } }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      fill: "none",
                      stroke: "#fff",
                      "stroke-width": "1.8",
                      d: "M2.3 7l4.2 4.2l6.5 -6.5"
                    }
                  })
                ]
              )
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "col c-radio__title order-1 order-md-2 px-0 text-md-gray-800",
            class: { "text-primary": _vm.active }
          },
          [_vm._t("default")],
          2
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }