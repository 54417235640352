var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "c-row",
        [
          _c(
            "c-col",
            { staticClass: "col-12 col-md-4" },
            [
              _c("c-input", {
                staticClass: "mb-2",
                attrs: { placeholder: "Имя" },
                model: {
                  value: _vm.form.name,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name"
                }
              }),
              _vm._v(" "),
              _c("c-input", {
                staticClass: "mb-2",
                attrs: { placeholder: "E-mail" },
                model: {
                  value: _vm.form.email,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "email", $$v)
                  },
                  expression: "form.email"
                }
              }),
              _vm._v(" "),
              _c("c-input", {
                directives: [
                  {
                    name: "mask",
                    rawName: "v-mask",
                    value: { mask: "+99999999999" },
                    expression: "{mask: '+99999999999'}"
                  }
                ],
                staticClass: "mb-2",
                attrs: { placeholder: "Телефон" },
                model: {
                  value: _vm.form.phone,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "phone", $$v)
                  },
                  expression: "form.phone"
                }
              }),
              _vm._v(" "),
              _c("c-input", {
                staticClass: "mb-2",
                attrs: { placeholder: "Город" },
                model: {
                  value: _vm.form.city,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "city", $$v)
                  },
                  expression: "form.city"
                }
              }),
              _vm._v(" "),
              _c("c-textarea", {
                staticClass: "form-control mb-2",
                attrs: { placeholder: "Сообщение" },
                model: {
                  value: _vm.form.message,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "message", $$v)
                  },
                  expression: "form.message"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "c-row",
        { staticClass: "mt-4" },
        [
          _c(
            "c-col",
            [
              _c(
                "el-upload",
                {
                  ref: "upload",
                  attrs: {
                    action: _vm.api.uploudFileUrl,
                    limit: 3,
                    "on-success": _vm.handleSuccess,
                    "on-remove": _vm.handleRemove
                  }
                },
                [
                  _vm.form.files.length < 3
                    ? _c(
                        "span",
                        {
                          staticClass: "el-upload__btn",
                          attrs: { slot: "trigger" },
                          slot: "trigger"
                        },
                        [
                          _c("i", { staticClass: "icon-attach-file" }),
                          _vm._v(" Прикрепить файл")
                        ]
                      )
                    : _vm._e()
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "c-row",
        { staticClass: "mt-4" },
        [
          _c(
            "c-col",
            [
              _c("c-button", { on: { click: _vm.bSave } }, [
                _vm._v("Отправить")
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }