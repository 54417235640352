<template lang="pug">
  div
    settings-menu
    .mt-3.mb-2.f-18.text-gray-800.fw-500
      | Права пользователей
    c-table.mt-3(:data="list")
      template(slot="empty")
        | Список пуст.
      c-column(prop="email", label="Пользователь")
      c-column(prop="rights", label="Назначение прав пользователей")
        template(slot-scope="scope")
          c-checkbox.d-inline-block.d-md-block(v-model="scope.row.right_to_edit_rights", :disabled="!canEdit")
      c-column(prop="request", label="Создание новой заявки")
        template(slot-scope="scope")
          c-checkbox.d-inline-block.d-md-block(v-model="scope.row.right_to_create_order", :disabled="!canEdit")
      c-column(prop="requestDocs", label="Доступ к документам заявки")
        template(slot-scope="scope")
          c-checkbox.d-inline-block.d-md-block(v-model="scope.row.right_to_see_order_docs", :disabled="!canEdit")
      c-column(prop="payers", label="Добавление плательщиков")
        template(slot-scope="scope")
          c-checkbox.d-inline-block.d-md-block(v-model="scope.row.right_to_add_payer", :disabled="!canEdit")
</template>

<script>
  import _ from 'lodash';
  import api from '@/js/api';

  export default {
    data: () => ({
      list: [],
      disabled: true
    }),
    computed: {
      canEdit() {
        return _.get(this, '$viewer.data.right_to_edit_rights');
      }
    },
    watch: {
      list: {
        handler(value) {
          if (value && value.length && !this.disabled) {
            this.save();
          }
        },
        deep: true
      }
    },
    provide() {
      return {
        validation: this.$v
      };
    },
    methods: {
      loadList() {
        api.getInviteRights()
          .then(({data}) => {
            this.list = data;
            this.$notify({
              group: 'foo',
              clean: true
            })
            this.$nextTick(() => {
              this.disabled = false;
            });
          }, err => {
            if (err.response.status === 504) {
              this.$notify({
                group: 'foo',
                type: 'error',
                duration: -1,
                title: 'Ошибка',
                text: 'Сервер временно недоступен'
              })
            }
          })
      },
      save() {
        this.saving = true;
        api.saveInviteRights(this.list)
          .then(({data}) => {
            // console.log(data);
            this.$notify({
              group: 'foo',
              clean: true
            })
          }, err => {
            if (err.response.status === 504) {
              this.$notify({
                group: 'foo',
                type: 'error',
                duration: -1,
                title: 'Ошибка',
                text: 'Сервер временно недоступен'
              })
            }
          })
          .finally(() => {
            this.saving = false;
          })
      }
    },
    mounted() {
      this.loadList();
    }
  };
</script>

