<template lang="pug">
  .mt-5.mt-md-0
    .mb-2.f-18.text-gray-800.fw-500
      | Права пользователей
    c-table.mt-3(:data="list")
      c-column(prop="email", label="Пользователь")
      c-column(prop="rights", label="Назначение прав пользователей")
        template(slot-scope="scope")
          c-checkbox.d-inline-block.d-md-block(v-model="scope.row.rights.rights")
      c-column(prop="request", label="Создание новой заявки")
        template(slot-scope="scope")
          c-checkbox.d-inline-block.d-md-block(v-model="scope.row.rights.request")
      c-column(prop="requestDocs", label="Доступ к документам заявки")
        template(slot-scope="scope")
          c-checkbox.d-inline-block.d-md-block(v-model="scope.row.rights.requestDocs")
      c-column(prop="payers", label="Добавление плательщиков")
        template(slot-scope="scope")
          c-checkbox.d-inline-block.d-md-block(v-model="scope.row.rights.payers")
</template>

<script>
  export default {
    data: () => ({
      list: [
        {email: 'mail@mail.ru', rights: {rights: false, request: true, requestDocs: true, payers: true}},
        {email: 'mail@mail.ru', rights: {rights: false, request: true, requestDocs: true, payers: true}},
        {email: 'mail@mail.ru', rights: {rights: false, request: true, requestDocs: true, payers: true}},
        {email: 'mail@mail.ru', rights: {rights: false, request: true, requestDocs: true, payers: true}},
        {email: 'mail@mail.ru', rights: {rights: false, request: true, requestDocs: true, payers: true}},
        {email: 'mail@mail.ru', rights: {rights: false, request: true, requestDocs: true, payers: true}}
      ]
    }),
    provide() {
      return {
        validation: this.$v
      };
    },
  };
</script>

