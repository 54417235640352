import _ from 'lodash';
import Router from 'vue-router';

let loadPage = path => require(`./pages/${path}/page.vue`).default;

const PAGES = {
  container: loadPage('container'),
  mobile: {
    list: loadPage('mobile/list'),
    view: loadPage('mobile/view'),
    vacancy: loadPage('mobile/vacancy'),
    send: loadPage('mobile/send'),
  },
  desktop: {
    list: loadPage('desktop/list')
  }
};

const CONFIG = {
  mode: 'history',
  linkActiveClass: 'active',
  routes: [{
      path: '/vacancy',
      // name: 'vacancy',
      component: PAGES.container,
      children: [{
          path: '',
          name: 'desktop.list',
          component: PAGES.desktop.list,
          children:[{
            path: ':desk_id',
          }
          ]
        },
        {
          path: 'mobile',
          name: 'vacancy-mobile',
          component: PAGES.container,
          children: [{
              path: 'list',
              name: 'mobile.list',
              component: PAGES.mobile.list,
            },
            {
              path: 'vacancy',
              name: 'vacancy-category-container',
              component: PAGES.container,
              children: [{
                path: ':id',
                name: 'mobile.vacancy',
                component: PAGES.mobile.vacancy
              }]
            },
            {
              path: 'send',
              name: 'vacancy-send-container',
              component: PAGES.container,
              children: [{
                path: ':id',
                name: 'mobile.vacancy.send',
                component: PAGES.mobile.send
              }]
            },
            {
              path: ':category_id',
              name: 'mobile.view',
              component: PAGES.mobile.view
            }
          ]
        }
      ]
    },
    {
      path: '*',
      component: PAGES.empty
    }
  ]
};

let router = new Router(CONFIG);

export default router;