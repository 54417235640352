<template>
  <div>
    <h3>
      <span class="d-inline-block mb-3">
        Вакансии
      </span>
    </h3>
    <preloader over v-if="loading"></preloader>
    <div v-if="error" class="alert alert-danger">
      {{error}}
    </div>
    <nav class="nav-mobile" role="nav" v-if="!error && !loading">
      <div class="nav-mobile-title">
        Категории
      </div>
      <ul v-if="navs && navs.length">
        <li v-for="item in navs" :key="item.id">
          <a href="javascript:void(0)" class="nav-mobile-item" @click="$router.push({ name: 'mobile.view', params: { category_id: item.id } })">
            <div class="nav-mobile-item__icon">
              <i class="icon-arrow-chevron rotate-left"></i>
            </div>
            <div class="nav-mobile-item__text">{{item.name || 'Нет названия'}}</div>
            <div class="nav-mobile-item__subtext">{{item.countVacancy || 0}} {{(item.countVacancy || 0) | plural({
              base: 'ваканс',
              one: 'ия',
              few: 'ии',
              many: 'ий',
              other: 'ии'
            })}}</div>
          </a>
        </li>
      </ul>
      <div v-else class="alert alert-danger">
        Нет данных
      </div>
    </nav>
  </div>
</template>

<script>
  import Base from '../base'
  import api from '@/js/api'
  import { isEmpty } from 'lodash'

  export default {
    extends: Base,
    data: () => ({
      navs: [],
      loading: true,
      error: ''
    }),
    methods: {
      load() {
        this.loading = true
        this.error = ''
        api.vacancy.category.list().then(response => {
          if (isEmpty(response.data)) {
            this.error = 'Нет данных'
            return false
          }
          this.navs = response.data
        }, error => {
          // this.error = error
          this.error = 'Нет данных'
        }).finally(() => {
          this.loading = false
        })
      }
    },
    mounted() {
      this.load()
    }
  }
</script>