var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("settings-menu"),
      _c(
        "back",
        { staticClass: "mt-3 text-gray-300", attrs: { name: "payers-list" } },
        [_vm._v("Назад к списку")]
      ),
      _vm.loading
        ? _c("preloader", { staticClass: "mt-5" })
        : [
            _c("div", { staticClass: "f-18 fw-500 text-gray-800 mt-2" }, [
              _vm._v("Создание плательщика")
            ]),
            _c(
              "c-row",
              { staticClass: "mt-3" },
              [
                _c(
                  "c-col",
                  { staticClass: "col-12 col-md-6 col-lg-4" },
                  [
                    _c(
                      "c-subject",
                      {
                        attrs: { options: _vm.options },
                        model: {
                          value: _vm.agents[0],
                          callback: function($$v) {
                            _vm.$set(_vm.agents, 0, $$v)
                          },
                          expression: "agents[0]"
                        }
                      },
                      [
                        _c(
                          "template",
                          { slot: "before" },
                          [
                            _c("c-select", {
                              attrs: {
                                placeholder: "Город",
                                options: _vm.options.cities,
                                filter: _vm.filterCities,
                                getOptionLabel: _vm.getCityLabel,
                                accessor: "value.TownID"
                              },
                              model: {
                                value: _vm.agents[0].TownID,
                                callback: function($$v) {
                                  _vm.$set(_vm.agents[0], "TownID", $$v)
                                },
                                expression: "agents[0].TownID"
                              }
                            }),
                            _c("c-input", {
                              staticClass: "mt-2 mt-md-0",
                              attrs: {
                                placeholder: "Адрес",
                                accessor: "value.Address"
                              },
                              model: {
                                value: _vm.agents[0].Address,
                                callback: function($$v) {
                                  _vm.$set(_vm.agents[0], "Address", $$v)
                                },
                                expression: "agents[0].Address"
                              }
                            }),
                            _c("c-select", {
                              staticClass: "mt-2 mt-md-0 mb-2 mb-md-0",
                              attrs: {
                                placeholder: "Форма оплаты",
                                options: _vm.options.paymentTypes,
                                accessor: "value.type_pay"
                              },
                              model: {
                                value: _vm.agents[0].type_pay,
                                callback: function($$v) {
                                  _vm.$set(_vm.agents[0], "type_pay", $$v)
                                },
                                expression: "agents[0].type_pay"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "template",
                          { slot: "after" },
                          [
                            _c(
                              "c-checkbox",
                              {
                                staticClass: "mt-3",
                                model: {
                                  value: _vm.defaultPayer,
                                  callback: function($$v) {
                                    _vm.defaultPayer = $$v
                                  },
                                  expression: "defaultPayer"
                                }
                              },
                              [_vm._v("Основной плательщик")]
                            ),
                            _c("c-input", {
                              staticClass: "mt-3 mt-md-2",
                              attrs: {
                                placeholder: "E-mail для информирования",
                                accessor: "value.AddEmail"
                              },
                              model: {
                                value: _vm.agents[0].AddEmail,
                                callback: function($$v) {
                                  _vm.$set(_vm.agents[0], "AddEmail", $$v)
                                },
                                expression: "agents[0].AddEmail"
                              }
                            }),
                            _c("c-input", {
                              directives: [
                                {
                                  name: "mask",
                                  rawName: "v-mask",
                                  value: { mask: "+99999999999" },
                                  expression: "{mask: '+99999999999'}"
                                }
                              ],
                              staticClass: "mt-1 mt-md-0",
                              attrs: {
                                placeholder: "Телефон для информирования",
                                accessor: "value.AddPhone"
                              },
                              model: {
                                value: _vm.agents[0].AddPhone,
                                callback: function($$v) {
                                  _vm.$set(_vm.agents[0], "AddPhone", $$v)
                                },
                                expression: "agents[0].AddPhone"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      2
                    ),
                    _vm.error
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "alert alert-danger mb-0 f-12 d-block mt-3"
                          },
                          [_vm._v(_vm._s(_vm.error))]
                        )
                      : _vm._e(),
                    _c(
                      "c-button",
                      {
                        staticClass: "mt-4 w-100 w-md-auto",
                        attrs: {
                          disabled:
                            _vm.$v.agents.$each[0].$invalid || _vm.saving
                        },
                        on: { click: _vm.save }
                      },
                      [_vm._v("Сохранить")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }