<template>
  <v-easy-dialog backdrop-class="backdrop" v-model="isOpen">
    <div class="dialog">
      <div class="dialog-header">Копировать заявку №{{order.OrderShortNo}}</div>

      <div class="dialog-body">
        <c-checkbox v-model="copyConf.direction">Направление</c-checkbox>
        <c-checkbox v-model="copyConf.cargoParameters">Параметры груза</c-checkbox>
        <c-checkbox v-model="copyConf.services">Услуги</c-checkbox>
      </div>

      <div class="dialog-footer">
        <c-button class="white" @click="$emit('change', false)">
          Отмена
        </c-button>
        <c-button class="mt-3" @click="openCalc()" :disabled="disable">
          Копировать
        </c-button>
      </div>
    </div>
  </v-easy-dialog>
</template>

<script>
import VEasyDialog from 'v-easy-dialog'
import CButton from "@/js/core/components/c-button";

export default {
  components: {
    CButton,
    VEasyDialog
  },
  data:() => ({
    copyConf:{
      direction: true,
      cargoParameters: true,
      services: true
    }
  }),
  provide() {
    return {
      validation: this.$v
    };
  },
  props: {
    order: {
      type: Object,
      default: {}
    },
    open: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isOpen: {
      set: function (value) {
        this.$emit('change', value)
      },
      get: function () {
        return this.open
      }
    },
    disable: function () {
      return Object.values(this.copyConf).every(item => !item)
    },
  },
  watch: {
    open(value) {
      this.isOpen = value
    }
  },
  methods: {
    openCalc() {
      localStorage.setItem('copyObject', JSON.stringify({
        order: this.order,
        copyConf: this.copyConf
      }))
      window.open('/calculator',"_self");
    }
  }
}
</script>

<style lang="scss">
.dialog {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 16px;
  border-radius: 0.25rem;

  .dialog-header {
    font-size: 20px;
    font-weight: 500;
    border-bottom: 2px solid #f0f0f0;
  }

  .dialog-body {
    padding: 8px;
  }

  .dialog-footer {
    display: flex;
    justify-content: flex-end;
    align-items: end;
    gap: 8px;
    border-top: 2px solid #f0f0f0;
  }
}

.backdrop {
  & > button {
    background-color: transparent;
    background-image: none;
  }
}

</style>

