var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mb-5" },
    [
      _c("div", { staticClass: "f-20 fw-500 text-gray-800" }, [
        _vm._v("Заявки")
      ]),
      _c(
        "c-row",
        { staticClass: "mt-4" },
        [
          _c(
            "c-col",
            {
              staticClass: "col-12 col-md-8 col-lg-9",
              class: { "r-hide": _vm.isFiltersOpened }
            },
            [
              _c("paginate", {
                ref: "list",
                attrs: { load: _vm.load },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var item = ref.item
                      var index = ref.index
                      return _c("item-request", {
                        staticClass: "mb-3 hoverable",
                        attrs: { item: item },
                        nativeOn: {
                          click: function($event) {
                            return _vm.show(item)
                          }
                        }
                      })
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c("c-col", { staticClass: "col-4 col-lg-3 w-0" }, [
            _c(
              "div",
              { staticClass: "filter-button px-3 d-block d-md-none" },
              [
                _c(
                  "c-button",
                  { staticClass: "mt-3 w-100", on: { click: _vm.showFilters } },
                  [_vm._v("Фильтры")]
                )
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "filter-window border border-2 border-gray-200",
                class: { show: _vm.isFiltersOpened }
              },
              [
                _c(
                  "div",
                  { staticClass: "bg-gray-100 px-3 py-3" },
                  [
                    _c(
                      "c-row",
                      [
                        _c(
                          "c-col",
                          { staticClass: "f-16 fw-500 text-gray-800" },
                          [
                            _c("div", { staticClass: "h-100 fa" }, [
                              _vm._v("Фильтры")
                            ])
                          ]
                        ),
                        _c(
                          "c-col",
                          { staticClass: "col-auto f-12 text-gray-300" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "h-100 fa hoverable d-none d-md-flex",
                                on: { click: _vm.clearAll }
                              },
                              [_vm._v("Очистить все")]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "p-2 lh-0 d-block d-md-none",
                                on: {
                                  click: function($event) {
                                    return _vm.hideFilters()
                                  }
                                }
                              },
                              [_c("i", { staticClass: "icon-close" })]
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "p-3" },
                  [
                    _c("c-datepicker", {
                      attrs: { placeholder: "Период", range: true },
                      model: {
                        value: _vm.filters.period,
                        callback: function($$v) {
                          _vm.$set(_vm.filters, "period", $$v)
                        },
                        expression: "filters.period"
                      }
                    }),
                    _c("c-select-list", {
                      staticClass: "mt-2",
                      attrs: {
                        placeholder: "Статус заявки",
                        options: _vm.options.status
                      },
                      model: {
                        value: _vm.filters.CurrCargoState,
                        callback: function($$v) {
                          _vm.$set(_vm.filters, "CurrCargoState", $$v)
                        },
                        expression: "filters.CurrCargoState"
                      }
                    }),
                    _c("c-input", {
                      staticClass: "mt-2",
                      attrs: { placeholder: "Номер заявки" },
                      model: {
                        value: _vm.filters.OrderShortNo,
                        callback: function($$v) {
                          _vm.$set(_vm.filters, "OrderShortNo", $$v)
                        },
                        expression: "filters.OrderShortNo"
                      }
                    }),
                    _c("c-input", {
                      staticClass: "mt-2",
                      attrs: { placeholder: "Номер заказа" },
                      model: {
                        value: _vm.filters.ClientOrderNo,
                        callback: function($$v) {
                          _vm.$set(_vm.filters, "ClientOrderNo", $$v)
                        },
                        expression: "filters.ClientOrderNo"
                      }
                    }),
                    _c("c-input", {
                      staticClass: "mt-2",
                      attrs: { placeholder: "№ Сопроводительных док-ов" },
                      model: {
                        value: _vm.filters.ClientDocsNo,
                        callback: function($$v) {
                          _vm.$set(_vm.filters, "ClientDocsNo", $$v)
                        },
                        expression: "filters.ClientDocsNo"
                      }
                    }),
                    _c("c-select", {
                      staticClass: "mt-2",
                      attrs: {
                        placeholder: "Оплата",
                        options: _vm.options.payed
                      },
                      model: {
                        value: _vm.filters.PayResult,
                        callback: function($$v) {
                          _vm.$set(_vm.filters, "PayResult", $$v)
                        },
                        expression: "filters.PayResult"
                      }
                    }),
                    _c("c-select", {
                      staticClass: "mt-2",
                      attrs: {
                        placeholder: "Откуда",
                        options: _vm.options.cities,
                        filter: _vm.filterCities,
                        getOptionLabel: _vm.getOptionLabel,
                        clearable: true
                      },
                      model: {
                        value: _vm.filters.SenderTownName,
                        callback: function($$v) {
                          _vm.$set(_vm.filters, "SenderTownName", $$v)
                        },
                        expression: "filters.SenderTownName"
                      }
                    }),
                    _c("c-select", {
                      staticClass: "mt-2",
                      attrs: {
                        placeholder: "Куда",
                        options: _vm.options.cities,
                        filter: _vm.filterCities,
                        getOptionLabel: _vm.getOptionLabel,
                        clearable: true
                      },
                      model: {
                        value: _vm.filters.RecipTownName,
                        callback: function($$v) {
                          _vm.$set(_vm.filters, "RecipTownName", $$v)
                        },
                        expression: "filters.RecipTownName"
                      }
                    }),
                    _c("c-select", {
                      staticClass: "mt-2",
                      attrs: {
                        placeholder: "Отправитель",
                        options: _vm.options.sender,
                        clearable: true
                      },
                      model: {
                        value: _vm.filters.SenderName,
                        callback: function($$v) {
                          _vm.$set(_vm.filters, "SenderName", $$v)
                        },
                        expression: "filters.SenderName"
                      }
                    }),
                    _c("c-select", {
                      staticClass: "mt-2",
                      attrs: {
                        placeholder: "Получатель",
                        options: _vm.options.receiver,
                        clearable: true
                      },
                      model: {
                        value: _vm.filters.RecipName,
                        callback: function($$v) {
                          _vm.$set(_vm.filters, "RecipName", $$v)
                        },
                        expression: "filters.RecipName"
                      }
                    }),
                    _c("c-select", {
                      staticClass: "mt-2",
                      attrs: {
                        placeholder: "Плательщик",
                        options: _vm.options.payer,
                        clearable: true
                      },
                      model: {
                        value: _vm.filters.PayerName,
                        callback: function($$v) {
                          _vm.$set(_vm.filters, "PayerName", $$v)
                        },
                        expression: "filters.PayerName"
                      }
                    }),
                    _c(
                      "c-button",
                      {
                        staticClass: "mt-3 w-100",
                        on: { click: _vm.applyFilters }
                      },
                      [_vm._v("Применить фильтры")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-outline-primary f-14 fw-500 w-100 mt-3 py-2 d-block d-md-none",
                        on: { click: _vm.clearAll }
                      },
                      [_vm._v("Очистить")]
                    ),
                    _vm.$viewer.data.right_to_create_order
                      ? _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-outline-primary f-14 fw-500 w-100 mt-3 py-2",
                            on: {
                              click: function($event) {
                                return _vm.openCalc()
                              }
                            }
                          },
                          [_vm._v("Новая заявка")]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ]
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }