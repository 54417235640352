var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._m(0),
      _vm._v(" "),
      _vm.loading ? _c("preloader", { attrs: { over: "" } }) : _vm._e(),
      _vm._v(" "),
      _vm.error
        ? _c("div", { staticClass: "alert alert-danger" }, [
            _vm._v("\n    " + _vm._s(_vm.error) + "\n  ")
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.error && !_vm.loading && _vm.navs.length != 0
        ? _c("nav", { staticClass: "nav-mobile", attrs: { role: "nav" } }, [
            _c("div", { staticClass: "nav-mobile-title" }, [
              _vm._v("\n      Категории\n    ")
            ]),
            _vm._v(" "),
            _vm.navs && _vm.navs.length
              ? _c(
                  "ul",
                  _vm._l(_vm.navs, function(item) {
                    return _c("li", { key: item.id }, [
                      _c(
                        "a",
                        {
                          staticClass: "nav-mobile-item",
                          attrs: { href: "javascript:void(0)" },
                          on: {
                            click: function($event) {
                              return _vm.$router.push({
                                name: "mobile.view",
                                params: { category_id: item.id }
                              })
                            }
                          }
                        },
                        [
                          _vm._m(1, true),
                          _vm._v(" "),
                          _c("div", { staticClass: "nav-mobile-item__text" }, [
                            _vm._v(_vm._s(item.name || "Нет названия"))
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "nav-mobile-item__subtext" },
                            [
                              _vm._v(
                                _vm._s(item.countInstrs || 0) +
                                  " " +
                                  _vm._s(
                                    _vm._f("plural")(item.countInstrs || 0, {
                                      base: "Инструкци",
                                      one: "я",
                                      few: "и",
                                      many: "й",
                                      other: "й"
                                    })
                                  )
                              )
                            ]
                          )
                        ]
                      )
                    ])
                  }),
                  0
                )
              : _c("div", { staticClass: "alert alert-danger" }, [
                  _vm._v("\n      Нет данных\n    ")
                ])
          ])
        : _c(
            "c-row",
            [
              _c("c-col", { staticClass: "col-12" }, [
                _c("div", { staticClass: "instr-dummy" }, [
                  _c("i", { staticClass: "icon-archive instr-dummy-icon" }),
                  _vm._v(" "),
                  _c("p", { staticClass: "instr-dummy-title" }, [
                    _vm._v("Здесь еще нет инструкций")
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "instr-dummy-greytext" }, [
                    _vm._v("Идет разработка данного раздела."),
                    _c("br"),
                    _vm._v("\n          Пожалуйста, загляните позже.\n        ")
                  ])
                ])
              ])
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", [
      _c("span", { staticClass: "d-inline-block mb-3" }, [
        _vm._v("\n      Инструкции\n    ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "nav-mobile-item__icon" }, [
      _c("i", { staticClass: "icon-arrow-chevron rotate-left" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }