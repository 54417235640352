<template>
  <div class="col" :class="colClass">
    <slot></slot>
  </div>
</template>

<script>
  export default {
    props: ['cols'],
    computed: {
      colClass() {
        return this.cols ? 'col-' + this.cols : '';
      }
    }
  }
</script>