

import _ from 'lodash'

class Point {
  constructor(data = {}) {
    this._data = data
    this.sidebar = this.getInfo()
  }

  get FSID() {
    return this._data.FSID
  }

  get PossibleToSendTermo() {
    return this._data.PossibleToSendTermo
  }

  get FSTermo() {
    return this._data.FSTermo
  }

  get TownID() {
    return this._data.TownID
  }

  get isFilial() {
    return this._data.isFilial
  }

  get FSGeoLocation() {
    return _.isString(this._data.FSGeoLocation) ? _.map(this._data.FSGeoLocation.split(','), item => {
      return item.replace(/\s+/g, '')
    }) : []
  }

  get FSName() {
    return _.get(this._data, 'FSName', 'Нет названия')
  }

  get TownName() {
    return _.get(this._data, 'TownName', 'Нет названия')
  }

  get areaItems() {
    return (this._data.area_items && this._data.area_items.length) ? this._data.area_items.map(item => new PointFull(item)) : null
  }

  getInfo() {

    let res = []
    if (!this.areaItems) return res
    _.each(this.areaItems, (value, key) => {
      let obj = {
        data: value,
        active: false,
        tpl: {
          4: {
            icon: 'icon-thermometer',
            extra: value.FSTermo ? true : false,
            value: 'Возможна доставка грузов с терморежимом',
            empty: value.FSTermo ? false : true
          },
          5: {
            icon: 'icon-thermometer',
            extra: value.PossibleToSendTermo ? true : false,
            value: 'Возможна отправка грузов с терморежимом.',
            empty: value.PossibleToSendTermo ? false : true
          },
          6: {
            icon: 'icon-shield',
            extra: value.FSCustody ? true : false,
            value: 'Ответственное хранение',
            empty: value.FSCustody ? false : true
          },
          7: {
            icon: 'icon-blockchain',
            extra: value.FS3pl ? true : false,
            value: '3PL',
            empty: value.FS3pl ? false : true
          }
        }
      }

      if (value.FSAddress) {
        obj.tpl[1] = {
          icon: 'icon-placeholder-copy',
          extra: false,
          value: value.FSAddress
        }
      }

      if (value.FSPhone) {
        obj.tpl[2] = {
          icon: 'icon-call',
          extra: false,
          value: value.FSPhone
        }
      }

      if (value.schedule && value.schedule.length) {
        obj.tpl[3] = {
          icon: 'icon-calendar-light',
          extra: false,
          value: value.schedule
        }
      }

      res.push(obj)
    })
    return res
  }
}

class PointFull extends Point {
  constructor(data = {}) {
    super(data)
  }

  get schedule() {
    return [
      {
        name: 'Пн-Пт',
        value: _.get(this._data, 'WorkDaysSchedule', '–') || '–'
      },
      {
        name: 'Сб',
        value: _.get(this._data, 'SaturdaySchedule') || '–'
      },
      {
        name: 'Вс',
        value: _.get(this._data, 'SundaySchedule') || '–'
      }
    ]
  }

  get FS3pl() {
    return this._data.FS3pl
  }

  get FSAddress() {
    return this._data.FSAddress
  }

  get FSCustody() {
    return this._data.FSCustody
  }

  get FSPhone() {
    return _.isString(this._data.FSPhone) ? this._data.FSPhone.split(',') : '–'
  }

  get FSIsOffice() {
    return this._data.FSIsOffice
  }
}

export default Point
