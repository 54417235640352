var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "labeled",
    {
      staticClass: "c-input__cont",
      class: { "is-invalid": _vm.isInvalid, disabled: _vm.disabled },
      attrs: { label: _vm.label }
    },
    [
      _c(
        "div",
        { staticClass: "row mx-0", class: { "flex-nowrap": _vm.suffix } },
        [
          _c(
            "div",
            { staticClass: "col px-0", class: { "flex-shrink": _vm.suffix } },
            [
              _c("input", {
                staticClass: "c-input",
                class: _vm.subclass,
                attrs: {
                  type: _vm.inputType,
                  placeholder: _vm.placeholder,
                  disabled: _vm.disabled,
                  min: _vm.min,
                  max: _vm.max
                },
                domProps: { value: _vm.value },
                on: {
                  keydown: _vm.keydown,
                  input: _vm.input,
                  change: _vm.input
                }
              })
            ]
          ),
          _vm._v(" "),
          _vm.suffix
            ? _c(
                "div",
                { staticClass: "col col-auto px-0 va" },
                [
                  _vm._t("suffix", [
                    _c("span", {
                      staticClass:
                        "vam pr-3 c-input__suffix f-12 f-md-14 fw-md-500",
                      domProps: { innerHTML: _vm._s(_vm.suffix) }
                    })
                  ])
                ],
                2
              )
            : _vm._e()
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }