<template>
  <div>
    <h3>
      <span class="d-inline-block mb-3">
        Вакансии
      </span>
    </h3>
    <div v-if="error" class="alert alert-danger">
      {{error}}
    </div>
    <preloader over v-if="loading"></preloader>
    <template v-if="!error && !loading">
      <div class="nav-mobile-title mb-3" @click="$router.go(-1)">
        <div class="nav-mobile-title--sub">Категория</div>
        {{category.name || 'Не названия'}}
      </div>
      <template v-if="content">
        <div class="vacancy-card vacancy-card--mobile">
          <div class="vacancy-card-header">
            <div class="vacancy-card-header__name f-14 f-md-16 fw-500 mb-3">{{content.name}}</div>
            <ul class="vacancy-card-header__actions">
              <li v-if="content.city_name">
                <i class="icon-placeholder-copy"></i>
                {{content.city_name}}
              </li>
              <li v-if="content.schedule">
                <i class="icon-time-line"></i>
                {{content.schedule}}
              </li>
              <li v-if="content.wage">
                <i class="icon-credit-card-line"></i>
                {{content.wage}}
              </li>
            </ul>
          </div>
          <div class="vacancy-card-body">
            <template v-if="content.responsibility">
              <div class="fw-500 mb-2 mt-3">
                Обязанности:
              </div>
              <div class="vacancy-card-body__text" v-html="content.responsibility"></div>
            </template>
            <template v-if="content.requirements">
              <div class="fw-500 mb-2 mt-3">
                Требования:
              </div>
              <div class="vacancy-card-body__text" v-html="content.requirements"></div>
            </template>
            <template v-if="content.conditions">
              <div class="fw-500 mb-2 mt-3">
                Условия:
              </div>
              <div class="vacancy-card-body__text" v-html="content.conditions"></div>
            </template>
          </div>
          <div class="vacancy-card-footer mt-5">
            <c-button @click="$router.push({ name: 'mobile.vacancy.send', params: { id: content.id } })" class="w-100">Откликнуться</c-button>
          </div>
        </div>
      </template>
      <div v-else class="alert alert-danger">
        Нет данных
      </div>
    </template>
  </div>
</template>

<script>
  import Base from '../base'
  import api from '@/js/api'
  import parseContent from '@/js/core/parse-content-simple'

  export default {
    extends: Base,
    data: () => ({
      category: {},
      loading: false,
      error: '',
      content: null
    }),
    mounted() {
      this.loading = true
      api.vacancy.content.get(this.$route.params.id).then(content => {
        if (!content.data) throw 'Нет вакансий'
        content.data.conditions = parseContent(content.data.conditions)
        content.data.requirements = parseContent(content.data.requirements)
        content.data.responsibility = parseContent(content.data.responsibility)
        this.content = content.data

        api.vacancy.category.get(content.data.category_id).then(category => {
          this.category = category.data
        }, error => {
          // this.error = error
          this.error = 'Нет данных'
        }).finally(() => {
          this.loading = false
        });

      }).catch(error => {
        // this.error = error
        this.error = 'Нет данных'
        this.loading = false
      })
    }
  };
</script>