var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cont va", class: { "preloader-overlay": _vm.over } },
    [_vm._m(0)]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "sk-fading-circle vam" }, [
      _c("div", { staticClass: "sk-circle1 sk-circle" }),
      _vm._v(" "),
      _c("div", { staticClass: "sk-circle2 sk-circle" }),
      _vm._v(" "),
      _c("div", { staticClass: "sk-circle3 sk-circle" }),
      _vm._v(" "),
      _c("div", { staticClass: "sk-circle4 sk-circle" }),
      _vm._v(" "),
      _c("div", { staticClass: "sk-circle5 sk-circle" }),
      _vm._v(" "),
      _c("div", { staticClass: "sk-circle6 sk-circle" }),
      _vm._v(" "),
      _c("div", { staticClass: "sk-circle7 sk-circle" }),
      _vm._v(" "),
      _c("div", { staticClass: "sk-circle8 sk-circle" }),
      _vm._v(" "),
      _c("div", { staticClass: "sk-circle9 sk-circle" }),
      _vm._v(" "),
      _c("div", { staticClass: "sk-circle10 sk-circle" }),
      _vm._v(" "),
      _c("div", { staticClass: "sk-circle11 sk-circle" }),
      _vm._v(" "),
      _c("div", { staticClass: "sk-circle12 sk-circle" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }