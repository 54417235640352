var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "labeled",
    {
      staticClass: "c-input__cont",
      class: { "is-invalid": _vm.isInvalid, disabled: _vm.disabled },
      attrs: { label: _vm.label }
    },
    [
      _c("div", { staticClass: "row mx-0" }, [
        _c("div", { staticClass: "col px-0" }, [
          _c("textarea", {
            staticClass: "c-input",
            class: _vm.subclass,
            attrs: { placeholder: _vm.placeholder, disabled: _vm.disabled },
            domProps: { value: _vm.value },
            on: { input: _vm.input, change: _vm.input }
          })
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }