import _ from 'lodash';
import Router from 'vue-router';


const CONFIG = {
  mode: 'history',
  scrollBehavior: () => ({
    x: 0,
    y: 0
  }),
  linkActiveClass: 'active',
  routes: [{
    path: '/tracking/:order_id',
  }]
}

let router = new Router(CONFIG);

export default router;