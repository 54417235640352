<template lang="pug">
  div
    settings-menu
    back.mt-3.text-gray-300(name="payers-list")
      | Назад к списку
    preloader.mt-5(v-if="loading")
    template(v-else)
      .f-18.fw-500.text-gray-800.mt-2
        | Редактирование плательщика
      c-row
        c-col.col-12.col-md-6.col-lg-4
          .mt-4.mb-2.f-16.text-gray-800.fw-500
            | Общая информация
          .alert.alert-danger.mb-0.f-12.d-block.mt-3(v-if="error")
            | {{error}}
          c-input.mt-1(placeholder="Наименование плательщика", v-model="payer.Name", :disabled="true")
          c-input.mt-1(placeholder="Город", v-model="payer.TownName", :disabled="true")
          c-input.mt-1(placeholder="Контактное лицо плательщика", v-model="payer.Contact", :disabled="true")
          //- c-row.mx-n1(v-if="payer.agent_extra_phone")
          //-   c-col.col-12.col-md-6.px-1.mt-2.mt-md-0
          //-     c-input.mt-1(placeholder="Телефон", v-model="payer.Phone", :disabled="true")
          //-   c-col.col-12.col-md-6.px-1.mt-2.mt-md-0
          //-     c-input.mt-1(placeholder="Добавочный", v-model="value.agent_extra_phone", v-mask="{mask: '9999'}", disabled)
          c-input.mt-1(placeholder="Телефон", v-model="payer.Phone", :disabled="true")
          c-input.mt-1(placeholder="Адрес", v-model="payer.Address", :disabled="true")
          c-input.mt-1(placeholder="E-mail", v-model="payer.Email", :disabled="true")
          .mt-4.mb-2.f-16.text-gray-800.fw-500
            | Собственность
          c-input.mt-1(placeholder="Форма собственности", v-model="payer.AgentForm", :disabled="true")
          c-input.mt-1(placeholder="Форма оплаты", v-model="payer.PayForm", :disabled="true")
          .mt-4.mb-2.f-16.text-gray-800.fw-500
            | Настройка информирования
          c-input.mt-1(placeholder="Телефон для информирования", v-model="payer.AddPhone", v-mask="{mask: '+99999999999'}")
          .shadow-box.mt-3
            c-checkbox(v-model="payer.AddPhoneActive")
              | Включить уведомления
          c-input.mt-3.mt-md-2(placeholder="E-mail для информирования", v-model="payer.AddEmail")
          .shadow-box.mt-3
            c-checkbox(v-model="payer.AddEmailActive")
              | Включить уведомления
          c-button.mt-4.w-100.w-md-auto(@click="save", :disabled="$v.payer.$invalid || saving")
            | Сохранить
</template>

<script>
  import api from '@/js/api';
  import {required, email} from 'vuelidate/lib/validators';

  const phoneRegExp = /^[+]?\d{11,12}$/;
  const phoneCheck = value => phoneRegExp.test(('' + value || '').replace(/[\!\-\(\)\{\} ]/g, ''));

  export default {
    data: () => ({
      loading: true,
      payer: {},
      error: false,
      saving: false,
      defaultPayer: false,
      options: {
        ownershipTypes: [
          {
            value: 1,
            title: 'Физическое лицо'
          },
          {
            value: 2,
            title: 'Индивидуальный предприниматель'
          },
          {
            value: 3,
            title: 'Юридическое лицо'
          }
        ],
      }
    }),
    provide() {
      return {
        validation: this.$v
      };
    },
    validations() {
      return {
        payer: {
          AddEmail: {check: (value, model) => model.AddEmailActive ? email(value) : true},
          AddPhone: {check: (value, model) => model.AddPhoneActive ? phoneCheck(value) : true},
        }
      }
    },
    methods: {
      save() {
        if (!this.saving) {
          this.saving = true;
          let data = _.pick(this.payer, ['AgentID', 'AddEmail', 'AddPhone', 'AddEmailActive', 'AddPhoneActive']);
          api.updatePayerContacts(data)
            .then(({data: {status, error}}) => {
              if (status == 200 && !error) {
                if (this.payer.DefaultPayer) api.setDefaultPayer(this.$route.params.id).then(() => {
                  this.$router.push({name: 'payers-list'});
                })
              }
              if (error) {
                this.error = error;
              } else {
                this.error = null;
              }
            })
            .finally(() => {
              this.saving = false;
            })
        }
      }
    },
    mounted() {
      api.getPayer(this.$route.params.id)
        .then(({data}) => {
          this.payer = data;
          if (data.DefaultPayer) this.defaultPayer = true
        })
        .finally(() => {
          this.loading = false;
        });
    }
  };
</script>

