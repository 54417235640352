<template>
  <div>
    <a href="#" v-if="page !== 1" class="btn-back mb-2" @click="setPage(1)"><i class="icon-arrow-simple rotate-down"></i> Назад к списку</a>
    <h3>
      <span v-if="page === 2" class="d-inline-block mb-3">
        Отклик на вакансию <span v-if="currentModelPage.name">«{{currentModelPage.name}}»</span>
      </span>
      <span v-else class="d-inline-block mb-3">
        Вакансии
      </span>
    </h3>
    <div class="mt-3"></div>
    <div v-if="success" class="alert alert-success">
      {{success}}
    </div>
    <div v-if="error" class="alert alert-danger">
      {{error}}
    </div>
    <preloader over v-if="loading"></preloader>
    <template v-if="page === 1 && !error">
      <c-row v-if="!loading">
        <c-col class="col-12 col-lg-3 col-md-4">
          <nav-sidebar v-if="navs && navs.length" :value="currentCategoryId" :items="navs" @nav-change="getContent"/>
          <div v-else class="alert alert-danger">
            Нет данных
          </div>
        </c-col>
        <c-col class="col-12 col-lg-9 col-md-8">
          <preloader v-if="loadingContent"></preloader>
          <template v-else>
            <template v-if="content && content.length">
              <div class="vacancy-card" v-for="item in content" :key="item.id">
                <div class="vacancy-card-header">
                  <div class="f-18 f-md-16 fw-500 mb-3">{{item.name}}</div>
                  <ul class="vacancy-card-header__actions mb-2">
                    <li class="mb-2" v-if="item.city_name">
                      <i class="icon-placeholder-copy"></i>
                      {{item.city_name}}
                    </li>
                    <li class="mb-2">
                      <i class="icon-time-line"></i>
                      {{item.schedule}}
                    </li>
                    <li class="mb-2">
                      <i class="icon-credit-card-line"></i>
                      {{item.wage}}
                    </li>
                  </ul>
                </div>
                <div class="vacancy-card-body">
                  <ul>
                    <li>
                      <div class="fw-500 mb-2 mt-3">
                        Обязанности:
                      </div>
                      <c-row>
                        <c-col class="col-12 col-lg-10">
                          <div class="vacancy-card-body__text" v-html="item.responsibility"></div>
                        </c-col>
                      </c-row>
                    </li>
                    <li>
                      <div class="fw-500 mb-2 mt-3">
                        Требования:
                      </div>
                      <c-row>
                        <c-col class="col-12 col-lg-10">
                          <div class="vacancy-card-body__text" v-html="item.requirements"></div>
                        </c-col>
                      </c-row>
                    </li>
                    <li>
                      <div class="fw-500 mb-2 mt-3">
                        Условия:
                      </div>
                      <c-row>
                        <c-col class="col-12 col-lg-10">
                          <div class="vacancy-card-body__text" v-html="item.conditions"></div>
                        </c-col>
                      </c-row>
                    </li>
                  </ul>
                </div>
                <div class="vacancy-card-footer mt-4">
                  <c-button @click="bSend(item)">Откликнуться</c-button>
                </div>
              </div>
            </template>
            <div v-else class="alert alert-danger">
              Нет данных
            </div>
          </template>
        </c-col>
      </c-row>
    </template>
    <template v-if="page === 2">
      <send-form
        ref="sendForm"
        :currentModel="currentModelPage"
        @success="successEvent"
        @error="errorEvent"
        @loading="loadingEvent"
      />
    </template>
  </div>
</template>

<script>
import api from '@/js/api'
import _ from 'lodash'
import isMobile from '@/js/core/is-mobile'
import parseContent from '@/js/core/parse-content-simple'
import SendForm from '../../../components/SendForm.vue'

export default {
  components: {
    SendForm
  },
  data() {
    return {
      api,
      page: 1,
      currentModelPage: {},
      error: '',
      loading: true,
      loadingContent: false,
      navs: [],
      content: [],
      success: '',
      currentCategoryId: null
    }
  },
  methods: {
    successEvent(event) {
      this.success = event
      this.error = ''
    },
    errorEvent(event) {
      this.success = ''
      this.error = event
    },
    loadingEvent(event) {
      this.success = ''
      this.error = ''
      this.loading = event
    },
    setPage(page) {
      if (page === 1) {
        this.currentModelPage = {}
        this.$refs.sendForm.clear()
      }
      this.page = page
    },
    bSend(item) {
      this.setPage(2)
      window.scrollTo(0, 0);
      this.currentModelPage = item
    },
    getContent(id) {
      this.$router.push(`/vacancy/${id}`)
      this.error = ''
      this.loadingContent = true
      api.vacancy.content.list(id).then(response => {
        this.content = response.data.map(item => {
          item.conditions = parseContent(item.conditions)
          item.requirements = parseContent(item.requirements)
          item.responsibility = parseContent(item.responsibility)
          return item
        })
      }, error => {
        // this.error = error
        this.error = 'Нет данных'
      }).finally(() => {
        this.loadingContent = false
      })
    },
    getMenu() {
      this.loading = true
      this.error = ''
      return api.vacancy.category.list().then(response => {
        if (_.isEmpty(response.data)) {
          this.error = 'Нет данных'
          return false
        }

        this.navs = response.data
        return this.navs
      }, error => {
        // this.error = error
        this.error = 'Нет данных'
        return this.error
      }).finally(() => {
        this.loading = false
      })
    }
  },
  mounted() {
    // window.addEventListener('resize', (event) => {
    //   if (isMobile()) this.$router.push({ name: 'mobile.list' })
    // })

    if (isMobile()) {
      this.$router.push({ name: 'mobile.list' })
      return false
    }
    this.getMenu().then(res => {
      if (!_.isEmpty(res)) {
        if (this.$route.params.desk_id) {
          this.currentCategoryId = this.$route.params.desk_id
          this.getContent(this.currentCategoryId)
        } else {
          let id = res[0].id;
          this.currentCategoryId = id
          this.getContent(id)
        }
      }
    })
  }
}
</script>
