<template>
  <div>
    <h3>
      <span class="d-inline-block mb-3">
        Вакансии
      </span>
      <div class="f-16 mb-1">
        Отклик на вакансию
      </div>
      <div class="f-14 fw-400 text-gray-400 mb-3" v-if="content.name">«{{content.name}}»</div>
    </h3>
    <div v-if="error" class="alert alert-danger">
      {{error}}
    </div>
    <div v-if="success" class="alert alert-success">
      {{success}}
    </div>
    <preloader over v-if="loading"></preloader>
    <send-form
      :currentModel="content"
      @success="successEvent"
      @error="errorEvent"
      @loading="loadingEvent"
    ></send-form>
  </div>
</template>

<script>
  import Base from '../base'
  import api from '@/js/api'
  import SendForm from '../../../components/SendForm.vue'
  import parseContent from '@/js/core/parse-content-simple'

  export default {
    extends: Base,
    components: {
      SendForm
    },
    data: () => ({
      content: {},
      error: '',
      success: '',
      loading: false
    }),
    methods: {
      successEvent(event) {
        this.success = event
        this.error = ''
      },
      errorEvent(event) {
        this.success = ''
        this.error = event
      },
      loadingEvent(event) {
        this.success = ''
        this.error = ''
        this.loading = event
      }
    },
    mounted() {
      this.loading = true
      api.vacancy.content.get(this.$route.params.id).then(content => {
        if (!content.data) throw 'Нет вакансий'
        content.data.conditions = parseContent(content.data.conditions)
        content.data.requirements = parseContent(content.data.requirements)
        content.data.responsibility = parseContent(content.data.responsibility)
        this.content = content.data

      }).catch(error => {
        // this.error = error
        this.error = 'Нет данных'
      }).finally(() => {
        this.loading = false
      });
    }
  };
</script>