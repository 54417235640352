import _ from 'lodash';

let getParseableKeys = field => _.isPlainObject(field) && _.keys(field).filter(key => key[0] != '$');

let isObjectField = field => {
  if (_.isPlainObject(field)) {
    let keys = getParseableKeys(field);
    return _.reduce(keys, (s, key) => s || isValueField(field[key]), false);
  }
  return false;
};

let isArrayField = field => {
  if (_.isPlainObject(field) && field.$each) {
    return true;
  }
  return false;
};

let isValueField = field => {
  if (_.isPlainObject(field)) {
    let keys = getParseableKeys(field);
    if (keys.length) {
      return _.reduce(keys, (s, key) => s && _.isBoolean(field[key]), true);
    }
  }
  return false;
};

let parseValidation = (model, validation, path = []) => {
  let result = {};
  let keys = getParseableKeys(validation);
  for (let key of keys) {
    // console.log(key);
    let field = validation[key];
    let params = _.get(field, '$params', {});
    let skip = _.reduce(params, (s, field) => s || _.get(field, 'skip.value'), false);
    if (skip) continue;
    if (isObjectField(field)) {
      // console.log('object', key);
      let value = parseValidation(model, field, _.concat(path, [key]));
      _.set(result, key, value);
    }
    if (isArrayField(field)) {
      // console.log('array', key);
      // console.log(field);
      let keys = getParseableKeys(field.$each).filter(key => key == parseInt(key));
      for (let fkey in keys) {
        let value = parseValidation(model, field.$each[fkey], _.concat(path, [key, fkey]));
        // console.log(value);
        _.set(result, key + `[${fkey}]`, value);
      }
    }
    if (isValueField(field)) {
      // console.log('value', key, _.get(model, _.concat(path, [key]).join('.')));
      _.set(result, key, _.get(model, _.concat(path, [key]).join('.')));
    }
  }
  return result;
};

export default parseValidation;