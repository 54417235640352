<script>
import api from '@/js/api.js';
import _ from 'lodash';
import isMobile from '@/js/core/is-mobile'

const MAP_COORDS_DEFAULT = [55.76, 37.64] // Москва
const MAP_ZOOM_DEFAULT = 15
const ERROR = {
  NOT_API_MAPS: 'API яндекс карт не загружено',
  ID_NO_FOUND: 'id не указан'
}
const DEFAULT_CITY_ID = -1

export default {
  data() {
    return {
      DEFAULT_CITY_ID,
      lodash: _,
      loading: true,
      error: '',
      options: {
        cities: null,
        citiesAll: null,
        towns: null
      },
      model: {
        city_id: null
      },
      isMobile: false,
      map: null,
      placemarkMapClick: true, // признак точки, кликается или нет
      FSGeoLocationCurrent: null, // тукущая точка, надо для переходов
      info: null // модель просмотра точки
    }
  },
  provide() {
    return {
      validation: this.$v
    }
  },
  computed: {
    /**
     * Заголовок города для мобилки
     */
    title() {
      return (this.$route.params.id && this.info && this.info.TownName && this.isMobile) ? this.info.TownName : 'Контакты сети доставки'
    }
  },
  methods: {
    /**
     * Событие поиска для мобилки
     */
    inputSearch(value) {
      // if (!this.isMobile) return false
      if (value == DEFAULT_CITY_ID) {
        this.$router.push({ name: 'map' })
      } else {
        this.$router.push({ name: 'mapView', params: { id: value } })
      }
    },
    /**
     * Парсит города
     */
    parseCity(city) {
      return city ? {
        value: city.town_id,
        title: city.town_name,
        subtitle: city.area_name
      } : city;
    },

    /**
    * Отобразить название в форме поиска
    */
    getCityLabel(option) {
      return _.get(this.options.towns[option], 'title');
    },

    /**
    * Фильтр городов
    */
    filterCities(options, search) {
      return search ? _.filter(this.options.citiesAll, town => (town.title || '').toLowerCase().indexOf(search.toLowerCase()) == 0) : options;
    },

    /**
    * Валидатор формы
    */
    validations() {
      return {}
    },

    /**
    * Установить активный, только в списке, без перехода к точке на карте (требования такие)
    */
    setActiveItem(item) {
      if (item) item.active = true
    },

    /**
    * Добавление коллекции меток на карту
    */
    addCollectionMap() {
      let сollection = new ymaps.GeoObjectCollection({}, {
        preset: 'islands#redIcon'
      })
      _.each(this.options.map, (value, key) => {
        if (!_.isEmpty(value.FSGeoLocation)) {
          сollection.add(this.placemarkMap({
            title: value.FSName,
            coord: value.FSGeoLocation,
            city_id: value.TownID,
            fs_id: value.FSID
          }))
        }
      })

      сollection.events.add('click', (e) => {
        let id = e.get('target').properties.get('city_id')

        if (this.placemarkMapClick) {
          this.$router.push({ name: 'mapView', params: { id: id } })
        } else {
          let fs_id = e.get('target').properties.get('fs_id')
          let find = _.find(this.info.areaItems, item => {
            return item.TownID == id && item.FSID == fs_id
          })
          if (find) this.goToPlacemark(find)
        }
      })
      this.map.geoObjects.add(сollection)
      this.map.setBounds(сollection.getBounds(), { checkZoomRange: true });
    },

    /**
    * Создать метку на карте
    */
    placemarkMap(options = {}) {
      options = _.defaults(options, {
        title: 'Нет названия',
        coord: [],
        city_id: null,
        fs_id: null
      })
      return new ymaps.Placemark(options.coord, {
          hintContent: options.title,
          city_id: options.city_id,
          fs_id: options.fs_id
      })
    },

    /**
    * Инициализация карты
    */
    initMap() {
      if (this.map) this.map.destroy()
      this.map = new ymaps.Map('js-map', {
        center: MAP_COORDS_DEFAULT,
        zoom: 10,
        controls: ['zoomControl']
      }, {
          searchControlProvider: 'yandex#search'
      })
      this.addCollectionMap()
    },

    /**
    * Перейти к метке на карте
    */
    goToPlacemark(item, options = {}) {
      options = _.defaults(options, {
        zoom: true,
        isMobile: false
      })
      if (_.isEmpty(item.FSGeoLocation)) return false

      if (options.isMobile) document.documentElement.scrollTop = 0;

      this.map.panTo([this.FSGeoLocationCurrent || MAP_COORDS_DEFAULT, item.FSGeoLocation], {
          delay: 0,
          flying: true
      }).then(() => {
        this.map.setZoom(MAP_ZOOM_DEFAULT)
        this.info.sidebar.forEach(el => el.active = el.data.FSID === item.FSID ? true : false)
        this.FSGeoLocationCurrent = item.FSGeoLocation
      })
    },

    setTownOptions(towns) {
      this.options.citiesAll = _.map(towns, this.parseCity)
      this.options.citiesAll.unshift(this.parseCity({
        area_name: '',
        is_filial: false,
        town_id: DEFAULT_CITY_ID,
        town_name: 'Выбрать нужный город'
      }))
      this.options.towns = _.keyBy(this.options.citiesAll, 'value');
      this.options.cities = _.take(this.options.citiesAll, 1000)
    },

    /**
    * Загрузить все геоточки
    */
    loadAll() {
      this.loading = true
      this.error = ''
      this.model.city_id = DEFAULT_CITY_ID

      Promise.all([
        this.$store.dispatch('towns'),
        api.getMap()
      ]).then(([towns, map]) => {
        this.setTownOptions(towns)
        this.options.map = map
        this.placemarkMapClick = true
        this.loading = false
        ymaps.ready(() => {
          this.initMap()
        }, () => {
          this.error = ERROR.NOT_API_MAPS
        })
      }).catch(e => {
        this.loading = false
        this.error = e
      })
    },

    /**
    * Загрузить геоточки по id города/филиала
    */
    loadById(id) {
      if (!id) this.error = ERROR.ID_NO_FOUND
      this.loading = true
      this.model.city_id = id

      Promise.all([
        this.$store.dispatch('towns'),
        api.getMapById(id)
      ]).then(([towns, map]) => {
        this.setTownOptions(towns)
        this.info = map
        this.model.city_id = this.info.TownID
        // if (!map.areaItems) {
        //   if (this.map) this.map.destroy()
        //   this.error = 'Нет геоданных города'
        //   return false
        // }
        // setCoordinates
        this.error = ''
        this.options.map = map.areaItems || MAP_COORDS_DEFAULT
        this.placemarkMapClick = false
        ymaps.ready(() => {
          this.initMap()
          if (this.isMobile && this.info.sidebar[0]) this.setActiveItem(this.info.sidebar[0])
        }, () => {
          this.error = ERROR.NOT_API_MAPS
        })
      }).finally(() => {
        this.loading = false
      })
    }
  },
  mounted() {
    const scriptYmaps = document.createElement('script')
    scriptYmaps.src = 'https://api-maps.yandex.ru/2.1/?apikey=dff215b5-0eed-4dc9-9ccb-89a1979de6e3&lang=ru_RU'
    document.body.appendChild(scriptYmaps)
    scriptYmaps.onload = () => {
      let id = _.get(this.$route, 'params.id')
      if (!id) {
        this.loadAll()
      } else {
        this.loadById(id)
      }
    }

    this.isMobile = isMobile()
    window.addEventListener('resize', (event) => {
      this.isMobile = isMobile()
    })
  }
}
</script>
